import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import totumRiskLogoScore from '../../../assets/img/Totum Logo_Dark_X2.png'
import TotumRiskSlider from '../component/totumRiskSlider'
import FilledButton from '../component/filledButton'
import NoFilledButton from '../component/noFilledButton'

import { StyledFormWrapper } from '../styles'

@inject(({ store: { totumRisk, navigate } }) => {
  return {
    portfolioScore: totumRisk.portfolioScore,
    setSelectedTab: totumRisk.setSelectedTab,
    capacity: totumRisk.capacity,
    preference: totumRisk.preference,
    navigate: navigate
  }
})
@observer
class Score extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: [],
    }
  }
  componentDidMount() {
    const { strategy, formErrors } = this.props
    this.setState({ strategy, formErrors })
  }

  done() {
    const { navigate } = this.props
    navigate('/smartfunds')    
  }

  render() {
    const { setSelectedTab, capacity, preference } = this.props
    const { score : capacityScore } = capacity 
    const { score : preferenceScore } = preference
    return (
      <StyledFormWrapper>
        <Box component="section" className="questionnaire-info">
          <Grid container spacing={10}>
            <Grid item>
              <Typography className="page-header">Risk Score Questionnaire</Typography>
            </Grid>
            {/* <Grid item className="totum-info-container">
              <Grid container spacing={1} direction="column">
                <Grid item className="totum-logo-label">
                  {' '}
                  Powered By
                </Grid>
                <Grid item>
                  <img className="totum-logo" src={totumRiskLogo} alt="TotumRisk Logo" />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Typography className="step-title">Score</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Card>
                <CardContent className="info-card">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="card-header-info">
                        Your Totum Risk Band is the area between your Totum Risk Preference score and your Totum Risk Capacity score. Any Smart Fund that falls between your 
                        risk Preference and risk Capacity score is considered to be suitable. 
                      </Typography>
                    </Grid>
                    <Grid item className="totum-slider-container">
                      <TotumRiskSlider />
                    </Grid>
                    <Grid item>
                     <Typography className="card-footer-info">
                        Totum Risk tolorence scale is 1-99 with a score being 1 extremely risk-adverse and a score of 99 would indicate you are an aggressive risk-taker.
                        Your Totum Risk Preference score is {preferenceScore || 0}, which represents the perceived amount of risk you are willing to take at this time. However, your Totum Risk Capacity
                        score is {capacityScore || 0}, which means you are actually able to take on additional risk given your current life situation. For more information visit Totum Risk at www.totumrisk.com.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={7}>
                    <Grid item className="step-three-button-spacer">
                      <NoFilledButton title={`Back`} action={setSelectedTab.bind(this, -1)} />
                    </Grid>
                    <Grid item className="step-three-button-spacer">
                      <FilledButton title={`Done`} action={this.done.bind(this)} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
            <Card>
                <CardContent className="score-card">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="risk-score-message">
                      Powered By
                      </Typography>
                    </Grid>
                    <Grid item style={{ 'marginLeft': '55px' }}>
                    <img  src={totumRiskLogoScore} alt="TotumRisk Logo" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

            </Grid>
          </Grid>
        </Box>
      </StyledFormWrapper>
    )
  }
}

export default Score
