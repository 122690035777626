import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Details from './Details'
import Clients from './Clients'
import Metrics from './Metrics'
import Models from './Models'
import Access from './Access'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
// import LoadingDateTooltip from '../../../common/components/LoadingDateTooltip'
import Typography from '@material-ui/core/Typography'
import { StyledFundDetails } from './styles'
import FundSideNav from './FundSideNav'
import AddedNewClientsModal from './AddedNewClientsModal'
import { Confirm } from '../../../common/components/Dialogs'
import ActionButton from '../../../common/components/ActionButton'
import currencyFilter from '../../../lib/currency'
import Grid from '@material-ui/core/Grid'
import op from 'simple-object-path'

@inject(({ store: { navigate, user, fund, auth, model } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getFundDetails: fund.getFundDetails,
    saveAndExit: fund.saveAndExit,
    publish: fund.publish,
    unpublish: fund.unpublish,
    addToMarketplace: fund.addToMarketplace,
    removeFromMarketplace: fund.removeFromMarketplace,
    setCurrentTab: fund.setCurrentTab,
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
    currentTab: fund.currentTab,
    isRetail: user.isRetail,
    currentUser: user.currentUser,
    profile: auth.profile,
    userProfile: op(auth, 'profile/user/userProfile'),
    setNavigationTabs: user.headerStore.setNavigationTabs,
    getModelCostRequirements: model.getModelCostRequirements,
    requiredTotalAllocations: model.requiredTotalAllocations,
    resetRequiredTotalAllocations: model.resetRequiredTotalAllocations,
    toggleAddedNewClientsModal: user.clientStore.toggleAddedNewClientsModal,
    showAddedNewClientsModal: user.clientStore.showAddedNewClientsModal,
    existingClients: fund.existingClients,
    currentFundPerformance: user.metricStore.currentFundPerformance,
    retailFundExit: fund.retailFundExit,
    setFundMetricsByUserId: user.metricStore.setFundMetricsByUserId,
  }
})
@observer
class FundDetails extends Component {
  state = {
    isLoading: true,
    showPublishConfirm: false,
    showUnpublishConfirm: false,
  }

  async componentDidMount() {
    const {
      profile,
      getFundDetails,
      isAuthenticated,
      currentFund,
      getModelCostRequirements,
      setCurrentFund,
      setFundMetricsByUserId,
      currentUser = {},
    } = this.props
    await isAuthenticated()
    window.document.title = 'Bob | Fund Detail'
    const fundId = window.location.pathname.split('/')[2]
    await getFundDetails(fundId)
    const { clients, strategies, buyIn } = currentFund
    if (strategies.length > 0) {
      const strategyIds = strategies.map(s => s._id)
      const requiredTotalAllocations = await getModelCostRequirements(strategyIds)
      currentFund.buyIn = requiredTotalAllocations || buyIn
      await setCurrentFund(currentFund)
    }

    if (currentUser.userType === 'retail') {
      const userId = op(profile, 'user/_id')
      await setFundMetricsByUserId(userId, fundId)
    }

    this.setState({
      isLoading: false,
      clients,
    })
  }

  closeFundDetails = () => {
    const { navigate } = this.props
    navigate('/smartfunds')
  }

  handleChangeTab = async val => {
    const { setCurrentTab } = this.props
    await setCurrentTab(val)
  }

  updateFund = async () => {
    let {
      toggleAddedNewClientsModal,
      existingClients,
      currentFund: { clients },
    } = this.props
    if (existingClients.length < clients.length) {
      toggleAddedNewClientsModal()
    } else {
      await this.close()
    }
  }

  close = async () => {
    let {
      saveAndExit,
      resetRequiredTotalAllocations,
      showAddedNewClientsModal,
      toggleAddedNewClientsModal,
    } = this.props
    if (showAddedNewClientsModal) toggleAddedNewClientsModal()
    await saveAndExit()
    resetRequiredTotalAllocations()
    this.closeFundDetails()
  }

  publishFund = async () => {
    const { publish } = this.props
    await publish()
    this.setState({ showPublishConfirm: false })
  }

  unpublishFund = async () => {
    const { unpublish } = this.props
    await unpublish()
    this.setState({ showUnpublishConfirm: false })
  }

  confirmPublish() {
    const message = `Are you sure you want to publish this fund?`
    this.setState({
      showPublishConfirm: true,
      confirming: {},
      confirmingPublishMessage: message,
    })
  }

  confirmUnpublish() {
    const message = `Are you sure you want to unpublish this fund?`
    this.setState({
      showUnpublishConfirm: true,
      confirming: {},
      confirmingUnpublishMessage: message,
    })
  }

  removeFundFromMarketplace = async () => {
    const { removeFromMarketplace } = this.props
    await removeFromMarketplace()
    this.setState({ showRemoveFromMarketplaceConfirm: false })
  }

  addFundToMarketplace = async () => {
    const { addToMarketplace } = this.props
    await addToMarketplace()
    this.setState({ showAddToMarketplaceConfirm: false })
  }

  confirmAddToMarketplace() {
    const message = `Are you sure you want to add this fund to the Bob Marketplace?`
    this.setState({
      showAddToMarketplaceConfirm: true,
      confirming: {},
      confirmingPublishMessage: message,
    })
  }

  confirmRemoveFromMarketplace() {
    const message = `Are you sure you want to remove this fund from the Bob Marketplace?`
    this.setState({
      showRemoveFromMarketplaceConfirm: true,
      confirming: {},
      confirmingUnpublishMessage: message,
    })
  }

  async leaveFund() {
    const { currentFund: fund, retailFundExit } = this.props
    console.log('fund', fund)
    this.setState({ showLeaveFundConfirm: false })
    await retailFundExit(fund._id)
    this.setState({ showLeaveComplete: true })
  }
  //TO DO: ADD LOGIC FOR DELETING FUNDS
  deleteFund() {}

  getMenuItems() {
    const { currentFund: fund, userProfile = [] } = this.props

    const publishItem = {
      name: !fund.published ? 'PUBLISH' : 'UNPUBLISH',
      action: !fund.published ? this.confirmPublish.bind(this) : this.confirmUnpublish.bind(this),
    }
    const makePublic = {
      name: fund.public ? 'REMOVE FROM BOB' : 'ADD TO BOB',
      action: fund.public
        ? this.confirmRemoveFromMarketplace.bind(this)
        : this.confirmAddToMarketplace.bind(this),
    }
    const menuItems = [
      { name: 'SAVE & EXIT', action: this.updateFund.bind(this) },
      { name: 'DELETE', action: this.deleteFund.bind(this) },
    ]
    if (userProfile.find(role => role.roleCode === 'smartFundAdmin')) {
      menuItems.unshift(makePublic)
    } else {
      menuItems.unshift(publishItem)
    }
    if (fund.published) return menuItems.slice(0, 2)

    return menuItems
  }

  async leaveRefreshData() {
    const { currentFund, getFundDetails } = this.props
    await getFundDetails(currentFund._id)
    this.setState({ showLeaveComplete: false })
  }

  render() {
    const {
      currentFund: fundData,
      currentTab,
      showAddedNewClientsModal,
      toggleAddedNewClientsModal,
      currentFundPerformance,
    } = this.props
    const {
      isLoading,
      clients,
      showPublishConfirm,
      showUnpublishConfirm,
      showAddToMarketplaceConfirm,
      showRemoveFromMarketplaceConfirm,
      showLeaveFundConfirm,
      confirmingPublishMessage,
      confirmingUnpublishMessage,
      showLeaveComplete,
    } = this.state
    const { strategies = [] } = currentFundPerformance || {}
    const showLeaveFund = strategies.some(strategy => {
      const { positions = [] } = strategy
      return !!positions.length
    })
    const { fundExited = false } = fundData || {}
    return (
      <StyledFundDetails>
        {isLoading ? (
          <LoadingIndicator loadingText="Getting fund details" />
        ) : (
          <div>
            <Grid item>
              <div className="back-to-smart-funds" onClick={this.closeFundDetails}>
                {' '}
                Back to Smart Funds{' '}
              </div>{' '}
            </Grid>
            <Grid container direction="column">
              <Grid container direction="row" spacing={1} style={{ marginBottom: '15px' }}>
                <Grid item xs={4}>
                  <div className="bob-page-heading">{fundData.name}</div>
                  {this.props.isRetail && !fundExited && showLeaveFund && (
                    <button
                      className="bob-button-secondary"
                      onClick={() => this.setState({ showLeaveFundConfirm: true })}
                    >
                      Leave Fund
                    </button>
                  )}
                </Grid>
                <Grid item xs={2}>
                  {fundData.published ? (
                    <div className="is-live-sticker">
                      <label className="live">LIVE</label>
                    </div>
                  ) : (
                    <div className="not-live-sticker">
                      <label className="not-live"> NOT LIVE</label>
                    </div>
                  )}
                </Grid>
                <Grid>
                  {!this.props.isRetail && <ActionButton menuItems={this.getMenuItems()} />}
                </Grid>
              </Grid>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={2} className="flex-group tooltip-wrapper">
                  <Grid container direction="row">
                    <Grid>
                      <Typography className="account-buy-in">FUND BUY IN AMOUNT</Typography>
                    </Grid>
                    {/* <Grid item >
                      <button className="tooltip-trigger as-link" onClick={() => {}}>
                        What’s this?
                        <LoadingDateTooltip enabled={true}>
                          <p className="tooltip-content">
                            The Buy In amount is the minimum budget to allocate across all models
                          </p>
                        </LoadingDateTooltip>
                      </button>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Typography className="buy-in-amount">
                    {currencyFilter(fundData.buyIn)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <FundSideNav
              showClients={!this.props.isRetail}
              showDetails={!this.props.isRetail}
              currentTab={currentTab}
              onChangeTab={this.handleChangeTab}
            />
            <div className="two-column-wrapper">
              <div
                className={
                  currentTab !== 'portfolios' ? 'main-container' : 'main-container no-background'
                }
              >
                {
                  {
                    details: <Details fundData={fundData} pushUpdates={this.handlePushUpdates} />,
                    portfolios: (
                      <Models
                        fundData={fundData}
                        portfolioData={fundData.strategies}
                        navigate={this.props.navigate}
                        setNavigationTabs={this.props.setNavigationTabs}
                      />
                    ),
                    clients: <Clients clients={clients} fundData={fundData} />,
                    access: <Access />,
                    metrics: <Metrics />,
                  }[currentTab]
                }
              </div>
            </div>
          </div>
        )}
        <Confirm
          show={showPublishConfirm}
          onHide={() => this.setState({ showPublishConfirm: false })}
          onCommit={this.publishFund.bind(this)}
          title={confirmingPublishMessage}
        />
        <Confirm
          show={showUnpublishConfirm}
          onHide={() => this.setState({ showUnpublishConfirm: false })}
          onCommit={this.unpublishFund.bind(this)}
          title={confirmingUnpublishMessage}
        />
        <Confirm
          show={showAddToMarketplaceConfirm}
          onHide={() => this.setState({ showAddToMarketplaceConfirm: false })}
          onCommit={this.addFundToMarketplace.bind(this)}
          title={confirmingPublishMessage}
        />
        <Confirm
          show={showRemoveFromMarketplaceConfirm}
          onHide={() => this.setState({ showRemoveFromMarketplaceConfirm: false })}
          onCommit={this.removeFundFromMarketplace.bind(this)}
          title={confirmingUnpublishMessage}
        />
        <Confirm
          show={showLeaveFundConfirm}
          onHide={() => this.setState({ showLeaveFundConfirm: false })}
          onCommit={this.leaveFund.bind(this)}
          body={'This action will sell off all current fund positions.'}
          commitMsg="Yes, exit positions now"
        />
        <Confirm
          show={showLeaveComplete}
          onHide={() => this.setState({ showLeaveComplete: false })}
          onCommit={this.leaveRefreshData.bind(this)}
          body={
            'Fund exit complete. All current positions will be sold immediately or at the start of the next trade day.'
          }
          commitMsg="Ok"
        />
        <AddedNewClientsModal
          fund={fundData}
          show={showAddedNewClientsModal}
          onClose={toggleAddedNewClientsModal.bind(this)}
          onCommit={this.close.bind(this)}
        />
      </StyledFundDetails>
    )
  }
}

export default FundDetails
