import styled from 'styled-components'
import { typography, patterns } from '../../../../assets/styles'

const StyledHistoricalPerformanceTable = styled.div`
  .fund-data-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding: 0 20px;
    align-items: flex-start;
  }
  .card-title {
    font-size: ${typography.mediumFontSize};
    font-weight: ${typography.baseFontWeight};
    margin: 0;
    margin-right: 3rem;
  }
  .viewing-tag {
    ${patterns.label};
    margin-right: 1rem;
    margin-bottom: 11px;
  }
  .viewing-portfolio {
    font-size: ${typography.baseFontSize};
    margin: 0;
  }
`
export default StyledHistoricalPerformanceTable
