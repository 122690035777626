import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../../common/components/Table'
import tableColumns from './data'

// TODO: Seems like this is a simple component that may
// not need to be abstracted into its own file
const LedgerTable = ({ tableData }) => {
  console.log(tableData)
  console.log('Hello')
  return (

  <Table rowsPerPage={5} list={tableData} listColumns={tableColumns} />
)}

LedgerTable.defaultProps = {
  tableData: null,
}

LedgerTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
}

export default LedgerTable
