import { createMuiTheme } from '@material-ui/core/styles'
export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#0cb0c8',
      // dark: '#1a4e63'
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0cb0c8',
      main: '#78c263',
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
    error: {
      main: '#8b0000',
    },
  },
})
