import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import CoolTable from '../../../../common/components/Table'
import currencyFilter from '../../../../lib/currency'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { Button } from '@material-ui/core'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getUserClients: user.getUserClients,
    currentFund: fund.currentFund,
    setCurrentFund: fund.setCurrentFund,
    listColumns: user.clientStore.listColumns,
    setListColumns: user.clientStore.setListColumns,
    toggleFreezeSharesModal: user.clientStore.toggleFreezeSharesModal,
    setSelectedClient: user.clientStore.setSelectedClient,
  }
})
@observer
class ClientTable extends Component {
  state = {}

  async setFreezeSells(item) {
    const { setCurrentFund, currentFund } = this.props
    const { clients } = currentFund
    const client = clients.find(c => String(c._id) === String(item._id))
    if (client.freezeSells) {
      client.freezeSells = false
      await setCurrentFund(currentFund)
      return this.forceUpdate()
    }
    client.freezeSells = true
    await setCurrentFund(currentFund)
    return this.forceUpdate()
  }

  async setFreezeBuys(item) {
    const { setCurrentFund, currentFund } = this.props
    const { clients } = currentFund
    const client = clients.find(c => String(c._id) === String(item._id))
    if (client.freezeBuys) {
      client.freezeBuys = false
      await setCurrentFund(currentFund)
      return this.forceUpdate()
    }
    client.freezeBuys = true
    await setCurrentFund(currentFund)
    return this.forceUpdate()
  }

  handleFreezeSellToggle(item) {
    const { currentFund } = this.props
    const { clients } = currentFund
    const client = clients.find(c => String(c._id) === String(item._id))
    return (
      <FormControlLabel
        control={
          <Switch
            checked={client.freezeSells}
            onClick={e => e.stopPropagation()}
            onChange={this.setFreezeSells.bind(this, client)}
            color="secondary"
          />
        }
      />
    )
  }

  handleFreezeBuyToggle(item) {
    const { currentFund } = this.props
    const { clients } = currentFund
    const client = clients.find(c => String(c._id) === String(item._id))
    return (
      <FormControlLabel
        control={
          <Switch
            disabled={client.fundDisqualified}
            checked={client.freezeBuys}
            onClick={e => e.stopPropagation()}
            onChange={this.setFreezeBuys.bind(this, client)}
            color="secondary"
          />
        }
      />
    )
  }

  async handleFreezeShares(e, item) {
    e.stopPropagation()
    const { toggleFreezeSharesModal, setSelectedClient } = this.props
    setSelectedClient(item)
    await toggleFreezeSharesModal()
  }

  async handleClientDetail(item) {
    const { toggleClientDetailModal, setSelectedClient } = this.props
    setSelectedClient(item)
    await toggleClientDetailModal()
  }

  render() {
    const { list = [], onPageChange, onFilterChange, tableConfigs = [] } = this.props
    const tableConfig = tableConfigs.find(c => (c.tableId = 'unpublished-fund-clients')) || {}
    const listColumns = [
      {
        id: 'd4ea42b5',
        displayName: 'Name',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.name,
        displayFn: item => {
          return <div>{item.name}</div>
        },
      },
      {
        id: 'a52028ed',
        displayName: 'Account Number',
        propPath: 'accountNum',
        filter: false,
        filterToolTip: true,
      },
      {
        id: 'f7b7fc5ex',
        displayName: 'Account Value',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.totalAccountValue,
        sortProp: 'totalAccountValue',
        toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
        numericEval: true,
        displayFn: item => currencyFilter(item.totalAccountValue || 0),
      },
      {
        id: 'f7b7fc5ey',
        displayName: 'Cash On Hand',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.tdaCash,
        sortProp: 'tdaCash',
        toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
        numericEval: true,
        displayFn: item => currencyFilter(item.tdaCash || 0),
      },
      {
        id: 'f7b7fc5ez',
        displayName: 'Bob Budget',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.bobBudget,
        sortProp: 'bobBudget',
        toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
        numericEval: true,
        displayFn: item => currencyFilter(item.bobBudget || 0),
      },
      {
        id: 'f7b7fc5em',
        displayName: 'Bob Buying Power',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.bobBuyingPower,
        sortProp: 'bobBuyingPower',
        toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
        numericEval: true,
        displayFn: item => {
          return <div style={{ color: 'green' }}>{currencyFilter(item.bobBuyingPower || 0)}</div>
        },
      },
      {
        id: 'f7b7fc5e',
        displayName: 'Target Reserves',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.reserves,
        sortProp: 'reserves',
        toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
        numericEval: true,
        displayFn: item => currencyFilter(item.reserves || 0),
      },
      {
        id: 'f7b7fc58',
        displayName: 'Freeze Buys',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.freezeBuys,
        sortProp: 'freezeBuys',
        displayFn: this.handleFreezeBuyToggle.bind(this),
      },
      {
        id: 'u7b7fc5e',
        displayName: 'Freeze Sells',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.freezeSells,
        sortProp: 'freezeSells',
        displayFn: this.handleFreezeSellToggle.bind(this),
      },
      {
        id: 'u7b7fc5k',
        displayName: 'Freeze Shares',
        propPath: '/',
        filter: false,
        filterToolTip: true,
        filterVal: item => item.freezeSells,
        sortProp: 'freezeSells',
        displayFn: item => {
          return (
            <Button variant="outlined" onClick={e => this.handleFreezeShares(e, item)}>
              Lock
            </Button>
          )
        },
      },
    ]

    const tableOpts = {
      selectable: true,
      onSelect: this.handleClientDetail.bind(this),
      listColumns,
      list: list.filter(item => item.fundActive === true),
    }
    return (
      <CoolTable
        {...tableOpts}
        tableConfig={tableConfig}
        tableId="unpublished-fund-clients"
        onFilterChange={onFilterChange}
        onPageChange={onPageChange}
      />
    )
  }
}

export default ClientTable
