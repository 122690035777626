import { observable } from 'mobx'

export let FundState = observable({
  _id: null,
  name: null,
  type: 'Mixed',
  sectors: [],
  clients: [],
  strategies: [],
  monitoring: { email: false, chart: false, api: false },
  description: null,
  reservePercentage: 0,
  suggestedAccountSize: null,
  published: false,
  aum: 0,
  buyIn: 0,
  accountAllocation: 0
})

export const FundReset = () => {
  FundState._id = null
  FundState.name = null
  FundState.type = 'Mixed'
  FundState.sectors = []
  FundState.clients = []
  FundState.strategies = []
  FundState.monitoring = { email: false, chart: false, api: false }
  FundState.description = null
  FundState.reservePercentage = 0
  FundState.suggestedAccountSize = null
  FundState.published = false
  FundState.aum = 0
  FundState.mirroredFund = false
  FundState.buyIn = 0
  FundState.accountAllocation = 0
}
