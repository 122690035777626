import styled from 'styled-components'
import { colors } from '../../../../assets/styles'

const StyledFormGroup = styled.div`
  width: ${props => (props.customSize ? props.customSize : '100%')};
  position: relative;
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  vertical-align: top;
  &:focus {
    outline: none;
  }

  .input-field-label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    color: ${props => (props.hasFocus ? colors.labelGrey : `rgba(${colors.labelGreyRGB}, 0.7)`)};
    line-height: 1;
    letter-spacing: 0.000938rem;
    padding: 0;
    transform-origin: top left;
    transform: ${props =>
      props.hasFocus || props.hasInputValue
        ? 'translate(0, 1.5px) scale(0.75)'
        : 'translate(0, 24px) scale(1)'};
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.has-error {
      color: ${colors.alertRed} !important;
    }
  }

  .input-field-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    line-height: 1.1875rem;
    color: ${colors.textGrey};
    cursor: text;
    font-size: 1rem;
    box-sizing: border-box;
    margin-top: 1rem;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: ${props =>
        props.hasError
          ? `1px solid ${colors.alertRed}`
          : `1px solid rgba(${colors.labelGreyRGB}, 0.7)`};
      pointer-events: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: ${props => (props.hasFocus ? 'scaleX(1)' : 'scaleX(0)')};
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-bottom: 2px solid ${colors.textGreyRGB};
      pointer-events: none;
    }
  }

  .input-field {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: textfield;
    &.has-error {
      color: ${colors.alertRed} !important;
    }
    &:focus {
      outline: none;
    }
  }

  .field-error {
    color: ${colors.alertRed};
    position: absolute;
    margin-top: 9px;
  }
`

export default StyledFormGroup
