import React, { Component } from 'react';

export default class Widget extends Component {

	constructor(props) {
		super(props);
		const { space = false } = props;
		const defaultBody = space ? <br/> : <div>Widget!</div>;
		const { body = defaultBody } = props;
		this.body = body ;
	}

	theClass() {
		const { classProps = {} } = this.props;
		const { columnSize = 4, size = 'md', override, append = '' } = classProps;
		return override ? override : `col-${size}-${columnSize} ${append}`;
	}

	render() {
		const Body = typeof this.body === 'function' ? this.body : () => this.body;
		return (
			<div className={this.theClass()}>
				<Body {...this.props}/>
			</div>
		);
	}
}
