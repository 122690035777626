import React from 'react'

const FavoriteIconSvg = () => (
  <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-256.000000, -154.000000)" fill="#F7B500" fillRule="nonzero">
        <g transform="translate(241.000000, 139.000000)">
          <path
            d="M25,33.35 L23.55,32.03 C18.4,27.36 15,24.28 15,20.5 C15,17.42 17.42,15 20.5,15 C22.24,15 23.91,15.81 25,17.09 C26.09,15.81 27.76,15 29.5,15 C32.58,15 35,17.42 35,20.5 C35,24.28 31.6,27.36 26.45,32.04 L25,33.35 Z"
            id="Favorited"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default FavoriteIconSvg
