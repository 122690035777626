import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Pick } from '../../../../common/components/Dialogs'
import InputField from '../../../../common/components/InputField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import currencyFilter from '../../../../lib/currency'

import { StyledClientDetailWrapper } from './styles'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
    getAccountHoldings: fund.getAccountHoldings,
    isClientQualifiedForFund: user.isClientQualifiedForFund,
    selectedClient: user.clientStore.selectedClient,
  }
})
@observer
class ClientDetail extends Component {
  constructor(props) {
    super(props)
    this.state = { fund: {}, width: 4, filters: [] }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    let { currentFund } = this.props
    const { clients } = currentFund
    const holdings = await this.getHoldings()
    this.setState({ fund: currentFund, clients, loading: false, holdings })
  }

  async getHoldings() {
    const { selectedClient, getAccountHoldings, currentFund } = this.props
    const payload = {
      fundId: currentFund._id,
      accounts: [selectedClient],
    }
    await getAccountHoldings(payload)
  }
  pickViewClient() {
    const { loading = false } = this.state
    const { selectedClient, isClientQualifiedForFund, currentFund } = this.props

    return (
      <div>
        {!loading && (
          <StyledClientDetailWrapper>
            <Grid container className="details-form">
              <Grid container direction="row" className="form-name">
                <Grid item xs={3}>
                  <InputField
                    readOnly={true}
                    type="text"
                    label="Name"
                    customSize="100%"
                    initialValue={selectedClient.name}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Grid>
                    <Typography className="account-info-label">TOTAL ACCOUNT VALUE</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {currencyFilter(
                        selectedClient.actualTotalAccountValue > 0
                          ? selectedClient.actualTotalAccountValue
                          : selectedClient.totalAccountValue
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid>
                    <Typography className="account-info-label">FUND BUYIN</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="buy-in-amount">
                      {currencyFilter(currentFund.buyIn)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" className="form-name">
                <Grid item xs={3}>
                  <InputField
                    readOnly={true}
                    type="text"
                    label="Account Number"
                    customSize="100%"
                    initialValue={selectedClient.accountNum}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Grid>
                    <Typography className="account-info-label">CASH ON HAND</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="buy-in-amount">
                      {currencyFilter(selectedClient.tdaCash)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid>
                    <Typography className="account-info-label">CURRENT HOLDINGS VALUE</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="buy-in-amount">
                      {currencyFilter(selectedClient.securitiesValue)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginBottom: '30px' }}>
                <Grid item xs={3}>
                  <InputLabel>% of Account Reserved for Bob</InputLabel>
                  <Select
                    style={{ display: 'flex' }}
                    value={
                      selectedClient.accountAllocation
                        ? selectedClient.accountAllocation
                        : currentFund.accountAllocation
                    }
                    onChange={event => {
                      selectedClient.accountAllocation = event.target.value
                      console.log(isClientQualifiedForFund(selectedClient))
                    }}
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={0.1}>10%</MenuItem>
                    <MenuItem value={0.2}>20%</MenuItem>
                    <MenuItem value={0.3}>30%</MenuItem>
                    <MenuItem value={0.4}>40%</MenuItem>
                    <MenuItem value={0.5}>50%</MenuItem>
                    <MenuItem value={0.6}>60%</MenuItem>
                    <MenuItem value={0.7}>70%</MenuItem>
                    <MenuItem value={0.8}>80%</MenuItem>
                    <MenuItem value={0.9}>90%</MenuItem>
                    <MenuItem value={1.0}>100%</MenuItem>
                  </Select>
                  <FormHelperText>Percentage of the total account reserved for Bob.</FormHelperText>
                </Grid>
                <Grid item xs={5}>
                  <Grid>
                    <Typography className="account-info-label">BOB BUDGET</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="buy-in-amount">
                      {currencyFilter(selectedClient.bobBudget || 0)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid>
                    <Typography className="account-info-label">BOB BUYING POWER</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="buy-in-amount">
                      {currencyFilter(selectedClient.bobBuyingPower)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={3}>
                  <InputLabel>Cash Reserve Requirement</InputLabel>
                  <Select
                    style={{ display: 'flex' }}
                    value={
                      selectedClient.reservePercentage
                        ? selectedClient.reservePercentage
                        : currentFund.reservePercentage
                    }
                    onChange={event => {
                      selectedClient.reservePercentage = event.target.value
                      return isClientQualifiedForFund(selectedClient)
                    }}
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={0.01}>1%</MenuItem>
                    <MenuItem value={0.02}>2%</MenuItem>
                    <MenuItem value={0.03}>3%</MenuItem>
                    <MenuItem value={0.04}>4%</MenuItem>
                    <MenuItem value={0.05}>5%</MenuItem>
                  </Select>
                  <FormHelperText>Reserved cash for {selectedClient.name} account.</FormHelperText>
                </Grid>
                <Grid item xs={5}>
                  <Grid>
                    <Typography className="account-info-label">TARGET RESERVES</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="buy-in-amount">
                      {currencyFilter(selectedClient.reserves || 0)}
                    </Typography>
                  </Grid>
                </Grid>
                {selectedClient.fundDisqualified && (
                  <Grid item xs={2}>
                    <Grid item>
                      <Typography className="participate-message">
                        *This client will be set to freeze buying because they do not have enough
                        cash to participate in the initial fund buy in.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </StyledClientDetailWrapper>
        )}
        {loading && (
          <div>
            <div className="row">
              <div className="col-md-12">Loading Client Info</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <CircularProgress color="secondary" />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6"></div>
          <div className="col-md-3"></div>
        </div>
        {this.pickViewClient()}
      </div>
    )
  }
}

export default class ClientDetailnModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  body() {
    return (
      <div>
        <ClientDetail />
      </div>
    )
  }
  render() {
    const { show = false, onClose, onCommit } = this.props
    return (
      <Pick
        show={show}
        onHide={onClose}
        onCommit={onCommit}
        title="Manage Client"
        className="strategies-selector"
        body={this.body()}
      />
    )
  }
}
