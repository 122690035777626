import React, { Component } from 'react'

import axios from 'axios'
import { theme } from './theme'
import Table from './Table'
import { Pick } from '../../common/components/Dialogs'
import { Checkbox, Button } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'

import '../../App/App.css'

function RoleDisplay(props) {
  return props.assigned.map((p, idx) => {
    const foundRole = props.roles.find(role => role._id === p) || {}
    const { roleName = '' } = foundRole
    return (
      <li key={idx}>
        <span>{roleName}</span>
      </li>
    )
  })
}

export default class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    let { data: roles = [] } = await axios.get('/v0/role')
    let { data: users = [] } = await axios.get('/v0/users')
    let { data: permissions = [] } = await axios.get('/v0/permission')
    const listColumns = [
      {
        displayName: 'User',
        propPath: '/',
        displayFn: item => (
          <span>
            {item.firstName || ''} {item.lastName || ''}
          </span>
        ),
        filterVal: item => `${item.firstName || ''} ${item.lastName || ''}`,
        style: {
          width: '30%',
        },
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Email',
        propPath: 'email',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Assigned Roles',
        propPath: 'roleAssignment',
        filter: false,
        displayFn: items => <RoleDisplay assigned={items || []} roles={roles} />,
      },
      {
        displayName: 'Assign',
        propPath: '/',
        filter: false,
        displayFn: items => (
          <Button onClick={this.openPickRoleModal.bind(this, items)}>+ Add roles</Button>
        ),
      },
    ]
    this.setState({ roles, permissions, listColumns, users })
  }

  updateState(newState, cb) {
    console.log('role newState', newState)
    this.setState(
      () => newState,
      () => {
        if (cb) cb()
      }
    )
  }
  openPickRoleModal(user) {
    this.setState({ activeRoleSelectionUser: user, showPickRoleModal: true })
  }
  addRolesToUser(role, e) {
    console.log(role)
    const { activeRoleSelectionUser } = this.state
    if (activeRoleSelectionUser) {
      const { roleAssignment = [] } = activeRoleSelectionUser
      console.log('roleAssignment', roleAssignment)

      if (e.target.checked === false) {
        roleAssignment.splice(roleAssignment.findIndex(r => r === role._id), 1)
      } else {
        if (!roleAssignment.includes(role._id)) roleAssignment.push(role._id)
      }
      activeRoleSelectionUser.roleAssignment = roleAssignment
      this.setState({ activeRoleSelectionUser })
    }
  }
  async saveUserRoles() {
    const { activeRoleSelectionUser } = this.state
    console.log('save', activeRoleSelectionUser)
    this.closePickRoleModal()
    let { data } = await axios.put('/v0/users', activeRoleSelectionUser)
    console.log('saved?', data)
  }
  closePickRoleModal() {
    this.setState({ showPickRoleModal: false })
  }
  render() {
    const {
      users = {},
      listColumns = [],
      roles = [],
      showPickRoleModal = false,
      activeRoleSelectionUser = {},
    } = this.state
    const { roleAssignment = [] } = activeRoleSelectionUser
    const tableOpts = {
      listColumns,
      list: users.users || [],
      rowsPerPage: 10,
    }
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Table {...tableOpts}></Table>
        </div>
        <RolePickModal
          show={showPickRoleModal}
          roleAssignment={roleAssignment}
          addRolesToUser={this.addRolesToUser.bind(this)}
          roles={roles}
          onCommit={this.saveUserRoles.bind(this)}
          onClose={this.closePickRoleModal.bind(this)}
        />
      </MuiThemeProvider>
    )
  }
}

class RolePickModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  body() {
    const { roles = [] } = this.props
    const tableOpts = {
      listColumns: this.listColumns(),
      list: roles,
      rowsPerPage: 5,
    }
    return (
      <div>
        <Table {...tableOpts}></Table>
      </div>
    )
  }
  render() {
    const { show = false, onClose, onCommit } = this.props
    return (
      <Pick
        show={show}
        onHide={onClose}
        onCommit={onCommit}
        title="Select roles"
        className="strategies-selector"
        body={this.body()}
      />
    )
  }
  handlePick(...a) {
    console.log(...a)
  }
  listColumns() {
    // console.log(this.props.addRolesToUser);
    const { roleAssignment = [] } = this.props
    return [
      {
        displayName: 'Select',
        propPath: '/',
        displayFn: item => (
          <Checkbox
            checked={roleAssignment.includes(item._id)}
            onClick={e => this.props.addRolesToUser(item, e)}
          />
        ),
        filterVal: item => roleAssignment.includes(item._id),
        filter: false,
      },
      {
        displayName: 'Role Code',
        propPath: 'roleCode',
      },
      {
        displayName: 'Role Name',
        propPath: 'roleName',
      },
    ]
  }
}
