import { observable, action } from 'mobx'

export default class ModelBuilderStore {
  @observable selectedTab = 0
  @observable selectedSecurity = null

  constructor(modelStore) {
    this.rootStore = modelStore.rootStore
    this.modelStore = modelStore
  }

  @action('Model Store | setSelectedSecurity')
  setSelectedSecurity = security => {
    this.selectedSecurity = security
    this.modelStore.save()
    return this.selectedSecurity
  }

  @action('Model Store | setSelectedSecurity')
  setSelectedTab = step => {
    this.selectedTab = step
    this.modelStore.save()
    return this.selectedTab
  }

  @action('Model Store | getSelectedTab')
  getSelectedTab = (history, idx) => {
    let selectedTab = 0
    const tabMap = {
      details: 0,
      securities: 1,
      analysis: 2,
      backtest: 3,
      complete: 4,
    }
    if (typeof idx === 'number') {
      const url = Object.keys(tabMap)[idx]
      selectedTab = tabMap[url]
      history.push(url)
    } else {
      const tabName = window.location.href.split('/').pop()
      selectedTab = tabMap[tabName]
    }
    return selectedTab
  }

  @action('Model Store | deleteSecurityChart')
  deleteSecurityChart = async chartId => {
    const { api, log } = this.rootStore
    const result = await api.delete(`/v0/deleteChartById/${chartId}`)
    const { data : { success, message } } = result
    if(!success)log.warn(`${message}`)
  }
  
}
