import styled from 'styled-components'
import { colors, patterns, typography } from '../../../../assets/styles'
import chevronDownIcon from '../../../../assets/icons/chevron-down-icon.svg'

export const StyledMultiFundView = styled.div`
  display: grid;
  grid-gap: 1.562rem;
  grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
  margin-top: 3rem;
  .fund-card {
    ${patterns.defaultCard};
    .card-header {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
  .favorite-icon {
    ${patterns.buttonDefault};
    display: none; // remove when ready
    background: none;
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 1.25rem;
    height: 1.187rem;
  }
  .card-title-as-link {
    ${patterns.buttonDefault};
    background: none;
    width: auto;
    margin: 1.25rem auto;
    padding: 0;
    .card-title {
      color: ${colors.linkBlue};
      font-size: ${typography.baseFontSize};
      text-align: center;
      margin: 0;
    }
  }
`

export const StyledCardBody = styled.div`
  .card-label-text {
    ${patterns.label};
    text-align: center;
    margin-bottom: 0.312rem;
  }
  .total-fund-balance {
    text-align: center;
    font-size: 1.625rem;
  }
  .badge-wrapper,
  .stats-bar-fund {
    display: flex;
    justify-content: center;
    margin-bottom: 0.625rem;
  }
  .cash-badge {
    height: 1.625rem;
    margin-right: 0.625rem;
    padding: 0 15px;
    background-color: ${colors.offWhite};
    border: 1px solid ${colors.bobGreen};
    border-radius: 100rem;
    line-height: 26px;
    text-align: center;
    font-size: ${typography.smallBaseFontSize};
  }
  .live-sticker {
    ${patterns.liveSticker};
    border-radius: 100rem;
  }
  .stats-bar-fund {
    margin-top: 20px;
    .data-field,
    .data-label {
      text-align: center;
    }
  }
`

export const StyledCardFooterPortfolio = styled.div`
  padding: 0.937rem 0;
  .has-liners {
    ${patterns.label};
    display: block;
    position: relative;
    text-align: center;
    &:before {
      content: '';
      position: absolute;
      top: 0.625rem;
      left: 0;
      width: 6.25rem;
      height: 1px;
      background: ${colors.borderGrey};
    }
    &:after {
      content: '';
      position: absolute;
      top: 0.625rem;
      right: 0;
      width: 6.25rem;
      height: 1px;
      background: ${colors.borderGrey};
    }
  }
  .strategies-dropdown {
    position: relative;
  }
  .strategies-dropdown-button {
    ${patterns.dropdownButtonWithBg};
    margin: 1rem auto;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 2.3rem;
    font-size: ${typography.mediumFontSize};
    font-weight: ${typography.baseFontWeight};
    max-height: 46px;
    background: none;
    &:after {
      content: '';
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      width: 0.562rem;
      height: 0.437rem;
      background-image: url(${chevronDownIcon});
      background-repeat: no-repeat;
    }
  }
  .strategies-dropdown-menu {
    ${patterns.dropdownMenuBackground};
    top: 2rem;
    left: 0;
    min-width: 15rem;
    margin-bottom: 0;
    text-align: left;
  }
  .strategy-dropdown-item {
    ${patterns.buttonDefault};
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    text-align: left;
    transition: all 200ms linear;
    &:hover,
    &:focus {
      background: #f3f3f3;
    }
  }
  .mini-strategy-bar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.625rem;
    .stat-number {
      min-width: 6.625rem;
      margin-right: 0;
      text-align: center;
      &:not(:last-child) {
        border-right: 1px solid ${colors.borderGrey};
      }
    }
  }
  .single-strategy-name {
    text-align: center;
    margin: 1rem 0;
    font-size: ${typography.mediumFontSize};
  }
`
