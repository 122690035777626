import styled from 'styled-components'

export const StyledTotumRiskSlider = styled.div`
.slider  .slider-handles div{
  height: 50px !important;
  width: 50px !important;
  background: #f5f5f5 !important;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px 1px !important;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1.43px;
  line-height: 26px;
  border-radius: 30px;
}

.slider .slider-tracks div{
  background: transparent !important;
  background-color: transparent !important;
}

.slider div{
  background: linear-gradient(90deg, #007CDE 0%, #42B7E8 12.13%, #34BBC8 27.74%, #74BE8F 51.72%, #FCCF4A 81.13%, #FFCF48 100%) !important; 
  width: 520px;
}

.risk-score{
  position: absolute;
  margin-top: 13px;
  z-index: 100;
  margin-left: 16px;
  font-size: 12px;
}

.portfolio-risk-score{
  position: absolute;
  margin-top: 13px;
  z-index: 100;
  font-size: 22px;
  margin-left: 9px;
  color: #0CB0C8;
}

.preference-risk-title{
  position: absolute;
  margin-top: 49px;
  z-index: 100;
  margin-left: -10px;
  font-size: 10px;
  width: 150px
}

.capacity-risk-title{
  position: absolute;
  margin-top: -25px;
  z-index: 100;
  margin-left: -5px;
  font-size: 10px;
  width: 150px
}


.risk-score-min-max {
  margin-top: -12px;
}

`

export const  StyledPortfolioScore = styled.div`

.portfolio-score-container {
  height: 250px !important;
  width: 250px !important;
  background: #f5f5f5 !important;
  box-shadow: rgba(0,0,0,0.3) 1px 1px 1px 1px !important;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  -webkit-letter-spacing: 1.43px;
  -moz-letter-spacing: 1.43px;
  -ms-letter-spacing: 1.43px;
  letter-spacing: 1.43px;
  line-height: 26px;
  border-radius: 180px;
  background: linear-gradient(90deg, #007CDE 0%, #42B7E8 12.13%, #34BBC8 27.74%, #74BE8F 51.72%, #FCCF4A 81.13%, #FFCF48 100%) !important; 
  opacity: 0.8
}

.score {
  position: absolute;
  margin-left: 70px;
  margin-top: 1.15em;
  font-size: 95px;
  font-weight: 400;
  font-family: Avenir;
  z-index: 10;
}

.score-container {
  position: absolute;
  margin-top: -10.5em;
  margin-left: 1.9em;
  height: 175px !important;
  width: 175px !important;
  background: #f5f5f5 !important;
  box-shadow: rgba(0,0,0,0.3) 1px 1px 1px 1px !important;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  -webkit-letter-spacing: 1.43px;
  -moz-letter-spacing: 1.43px;
  -ms-letter-spacing: 1.43px;
  letter-spacing: 1.43px;
  line-height: 26px;
  border-radius: 100px;
  background: white !important; 
}

.score p{
  position: absolute;
  margin-top: -3.10em;
  font-size: 95px;
  font-weight: 400;
  font-family: Avenir;
  z-index: 109;
}


.header-portfolio-score-container {
  height: 50px !important;
  width: 50px !important;
  background: #f5f5f5 !important;
  box-shadow: rgba(0,0,0,0.3) 1px 1px 1px 1px !important;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  -webkit-letter-spacing: 1.43px;
  -moz-letter-spacing: 1.43px;
  -ms-letter-spacing: 1.43px;
  letter-spacing: 1.43px;
  line-height: 26px;
  border-radius: 180px;
  background: linear-gradient(90deg, #007CDE 0%, #42B7E8 12.13%, #34BBC8 27.74%, #74BE8F 51.72%, #FCCF4A 81.13%, #FFCF48 100%) !important; 
  opacity: 0.8
}

.header-score-container {
  position: relative;
  margin-top: -2.2em;
  margin-left: 0.3em;
  height: 39px !important;
  width: 39px !important;
  background: #f5f5f5 !important;
  box-shadow: rgba(0,0,0,0.3) 1px 1px 1px 1px !important;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  -webkit-letter-spacing: 1.43px;
  -moz-letter-spacing: 1.43px;
  -ms-letter-spacing: 1.43px;
  letter-spacing: 1.43px;
  line-height: 26px;
  border-radius: 100px;
  background: white !important; 
}

.header-score {
  position: relative;
  margin-left: 70px;
  margin-top: 1.45em;
  font-size: 15px;
  font-weight: 900;
  font-family: Avenir;
  z-index: 10;
}

.header-score p{
  position: relative;
  margin-top: -3.4em;
  margin-left: -3.6em
  font-size: 15px;
  font-weight: 900;
  font-family: Avenir;
  z-index: 109;
}
`

export const StyledNoFilledButton = styled.div `
.no-filled-button{
  box-sizing: border-box;
  height: 33px;
  width: 137px;
  border: 1px solid #32C5FF;
  border-radius: 6px;
  cursor: pointer;  
}

.no-filled-button-content {
  position: absolute;
  height: 16px;
  width: 26px;
  color: #32C5FF;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.06px;
  line-height: 16px;
  text-align: center;
  margin-left: 55.5px;
  margin-top: 8.5px;
}
`

export const StyledFilledButton = styled.div `

.filled-button {
  height: 32px;
  width: 136px;
  border-radius: 6px;
  background-color: #0CB0C8;
  cursor: pointer;  
}

.filled-button-content{
  position:absolute;
    height: 16px;
  width: 28px;
  color: #FFFFFF;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.06px;
  line-height: 16px;
  text-align: center;
    margin-left: 55.5px;
  margin-top: 7.5px;

}
`


