import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { Pick } from '../../common/components/Dialogs'
import CoolTable from '../../common/components/Table'

const styles = theme => ({
    typography: {
        useNextVariants: true,
    },
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
})

@inject(({ store: { model, auth } }) => {
    return {
        currentStudy: model.studyStore.currentStudy,
        setCurrentStudy: model.studyStore.setCurrentStudy,
        initials: model.studyStore.initials,
        isAuthenticated : auth.isAuthenticated
    }
})
@observer
class Studies extends Component {
    constructor(props) {
        super(props)
        this.state = { studies: [], currentStudy: {} }
    }
    async componentDidMount() {
        // TODO: add additional filters from state here
        const { isAuthenticated } = this.props
        await isAuthenticated()
        let params = { Private: true }
        let {
      data: { studies },
    } = await axios.get('/v0/study', { params })
        const { data: groups } = await axios.get('/v0/study-publish-list').catch(e => [])
        this.setState({ studies, groups })
    }
    async openPickPermModal(study) {
        console.log('STUDY', study)
        const { data: payload } = await axios
            .get(`/v0/study-assignments?studyId=${study._id}`)
            .catch(e => [])
        this.setState({
            study,
            showPickPermModal: true,
            targetPublishGroups: payload.targetPublishGroups,
        })
    }
    closePickPermModal() {
        this.setState({ showPickPermModal: false })
    }
    async getCurrentGroups() {
        const { data: payload } = await axios
            .get(`/v0/study-assignments?studyId=${this.props.study._id}`)
            .catch(e => [])
        this.setState({ targetPublishGroups: payload.targetPublishGroups })
        return payload
    }
    addPublishGroup(item, e) {
        const { targetPublishGroups = [] } = this.state
        if (e.target.checked) {
            if (!targetPublishGroups.includes(item._id)) {
                targetPublishGroups.push(item._id)
            }
        } else {
            targetPublishGroups.splice(targetPublishGroups.indexOf(item._id), 1)
        }
        this.setState({ targetPublishGroups })
    }
    async commitPublish() {
        const { targetPublishGroups } = this.state
        const { study } = this.state
        await axios
            .put('/v0/study-publish', { studyId: study._id, targetPublishGroups: targetPublishGroups })
            .catch(e => false)
        this.closePickPermModal()
    }

    publishListColumns() {
        const { targetPublishGroups = [] } = this.state
        return [
            {
                displayName: 'Publish',
                propPath: '/',
                displayFn: item => (
                    <Checkbox
                        checked={targetPublishGroups.includes(item._id)}
                        onClick={this.addPublishGroup.bind(this, item)}
                    />
                ),
                filter: false,
                style: {
                    width: '10%',
                },
            },
            {
                displayName: 'Group Name',
                propPath: 'roleName',
                style: {
                    width: '15%',
                },
            },
        ]
    }

    render() {
        const { classes, initials } = this.props
        const { showPickPermModal = false } = this.state

        return (
            <div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <br />
                        <hr />
                        <br />
                    </div>
                    <div className="col-md-3"></div>
                </div>
                {this.state.studies.map((study, idx) => (
                    <div key={idx}>
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <ExpansionPanel defaultExpanded={true}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Avatar>{initials(study.name || '')}</Avatar>&nbsp;&nbsp;
                    <Typography variant="title">{study.name || ''}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>{study.description || ''}</Typography>
                                    </ExpansionPanelDetails>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        className={classes.button + ' pull-right'}
                                        onClick={this.openPickPermModal.bind(this, study)}
                                    >
                                        <AssignmentIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                                        Publish
                  </Button>
                                </ExpansionPanel>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <br />
                    </div>
                ))}
                <PermPickModal
                    groups={this.state.groups}
                    targetPublishGroups={this.state.targetPublishGroups}
                    study={this.state.activeStudy}
                    show={showPickPermModal}
                    close={this.closePickPermModal.bind(this)}
                    publishListColumns={this.publishListColumns.bind(this)}
                    commitPublish={this.commitPublish.bind(this)}
                    addPublishGroup={this.addPublishGroup.bind(this)}
                />
            </div>
        )
    }
}

class PermPickModal extends Component {
    constructor(props) {
        super(props)
        this.state = { targetPublishGroups: this.props.targetPublishGroups }
    }

    updateTargetPublishGroups() {
        return this.setState({ targetPublishGroups: this.props.targetPublishGroups })
    }

    body() {
        const { groups = [] } = this.props
        // this.setState({targetPublishGroups : this.props.targetPublishGroups})
        const tableOpts = {
            list: groups,
            listColumns: this.props.publishListColumns(),
            rowsPerPage: 5,
        }
        return (
            <div>
                <CoolTable {...tableOpts}></CoolTable>
            </div>
        )
    }

    render() {
        const { show = false, close } = this.props
        //need to address this code. Needs to be peformed with setState()
        //this.state.targetPublishGroups = this.props.targetPublishGroups;
        return (
            <Pick
                show={show}
                onHide={close}
                onCommit={this.props.commitPublish}
                title="Assign Studies"
                className="strategies-selector"
                body={this.body()}
            />
        )
    }

    handlePick(...a) {
        console.log(...a)
    }
}

Studies.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Studies)