import { action } from 'mobx'

class AssetManager {
  constructor(rootStore) {
    this.rootStore = rootStore
    this.auth = this.rootStore.auth
  }

  @action('Asset Manager Store | getAssetManagerModels')
  getAssetManagerModels = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/asset-manager/model-detail`)
      const serviceCategories = result ? result.data || [] : []
      return serviceCategories
    } catch (e) {
      console.log(`Asset Manager Store | getAssetManagerModels ${e.stack}`)
    }
  }

  @action('Asset Manager Store | getAssetManagerModelConsumers')
  getAssetManagerModelConsumers = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/asset-manager/model-detail/${id}`)
      const serviceCategories = result ? result.data || [] : []
      return serviceCategories
    } catch (e) {
      console.log(`Asset Manager Store | getAssetManagerModelConsumers ${e.stack}`)
    }
  }
}

export default AssetManager
