import { action, observable, set } from 'mobx'
import { TotumRiskForm } from './models/totumRiskForm'

class TotumRisk {
  @observable riskForm = TotumRiskForm
  @observable lowScore = {}
  @observable middleScore = {}
  @observable highScore = {}
  @observable allScoreValues = []
  @observable selectedTab = 0
  @observable result = {}
  @observable capacity = null
  @observable preference = null
  @observable radioButtonOptions = [
    { value: 'Healthy', label: 'Healthy - All Healthy. No Significant healthcare expenses.' },
    { value: 'Family', label: 'Family - Significant healthcare expenses for whole family.' },
    { value: 'Household Head', label: 'Household Head - Health expenses head of household.' },
    {
      value: 'Dependents',
      label: 'Dependents - Significant healthcare expenses for one or more dependents.',
    },
  ]
  @observable errors = []
  @observable lossGreaterThanInvestmentError = null

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @action('TotumRisk Store | setScoresInfo')
  setScoresInfo = async riskScores => {
    try {
      this.capacity = riskScores.find(s => s.title === 'Capacity')
      this.preference = riskScores.find(s => s.title === 'Preference')
      this.setScores()
    } catch (e) {
      console.log(`Issue in TotumRisk Store | ${e.message}`)
    }
  }

  @action('TotumRisk Store | setRiskForm')
  setRiskForm = riskForm => {
    const fields = Object.keys(riskForm)
    if (fields.length === 1) this.riskFormFieldValidator(fields[0])
    set(this.riskForm, riskForm)
    return this.riskForm
  }

  @action('TotumRisk | setScores')
  setScores = (low, high) => {
    this.lowScore = this.capacity.score < this.preference.score ? this.capacity : this.preference
    this.highScore = this.capacity.score > this.preference.score ? this.capacity : this.preference
    this.allScoreValues = [this.lowScore.score, this.highScore.score]
  }

  @action('TotumRisk | getTitleClassName')
  getTitleClassName(riskTitle) {
    if (riskTitle === 'Capacity') return 'capacity-risk-title'
    if (riskTitle === 'Preference') return 'preference-risk-title'
    return 'portfolio-risk-title'
  }

  @action('TotumRisk Store | setSelectedSecurity')
  setSelectedTab = index => {
    this.selectedTab += index
  }

  @action('TotumRisk Store | getSelectedTab')
  getSelectedTab = () => {
    return this.selectedTab
  }

  @action('TotumRisk Store | getRiskForm')
  getRiskForm = async () => {
    try {
      const { api } = this.rootStore
      const response = await api.get(`/v0/getRiskSavedForm`)
      const { data } = response
      const { success, message, riskForm } = data
      if (!riskForm) return
      const { questionnaire, riskScores, result } = riskForm
      if (!success) throw new Error(message)
      if (riskScores) {
        this.result = result
        await this.setScoresInfo(riskScores)
      }

      await this.setRiskForm(questionnaire)
    } catch (e) {
      console.log(`Issue in TotumRisk Store | ${e.message}`)
    }
  }

  @action('TotumRisk Store | saveRiskForm')
  saveRiskForm = async () => {
    try {
      const { api } = this.rootStore
      const response = await api.put(`/v0/saveRiskForm`, this.riskForm)
      const { data } = response
      const { success, message } = data
      if (!success) throw new Error(message)
    } catch (e) {
      console.log(`Issue in TotumRisk Store | ${e.message}`)
    }
  }

  @action('TotumRisk Store | submitRiskForm')
  submitRiskForm = async confirmed => {
    try {
      this.errors = []
      this.riskFormValidator()
      if (this.errors.length > 0) return
      if (!confirmed) {
        this.errors = ['confirmedSubmit']
        return
      }
      const { api } = this.rootStore
      const response = await api.put(`/v0/submitRiskForm`, this.riskForm)
      const { data } = response
      const { success, message, riskScores } = data
      if (!success) throw new Error(message)
      await this.setScoresInfo(riskScores)
      this.selectedTab = 2
    } catch (e) {
      console.log(`Issue in TotumRisk Store | ${e.message}`)
    }
  }

  @action('TotumRisk Store | riskFormValidator')
  riskFormValidator = async () => {
    const { maxLoss, investmentAmount } = this.riskForm
    const newErrors = []
    Object.keys(this.riskForm).forEach(field => {
      const value = this.riskForm[field]
      if (typeof value === 'boolean') {
        return
      }
      if (!value || value === 'none') {
        newErrors.push(field)
      }
      if (field === 'zip') {
        const validZip = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$').test(value)
        if (!validZip) {
          newErrors.push(field)
        }
      }
    })
    if (+maxLoss > +investmentAmount) {
      this.lossGreaterThanInvestmentError = 'Must Be Less than Investment Amt.'
      newErrors.push('maxLoss')
    }
    this.errors = newErrors
  }

  @action('TotumRisk Store | riskFormValidator')
  riskFormFieldValidator = async field => {
    this.errors = this.errors.filter(e => e !== field)
  }
}

export default TotumRisk
