import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '../Table'
import currencyFilter from '../../../lib/currency'
import StatNumber from '../StatNumber'
import { StyledCard, StyledCurrentPerformance } from './styles'
import tableColumns from './data'

class CurrentPerformance extends Component {
  state = {
    currentStrategy: null,
    dropdownIsOpen: false,
  }

  componentDidMount() {
    const { strategies = [], handleCurrentStrategy } = this.props
    this.setState({ currentStrategy: strategies[0] })
    handleCurrentStrategy(strategies[0])
  }
  componentDidUpdate(prevProps) {
    const { strategies } = this.props
    if (strategies !== prevProps.strategies) {
      this.setState({ currentStrategy: strategies[0] })
    }
  }

  toggleStrategiesDropdown = () => {
    const { dropdownIsOpen } = this.state
    this.setState({ dropdownIsOpen: !dropdownIsOpen })
  }

  handleChangeCurrentStrategy = strategy => {
    const { handleCurrentStrategy } = this.props
    this.setState({ dropdownIsOpen: false, currentStrategy: strategy })
    handleCurrentStrategy(strategy)
  }

  render() {
    const { strategies } = this.props
    const { currentStrategy, dropdownIsOpen } = this.state

    return (
      <StyledCurrentPerformance>
        <StyledCard>
          <header className="card-header">
            <p className="card-header-label">Current Performance</p>
            {currentStrategy && (
              <div className="card-header-bar">
                {strategies.length > 1 && (
                  <div className="strategies-dropdown">
                    <button
                      onClick={() => this.toggleStrategiesDropdown()}
                      className="strategies-dropdown-button"
                    >
                      {currentStrategy.name}
                    </button>
                    {dropdownIsOpen ? (
                      <ul className="strategies-dropdown-menu">
                        {strategies.map(strategy => (
                          <li key={strategy._id}>
                            <button
                              onClick={() => this.handleChangeCurrentStrategy(strategy)}
                              className="strategy-dropdown-item"
                            >
                              {strategy.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                )}
                {strategies.length === 1 && <h2 className="card-title">{currentStrategy.name}</h2>}
                <div className="portfolio-stat-bar">
                  <StatNumber
                    statLabel="Market Value"
                    statValue={currencyFilter(currentStrategy.currentPositionValue)}
                    isNegative={Math.sign(currentStrategy.currentPositionValue)}
                  />
                </div>
              </div>
            )}
          </header>
          {currentStrategy && (
            <Table rowsPerPage={5} list={currentStrategy.positions} listColumns={tableColumns} />
          )}
        </StyledCard>
      </StyledCurrentPerformance>
    )
  }
}

CurrentPerformance.propTypes = {
  strategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleCurrentStrategy: PropTypes.func.isRequired,
}

export default CurrentPerformance
