import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import objectPath from 'simple-object-path'
import Button from '@material-ui/core/Button'

import Widget from '../../common/components/Widget'
import CoolTable from '../../common/components/Table'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LockIcon from '@material-ui/icons/Lock'
import InfoIcon from '@material-ui/icons/Info'
import PlusIcon from '@material-ui/icons/Add'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { theme } from './wizardUtils'
import { Tooltip } from '@material-ui/core'
import moment from 'moment'
import currencyFilter from '../../lib/currency'

@inject(({ store: { navigate, model, auth, user } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    setNavigationTabs: user.headerStore.setNavigationTabs,
    currentModel: model.currentModel,
    setCurrentModel: model.setCurrentModel,
    getUserModels: model.getUserModels
  }
})
@observer
export default class Models extends Component {
  constructor(props) {
    super(props)
    this.state = { strategies: [], width: 4, isLoading: true }
  }

  async componentDidMount() {
    const { isAuthenticated, getUserModels } = this.props
    await isAuthenticated()
    this.setState({ isLoading : true})
    const {
      strategies,
      positionOverviews,
      groups,
      assignedGroupsList,
    } = await getUserModels()
    this.setState({ strategies, positionOverviews, groups, assignedGroupsList, isLoading : false })
  }

  onSubscribe = async () => {}

  onUnflip = () => this.setState({ flipped: null })
  onFlip = idx => this.setState({ flipped: idx })

  handleWidth = value => {
    this.setState({ width: value }, () => {
      let resizeEvent = window.document.createEvent('UIEvents')
      resizeEvent.initUIEvent('resize', true, false, window, 0)
      window.dispatchEvent(resizeEvent)
    })
  }

  // SET CURRENT MODEL HERE
  async clickStrategy(strategy) {
    const { pickMode = false, setCurrentModel, navigate } = this.props
    if (pickMode) {
      // do nothing.
    } else {
      await setCurrentModel(strategy)
      await navigate(`/model/${strategy._id}/details`)
    }
  }
  fullViewStrategy(strategy, idx) {
    const {
      positionOverviews = [],
      assignedGroupsList = [],
      [`tabId-${strategy._id}`]: tabId = 0,
    } = this.state
    const groups = objectPath(this.state, 'groups/groups') || []
    let portfolioPositions = positionOverviews.find(p => p.strategyId === strategy._id)
    let assignedGroups = assignedGroupsList.find(t => t.strategyId === strategy._id)
    assignedGroups = assignedGroups.assignedGroups || []
    portfolioPositions = portfolioPositions.positions || {}
    const handleChange = (e, newTabId) => {
      this.setState({ [`tabId-${strategy._id}`]: newTabId })
    }
    const assignmentTableOpts = {
      list: groups,
      listColumns: this.publishListColumns(strategy, assignedGroups),
    }
    const positionTableOpts = {
      list: portfolioPositions,
      listColumns: this.portfolioPositionsListColumns(),
    }
    return (
      <div key={idx} className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{strategy.strategy} </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <Button
                  className="pull-right"
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={this.clickStrategy.bind(this, strategy)}
                >
                  <InfoIcon /> View Portfolio
                </Button>
                <div className="row">
                  <Widget classProps={{ columnSize: 12 }} space={true} />
                </div>
                <div className="row">
                  <AppBar position="static" color="inherit">
                    <Tabs value={tabId} onChange={handleChange}>
                      <Tab label="Position Overview" />
                      <Tab label="Assignments" />
                    </Tabs>
                    {tabId === 0 && (
                      <Widget
                        {...positionTableOpts}
                        body={CoolTable}
                        classProps={{ columnSize: 12 }}
                      />
                    )}
                    {tabId === 1 && (
                      <Widget
                        {...assignmentTableOpts}
                        body={CoolTable}
                        classProps={{ columnSize: 12 }}
                      />
                    )}
                  </AppBar>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <br />
        </div>
        <div className="col-md-3"></div>
      </div>
    )
  }

  publishListColumns(strategy, assignedGroups) {
    return [
      {
        displayName: 'Published',
        propPath: '/',
        displayFn: item =>
          item.liveFund.includes(strategy._id) ? (
            <Tooltip title="Locked!" placement="top">
              <LockIcon color="secondary" />
            </Tooltip>
          ) : (
            <Checkbox disabled checked={assignedGroups.includes(item._id)} />
          ),
        filter: false,
        style: {
          width: '10%',
        },
      },
      {
        displayName: 'Group Name',
        propPath: 'roleName',
        style: {
          width: '15%',
        },
      },
    ]
  }
  portfolioPositionsListColumns() {
    return [
      {
        displayName: 'Date/Time',
        propPath: 'timestamp',
        displayFn: item => moment(item).format('MM/DD/YY HH:MM:SS'),
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'State',
        propPath: '/',
        displayFn: item => `${item.state === null ? 'ERROR' : item.state} ${item.side}`,
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Avg $',
        propPath: 'average_price',
        displayFn: item => currencyFilter(Number(item).toFixed(2), '$'),
        default: '0',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Open Shares',
        propPath: 'shares',
        default: '0',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Security',
        propPath: 'symbol',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'User',
        propPath: '/',
        displayFn: item => `${item.user.firstName} ${item.user.lastName}`,
        filterToolTip: true,
        filter: false,
      },
    ]
  }

  changePage(...a) {
    console.log(a)
  }

  handleChangePage(event, tablePage) {
    this.setState({ tablePage })
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value })
  }

  pickViewStrategy() {
    const { strategies, tablePage = 0, rowsPerPage = 5 } = this.state
    const { selectedStrategies = [] } = this.props
    const page = []
      .concat(strategies)
      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
    const minAccountSizeValues = [
      '$25,000 - $50,000',
      '$50,001 - $100,000',
      '$100,001 - $200,000',
      'Over $200,000',
    ]
    const maxAllocationValues = [
      '$250,000 - $500,000',
      '$500,001 - $1,000,000',
      '$1,000,001 - $2,000,000',
      'Over $2,000,000',
    ]
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Selected</TableCell>
              <TableCell>Portfolio</TableCell>
              <TableCell>Min Account Size</TableCell>
              <TableCell>Max Allocation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page.map((strategy, idx) => {
              const { strategy: strategyName = '', maxAllocation, minAccountSize } = strategy
              const selected = selectedStrategies.includes(strategy._id)
              return (
                <TableRow key={idx}>
                  <TableCell>
                    <Checkbox
                      checked={selected}
                      onClick={e => this.props.pickHandler(e, strategy)}
                    />
                  </TableCell>
                  <TableCell>{strategyName}</TableCell>
                  <TableCell>
                    <small>{minAccountSizeValues[minAccountSize - 1] || ''}</small>
                  </TableCell>
                  <TableCell>
                    <small>{maxAllocationValues[maxAllocation - 1] || ''}</small>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={strategies.length}
          page={tablePage}
          rowsPerPage={rowsPerPage}
          component="div"
          onChangePage={this.handleChangePage.bind(this)}
          onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
        />
      </div>
    )
  }
  toggleGroupView(e) {
    this.setState({ groupView: e.target.checked })
    setTimeout(async () => await this.getStrategies(), 0)
  }
  render() {
    const { isLoading } = this.state
    const { pickMode = false } = this.props
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <FormControlLabel
                className="pull-right"
                label="Group View"
                control={<Switch onClick={this.toggleGroupView.bind(this)} />}
              />
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              {!pickMode && (
                <div>
                  <br />
                  <Button
                    className="pull-right"
                    onClick={() => (window.location.href = '/model/new/details')}
                  >
                    <PlusIcon /> Create new portfolio
                  </Button>
                  <hr />
                  <br />
                </div>
              )}
            </div>
            <div className="col-md-3"></div>
          </div>
          {isLoading && (
            <div style={{ marginTop: '10%' }}>
              <div className="row">
                <div className="col-md-12 client-loading">
                  Loading Portfolios
                  <br></br>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 client-loading">
                  <CircularProgress color="secondary" />
                </div>
              </div>
            </div>
          )}
          {!pickMode && this.state.strategies.map(this.fullViewStrategy.bind(this))}
          {pickMode && this.pickViewStrategy()}
        </div>
      </MuiThemeProvider>
    )
  }
}
