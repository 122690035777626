import { colors, typography } from './index'

const patterns = {
  buttonDefault: {
    'font-family': `${typography.baseFontFamily}`,
    'user-select': 'none',
    cursor: 'pointer',
    '-webkit-appearance': 'none',
    border: 0,
    outline: 'none',
  },
  dropdownButtonWithBg: {
    'font-family': `${typography.baseFontFamily}`,
    'user-select': 'none',
    cursor: 'pointer',
    '-webkit-appearance': 'none',
    border: 0,
    outline: 'none',
    padding: '0.75rem 1.5rem',
    'vertical-align': 'middle',
    'max-height': '2.625rem',
    'border-radius': '0.25rem',
  },
  primaryButton: {
    height: '48px',
    'font-family': typography.baseFontFamily,
    'font-weight': typography.baseFontWeight,
    'text-transform': 'uppercase',
    background: colors.linkBlue,
    color: 'white',
    'user-select': 'none',
    cursor: 'pointer',
    '-webkit-appearance': 'none',
    border: 0,
    outline: 'none',
    padding: '0.95rem 1.75rem',
    'vertical-align': 'middle',
    'max-height': '3rem',
    'letter-spacing': '0.75px',
    'border-radius': '0.25rem',
    'box-shadow': 'rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px',
    transition:
      'transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;',
    '&:hover, &:focus': {
      background: '#0CA7BD',
      transform: 'translateY(-0.15rem)',
      'box-shadow': 'rgba(46, 41, 51, 0.08) 0px 4px 8px, rgba(71, 63, 79, 0.16) 0px 8px 16px',
    },
  },
  buttonAsLink: {
    'font-family': `${typography.baseFontFamily}`,
    'font-weight': `${typography.baseFontWeight}`,
    color: colors.linkBlue,
    'user-select': 'none',
    cursor: 'pointer',
    '-webkit-appearance': 'none',
    border: 0,
    outline: 'none',
    'vertical-align': 'middle',
    'max-height': '3rem',
    'text-shadow': 'none !important',
    padding: 0,
    '&:hover, &:focus': {
      'text-decoration': 'underline',
    },
  },
  dropdownMenuBackground: {
    position: 'absolute',
    top: '3.125rem',
    right: 0,
    width: 'auto',
    'min-width': '200px',
    background: 'white',
    'list-style': 'none',
    padding: 0,
    'text-align': 'right',
    'z-index': '99',
    'border-radius': '0.125rem',
    'box-shadow': '0 0.187rem 0.437rem rgba(0, 0, 0, 0.15)',
  },
  appContainer: {
    display: 'block',
    'padding-bottom': '2rem',
  },
  smallViewContainer: {
    display: 'block',
    margin: '2rem auto',
    padding: '2rem',
    '@media screen and (min-width: 768px)': {
      'max-width': 'calc(100% - 700px)',
    },
  },
  label: {
    'font-size': `${typography.extraSmallFontSize}`,
    color: `${colors.labelGrey}`,
  },
  defaultCard: {
    'border-radius': '0.125rem',
    background: 'white',
    'box-shadow': '0px 1px 2px rgba(46,41,51,0.08), 0px 2px 4px rgba(71,63,79,0.08)',
  },
  liveSticker: {
    display: 'inline-block',
    'background-color': colors.bobGreen,
    color: 'white',
    'text-transform': 'uppercase',
    width: '60px',
    height: '26px',
    'line-height': '29px',
    'text-align': 'center',
    'border-radius': '7px',
    'font-size': typography.smallBaseFontSize,
    'font-weight': typography.headingFontWeight,
    'letter-spacing': '1px',
  },
}

export default patterns
