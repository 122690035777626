import React, { Component } from 'react'
import { typography } from '../../../assets/styles'

export default class BaseTableColumn extends Component {
  render() {
    return (
      <div style={{ display: 'table' }}>
        <div
          style={{
            display: 'table-cell',
            height: 40,
            verticalAlign: 'middle',
            fontSize: typography.extraSmallFontSize,
          }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}
