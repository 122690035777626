const _stage = 'PROD';

const defaultLogLevel = ['debug', 'info', 'warn', 'error'];

const envs = {
	test: ['debug', 'info', 'warn', 'error'],
	staging: ['debug', 'info', 'warn', 'error'],
	prod: ['debug', 'info', 'warn', 'error']
};

const logLevel = envs[_stage] || defaultLogLevel;

const hostname = require('os').hostname();
const instance = 0;
const logger = (context = {}) => {
	const now = () => new Date().toISOString();
	const callingLocation = () => {
		return new Error().stack.split('\n')[4]
			.replace(/.*\((.*):[0-9]+\).*/, '$1')
	};
	const messageContext = () => {
		 return Object.keys(context).map(c => '<<' +  context[c] + '>>').join(' ');
	};
	const objectToMessage = (o) => {
		if(o === null) return '';
		if(typeof o !== 'object') return o;
		if(o instanceof Error) return o.stack;
		return JSON.stringify(o);
	};
	const write = (level, stdwhere, msg) => {
		if(!logLevel.includes(level)) return;
		const finalMessage = level.toUpperCase() + ' - ' + now() + ' (' + callingLocation() + ') - ' + messageContext() + ' - ' + hostname + ':' + instance + ' ' + objectToMessage(msg) + '\n';
		console.log(finalMessage);
		try{
			//if(level === 'error' && envs[_stage])_messenger.notify(`${_stage} - ${finalMessage}`);
		}catch(e){
			return console.log(e.stack);
		}
	};
	const info = (msg) => write('info', 'stdout', msg);
	const debug = (msg) => write('debug', 'stdout', msg);
	const error = (msg) => write('error', 'stderr', msg);
	const warn = (msg) => write('warn', 'stderr', msg);
	return {
		info,
		debug,
		warn,
		error
	};
};

export default logger;
