import { action, observable } from 'mobx'

class Service {
  @observable serviceCategory = {}
  constructor(rootStore) {
    this.rootStore = rootStore
    this.auth = this.rootStore.auth
  }

  @action('Service Store | getServiceCategories')
  getServiceCategories = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/service-category`)
      const serviceCategories = result ? result.data || [] : []
      return serviceCategories
    } catch (e) {
      console.log(`Service Store | getServiceCategories ${e.stack}`)
    }
  }

  @action('Service Store | getServiceCategoryDetail')
  getServiceCategoryDetail = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/service-category/${id}`)
      const serviceCategories = result ? result.data || [] : []
      return serviceCategories
    } catch (e) {
      console.log(`Service Store | getServiceCategoryDetail ${e.stack}`)
    }
  }

  @action('Service Store | getServiceTaskDetail')
  getServiceTaskDetail = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/service-task/${id}`)
      const serviceTask = result ? result.data || [] : []
      return serviceTask
    } catch (e) {
      console.log(`Service Store | getServiceTaskDetail ${e.stack}`)
    }
  }

  @action('Service Store | getServiceTaskInstructions')
  getServiceTaskInstructions = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/service-task/${id}/instructions`)
      const serviceTask = result ? result.data || [] : []
      return serviceTask
    } catch (e) {
      console.log(`Service Store | getServiceTaskInstructions ${e.stack}`)
    }
  }

  @action('Service Store | sendServiceTaskCommand')
  sendServiceTaskCommand = async payload => {
    try {
      const { api } = this.rootStore
      return await api.post(`/v0/service-task/command`, payload)
    } catch (e) {
      console.log(`Service Store | sendServiceTaskCommand ${e.stack}`)
    }
  }

  @action('Service Store | updateServiceCategoryInsights')
  updateServiceCategoryInsights = async payload => {
    try {
      const { api } = this.rootStore
      return await api.post(`/v0/service-category/update-insights`, payload)
    } catch (e) {
      console.log(`Service Store | updateServiceCategoryInsights ${e.stack}`)
    }
  }

  @action('Service Store | updateServiceCategoryActions')
  updateServiceCategoryActions = async payload => {
    try {
      const { api } = this.rootStore
      return await api.post(`/v0/service-category/update-actions`, payload)
    } catch (e) {
      console.log(`Service Store | updateServiceCategoryActions ${e.stack}`)
    }
  }

  // @action('Service Store | setServiceCategory')
  // setServiceCategory = serviceCategory => {
  //   set(this.serviceCategory, serviceCategory)
  // }
}

export default Service
