import styled from 'styled-components'
import { patterns } from '../../../../../assets/styles'

const StyledAccess = styled.div`
  padding: 3rem;
  .information-label-text {
    ${patterns.label};
  }
`

export default StyledAccess
