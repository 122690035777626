import { Breadcrumbs, Link } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component } from 'react'
import op from 'simple-object-path'
import { colors, typography } from '../../../assets/styles'
import BaseTableColumn from '../../../common/components/BaseTableColumn'
import DashboardCard from '../../../common/components/DashboardCard'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import CoolTable from '../../../common/components/Table'
import { Pick } from '../../../common/components/Dialogs'

@inject(({ store: { navigate, auth, service } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getServiceTaskDetail: service.getServiceTaskDetail,
    getServiceTaskInstructions: service.getServiceTaskInstructions,
    sendServiceTaskCommand: service.sendServiceTaskCommand,
  }
})
@observer
export default class ServiceTask extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mockList: [],
      loadingServiceTask: false,
      loadingServiceInstructions: false,
    }
  }
  async componentDidMount() {
    this.setState({ loadingServiceTask: true, loadingServiceInstructions: true })
    await this.seedData()
    this.calculateUptime()
  }

  async seedData() {
    const {
      serviceTaskId,
      getServiceTaskDetail,
      getServiceTaskInstructions,
      isAuthenticated,
    } = this.props
    await isAuthenticated()
    const serviceTask = await getServiceTaskDetail(serviceTaskId)
    this.setState({ serviceTask, loadingServiceTask: false })
    const taskInstructions = await getServiceTaskInstructions(serviceTaskId)
    this.setState({ taskInstructions, loadingServiceInstructions: false })
    if (serviceTask.state === 'RUNNING') {
      this.dataInterval = setTimeout(async () => await this.seedData(), 15000)
    }
  }
  onClickBack(e) {
    e.preventDefault()
    const { serviceTask = {} } = this.state
    const { navigate } = this.props
    navigate(`/admin/services/${serviceTask.serviceCategory._id}/tasks`)
  }

  onClickBackToServices() {
    const { navigate } = this.props
    navigate(`/admin/services`)
  }

  getExceptionCount() {
    return this.getExceptions().length
  }

  getExceptions() {
    const { serviceTask = {} } = this.state
    const uncaughtExceptions = op(serviceTask, 'insights/insights/uncaughtExceptions') || {}
    return Object.keys(uncaughtExceptions).map(key => ({
      id: key,
      message: uncaughtExceptions[key],
    }))
  }

  parseKeyInsights() {
    const { serviceTask = {} } = this.state
    const { insights, serviceCategory = {} } = serviceTask
    const { keyInsights: serviceCategoryKeyInsights = [] } = serviceCategory
    const keyInsights = serviceCategoryKeyInsights.map(ki => {
      const { delimiter = ' ' } = ki
      return {
        name: ki.name,
        value: (ki.paths || []).reduce((a, p) => a + op(insights, p) + delimiter, ''),
      }
    })
    return keyInsights
  }

  calculateUptime() {
    const { serviceTask = {} } = this.state
    const { startUp, shutDown } = serviceTask
    const themoment = shutDown ? moment(shutDown) : moment()
    const hours = themoment.diff(moment(startUp), 'hours')
    const minutes = themoment.diff(moment(startUp), 'minutes') % 60
    const seconds = themoment.diff(moment(startUp), 'seconds') % 60
    const uptime = `${hours} hr ${minutes} min. ${seconds} sec.`
    this.setState({ uptime })
    if (serviceTask.state === 'RUNNING') {
      this.uptimeInterval = setTimeout(async () => this.calculateUptime(), 10000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.uptimeInterval)
    clearTimeout(this.dataInterval)
  }

  onTaskViewChange(page) {
    const { serviceTask = {} } = this.state
    const { navigate } = this.props
    navigate(`/admin/services/task/${serviceTask._id}/${page}`)
  }

  getTableConfig() {
    const { taskInstructions = [] } = this.state
    const { page } = this.props
    const tableMap = {
      instructions: {
        listColumns: this.instructionListColumns(),
        list: taskInstructions,
      },
      exceptions: {
        listColumns: this.exceptionsListColumns(),
        list: this.getExceptions(),
      },
    }
    return tableMap[page]
  }

  viewInstruction(instruction) {
    this.setState({ showInstructionDialog: true, viewInstruction: instruction })
  }

  closeInstruction() {
    this.setState({ showInstructionDialog: false, viewInstruction: undefined })
  }

  copyEcsInfo() {
    const { serviceTask = {} } = this.state
    navigator.clipboard.writeText(op(serviceTask, 'ecsInfo/ecsTaskId') || 'no ecs info available')
  }

  async sendCommand(insightCommand) {
    const { sendServiceTaskCommand } = this.props
    const { serviceTask } = this.state
    const commands = [
      {
        serviceTask,
        insightCommand,
      },
    ]
    await sendServiceTaskCommand(commands)
    this.closeConfirmStop()
  }

  confirmStop() {
    this.setState({ showStopConfirmDialog: true })
  }

  closeConfirmStop() {
    this.setState({ showStopConfirmDialog: false })
  }

  render() {
    const {
      serviceTask = {},
      loadingServiceTask,
      loadingServiceInstructions,
      uptime,
      showInstructionDialog,
      showStopConfirmDialog,
      viewInstruction = {},
    } = this.state
    const { onPageChange, onFilterChange, tableConfigs = [], page } = this.props
    const tableConfig = tableConfigs.find(c => (c.tableId = 'asset-manager')) || { rowsPerPage: 12 }

    const tableOpts = this.getTableConfig()
    const stateColorMap = {
      SHUTDOWN: colors.alertRed,
      RUNNING: colors.successGreen,
    }
    const stateColor = stateColorMap[serviceTask.state] || colors.textGrey
    const keyInsights = this.parseKeyInsights()
    return (
      <React.Fragment>
        {loadingServiceTask && <LoadingIndicator loadingText="" />}
        {!loadingServiceTask && (
          <Container fixed style={{ fontFamily: typography.baseFontFamily, width: '100%' }}>
            <Breadcrumbs>
              onClickBackToServices
              <Link
                color="secondary"
                style={{ cursor: 'pointer' }}
                onClick={this.onClickBackToServices.bind(this)}
              >
                Services
              </Link>
              <Link
                color="secondary"
                style={{ cursor: 'pointer' }}
                onClick={this.onClickBack.bind(this)}
              >
                {serviceTask.serviceCategory ? serviceTask.serviceCategory.serviceName : ''}
              </Link>
              <span>Task: {serviceTask._id}</span>
            </Breadcrumbs>
            <br />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              style={{ verticalAlign: 'top' }}
            >
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: typography.largeFontSize,
                    fontWeight: typography.mediumFontWeight,
                  }}
                >
                  Task: {serviceTask._id}
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ float: 'right' }}
                    onClick={this.copyEcsInfo.bind(this)}
                  >
                    Copy ECS Task Id
                  </Button>
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>
              <Grid item xs={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={3}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '280px', width: '300px', textAlign: 'center' }}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing={3}
                        alignItems="flex-start"
                        justify="flex-end"
                        style={{ paddingTop: 10 }}
                      >
                        <Grid item style={{ paddingBottom: 10 }}>
                          <span
                            style={{
                              fontSize: typography.mediumFontSize,
                              fontWeight: typography.mediumFontWeight,
                            }}
                          >
                            Key Insights
                          </span>
                        </Grid>
                        {keyInsights &&
                          keyInsights.map((insight, index) => {
                            return (
                              <Grid item key={`key_insight_${index}`} style={{ paddingBottom: 4 }}>
                                <span
                                  style={{
                                    fontSize: typography.mediumFontSize,
                                    fontWeight: typography.mediumFontWeight,
                                  }}
                                >
                                  {insight.name}
                                </span>
                                : <span>{insight.value}</span>
                              </Grid>
                            )
                          })}

                        <Grid item></Grid>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>State</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.largeFontSize,
                                fontWeight: typography.mediumFontWeight,
                                color: stateColor,
                              }}
                            >
                              {serviceTask.state}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Uptime</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.smallFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {uptime}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Actions</span>
                          </Grid>
                          <Grid item>
                            {serviceTask.state === 'RUNNING' &&
                              (serviceTask.serviceCategory.actions || []).includes('STOP') && (
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={this.confirmStop.bind(this)}
                                >
                                  Stop
                                </Button>
                              )}
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={12}>
                    <DashboardCard hasTitle={false} title="" moreTool={false}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        style={{ padding: 15 }}
                      >
                        <Grid item xs={2}>
                          <Button
                            variant="outlined"
                            color={page === 'instructions' ? 'secondary' : ''}
                            onClick={() => this.onTaskViewChange('instructions')}
                          >
                            Instructions
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="outlined"
                            color={page === 'exceptions' ? 'secondary' : ''}
                            onClick={() => this.onTaskViewChange('exceptions')}
                          >
                            Errors ({this.getExceptionCount()})
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            color={page === 'insights' ? 'secondary' : ''}
                            variant="outlined"
                            onClick={() => this.onTaskViewChange('insights')}
                          >
                            Insights
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            color={page === 'ecsInfo' ? 'secondary' : ''}
                            variant="outlined"
                            onClick={() => this.onTaskViewChange('ecsInfo')}
                          >
                            ECS Info
                          </Button>
                        </Grid>
                        <Grid item xs={9} style={{ width: '100%' }}></Grid>
                      </Grid>
                      <Box>
                        {loadingServiceInstructions && <LoadingIndicator loadingText="" />}
                        {!loadingServiceInstructions &&
                          ['instructions', 'exceptions'].includes(page) && (
                            <CoolTable
                              list={tableOpts.list}
                              listColumns={tableOpts.listColumns}
                              tableConfig={tableConfig}
                              tableId={page}
                              onFilterChange={onFilterChange}
                              onPageChange={onPageChange}
                            />
                          )}
                        {!loadingServiceInstructions && ['insights'].includes(page) && (
                          <pre>
                            {JSON.stringify(op(serviceTask, 'insights/insights') || {}, 2, 2)}
                          </pre>
                        )}
                        {!loadingServiceInstructions && ['ecsInfo'].includes(page) && (
                          <pre>{JSON.stringify(op(serviceTask, 'ecsInfo') || {}, 2, 2)}</pre>
                        )}
                      </Box>
                    </DashboardCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Pick
              show={showInstructionDialog}
              body={<pre>{JSON.stringify(viewInstruction, null, 2)}</pre>}
              onHide={this.closeInstruction.bind(this)}
              onCommit={this.closeInstruction.bind(this)}
              commitMsg="Close"
              bodyAlignment="left"
              className="strategies-selector"
            />
            <Pick
              show={showStopConfirmDialog}
              onHide={this.closeConfirmStop.bind(this)}
              onCommit={() => this.sendCommand('STOP')}
              commitMsg="Yes, Stop Task"
            />
          </Container>
        )}
      </React.Fragment>
    )
  }
  instructionListColumns() {
    return [
      {
        id: 'd4ea42b5',
        displayName: 'Id',
        propPath: '/',
        filter: true,
        sortProp: '_id',
        displayFn: item => <BaseTableColumn>{item._id}</BaseTableColumn>,
      },
      {
        id: 'f7b7fc5e',
        displayName: 'State',
        propPath: '/',
        filter: true,
        sortProp: 'state',
        displayFn: item => <BaseTableColumn>{item.state}</BaseTableColumn>,
      },
      {
        id: 'f7b7fc58',
        displayName: 'Originator',
        propPath: '/',
        filter: true,
        sortProp: 'originator',
        displayFn: item => <BaseTableColumn>{item.originator}</BaseTableColumn>,
      },
      {
        id: 'u7b7fc5e',
        displayName: 'Security',
        propPath: '/',
        filter: true,
        sortProp: 'security',
        displayFn: item => <BaseTableColumn>{item.security}</BaseTableColumn>,
      },
      {
        id: 'u7b7fc390',
        displayName: 'Timestamp',
        propPath: '/',
        filter: true,
        isDateSort: true,
        sortProp: 'timestamp',
        displayFn: item => (
          <BaseTableColumn>{moment(item.timestamp).format('L LTS')}</BaseTableColumn>
        ),
      },
      {
        id: 'u7b7fc390x',
        displayName: 'View',
        propPath: '/',
        filter: false,
        sortable: false,
        displayFn: item => (
          <BaseTableColumn>
            <Button onClick={() => this.viewInstruction(item)}>View</Button>
          </BaseTableColumn>
        ),
      },
    ]
  }
  exceptionsListColumns() {
    return [
      {
        id: 'd4ea42b5',
        displayName: 'Id',
        propPath: '/',
        filter: false,
        sortProp: 'id',
        displayFn: item => <BaseTableColumn>{item.id}</BaseTableColumn>,
      },
      {
        id: 'f7b7fc5e',
        displayName: 'Message',
        propPath: '/',
        filter: true,
        sortProp: 'message',
        filterVal: item => item.message,
        displayFn: item => (
          <BaseTableColumn>
            <pre>{item.message}</pre>
          </BaseTableColumn>
        ),
      },
    ]
  }
}
