import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StyledStatNumber from './styles'

class StatNumber extends Component {
  state = {
    currentPillValue: '$',
  }

  togglePillValue = val => {
    this.setState({ currentPillValue: val })
  }

  render() {
    const {
      isNegative,
      statLabel,
      statValue,
      hasToggleValue,
      togglePercentageValue,
      darkenPillButtons,
      hasPositiveValue,
      alignText,
      isSingle,
    } = this.props
    const { currentPillValue } = this.state
    return (
      <StyledStatNumber
        isNegative={isNegative === -1}
        hasToggleValue={hasToggleValue}
        darkenPillButtons={darkenPillButtons}
        alignText={alignText}
        isSingle={isSingle}
      >
        <p className="data-label">
          {statLabel}
          {hasToggleValue ? (
            <span className="pill-button">
              <button
                className={
                  currentPillValue === '$' ? 'is-active pill-button-left' : 'pill-button-left'
                }
                onClick={() => this.togglePillValue('$')}
              >
                $
              </button>
              <button
                className={
                  currentPillValue === '%' ? 'is-active pill-button-right' : 'pill-button-right'
                }
                onClick={() => this.togglePillValue('%')}
              >
                %
              </button>
            </span>
          ) : null}
        </p>
        <span className={hasPositiveValue ? 'data-field has-positive' : 'data-field'}>
          {hasToggleValue && currentPillValue === '%' ? togglePercentageValue : statValue}
        </span>
      </StyledStatNumber>
    )
  }
}

StatNumber.defaultProps = {
  isNegative: 1,
  hasToggleValue: false,
  togglePercentageValue: null,
  darkenPillButtons: null,
  hasPositiveValue: null,
  isSingle: null,
  alignText: 'right',
}

StatNumber.propTypes = {
  statLabel: PropTypes.string.isRequired,
  statValue: PropTypes.string.isRequired,
  isNegative: PropTypes.number,
  hasToggleValue: PropTypes.bool,
  togglePercentageValue: PropTypes.string,
  darkenPillButtons: PropTypes.bool,
  hasPositiveValue: PropTypes.bool,
  alignText: PropTypes.string,
  isSingle: PropTypes.bool,
}

export default StatNumber
