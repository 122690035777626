import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CurrentPerformance from '../CurrentPerformance'
import Tab from '../Tab'
import HistoricalPerformanceTable from '../HistoricalPerformanceTable'
import LedgerTable from '../LedgerTable'
import { StyledSingleFundView } from './styles'

class SingleFundView extends Component {
  state = {
    currentViewingStrategy: null,
  }

  componentDidMount() {
    const { currentFundPerformance = {} } = this.props
    const { strategies = [] } = currentFundPerformance
    this.setState({
      currentViewingStrategy: strategies[0],
    })
  }

  componentDidUpdate(prevProps) {
    const { currentFund, currentFundPerformance } = this.props
    if (prevProps.currentFund !== currentFund) {
      this.setState({
        currentViewingStrategy: currentFundPerformance.strategies[0],
      })
    }
  }

  handleCurrentStrategy = strategy => {
    this.setState({ currentViewingStrategy: strategy })
  }

  render() {
    const {
      currentFund,
      currentFundPerformance = {},
      historicalCardActiveTab,
      handleToggleCardTabs,
    } = this.props
    const { legacyPositionsPerformance = [] } = currentFund || {}
    const { currentViewingStrategy } = this.state
    const hasLegacyData = currentFund && legacyPositionsPerformance.length > 0
    const hasLedgerData = currentFund && currentFund.ledgers.length > 0
    const showHistoricalCardTabNav = hasLegacyData && hasLedgerData
    const showHistoricalPerformanceOnly = hasLegacyData && !hasLedgerData
    const showLedgerOnly = !hasLegacyData && hasLedgerData

    return (
      <StyledSingleFundView>
        <div className="card-wrapper-grid">
          <CurrentPerformance
            strategies={currentFundPerformance.strategies}
            handleCurrentStrategy={this.handleCurrentStrategy}
          />
        </div>
        <article className="historical-ledger-card">
          <header>
            {showHistoricalCardTabNav && (
              <ul className="card-header-nav">
                <Tab
                  tabLabel="Historical Performance"
                  isActive={historicalCardActiveTab === 'historical'}
                  buttonClickMethod={() => handleToggleCardTabs('historical')}
                />
                <Tab
                  tabLabel="Ledger"
                  isActive={historicalCardActiveTab === 'ledger'}
                  buttonClickMethod={() => handleToggleCardTabs('ledger')}
                />
              </ul>
            )}
            {showHistoricalPerformanceOnly && (
              <div className="card-header">
                <p className="card-header-label">Historical Performance</p>
              </div>
            )}
            {showLedgerOnly && (
              <div className="card-header">
                <p className="card-header-label">Ledger</p>
              </div>
            )}
          </header>
          {historicalCardActiveTab === 'historical' ? (
            <HistoricalPerformanceTable
              fundData={currentFundPerformance}
              tableData={legacyPositionsPerformance}
              currentStrategy={currentViewingStrategy}
            />
          ) : (
            <LedgerTable tableData={currentFund.ledgers} />
          )}
        </article>
      </StyledSingleFundView>
    )
  }
}

SingleFundView.propTypes = {
  currentFund: PropTypes.shape({}).isRequired,
  filteredPerformancesForDropdown: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalAUM: PropTypes.number.isRequired,
  historicalCardActiveTab: PropTypes.string.isRequired,
  handleChangeFund: PropTypes.func.isRequired,
  handleToggleCardTabs: PropTypes.func.isRequired,
}

export default SingleFundView
