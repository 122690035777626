import { observable, action, set } from 'mobx'
import ModelBuilderStore from '../scenes/ModelBuilder/store'
import StudyStore from '../scenes/Study/store'

export default class ModelStore {
  @observable currentModel = { charts: [] }
  @observable modelBuyInAmounts = {}
  @observable requiredTotalAllocations = 0

  constructor(rootStore) {
    this.rootStore = rootStore
    this.modelBuilderStore = new ModelBuilderStore(this)
    this.studyStore = new StudyStore(this)
  }

  @action('Model Store | setCurrentModel')
  setCurrentModel = (model, chart) => {
    set(this.currentModel, model)
    if (!chart) this.getSingleModelCostRequirement(model._id)
    return (this.requiredTotalAllocations = 0)
  }

  @action('Model Store | resetRequiredTotalAllocations')
  resetRequiredTotalAllocations = () => {
    return (this.requiredTotalAllocations = 0)
  }

  @action('Model Store | saveModel')
  save = async () => {
    const { api } = this.rootStore
    await api.put(`/v0/strategy`, this.currentModel)
  }

  @action('Model Store | createModel')
  createModel = async () => {
    debugger
    const { api } = this.rootStore
    const response = await api.put(`/v0/strategy`, { charts: [], Tickers: [] })
    const { data } = response
    const { strategy: model } = data
    return model._id
  }

  @action('Model Store | getCurrentModel')
  getModelDetails = async modelId => {
    try {
      const { api } = this.rootStore
      const response = await api.get(`/v0/getStrategy/${modelId}`)
      const { data } = response
      let { strategy: model } = data
      set(this.currentModel, model)
      return this.currentModel
    } catch (e) {
      console.log(`Issue in Model Store | ${e.message}`)
    }
  }

  @action('Model Store | getUserModels')
  getUserModels = async (fundQuery = false, groupView = false) => {
    const { api } = this.rootStore
    const url = fundQuery ? '/v0/fund-strategies' : '/v0/strategy'
    let params = { Private: true, groupView }
    let {
      data: { strategies },
    } = await api.get(url, { params })

    const promises = []
    promises.push(
      api.get('/v0/strategy-publish-list').then(r => {
        const { data: groups } = r
        return { groups }
      })
    )
    for (let strategy of strategies) {
      promises.push(
        api
          .get(`/v0/portfolio-positions/${strategy._id}`)
          .then(r => {
            const { data: portfolioPositions = [] } = r
            return { strategyId: strategy._id, positions: portfolioPositions }
          })
          .catch(() => [])
      )
      promises.push(
        api
          .get(`/v0/strategy-assignments?strategyId=${strategy._id}`)
          .then(r => {
            const { data: payload } = r
            return { strategyId: strategy._id, assignedGroups: payload.targetPublishGroups }
          })
          .catch(() => [])
      )
    }
    const responses = await Promise.all(promises)
    const groups = responses.find(r => r.groups)
    const positionOverviews = responses.filter(r => r.positions)
    const assignedGroupsList = responses.filter(r => r.assignedGroups)
    return { strategies, positionOverviews, groups, assignedGroupsList, isLoading: false }
  }

  @action('Model Store | getModelCharts')
  getModelCharts = async () => {
    try {
      const { api } = this.rootStore
      const { _id: modelId } = this.currentModel
      const response = await api.get(`/v0/getChartsByModelId/${modelId}`)
      const { data } = response
      let { charts } = data
      set(this.currentModel, { charts })
    } catch (e) {
      console.log(`Issue in Model Store | ${e.message}`)
    }
  }

  @action('Model Store | getModelCharts')
  removeModelFromCostRequirements = modelId => {
    delete this.modelBuyInAmounts[modelId]
    let totalAmount = 0
    for (const model in this.modelBuyInAmounts) {
      totalAmount += +this.modelBuyInAmounts[model]
    }
    this.requiredTotalAllocations = totalAmount
    return this.requiredTotalAllocations
  }

  @action('Model Store | getModelCharts')
  addModelToCostRequirements = modelId => {
    delete this.modelBuyInAmounts[modelId]
    let totalAmount = 0
    for (const model in this.modelBuyInAmounts) {
      totalAmount += +this.modelBuyInAmounts[model]
    }
    this.requiredTotalAllocations = totalAmount
    return this.requiredTotalAllocations
  }

  @action('Model Store | getSingleModelCostRequirement')
  getSingleModelCostRequirement = async strategyId => {
    try {
      if (this.modelBuyInAmounts[strategyId]) return this.modelBuyInAmounts[strategyId]
      const { api } = this.rootStore
      const response = await api.post(`/v0/getFullAllocationRequirements`, {
        strategyIds: [strategyId],
      })
      const { data } = response
      const { allocations } = data
      const { amountRequired } = allocations[0]
      this.modelBuyInAmounts[strategyId] = Math.ceil(amountRequired)
      this.requiredTotalAllocations = amountRequired + this.requiredTotalAllocations
      return Math.ceil(amountRequired)
    } catch (e) {
      console.log(`Issue in Model Store | ${e.message}`)
    }
  }

  @action('Model Store | getModelCharts')
  getModelCostRequirements = async strategyIds => {
    try {
      const { api } = this.rootStore
      const response = await api.post(`/v0/getFullAllocationRequirements`, { strategyIds })
      const { data } = response
      const { allocations } = data
      let totalAmount = 0
      allocations.forEach(a => {
        this.modelBuyInAmounts[a.strategyId] = Math.ceil(a.amountRequired)
        totalAmount += Math.ceil(a.amountRequired)
      })
      this.requiredTotalAllocations = totalAmount
      return this.requiredTotalAllocations
    } catch (e) {
      console.log(`Issue in Model Store | ${e.message}`)
    }
  }
}
