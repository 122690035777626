
import styled from 'styled-components'

const StyledMarketPlace = styled.div`
.tab-link-group {
    margin-bottom: 30px !important; }
  
  .marketplace-tab-link {
    border-bottom: 3px solid #78C263;
    color: black !important;
    font-size: 16px;
    text-decoration: none !important;
    margin: 10px !important;
    font-weight: bold;
    padding-bottom: 5px; }
  
  .marketplace-tab-link:hover {
    text-decoration: none !important; }
  
  .smart-funds {
    height: 16px;
    width: 78px;
    color: #333333;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
    margin-top: 1em;
  }

  .page-heading {
    height: 49px;
    width: 239px;
    color: #333333;
    font-family: Avenir;
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 2.57px;
    line-height: 49px;
    margin-bottom: 30px;
  }
    `

export default StyledMarketPlace
