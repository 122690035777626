import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Auth from '../../lib/auth'
import DeleteIcon from '@material-ui/icons/Delete'
import LockIcon from '@material-ui/icons/Lock'
import { Success, Confirm } from '../../common/components/Dialogs'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { setProp, formValidation } from './wizardUtils'
import Table from '../Admin/Table'
import Subscribe from './Subscribe'
import moment from 'moment'
import currencyFilter from '../../lib/currency'

const styles = theme => ({
  typography: {
    useNextVariants: true,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
})

class StepComplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reinvestEarnings: false,
      realizeLosses: false,
      reinvestDividends: false,
      targetPublishGroups: [],
      user: {},
    }
  }
  async componentDidMount() {
    const profile = Auth.getProfile()
    const { strategy } = this.props
    const { data: groups } = await axios.get('/v0/strategy-publish-list').catch(e => [])
    const { data: portfolioPositions = [] } = await axios
      .get(`/v0/portfolio-positions/${strategy._id}`)
      .catch(e => [])
    const { data: payload } = await axios
      .get(`/v0/strategy-assignments?strategyId=${strategy._id}`)
      .catch(e => [])
    this.setState({
      user: profile.user,
      groups,
      targetPublishGroups: payload.targetPublishGroups,
      portfolioPositions,
    })
  }
  updateStrategy(a) {
    const { strategy } = this.props
    const { formErrors = [] } = this.state
    setProp(strategy, a.target.name, a.target.value)
    const validationFn = formValidation[a.target.name]
    if (formErrors.includes(a.target.name))
      formErrors.splice(formErrors.findIndex(f => f === a.target.name), 1)
    if (typeof validationFn === 'function') {
      if (!validationFn(a.target.value)) {
        formErrors.push(a.target.name)
      }
    }
    this.setState({ strategy, formErrors })
  }

  saveStrategy() {
    const { strategy } = this.props
    console.log('save strategy', strategy)
    const { _id } = strategy
    let method = 'put'
    if (_id) method = 'put'
    axios[method](`/v0/strategy`, strategy)
      .then(response => response.json())
      .then(json => this.setState({ savedStrategy: json, showSuccessDialog: false }))
      .catch(e => false)
  }

  deleteStrategy() {
    const { strategyId } = this.props
    let method = 'delete'
    if (strategyId) method = 'delete'
    axios[method](`/v0/deleteStrategy/${strategyId}`)
      .then(response => {
        if (response.data.success) {
          this.setState({ showSuccessDialog: false })
          window.document.location.assign(`/strategies`)
        }
      })
      .catch(e => false)
  }
  confirmPublish = () => {
    this.setState({ showConfirmPublishDialog: true })
  }
  async commitPublish() {
    this.setState({ showConfirmPublishDialog: false })
    const { strategyId } = this.props
    const { targetPublishGroups } = this.state
    const response = await axios
      .put('/v0/strategy-publish', { strategyId, targetPublishGroups })
      .catch(e => false)
    console.log('response publish', response)
    this.setState({ showSuccessPublishDialog: true })
    setTimeout(() => this.setState({ showSuccessPublishDialog: false }), 5000)
  }
  confirmDelete = () => {
    this.setState({ showConfirmDeleteDialog: true })
  }

  commitDelete = () => {
    this.setState({ showConfirmDeleteDialog: false, showSuccessDialog: true })
    this.deleteStrategy()
  }

  confirm = () => {
    this.setState({ showConfirmDialog: true })
  }

  commit = () => {
    this.setState({ showConfirmDialog: false, showSuccessDialog: true })
    this.saveStrategy()
  }

  addPublishGroup(item, e) {
    const { targetPublishGroups = [] } = this.state
    if (e.target.checked) {
      if (!targetPublishGroups.includes(item._id)) {
        targetPublishGroups.push(item._id)
      }
    } else {
      targetPublishGroups.splice(targetPublishGroups.indexOf(item._id), 1)
    }
    this.setState({ targetPublishGroups })
  }
  render() {
    const { classes } = this.props
    const { groups = [], user, portfolioPositions = [] } = this.state
    const { userProfile = [] } = user
    const bobAdmin = userProfile.find(p => p.roleCode === 'bobUserAdmin')

    const tableOpts = {
      list: groups,
      listColumns: this.publishListColumns(),
    }
    const positionTableOpts = {
      list: portfolioPositions,
      listColumns: this.portfolioPositionsListColumns(),
    }
    return (
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          {bobAdmin && (
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Chart Automation</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <div className="row">
                    <div className="col-md-12">
                      <Subscribe
                        strategy={this.props.strategy}
                        realizeLosses={this.state.realizeLosses}
                        reinvestEarnings={this.state.reinvestEarnings}
                        reinvestDividends={this.state.reinvestDividends}
                      />
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Position Overview</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div className="col-md-12">
                    <Table {...positionTableOpts} />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className="row">
            <div className="col-md-12"></div>
          </div>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Publish Options</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div className="col-md-12">
                    <Table {...tableOpts} />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className="row">
            <div className="col-md-12"></div>
          </div>
          <div className="row" style={{ width: '100%', height: '100%' }}>
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={this.confirmDelete.bind(this)}
            >
              <DeleteIcon className={`${classes.rightIcon}	${classes.iconSmall}`} />
              Delete
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button + ' pull-right'}
              onClick={this.confirmPublish.bind(this)}
            >
              <AssignmentIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
              Save Assignments
            </Button>
          </div>
          <div className="row" style={{ width: '100%', height: '100%' }}>
            <div className="col-md-12">
              <hr />
            </div>
          </div>

          <Confirm
            show={this.state.showConfirmDialog}
            onHide={() => this.setState({ showConfirmDialog: false })}
            onCommit={this.commit.bind(this)}
            title="Are you sure?"
            body="This change will take effect immediately."
          />
          <Confirm
            show={this.state.showConfirmPublishDialog}
            onHide={() => this.setState({ showConfirmPublishDialog: false })}
            onCommit={this.commitPublish.bind(this)}
            title="Are you sure you want to publish?"
            body={'This change will take effect immediately.'}
          />
          <Success
            show={this.state.showSuccessDialog}
            onHide={() => this.setState({ showSuccessDialog: false })}
            title="Your Portfolio settings have been updated."
          />
          <Success
            show={this.state.showSuccessPublishDialog}
            onHide={() => this.setState({ showSuccessPublishDialog: false })}
            title="Your Portfolio settings have been published."
          />
          <Confirm
            show={this.state.showConfirmDeleteDialog}
            onHide={() => this.setState({ showConfirmDeleteDialog: false })}
            onCommit={this.commitDelete.bind(this)}
            title="Are you sure?"
            body="This complete removes this strategy from your account."
          />
        </div>
      </div>
    )
  }

  publishListColumns() {
    const { targetPublishGroups = [] } = this.state
    const { strategy } = this.props
    return [
      {
        displayName: 'Publish',
        propPath: '/',
        displayFn: item =>
          item.liveFund.includes(strategy._id) ? (
            <LockIcon />
          ) : (
            <Checkbox
              checked={targetPublishGroups.includes(item._id)}
              onClick={this.addPublishGroup.bind(this, item)}
            />
          ),
        filter: false,
        style: {
          width: '10%',
        },
      },
      {
        displayName: 'Group Name',
        propPath: 'roleName',
        style: {
          width: '15%',
        },
      },
    ]
  }
  portfolioPositionsListColumns() {
    return [
      {
        displayName: 'Date/Time',
        propPath: 'timestamp',
        displayFn: item => moment(item).format('MM/DD/YY HH:MM:SS'),
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'State',
        propPath: '/',
        displayFn: item => `${item.state === null ? 'ERROR' : item.state} ${item.side}`,
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Avg $',
        propPath: 'average_price',
        displayFn: item => currencyFilter(Number(item).toFixed(2), '$'),
        default: '0',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Open Shares',
        propPath: 'shares',
        default: '0',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Security',
        propPath: 'symbol',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'User',
        propPath: '/',
        displayFn: item => `${item.user.firstName} ${item.user.lastName}`,
        filterToolTip: true,
        filter: false,
      },
    ]
  }
}

StepComplete.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(StepComplete)
