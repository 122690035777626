import React from 'react'
import PropTypes from 'prop-types'

const FundDetailsIconSvg = ({ fill }) => (
  <svg width="16px" height="10px" viewBox="0 0 16 10">
    <g id="Bob-4.0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Fund-Detail" transform="translate(-45.000000, -220.000000)" fill={fill}>
        <g id="Side-Nav" transform="translate(45.000000, 214.000000)">
          <g id="Details-active">
            <g id="Details-icon-active" transform="translate(0.000000, 6.000000)">
              <rect id="Rectangle" x="0" y="0" width="11" height="2"></rect>
              <rect id="Rectangle" x="0" y="4" width="16" height="2"></rect>
              <rect id="Rectangle" x="0" y="8" width="16" height="2"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

FundDetailsIconSvg.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default FundDetailsIconSvg
