import styled from 'styled-components'
import { patterns, colors, typography } from '../../../../../assets/styles'
import plusIconSvg from '../../../../../assets/icons/plus-icon.svg'

export const StyledClients = styled.div`
  .total-clients {
    position: absolute;
    bottom: 6px;
    left: 1.6rem;
  }
  .alert-button.as-link {
    ${patterns.buttonAsLink};
    color: ${colors.alertRed};
  }
`
export const StyledAddClientsButton = styled.button`
  ${patterns.primaryButton};
  background: transparent;
  position: relative;
  color: ${colors.linkBlue};
  margin-top: 1rem;
  margin-left: 1rem;
  padding-left: 3rem;
  box-shadow: none;
  &:before {
    content: '';
    position: absolute;
    top: 18px;
    left: 19px;
    width: 13px;
    height: 12px;
    background-image: url(${plusIconSvg});
    background-repeat: no-repeat;
  }
  &:hover,
  &:focus {
    background: ${colors.offWhite};
    color: ${colors.linkBlue};
    box-shadow: none;
  }
`

export const StyledUpdateAllocationButton = styled.button`
  ${patterns.primaryButton};
  background: transparent;
  position: relative;
  color: ${colors.linkBlue};
  margin-top: 1rem;
  margin-left: 1rem;
  padding-left: 3rem;
  box-shadow: none;
  &:before {
    content: '';
    position: absolute;
    top: 18px;
    left: 19px;
    width: 13px;
    height: 12px;
    background-repeat: no-repeat;
  }
  &:hover,
  &:focus {
    background: ${colors.offWhite};
    color: ${colors.linkBlue};
    box-shadow: none;
  }
`

export const StyledClientDetailWrapper = styled.div`
.participate-message {
  color: #F70909;
}

.account-info-label{
  color : rgba(0, 0, 0, 0.54);
}

.details-form {
  font-family: 'Avenir LT Std', 'Verdana', sans-serif;
  font-size: 1em
}

.form-name {
  margin-bottom: 2em;
}

.form-description {
  margin-bottom: 2em;
}

.form-reserves {
  margin-bottom: 2em;
}

.form-wrapper {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 60%;
  }
}
.form-group {
  display: block;
  margin-bottom: 2rem;
}
.two-up-group {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  .form-control-group:first-child {
    margin-right: 1rem;
  }
}
.flex-group {
  display: flex;
  margin-bottom: 2rem;
  .form-control-group {
    flex-grow: 1;
    margin-right: 1rem;
  }
}

.checkbox-group {
  padding-left: 36px;
}

.tooltip {
  opacity: 0;
  transition: opacity 200ms ease-in;
  pointer-events: none;
  &.is-open {
    opacity: 1;
  }
}

.tooltip-wrapper {
  position: relative;
}

.tooltip-trigger.as-link {
  ${patterns.buttonAsLink};
  line-height: 0.7;
  align-self: flex-end;
}
.monitoring-section {
  margin: 2rem 0;
  border-top: 1px solid ${colors.borderGrey};
  .section-header {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    font-family: ${typography.baseFontFamily};
    font-size: ${typography.smallBaseFontSize};
    color: ${colors.labelGrey};
  }
  svg {
    margin-right: 1rem;
  }
}
`

