import React from 'react'
import PropTypes from 'prop-types'

const FundPortfoliosIconSvg = ({ fill }) => (
  <svg width="14px" height="14px" viewBox="0 0 14 14">
    <g id="Bob-4.0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Fund-Detail"
        transform="translate(-47.000000, -374.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Side-Nav" transform="translate(45.000000, 214.000000)">
          <g id="Portfolios-inactive" transform="translate(2.000000, 156.000000)">
            <path
              d="M12.4444444,4 L1.55555556,4 C0.7,4 0,4.7 0,5.55555556 L0,16.4444444 C0,17.3 0.7,18 1.55555556,18 L12.4444444,18 C13.3,18 14,17.3 14,16.4444444 L14,5.55555556 C14,4.7 13.3,4 12.4444444,4 Z M5,15 L3,15 L3,10 L5,10 L5,15 Z M8,15 L6,15 L6,7 L8,7 L8,15 Z M11,15 L9,15 L9,12 L11,12 L11,15 Z"
              id="portfolio-icon"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

FundPortfoliosIconSvg.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default FundPortfoliosIconSvg
