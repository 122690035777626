import styled from 'styled-components'

const StyledMarketPlaceList = styled.div`
  .model-container {
    max-width: 1300px;
  }
  .model-list-container {
    height: 600px;
    overflow: auto;
  }
  span.filter-link {
    text-decoration: none !important;
    color: #333333 !important;
    cursor: pointer
  }
  p.filter-label {
    height: 16px;
    width: 53px;
    color: #767676;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
  }

  .filter-container{
    width: 240px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
  }

  .detail-close-sign {
    margin: 11px 24px 0 205px;
    position: absolute;
    cursor: pointer;
  }
  .fund-card {
    margin-bottom: 15px;
  }
  .fund-card .MuiCardContent-root {
    padding-bottom: 10px !important;
  }
  .fund-card-label {
    margin-left: 25px !important;
  }
  .fund-card-label .asset-range {
    color: #767676;
  }
  .fund-card .model-row {
    margin-left: 12%;
    flex-wrap: nowrap;
  }

  .feature-fund {
    position: absolute
    height: 112px;
    width: 62px;
    background-image: linear-gradient(108deg, #78c263 -35%, #07b0c8 55%, #096775 93%);
  }

  .detail-wrapper.MuiGrid-item {
    max-width: 60%;
  }
  .fund-detail-card.model-detail-overlapped {
    position: relative;
    left: -10%;
    display: inline-block;
  }
  .fund-detail-card.MuiPaper-rounded {
    min-height: 600px;
    width: 55%;
  }
  .fund-detail-header {
    height: 150px;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 3px 3px 0 0;
    background: linear-gradient(180deg, #414141 0%, #373737 100%);
  }
  h5.MuiTypography-h5.fund-detail-name {
    color: white;
    font-size: 18px;
    margin-top: 15px;
  }
  button.MuiButton-contained.add-to-collection-button {
    border-radius: 20px;
    background-color: #32c5ff;
    font-size: 11px;
    position: relative;
    bottom: -34px;
  }
  button.MuiButton-contained.added-to-collection-button {
    border-radius: 20px;
    background-color: rgb(54, 56, 56);
    font-size: 11px;
    position: relative;
    bottom: -34px;
  }
  .model-list .MuiChip-root.model-chip {
    margin: 5px 0;
  }
  .model-detail-card.MuiPaper-rounded {
    min-height: 600px;
    width: 55%;
    display: inline-block;
    position: relative;
    margin-left: -10%;
  }
  .model-detail-fund-name.MuiTypography-h6 {
    font-size: 10px;
    padding-top: 30px;
  }
  .model-detail-name.MuiTypography-h5 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .model-detail-description .MuiTypography-body2 {
    font-size: 12px;
    margin-top: 15px;
  }
  .allocations-list .MuiTypography-body2 {
    font-size: 12px;
  }

  .MuiGrid-item.model-detail-securities,
  .MuiGrid-item.model-detail-allocations,
  .MuiGrid-item.model-detail-description,
	.MuiGrid-item.model-detail-sectors,
	.MuiGrid-item.model-fee {
    margin-left: 15px;
  }
  .model-detail-sectors .sector-list,
  .fund-detail-models .model-list,
  .model-detail-allocations .allocations-list {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
  }
  .MuiGrid-item.fund-detail-size,
  .MuiGrid-item.fund-detail-models,
	.MuiGrid-item.fund-detail-type,
	.MuiGrid-item.model-fee {
    margin-left: 15px;
  }
  label.detail-header {
    font-size: 10px;
    color: #767676;
    text-transform: uppercase;
  }
  .MuiChip-root.type-chip {
    height: 20px;
    width: 52px;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
  }
  .MuiChip-root.model-chip {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    border: 1px solid lightgray !important;
    margin: 10px;
  }
  .MuiChip-root.security-chip {
    height: 20px;
    width: 64px;
    border-color: lightblue;
    padding: 3px;
    font-size: 10px;
  }
  .MuiAvatar-root {
    font-size: 16px !important;
  }

  .search-box {
    height: 29px;
    width: 386px;
    border-radius: 14.5px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
    margin-bottom: 1em
    margin-top: 1em
  }

  .search-box-icon {
    height: 17.49px;
    width: 17.49px;
    margin: 6px 355.51px 5.51px 13px;
    position: absolute;
  }

  .search-box-divider {
      box-sizing: border-box;
      height: 18px;
      width: 1px;
      border: 1px solid #EBEBEB;
      margin: 6px 345px 5px 40px;
      position: absolute;
  }

  .search-box-content {
    height: 16px;
    width: 313px;
    color: #CDCDCD;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
      margin: 6px 15px 7px 58px;
      position: absolute;
      border: none;
      outline: none;
  }

  .filter-asset-manager-name {
    height: 16px;
    width: 187px;
    color: #333333;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
  }

  .feature-line-divider {
    box-sizing: border-box;
    height: 1px;
    width: 520px;
    border: 1px solid #EBEBEB;
    margin-bottom: 1em
    margin-top: 1em
  }

  .number-smartfunds-message{
    height: 16px;
    width: 155px;
    color: #767676;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.06px;
    line-height: 16px;
    margin-bottom: 1em
  }

  .recommended-by-bob {
    height: 16px;
    width: 144px;
    color: #333333;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.86px;
    line-height: 16px;
    margin-bottom: 1em
  }

  .model-logo {
    padding-bottom: 1em;
  }

  .filter-box {
    margin: 1em 0px 1em .05em;
  }

  .filer-chip-content {
    background-color: #1A4E63;
    color: #FFFFFF;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.06px;
    line-height: 16px;
    margin-bottom: .05em
    margin-top: .05em
  }

  .MuiChip-deleteIcon {
    color: #FFFFFF !important;
	}
`

export default StyledMarketPlaceList
