import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

const StyledLoadingIndicator = styled.div`
  .loading-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin: 0 auto;
    align-items: center;
    margin-top: 15%;
  }
  .loading-text {
    margin-bottom: 2rem;
  }
`

const LoadingIndicator = props => (
  <StyledLoadingIndicator>
    <div className="loading-wrapper">
      <p className="loading-text">{props.loadingText}</p>
      <CircularProgress color="inherit" />
    </div>
  </StyledLoadingIndicator>
)

LoadingIndicator.propTypes = {
  loadingText: PropTypes.string.isRequired,
}

export default LoadingIndicator
