import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Updating, Confirm, Success, Failure } from '../../common/components/Dialogs'
import { automationFees, strategyFees } from './FeeSchedules'
import auth from '../../lib/auth'
import axios from 'axios'

const costToRange = (feeSchedule, budget) => {
  const { range } = feeSchedule
  const [from, to] = range
  return to ? budget >= from && budget <= to : budget >= from
}
export default class Subscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subscribed: props.strategy.Subscribed,
      budget: props.strategy.Budget,
    }
  }
  onBudgetChange = e => {
    let val = e.target.value
    if (e.target.value.length === 0) val = 0
    if (val[val.length - 1] === '.') {
      this.setState({ budget: val })
    } else {
      this.setState({ budget: parseFloat(val) })
    }
    // console.log(val, val[val.length - 1]);
    // this.setState({ budget: parseFloat(val) });
  }

  automationFeeCalc(budget, tradeType) {
    if (budget === 0) return budget
    if (!budget) return 0
    let automationFeeObject = automationFees.find(f => costToRange(f, budget))

    // this poorly addresses a bug for a malformed budget value.
    if (!automationFeeObject) automationFeeObject = automationFees.find(f => costToRange(f, 10000))

    return ['day', 'any'].includes(tradeType)
      ? automationFeeObject.dayTradeRate
      : automationFeeObject.swingTradeRate
  }
  strategyFeeCalc(budget) {
    if (budget === 0) return budget
    if (!budget) return 0
    if (this.props.strategy.Owner === auth.getProfile().sub) return 0
    const strategyFeeObject = strategyFees.find(f => costToRange(f, budget))
    return strategyFeeObject.managingRate
  }
  invoice({ automationFee, strategyFee }) {
    return (
      <table className="table-striped" width="100%">
        <tbody>
          <tr>
            <th>Item</th>
            <th>Description</th>
            <th>Cost</th>
          </tr>
          <tr>
            <td>Automation</td>
            <td></td>
            <td>${automationFee.toFixed(2)}</td>
          </tr>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <td></td>
            <td>
              <b>${automationFee.toFixed(2)}</b>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
  startSubscriptionSave = () => {
    this.setState({ showConfirmDialog: true })
  }
  subscriptionSave = async () => {
    try {
      const { strategy, reinvestEarnings, realizeLosses, reinvestDividends } = this.props
      const { StrategyDef = {} } = strategy
      const { tradeType = 'day' } = StrategyDef
      const { budget = 0 } = this.state
      const automationFee = this.automationFeeCalc(budget, tradeType)
      const strategyRate = this.strategyFeeCalc(budget)
      // const strategyFee = budget * strategyRate;
      this.setState({ showUpdateDialog: true, showConfirmDialog: false })
      await axios.put('/v0/portfolio', {
        strategy: this.props.strategy,
        budget: this.state.budget,
        reinvestDividends,
        realizeLosses,
        reinvestEarnings,
      })
      console.log({ automationFee, strategyRate, strategyID: this.props.strategy._id })
      // const createdSub = await axios.post('/v0/createsubscription', {automationFee, strategyID: this.props.strategy._id});
      // console.log('createdSub', createdSub);
      // this.props.onSubscribe(this.props.strategy);
      this.setState({ subscribed: true, showUpdateDialog: false, showSuccessDialog: true })
    } catch (e) {
      this.setState({ failureMessage: e.message, showUpdateDialog: false, showFailureDialog: true })
    }
  }
  render() {
    const { strategy } = this.props
    const { StrategyDef = {} } = strategy
    const { tradeType = 'day' } = StrategyDef
    const {
      budget = 0,
      showConfirmDialog,
      showSuccessDialog,
      showFailureDialog,
      showUpdateDialog,
      subscribed,
    } = this.state
    const automationFee = this.automationFeeCalc(budget, tradeType)
    const strategyRate = this.strategyFeeCalc(budget)
    const strategyFee = budget * strategyRate

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <TextField
              label="Budget Amount"
              value={budget}
              name="budget"
              onChange={this.onBudgetChange}
              fullWidth={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">{this.invoice({ automationFee, strategyFee })}</div>
        </div>
        <div className="row">
          <div className="col-md-12 align-center">
            {subscribed && (
              <Button className="bob-button" disabled onClick={this.startSubscriptionSave}>
                <Glyphicon glyph="check" />
                &nbsp;Subscribed
              </Button>
            )}
            {!subscribed && (
              <Button className="bob-button" onClick={this.startSubscriptionSave}>
                <Glyphicon glyph="credit-card" />
                &nbsp;Subscribe
              </Button>
            )}
          </div>
        </div>
        <Confirm
          show={showConfirmDialog}
          title="Please confirm subscription"
          commitMsg="Subscribe"
          body={`We will charge your account $${automationFee.toFixed(2)}`}
          onHide={() => this.setState({ showConfirmDialog: false })}
          onCommit={this.subscriptionSave}
        />
        <Updating
          title="Completing your order"
          show={showUpdateDialog}
          onHide={() => this.setState({ showUpdateDialog: false })}
        />
        <Success
          show={showSuccessDialog}
          onHide={() => this.setState({ showSuccessDialog: false })}
        />
        <Failure
          show={showFailureDialog}
          onHide={() => this.setState({ showFailureDialog: false })}
        />
      </div>
    )
  }
}

Subscribe.propTypes = {
  strategy: PropTypes.any.isRequired,
  reinvestEarnings: PropTypes.any.isRequired,
  realizeLosses: PropTypes.any.isRequired,
  reinvestDividends: PropTypes.any.isRequired,
}
