import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Table from '../../common/components/Table'
import { Card } from '@material-ui/core'
import Widget from '../../common/components/Widget'

@inject(({ store: { auth, app, clientConnect } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    getCalls: clientConnect.getCalls,
    callPoller: clientConnect.callPoller,
    calls: clientConnect.calls,
  }
})
@observer
export default class ClientConnect extends Component {
  state = {}

  componentDidMount = async () => {
    window.document.title = 'Bob | Identify'
    const { getCalls, isAuthenticated, callPoller, calls } = this.props
    await isAuthenticated()
    await getCalls()
    console.log(calls)
    await callPoller()
  }

  render = () => {
    const { calls } = this.props
    console.log(calls.length)
    const clientTableOpts = {
      rowsPerPage: 100,
      list: calls,
      listColumns: this.listColumns(),
    }
    return (
      <div className="row" style={{ width: '85%' }}>
        <div className="row">
          <div className="col-md-12">
            <Card>
              <Widget {...clientTableOpts} classProps={{ columnSize: 12 }} body={Table} />
            </Card>
          </div>
        </div>
      </div>
    )
  }
  listColumns() {
    return [
      {
        displayName: 'From',
        propPath: 'From',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'First Name',
        propPath: 'client/firstName',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Last Name',
        propPath: 'client/lastName',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Phone',
        propPath: 'client/phone',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Email',
        propPath: 'client/email',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Address',
        propPath: 'client/address',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'City',
        propPath: 'client/city',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'State',
        propPath: 'client/state',
        filterToolTip: true,
        filter: false,
      },
    ]
  }
}
