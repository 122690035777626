import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import StyledUserProfileMenu from './styles'
import LogoutIconSvg from '../../../../assets/icons/logoutIconSvg'
import ChevronDownIcon from '../../../../assets/icons/chevron-down-icon.svg'

class UserProfileMenu extends Component {
  state = {
    isImpersonationDropdownOpen: false,
  }

  toggleImpersonationMenu = () => {
    const { isImpersonationDropdownOpen } = this.state
    this.setState({ isImpersonationDropdownOpen: !isImpersonationDropdownOpen })
  }

  render() {
    const { user, logout, impersonationData, initiateImpersonation, stopImpersonation } = this.props
    const { isImpersonationDropdownOpen } = this.state
    const { userType } = user
    const accountManager = user.userProfile.find(p => p.roleCode === 'accountManager')
    const userAdmin = user.userProfile.find(p => p.roleCode === 'bobUserAdmin')
    return (
      <StyledUserProfileMenu>
        {impersonationData.impersonationSession ? (
          <li>
            <button
              className="dropdown-button end-impersonation-button"
              onClick={stopImpersonation}
            >
              End impersonation
            </button>
          </li>
        ) : null}
        <li>
          <a href="/profile" className="dropdown-link">
            My profile
          </a>
        </li>
        {userType === 'retail' && (<li>
        <a href="/totumRisk" className="dropdown-link">
            My Risk Score
          </a>
        </li>)
        }
        {accountManager ? (
          <li>
            <a href="/companyprofile" className="dropdown-link">
              Company profile
            </a>
          </li>
        ) : null}
        {userAdmin ? (
          <Fragment>
            <li className="has-divider">
              <a href="/admin" className="dropdown-link">
                Admin
              </a>
            </li>
            <li>
              <a href="/instruction" className="dropdown-link">
                Instruction
              </a>
            </li>
            <li>
              <a href="/charts" className="dropdown-link">
                Charts
              </a>
            </li>
            <li>
              <button
                className="dropdown-button impersonation-button"
                onClick={() => this.toggleImpersonationMenu()}
              >
                Select profile
                <img src={ChevronDownIcon} alt="Select profile chevron icon" />
              </button>
              {isImpersonationDropdownOpen ? (
                <ul className="impersonation-menu">
                  {impersonationData.users.map((usr, key) => (
                    <li key={key}>
                      <button
                        className="impersonation-user"
                        onClick={() => initiateImpersonation(usr)}
                      >
                        {usr.firstName} {usr.lastName}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          </Fragment>
        ) : null}
        <li className="has-divider">
          <button className="dropdown-button log-out-button" onClick={e => logout(e)}>
            Log out
            <LogoutIconSvg />
          </button>
        </li>
      </StyledUserProfileMenu>
    )
  }
}

UserProfileMenu.defaultProps = {
  impersonationData: {
    users: [],
    impersonationSession: '',
  },
}

UserProfileMenu.propTypes = {
  user: PropTypes.shape({
    userProfile: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  logout: PropTypes.func.isRequired,
  initiateImpersonation: PropTypes.func.isRequired,
  stopImpersonation: PropTypes.func.isRequired,
  impersonationData: PropTypes.shape({}),
}

export default UserProfileMenu
