import styled from 'styled-components'
import { patterns, typography, colors } from '../../../../assets/styles'

export const StyledCreateFund = styled.div`
  ${patterns.smallViewContainer};
  .create-fund-card {
    ${patterns.defaultCard};
    padding: 2rem;
    .create-fund-card-header {
      display: flex;
    }
    .create-fund-card-title {
      font-size: ${typography.largeFontSize};
      font-weight: ${typography.baseFontWeight};
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }
  .card-footer {
    display: flex;
    margin-top: 3rem;
  }
  .submit-fund-button {
    ${patterns.primaryButton};
    margin-right: 2rem;
  }
  .cancel-button,
  .tooltip-trigger.as-link {
    ${patterns.buttonAsLink}
  }
`

export const StyledFormWrapper = styled.div`
  .details-form {
    font-family: 'Avenir LT Std', 'Verdana', sans-serif;
    font-size: 1em
  }
  
  .form-name {
    margin-bottom: 2em;
  }

  .form-description {
    margin-bottom: 2em;
  }

  .form-reserves {
    margin-bottom: 2em;
  }

  .form-wrapper {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 60%;
    }
  }
  .form-group {
    display: block;
    margin-bottom: 2rem;
  }
  .two-up-group {
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    .form-control-group:first-child {
      margin-right: 1rem;
    }
  }
  .flex-group {
    display: flex;
    margin-bottom: 2rem;
    .form-control-group {
      flex-grow: 1;
      margin-right: 1rem;
    }
  }

  .checkbox-group {
    padding-left: 36px;
  }

  .tooltip {
    opacity: 0;
    transition: opacity 200ms ease-in;
    pointer-events: none;
    &.is-open {
      opacity: 1;
    }
  }

  .tooltip-wrapper {
    position: relative;
  }

  .tooltip-trigger.as-link {
    ${patterns.buttonAsLink};
    line-height: 0.7;
    align-self: flex-end;
  }
  .monitoring-section {
    margin: 2rem 0;
    border-top: 1px solid ${colors.borderGrey};
    .section-header {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      font-family: ${typography.baseFontFamily};
      font-size: ${typography.smallBaseFontSize};
      color: ${colors.labelGrey};
    }
    svg {
      margin-right: 1rem;
    }
  }
`
