export default class Backtest {
  constructor(iframe) {
    this.iframe = iframe
  }

  async run(fundStrategy, strategy, fromDate, toDate, initialCash, commission, progressCB) {
    const { allocation : strategyAllocation } = strategy
    this.type = fundStrategy && fundStrategy.type ? fundStrategy.type : 'threshold'
    this.reinvestEarnings =
      fundStrategy && fundStrategy.reinvestEarnings !== undefined
        ? fundStrategy.reinvestEarnings
        : true
    this.reinvestDividends =
      fundStrategy && fundStrategy.reinvestDividends !== undefined
        ? fundStrategy.reinvestDividends
        : true
    this.symbols = Array.from(new Set(strategy.charts.map(c => c.security)))
    this.securityAllocation =
      this.type === 'uniform' ? 1 / this.symbols.length : strategy.allocation
    this.strategy = strategy
    this.fromDate = fromDate
    this.toDate = toDate
    this.initialCash = initialCash
    this.commission = commission
    this.progressCB = progressCB
    this.index = 0
    this.prices = {}
    this.positions = {}
    this.statistics = []
    this.minutes = Number.MAX_VALUE
    this.strategyAllocation = +strategyAllocation
    this.maxTrades = this.getMaxTrades()
    this.currentTrades = []
    return await this.loadChart()
  }

  getMaxTrades(){
     //if(this.symbols.length > 10) return 10
     if(this.strategyAllocation === 1) return 1
     return Math.floor(100 / (this.strategyAllocation * 100))
  }

  async loadChart() {
    let iframe = this.iframe.current.contentWindow || this.iframe.current
    for (let i = 0; i < this.symbols.length; i++) {
      iframe.location.reload()
      let symbol = this.symbols[i]
      let security = this.strategy.charts.find(c => c.security === symbol)
      if (security && security.charts && security.charts.length > 0) {
        let period = this.getPeriod(this.isJSON(security.charts[0].layout) ? security.charts[0].layout : JSON.parse(security.charts[0].layout))
        let minutes = this.getMinutes(period)
        if (minutes < this.minutes) {
          this.minutes = minutes
          this.periodicity = this.getPeriodicity(period)
        }
        iframe = await this.waitForIFrame(symbol, iframe, security)
      }
      if (this.progressCB) this.progressCB(((i + 1) * 100) / this.symbols.length)
    }
    iframe.location.reload()
    return this.backtest()
  }

  async waitForIFrame(symbol, iframe, security) {
    await new Promise(async resolve => {
      this.messageHandler = event => {
        //if (!/localhost:3000$|letbob.com$/.test(event.origin)) return
        if (event.data === 'ready') {
          event.source.postMessage(
            { symbol, chartData: JSON.stringify(security), fromDate: this.fromDate, toDate: this.toDate },
            event.origin
          )
        } else if (event.data.prices) {
          this.loadData(symbol, event.data.position, event.data.prices)
          window.removeEventListener('message', this.messageHandler)
          resolve()
        }
      }
      window.addEventListener('message', this.messageHandler)
      //iframe.postMessage('ready?', window.location.origin);
    })
    return iframe
  }

  async loadData(symbol, position, prices) {
    if (!position || !prices) return
    this.positions[symbol] = position
    for (let type in position) {
      if (!position[type].instructions) continue
      position[type].instructions.forEach(i => (i.symbol = symbol))
    }
    let previousClose = null
    for (let data of prices) {
      if (data.DT < this.fromDate) {
        previousClose = data
      } else if (data.DT <= this.toDate.valueOf() + 24 * 60 * 60 * 1000) {
        this.addPrice(data, symbol)
      }
    }
    if (previousClose) this.addPrice(previousClose, symbol)
  }

  addPrice(data, symbol) {
    let date = this.dateToYYYYMMDD(data.DT)
    if (!this.prices[date]) this.prices[date] = {}
    this.prices[date][symbol] = data.Close
  }

  backtest() {
    if (this.type === 'uniform') this.strategy.allocation = 1 / this.symbols.length
    let results = []
    let portfolio = { cash: this.initialCash, earnings: 0, securities: {} }
    for (let symbol of this.symbols) {
      portfolio.securities[symbol] = { shares: 0, allocation: 0, open: {} }
    }
    const dates = Object.keys(this.prices)
    dates.sort()
    let statistics = {
      concurrentTrades : { max: this.maxTrades },
      gross: { name: 'Gross Profit', portfolio: 0, comp: {} },
      commission: { name: 'Commissions', portfolio: 0, comp: {} },
      net: { name: 'Net Profit', portfolio: 0, comp: {} },
      CAGR: { name: 'Annualized Profit', portfolio: 0, comp: {} },
      trades: { name: 'Number of Trades', portfolio: 0, comp: {} },
      high: { name: 'Period High', portfolio: 0, comp: {} },
      drawdown: { name: 'Max Drawdown', portfolio: { dollar: 0, percent: 0 }, comp: {} },
    }
    for (let symbol of this.symbols) {
      statistics.gross[symbol] = 0
      statistics.commission[symbol] = 0
      statistics.net[symbol] = 0
      statistics.CAGR[symbol] = 0
      statistics.trades[symbol] = 0
      statistics.high[symbol] = 0
      statistics.drawdown[symbol] = { dollar: 0, percent: 0 }
    }
    let startDate = this.dateToYYYYMMDD(this.fromDate)
    for (let date of dates) {
      let prices = this.prices[date]
      let instructions = []
      if (date >= startDate) {
        for (let symbol in this.positions) {
          let position = this.positions[symbol]
          instructions = instructions.concat(
            position.LONG.instructions.filter(i => this.dateToYYYYMMDD(i.DT) === date)
          )
        }
      }
      //sort by date first, then Exits, then entries.
      instructions.sort((a, b) => (a.DT - b.DT === 0 ? b.side.localeCompare(a.side) : a.DT - b.DT))
      let value = this.executeLONG(portfolio, instructions, prices, statistics)
      //value = {orders:[...], equity, cash}
      value.timestamp = date + 'T14:30:00.000Z'
      for (let symbol of this.symbols) {
        value[symbol] = { price: prices[symbol] }
      }
      results.push(value)
    }
    for (let symbol in portfolio.securities) {
      let shares = portfolio.securities[symbol].shares
      if (shares === 0) continue
      let price = this.prices[dates[dates.length - 1]][symbol]
      statistics.gross[symbol] += shares * price
      statistics.gross.portfolio += shares * price
      statistics.commission[symbol] += this.commission
      statistics.commission.portfolio += this.commission
      statistics.net[symbol] += shares * price - this.commission
      statistics.net.portfolio += shares * price - this.commission
    }

    if (results.length > 0) {
      const last = results[results.length - 1]
      const first = results[0]
      const years =
        (new Date(last.timestamp) - new Date(first.timestamp)) / 1000 / 60 / 60 / 24 / 365
      statistics.CAGR.portfolio = {
        percent: Math.pow(statistics.net.portfolio / this.initialCash + 1, 1 / years) - 1,
      }
      for (let symbol of this.symbols) {
        statistics.CAGR[symbol] = {
          percent: Math.pow(statistics.net[symbol] / this.initialCash + 1, 1 / years) - 1,
        }
      }
    }

    this.formatStatistics(statistics)

    return { results, statistics }
  }

  formatStatistics(statistics, symbols) {
    if (!symbols) symbols = this.symbols.concat(['portfolio'])
    for (let symbol of symbols) {
      statistics.gross[symbol] = {
        dollar: statistics.gross[symbol],
        percent: statistics.gross[symbol] / this.initialCash,
      }
      statistics.commission[symbol] = { dollar: statistics.commission[symbol] }
      statistics.net[symbol] = {
        dollar: statistics.net[symbol],
        percent: statistics.net[symbol] / this.initialCash,
      }
      statistics.trades[symbol] = { text: statistics.trades[symbol].toFixed(0) }
      statistics.high[symbol] = { dollar: statistics.high[symbol] }
    }
  }

  threshold(symbol, state, states, percentage) {
    if (state.rating === 'sell') {
      state.allocation = 0
    } else if (state.rating === 'buy') {
      let sum = 0
      for (let sym in states) {
        if (sym !== symbol && states[sym].allocation) {
          sum += states[sym].allocation
        }
      }

      state.allocation = Math.min(1 - sum, percentage)
    }
  }

  uniform(symbol, state, states, percentage) {
    state.allocation = percentage
  }

  executeLONG(portfolio, instructions, prices, statistics) {
    let orders = []
    let equity = this.getEquity(portfolio, prices)
    let cashPerSecurity =
      (this.reinvestEarnings ? equity : Math.min(equity, this.initialCash)) *
      this.securityAllocation
    for (let i = 0; i < instructions.length; i++) {
      let instruction = instructions[i]
      let symbol = instruction.symbol
      if (!portfolio.securities[symbol])
        portfolio.securities[symbol] = { shares: 0, allocation: 0, open: {} }
      let security = portfolio.securities[symbol]
      let price = instruction.limit
      let afford = Math.floor((portfolio.cash - this.commission) / price)
      let shares = 0
      let allocation = 0
      if (instruction.side === 'EXIT' && this.type === 'threshold') {
        for (let entry of instruction.entries) {
          if (typeof entry === 'string') entry = { id: entry, percentage: 1 }
          let entryInstruction = security.open[entry.id]
          if (!entryInstruction) continue //over-allocation meant an entry was skipped
          allocation -= entryInstruction.allocation * entry.percentage
          let entryShares = Math.ceil(entryInstruction.shares * entry.percentage)
          entryShares = Math.min(entryInstruction.remainingShares, entryShares)
          shares -= entryShares
          entryInstruction.remainingShares -= entryShares
          if (entryInstruction.remainingShares === 0) delete security.open[entry.id]
        }
      } else if (instruction.side === 'ENTER' && this.type === 'threshold') {
        allocation = Math.min(1 - security.allocation, instruction.allocation)
        shares = Math.floor((cashPerSecurity / price) * allocation)
        shares = Math.min(shares, afford)
       if (shares > 0)
          security.open[instruction.id] = {
            id: instruction.id,
            shares,
            allocation,
            remainingShares: shares,
          }
      } else if (this.type === 'uniform') {
        let desired = Math.floor(cashPerSecurity / price)
        if (
          (desired < security.shares && instruction.side === 'EXIT') ||
          (desired > security.shares && instruction.side === 'ENTER')
        ) {
          shares = desired - security.shares
        }
        shares = Math.min(shares, afford)
      }
      if (shares !== 0) {
        if(shares > 0 && this.currentTrades.length === this.maxTrades)continue
        if(shares < 0 && !this.currentTrades.find( s=>s===symbol))continue
        equity -= this.commission
        security.shares += shares
        if (security.shares < 0) debugger
        security.allocation += allocation
        portfolio.cash -= price * shares + this.commission
        statistics.trades[symbol]++
        statistics.trades.portfolio++
        statistics.gross[symbol] -= shares * price
        statistics.gross.portfolio -= shares * price
        statistics.commission[symbol] += this.commission
        statistics.commission.portfolio += this.commission
        statistics.net[symbol] -= shares * price + this.commission
        statistics.net.portfolio -= shares * price + this.commission
        if(shares > 0 && this.currentTrades.length < this.maxTrades){
          this.currentTrades.push(symbol)
        } else{
          let index = this.currentTrades.indexOf(symbol)
          this.currentTrades.splice(index, 1)
        }
        orders.push({
          side: shares > 0 ? 'buy' : 'sell',
          time: instruction.DT,
          symbol,
          shares: Math.abs(shares),
          price,
        })
      }
    }

    for (let symbol in portfolio.securities) {
      let security = portfolio.securities[symbol]
      let symbolEquity =
        statistics.net[symbol] +
        security.shares * prices[symbol] -
        (security.shares > 0 ? this.commission : 0)
      if (symbolEquity > statistics.high[symbol]) statistics.high[symbol] = symbolEquity
      const dollar = statistics.high[symbol] - symbolEquity
      const percent = -dollar / (cashPerSecurity + statistics.high[symbol])
      if (percent < statistics.drawdown[symbol].percent)
        statistics.drawdown[symbol] = { dollar, percent }
    }

    if (equity > statistics.high.portfolio) statistics.high.portfolio = equity
    const dollar = statistics.high.portfolio - equity
    const percent = -dollar / statistics.high.portfolio
    if (percent < statistics.drawdown.portfolio.percent)
      statistics.drawdown.portfolio = { dollar, percent }

    if (!this.reinvestEarnings && equity > this.initialCash) {
      let earnings = Math.min(equity - this.initialCash, portfolio.cash)
      portfolio.cash -= earnings
      portfolio.earnings += earnings
      equity -= earnings
    }

    return { orders, equity, cash: portfolio.cash, earnings: portfolio.earnings }
  }

  getEquity(portfolio, prices) {
    let equity = portfolio.cash
    for (let symbol in portfolio.securities) {
      if (!prices[symbol]) continue
      let security = portfolio.securities[symbol]
      equity += security.shares * prices[symbol]
    }
    return equity
  }

  dateToYYYYMMDD(d) {
    var yyyy = d.getFullYear().toString()
    var mm = (d.getMonth() + 1).toString().padStart(2, '0')
    var dd = d
      .getDate()
      .toString()
      .padStart(2, '0')
    return `${yyyy}-${mm}-${dd}`
  }

  getPeriod(layout) {
    let { timeUnit, periodicity, interval } = layout
    if (!timeUnit) {
      timeUnit = 'day'
      interval = 1
    }
    let resolution = periodicity * interval
    if (resolution % 60 === 0 && timeUnit === 'minute') {
      resolution /= 60
      timeUnit = 'hour'
    }
    if (timeUnit === 'minute') timeUnit = 'min'
    return resolution + timeUnit
  }

  getMinutes(period) {
    let [, interval, timeUnit] = period.match(/([0-9]+)(.*)/)
    interval = +interval
    if (timeUnit === 'hour') interval *= 60
    if (timeUnit === 'day') interval *= 1440
    return interval
  }

  getPeriodicity(period) {
    let [, interval, timeUnit] = period.match(/([0-9]+)(.*)/)
    let periodicity = 1
    if (timeUnit === 'hour') {
      timeUnit = 'minute'
      interval *= 60
    }
    if (timeUnit === 'min') timeUnit = 'minute'
    if (timeUnit !== 'minute') {
      interval = timeUnit
      timeUnit = null
    } else {
      if (interval >= 15) {
        periodicity = interval / 15
        interval = 15
      } else if (interval >= 5) {
        periodicity = interval / 5
        interval = 5
      } else {
        periodicity = interval
        interval = 1
      }
    }
    return { periodicity, interval, timeUnit }
  }

  isJSON(data){
    var ret = false;
    try {
       JSON.parse(data);
    }catch(e) {
       ret = true;
    }
    return ret;
  }
}
