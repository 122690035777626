import auth0 from 'auth0-js'
import { action, observable, set } from 'mobx'
import Profile from './models/profile'

export default class Auth {
  @observable profile = Profile
  @observable authenticated = false
  @observable impersonationUser = {}

  auth0 = new auth0.WebAuth({
    domain: 'daytradingbob.auth0.com',
    clientID: 'ofS6Hw0H0NH3sSPnk9GfKMVdXPLRmxI4',
    redirectUri: `${window.location.origin}/callback`,
    audience: 'https://daytradingbob.auth0.com/userinfo',
    responseType: 'token id_token',
    scope: 'openid profile',
  })

  constructor(rootStore) {
    this.rootStore = rootStore
    this.api = rootStore.api
    this.user = rootStore.user
    this.log = rootStore.log
    this.totumRisk = rootStore.totumRisk
  }

  @action('Auth Store | setProfile')
  setProfile = profile => {
    set(this.profile, profile)
    return this.profile
  }

  @action('Auth Store | login')
  login = () => {
    this.auth0.authorize()
  }

  @action('Auth Store | logout')
  logout = () => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('expires_at')
    sessionStorage.removeItem('profile')
    this.authenticated = false
    return this.redirectToLogin()
  }

  @action('Auth Store | handelAuthentication')
  handleAuthentication = async () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash(async (err, authResult) => {
        if (authResult && authResult.idToken && authResult.idTokenPayload) {
          try {
            this.api.defaults.headers.common.Authorization = `Bearer ${authResult.idToken}`
            const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
            const profile = authResult.idTokenPayload
            const result = await this.api.get('/v0/user.getUser')
            const {
              data: { user },
            } = result
            const token = authResult.idToken
            const isAuthenticated = true
            sessionStorage.setItem('token', token)
            sessionStorage.setItem('expires_at', expiresAt)
            sessionStorage.setItem('profile', JSON.stringify(profile))
            if (!user || !user.UserId) {
              await this.user.addNewUser(profile)
              sessionStorage.setItem('user', JSON.stringify(this.user.currentUser))
            } else {
              this.user.setUser(user)
              sessionStorage.setItem('user', JSON.stringify(user))
            }
            this.setProfile({
              isAuthenticated,
              token,
              profile,
              user: this.user.currentUser,
              expiresAt,
            })
            resolve()
          } catch (e) {
            return reject(e)
          }
        } else if (err) {
          reject(err)
        }
      })
    })
  }

  @action('Auth Store | redirectToLogin')
  redirectToLogin = () => {
    if (!/\/login/.test(window.location)) window.location = '/login'
  }

  @action('Auth Store | isAuthenticated')
  isAuthenticated = async (isLogin) => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(sessionStorage.getItem('expires_at'))
    if (!(new Date().getTime() < expiresAt)) return this.redirectToLogin()
    const token = sessionStorage.getItem('token')
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`
    const result = await this.api.get('/v0/user.getUser')
    const {
      data: { user },
    } = result
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    this.user.setUser(user)
    this.setProfile({ isAuthenticated: true, token, profile, user, expiresAt })
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`
    this.authenticated = true
    await this.totumRisk.getRiskForm()
    return this.authenticated
  }

  @action('Auth Store | setUserProfile')
  setUserProfile = async () => {
    try {
      const result = await this.api.get('/v0/user.getUser')
      const {
        data: { user: userData },
      } = result
      sessionStorage.setItem('user', JSON.stringify(userData))
      await this.user.setUser(userData)
    } catch (e) {
      this.log.warn(`ISSUE In AuthStore | ${e.message}`)
    }
  }

  @action('Auth Store | isImpersonating')
  isImpersonating = () => {
    return typeof this.impersonationUser !== 'undefined'
  }

  @action('Auth Store | isImpersonating')
  setImpersonation = async user => {
    this.impersonationUser = user
    await this.setUserProfile()
  }
}
