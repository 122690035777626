import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Pick } from '../../../../common/components/Dialogs'
import currencyFilter from '../../../../lib/currency'

import Table from '../../../../common/components/Table'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getFundQualifiedClients: user.getFundQualifiedClients,
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
    currentUser: user.currentUser,
  }
})
@observer
class ClientList extends Component {
  constructor(props) {
    super(props)
    this.state = { strategies: [], width: 4, filters: [] }
  }

  async componentDidMount() {
    await this.getClients()
  }

  async getClients() {
    const {
      getFundQualifiedClients,
      currentFund: fund,
      currentUser: { userType },
    } = this.props
    const { clients: fundClients } = fund
    this.setState({ loading: true })
    let clients = fundClients
    if (userType === 'institutional') clients = await getFundQualifiedClients()
    this.setState({ fund, clients, loading: false })
  }

  async pickHandler(e, client) {
    const { setCurrentFund, currentFund: fund } = this.props
    const { clients: fundClients } = fund
    let clientPosition = null
    const foundClient = fundClients.find((c, position) => {
      clientPosition = position
      return c._id === client._id
    })
    if (e.target.checked && !foundClient) {
      fund.clients.push({
        name: `${client.firstName} ${client.lastName}`,
        _id: client._id,
        bobBudget: +client.bobBudget,
        bobBuyingPower: +client.bobBuyingPower,
        tdaCash: +client.tdaCash,
        totalAccountValue: +client.totalAccountValue,
        actualTotalAccountValue: +client.actualTotalAccountValue,
        reserves: +client.reserves,
        accountNum: client.accountNum,
        aum: +client.aum,
        fundActive: true,
        frozen: false,
        active: true,
        freezeBuys: false,
        freezeSells: false,
      })
    }

    if (!e.target.checked && foundClient) {
      fund.clients.splice(clientPosition, 1)
    }

    await setCurrentFund(fund)
  }

  handleChangePage(event, tablePage) {
    this.setState({ tablePage })
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleFilterValues(type, e) {
    const { filters = [] } = this.state
    const val = e.target.value
    filters.splice(
      filters.findIndex(f => f.type === type),
      1
    )
    filters.push({ type, val })
    this.setState({ filters, tablePage: 0 })
  }

  handleSelectAll(e) {
    this.setState({ loading: true })
    const { clients = [] } = this.state
    clients.forEach(client => {
      const mockElement = {
        target: { checked: e.target.checked },
      }
      this.pickHandler(mockElement, client)
    })
    this.setState({ loading: false })
  }

  toggleGroupView(e) {
    this.setState({ groupView: e.target.checked })
    setTimeout(async () => await this.getClients(), 0)
  }

  pickViewClient() {
    const { clients = [], tablePage = 0, rowsPerPage = 4, filters, loading = false } = this.state
    const { currentFund } = this.props
    const filtered = clients.filter(p => {
      return filters.every(f => {
        if (
          f.type === 'name' &&
          !(
            p.firstName.toUpperCase().includes(f.val.toUpperCase()) ||
            p.lastName.toUpperCase().includes(f.val.toUpperCase())
          )
        ) {
          return false
        }
        if (f.type === 'accountNumber' && !p.UserId.toUpperCase().includes(f.val.toUpperCase())) {
          return false
        }
        return true
      })
    })

    const listColumns = [
      {
        displayName: '',
        propPath: '/',
        colDisplayFn: () => (
          <Checkbox onClick={this.handleSelectAll.bind(this)} style={{ marginBottom: -134 }} />
        ),
        displayFn: client => (
          <Checkbox
            checked={(currentFund.clients || []).some(x => x._id === client._id)}
            onClick={e => this.pickHandler(e, client)}
          />
        ),
        filterToolTip: false,
        filter: false,
      },
      {
        displayName: 'Client Name',
        propPath: '/',
        filterVal: client => `${client.firstName} ${client.lastName}`,
        displayFn: client => {
          return <div>{`${client.firstName} ${client.lastName}`}</div>
        },
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Account #',
        propPath: 'accountNum',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Account Value',
        propPath: 'totalAccountValue',
        displayFn: item => currencyFilter(item),
        filterToolTip: false,
        filter: false,
      },
      {
        displayName: 'Cash on Hand',
        propPath: 'tdaCash',
        displayFn: item => currencyFilter(item),
        filterToolTip: false,
        filter: false,
      },
      {
        displayName: 'Bob Budget',
        propPath: 'bobBudget',
        displayFn: item => currencyFilter(item),
        filterToolTip: false,
        filter: false,
      },
      {
        displayName: 'Bob Buying Power',
        propPath: 'bobBuyingPower',
        displayFn: item => {
          return <div style={{ color: 'green' }}>{currencyFilter(item || 0)}</div>
        },
        filterToolTip: false,
        filter: false,
      },
      {
        displayName: 'Target Reserves',
        propPath: 'reserves',
        displayFn: item => currencyFilter(item),
        filterToolTip: false,
        filter: false,
      },
    ]

    const tableOpts = {
      list: filtered,
      listColumns,
      tablePage,
      rowsPerPage,
      onChangePage: this.handleChangePage.bind(this),
      onChangeRowsPerPage: this.handleChangeRowsPerPage.bind(this),
    }

    return (
      <div>
        {!loading && <Table {...tableOpts} />}
        {loading && (
          <div>
            <div className="row">
              <div className="col-md-12">Loading Clients</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <CircularProgress color="secondary" />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { pickMode = true } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6"></div>
          <div className="col-md-3"></div>
        </div>
        {pickMode && this.pickViewClient()}
      </div>
    )
  }
}

export default class ClientModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  body() {
    const { selectedClients = [], fund } = this.props
    return (
      <div>
        <ClientList pickMode={true} selectedClients={selectedClients} fund={fund} />
      </div>
    )
  }
  render() {
    const { show = false, onClose, onCommit } = this.props
    return (
      <Pick
        show={show}
        onHide={onClose}
        onCommit={onCommit}
        title="Select clients"
        className="strategies-selector"
        body={this.body()}
      />
    )
  }
}
