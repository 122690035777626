import { action, set, observable } from 'mobx'
import CallState from './models/call'

class Identify {
  @observable calls = []
  @observable currentCall = CallState

  constructor(rootStore) {
    this.rootStore = rootStore
    this.auth = this.rootStore.auth
  }

  @action('Call Store | setCall')
  setCall = call => {
    set(this.calls, call)
    return this.calls
  }

  @action('Call Store | getCalls')
  getCalls = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get('/v0/call')
      const { data } = result
      const { calls } = data
      calls.forEach(c => {
        const found = this.calls.find(ec => {
          if (ec && c) return String(ec._id) === String(c._id)
          return false
        })
        if (!found) this.calls.push(found)
      })
      console.log(this.calls.length)
      this.calls = calls
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.stack}`)
    }
  }

  @action('Call Store | callPoller')
  callPoller = async () => {
    try {
      setInterval(this.getCalls, 5000)
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }
}

export default Identify
