import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import { Success, Failure, Confirm, Updating } from '../../common/components/Dialogs'
import PaymentProfile from './PaymentProfile'
import tradierLogo from '../../assets/img/tradier-brokerage-logo.png'
import tdAmeritradeLogo from '../../assets/img/tdameritrade-logo.png'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// import Pricing from './Pricing'
import InputField from '../../common/components/InputField'

const title = 'Update Profile'

const brandLogos = {
  Tradier: <img style={{ width: '7em', height: '7em' }} src={tradierLogo} alt="Tradier logo" />,
  TdAmeritrade: (
    <img style={{ width: '7em', height: '7em' }} src={tdAmeritradeLogo} alt="Tradier logo" />
  ),
}

@inject(({ store: { navigate, user, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    currentUser: user.currentUser,
    setUser: user.setUser,
    linkTdAmeritrade: user.profileStore.linkTdAmeritrade,
    tdaLinked: user.profileStore.tdaLinked,
    isTdaLinked: user.profileStore.isTdaLinked,
  }
})
@observer
class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = { payment: {} }
  }

  async componentDidMount() {
    try {
      window.document.title = title
      const { isAuthenticated, isTdaLinked } = this.props
      await isAuthenticated()
      isTdaLinked()
      const paymentResponse = await axios(`/v0/payment`)
      const {
        data: { payment },
      } = paymentResponse
      this.setState({ payment })
    } catch (err) {
      console.log('Error getting profile info', err)
    }
  }

  handleMaxDailyLoss = event => {
    this.setState({ MaxDailyLoss: event.target.value })
  }
  handleMaxDailyGain = event => {
    this.setState({ MaxDailyGain: event.target.value })
  }
  handleRobinHoodUsername = event => {
    this.setState({ robinHoodUsername: event.target.value })
  }
  handleRobinHoodPassword = event => {
    this.setState({ robinHoodPassword: event.target.value })
  }
  handleSubmit = async e => {
    if (e) e.preventDefault()
    const user = {
      UserID: this.state.UserID,
    }
    try {
      const response = await axios.post('/v0/user.update', user)
      console.log(response)
      this.setState({ showUpdateDialog: false, showSuccessDialog: true })
    } catch (error) {
      console.log(error)
      this.setState({ showUpdateDialog: false, showFailureDialog: true })
    }
  }
  handlePaymentUpdated = async () => {
    const paymentResponse = await axios(`/v0/payment`)
    const {
      data: { payment },
    } = paymentResponse
    this.setState({ payment })
  }
  confirm = () => {
    this.setState({ showConfirmDialog: true })
  }
  commit = () => {
    this.setState({ showConfirmDialog: false, showUpdateDialog: true })
    this.handleSubmit()
  }

  confirmAccount() {
    const {
      currentUser: { accountNum },
      linkTdAmeritrade,
    } = this.props
    if (!accountNum || accountNum.length === 0) {
      this.setState({ showAccountLinkFailure: true })
    } else {
      linkTdAmeritrade()
    }
  }
  render() {
    const {
      currentUser: { accountNum },
      setUser,
      linkTdAmeritrade,
      tdaLinked,
    } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Brokerages Available</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <div className="row">
                    <div className="col-md-12">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Brokerage</TableCell>
                            <TableCell>Account Number</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* <TableRow>
                            <TableCell>{brandLogos['Tradier']}</TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {!this.state.user.Brokers.Tradier && (
                                <Button color="secondary" onClick={this.linkTradier.bind(this)}>
                                  Unlinked
                                </Button>
                              )}
                              {this.state.user.Brokers &&
                                this.state.user.Brokers.Tradier &&
                                this.state.user.Brokers.Tradier.accessToken && (
                                  <Button
                                    color="primary"
                                    style={{ color: '#78c263' }}
                                    onClick={this.linkTradier.bind(this)}
                                  >
                                    Linked
                                  </Button>
                                )}
                            </TableCell>
                          </TableRow> */}
                          <TableRow>
                            <TableCell>{brandLogos['TdAmeritrade']}</TableCell>
                            <TableCell>
                              <InputField
                                type="string"
                                label="ex. 123456"
                                customSize="20%"
                                handleOnChange={str => setUser({ accountNum: str })}
                                initialValue={accountNum}
                                isRequired
                              />
                            </TableCell>
                            <TableCell>
                              {!tdaLinked && (
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    this.confirmAccount()
                                  }}
                                >
                                  Link Account
                                </Button>
                              )}
                              {tdaLinked && (
                                <Button
                                  color="primary"
                                  style={{ color: '#78c263' }}
                                  onClick={() => {
                                    linkTdAmeritrade()
                                  }}
                                >
                                  Linked
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-12"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <PaymentProfile
              payment={this.state.payment}
              paymentUpdated={this.handlePaymentUpdated}
            />
          </div>
        </div>

        <Success
          show={this.state.showSuccessDialog}
          onHide={() => this.setState({ showSuccessDialog: false })}
          title="Your settings have been updated"
        />
        <Failure
          show={this.state.showFailureDialog}
          onHide={() => this.setState({ showFailureDialog: false })}
          title="Oops, looks like there was a problem."
        />
        <Confirm
          show={this.state.showAccountLinkFailure}
          onHide={() => this.setState({ showAccountLinkFailure: false })}
          onCommit={() => this.setState({ showAccountLinkFailure: false })}
          commitMsg="OK"
          title="Missing Account Number"
          body="Please enter your account number before linking your account."
        />
        <Confirm
          show={this.state.showConfirmDialog}
          onHide={() => this.setState({ showConfirmDialog: false })}
          onCommit={this.commit}
          title="Are you sure?"
          body="This change will take effect immediately."
        />
        <Updating
          show={this.state.showUpdateDialog}
          onHide={() => this.setState({ showUpdateDialog: false })}
          title="Updating Profile ..."
        />
      </div>
    )
  }
}

export default Profile
