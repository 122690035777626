import { observable } from 'mobx'

const User = observable({
  firstName: 'New',

  lastName: "User",

  accountNum: null,

  email: null,

  UserId: null,

  managedBy: null,

  userType: null
})

export default User
