import styled from 'styled-components'
import { patterns, colors } from '../../../assets/styles'

export const StyledSmartFund = styled.div`
.is-live-sticker {
  height: 26px;
  width: 93px;
  border-radius: 7px;	
  background-color: #78C263
}

.live {
  height: 19px;
  width: 55px;
  color: #FFFFFF;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: right;
  margin: 4px 10px 3px 10px;
  position: absolute;
}

.not-live-sticker {
  height: 26px;	
  width: 93px;	
  border-radius: 7px;	
  background-color: #7676
}

.not-live {
  height: 19px;
  width: 76px;
  color: #FFFFFF;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 19px;
  text-align: right;
  margin: 4px 10px 3px 10px;
  position: absolute;
}

.fund-type-chip {
  height: 14px;
  font-family: Avenir;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05px;
  color: #ffffff;s
}
.manage-button {
    cursor: pointer;
    width: 69px;
    height: 19px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #2cb7df;
}
.metric-button {
    cursor: pointer;
    width: 69px;
    height: 19px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #2cb7df;
}
.fund-card-sub-labels {
  width: 121px;
  height: 11px;
  font-family: Avenir;
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.57px;
  color: #767676;
}
.model-chip {
  width: 130px;
  height: 27px;
  border-radius: 3px;
  border: solid 1px #ebebeb;
  background-color: #ffffff;
}
.smart-funds-box {
	height: 22px;	width: 455px;	color: #767676;	font-family: Avenir;	font-size: 16px;	font-weight: 500;	letter-spacing: 1.14px;	line-height: 22px;
}
.new-user-guide {
	height: 60px;	width: 445px;	color: #333333;	font-family: Avenir;	font-size: 22px;	font-weight: 900;	letter-spacing: 1.57px;	line-height: 30px;
}
.new-user-guide h3 {
	width: 450px;
}
.new-user-guide .new-user-card {
	width: 450px;
  height: 135px;
  cursor: pointer;
}
.new-user-guide .new-user-card .MuiCardContent-root {
	padding: 0;
	height: 100%;
}
.new-user-guide .new-user-card .MuiGrid-root {
	height: 100%;
}
.new-user-guide .new-user-card .card-detail {
	content: '';
	height: 100%;
	clip-path: polygon(0 0, 100% 0%, 35% 100%, 0% 100%);;
	background-image: linear-gradient(163.1deg, #78C263 0%, #07B0C8 52.67%, #0E90A3 100%);
}
.new-user-guide .new-user-card .MuiTypography-h4 {
	font-size: 29px;
	letter-spacing: 2.07px;
	line-height: 35px;
	margin-top: 30px;
}

.smart-fund-card.MuiPaper-rounded {
	padding: 25px;
}
.smart-fund-card > .MuiCardContent-root > .MuiGrid-root > .MuiGrid-root {
	padding-left: 0;
}
.fund-card-name {
  width: 392px;
  height: 34px;
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.79px;
  color: #333333;
}
.fund-card-action-row .MuiLink-root {
	color: lightblue;
	margin-right: 25px;
}
.fund-card-sectors .sector-chip {
	margin: 10px;
}
.fund-totals {
  margin-top: .5em
  margin-bottom: 1em
}
.fund-details {
  margin-top: 1em;
}
.add-fund {
  width: 243px;
  height: 48px;
  border-radius: 4px;
  background-color: #0cb0c8;
  margin-top: 1em;
  cursor: pointer;
}
.add-fund-label {
  height: 22px;	
  width: 189px;	
  color: #FFFFFF;	
  font-family: Avenir;	
  font-size: 16px;	
  font-weight: 200;	
  letter-spacing: 1.14px;	
  line-height: 22px;
  margin-left: 12%;
  margin-top: 6%;
  cursor: pointer;
}

.heading {
  height: 49px;	
  width: 239px;	
  color: #333333;	
  font-family: Avenir;	
  font-size: 36px;	
  font-weight: 700;	
  letter-spacing: 2.57px;	
  line-height: 49px;  
}

.totum-logo-container {
  margin-top: 35px;
  margin-bottom: 30px;
}


.totum-risk-getting-started{
  height: 90px;
  width: 466px;
  color: #333333;
}

.totum-risk-getting-started p{
  height: 90px;
  width: 466px;
  color: #333333;
  font-family: Avenir;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 30px;
}

`

export const StyledDashboard = styled.div`
  margin: 0 2rem;
  ${patterns.appContainer};
  @media screen and (min-width: 900px) {
    margin: 0 auto;
    max-width: calc(100% - 22rem);
  }
`

export const StyledChangeView = styled.div`
  display: flex;
  margin-top: 2rem;
  .change-view-button-bar {
    display: flex;
    align-items: center;
    margin-left: auto;
    p {
      ${patterns.label};
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
  .change-view-button {
    ${patterns.buttonDefault};
    background: none;
    opacity: 0.7;
    transition: all 200ms ease-out;
    &.is-active {
      opacity: 1;
    }
    svg g g {
      fill: ${colors.labelGrey};
    }
    &.is-active svg g g {
      fill: ${colors.linkBlue};
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
`

export const StyledFilledButton = styled.div `

.filled-button {
  height: 32px;
  width: 136px;
  border-radius: 6px;
  background-color: #0CB0C8;
  cursor: pointer; 
  margin-top: 50px; 
}

.filled-button-content{
  position:absolute;
  height: 16px;
  color: #FFFFFF;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.06px;
  line-height: 16px;
  text-align: center;
  margin-left: 22.5px;
  margin-top: 7.5px;
}
`

export const StyledRetailMessage = styled.div `

.retail-message-container {
  height: 120px;
  width: 486px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
}

.retail-message{
  height: 72px;
  width: 279px;
  color: #1A4E63;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 24px;
  margin-left: 15px;
  margin-top: 15px;
}

.retail-message-dismiss{
  height: 24px;
  width: 162px;
  color: #2CB7DF;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
  margin-left: 15px;
  margin-top: 15px;
  cursor: pointer;
}

`


