import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Downshift from 'downshift'

function renderSuggestion({ item, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(item.label) > -1

  return (
    <MenuItem
      {...itemProps}
      key={item.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <span>
        {item.label} - <small>{item.name}</small>
      </span>
    </MenuItem>
  )
}

export default class SymbolSearch extends Component {
  state = { inputValue: '' }
  async componentDidMount() {
    const symbols = await fetch('https://api.iextrading.com/1.0/ref-data/symbols')
      .then(r => r.json())
      .catch(e => false)
    this.setState({ symbols })
  }
  tickerMapper = ticker => {
    return {
      value: ticker.symbol,
      label: ticker.symbol,
      name: ticker.name,
    }
  }
  tickerFilter = text => {
    if (typeof text === 'string') text = text.toUpperCase()
    let options = this.state.symbols
    if (!options) return []
    if (text === '') return options.map(this.tickerMapper).slice(0, 20)
    let a = 0
    let b = options.length - 1
    while (a < b) {
      let c = ((a + b) / 2) | 0
      if (text > options[c].symbol) a = c + 1
      else b = c
    }
    let start = a
    b = Math.min(a + 100, options.length - 1)
    while (a < b) {
      let c = ((a + b) / 2) | 0
      if (options[c].symbol.startsWith(text)) a = c + 1
      else b = c
    }
    let end = a
    if (start === end) return options.map(this.tickerMapper).slice(0, 20)
    return options.map(this.tickerMapper).slice(start, end)
  }
  handleTicker = ticker => {
    if (ticker === null) return
    const companyName = this.getCompanyName(ticker)
    this.props.handleTicker(ticker, companyName)
    this.setState({ inputValue: '' })
  }
  getCompanyName(security) {
    const { symbols = [] } = this.state
    const existing = symbols.find(s => s.symbol === security)
    return existing.name
  }
  changeInputValue = e => {
    this.setState({ inputValue: e.target.value })
  }
  render() {
    const { inputValue } = this.state
    return (
      <div>
        <Downshift onSelect={this.handleTicker} id="downshift-simple" defaultHighlightedIndex={0}>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            isOpen,
            selectedItem,
          }) => {
            return (
              <div>
                <TextField
                  fullWidth={true}
                  autoFocus={true}
                  {...getInputProps({
                    value: inputValue,
                    onChange: this.changeInputValue,
                  })}
                  label="Enter Security"
                />
                <div {...getMenuProps()}>
                  <Paper square>
                    {isOpen
                      ? this.tickerFilter(inputValue.toUpperCase())
                          .filter(
                            item => !inputValue || item.value.includes(inputValue.toUpperCase())
                          )
                          .map((item, index) =>
                            renderSuggestion({
                              item,
                              index,
                              itemProps: getItemProps({ item: item.value, name: item.name }),
                              highlightedIndex,
                              selectedItem,
                            })
                          )
                      : null}
                  </Paper>
                </div>
              </div>
            )
          }}
        </Downshift>
      </div>
    )
  }
}
