import React from 'react'
import PropTypes from 'prop-types'

const FundClientsIconSvg = ({ fill }) => (
  <svg width="13px" height="13px" viewBox="0 0 13 13">
    <g id="Bob-4.0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Fund-Detail"
        transform="translate(-48.000000, -296.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Side-Nav" transform="translate(45.000000, 214.000000)">
          <g id="Clients-inactive" transform="translate(3.000000, 78.000000)">
            <path
              d="M4.5,11 C4.224,11 4,11.224 4,11.5 C4,11.776 4.224,12 4.5,12 C4.776,12 5,11.776 5,11.5 C5,11.224 4.776,11 4.5,11 Z M8.5,11 C8.224,11 8,11.224 8,11.5 C8,11.776 8.224,12 8.5,12 C8.776,12 9,11.776 9,11.5 C9,11.224 8.776,11 8.5,11 Z M6.5,4 C2.912,4 0,6.912 0,10.5 C0,14.088 2.912,17 6.5,17 C10.088,17 13,14.088 13,10.5 C13,6.912 10.088,4 6.5,4 Z M6.5,16 C3.468125,16 1,13.7294448 1,10.940267 C1,10.7568517 1.01375,10.5734364 1.034375,10.3963457 C2.656875,9.7322558 3.9425,8.51159522 4.61625,7 C5.860625,8.61911455 7.909375,9.6753338 10.22625,9.6753338 C10.7625,9.6753338 11.278125,9.61841181 11.773125,9.51089248 C11.9175,9.95994378 12,10.4406184 12,10.940267 C12,13.7294448 9.531875,16 6.5,16 Z"
              id="clients-icon"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

FundClientsIconSvg.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default FundClientsIconSvg
