import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Table from '../../common/components/Table'
import { Card } from '@material-ui/core'
import Widget from '../../common/components/Widget'

@inject(({ store: { auth, app, ui } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    getAllInstructions: app.instructionStore.getAllInstructions,
    instructions: app.instructionStore.instructions,
  }
})
@observer
export default class Instruction extends Component {
  state = {}

  componentDidMount = async () => {
    window.document.title = 'Bob | Instructions'
    const { getAllInstructions, isAuthenticated } = this.props
    await isAuthenticated()
    await getAllInstructions()
  }

  render = () => {
    const { instructions } = this.props
    const clientTableOpts = {
      rowsPerPage: 100,
      list: instructions,
      listColumns: this.listColumns(),
    }
    return (
      <div className="row" style={{ width: '85%' }}>
        <div className="row">
          <div className="col-md-12">
            <Card>
              <Widget {...clientTableOpts} classProps={{ columnSize: 12 }} body={Table} />
            </Card>
          </div>
        </div>
      </div>
    )
  }
  listColumns() {
    return [
      {
        displayName: 'Instruction Id',
        propPath: '_id',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Security',
        propPath: 'security',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Side',
        propPath: 'signal/side',
        filterToolTip: true,
        filter: false,
      },
      // {
      //   displayName: 'Chart',
      //   propPath: '/',
      //   displayFn: item => {
      //     const strategy = String(item.strategyId)
      //     const symbol = item.security
      //     return (
      //       <div>
      //         <p
      //           onClick={() => (window.location.href = `/quickview/${symbol}${strategy}`)}
      //         >{`${symbol}${strategy}`}</p>
      //       </div>
      //     )
      //   },
      //   filterToolTip: true,
      //   filter: false,
      // },
      {
        displayName: 'Destination',
        propPath: 'destination',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Originator',
        propPath: 'originator',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'State',
        propPath: 'state',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Message',
        propPath: 'message',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Date',
        propPath: 'timestamp',
        filterToolTip: true,
        filter: false,
      },
    ]
  }
}
