import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import PersonalInformation from './steps/PersonalInformation'
import FinancialInformation from './steps/FinancialInformation'
import Score from './steps/Score'
import { StyledFormWrapper } from './styles'

import '../../App/App.css'

@inject(({ store: { navigate, auth, totumRisk } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    setSelectedTab: totumRisk.setSelectedTab,
    selectedTab: totumRisk.selectedTab
  }
})

@observer
class TotumRisk extends Component {
  state = {

  }

  async componentDidMount() {
    const { isAuthenticated} = this.props
    await isAuthenticated()
  }



  render() {
    const { selectedTab } = this.props
    const steps = [
      {
        name: 'Personal Information',
        component: <PersonalInformation  />,
      },
      {
        name: 'Financial Information',
        component: <FinancialInformation />,
      },
      {
        name: 'Score',
        component: <Score />,
      }
    ]
    return (
      <StyledFormWrapper>
        <div className="stepper-container">
          <Stepper nonLinear activeStep={selectedTab} className="stepper-override">
            {steps.map((step, index) => {
              return (
                <Step key={index}>
                    <StepButton>
                      {step.name}
                    </StepButton>
                </Step>
              )
            })}
          </Stepper>
          {steps[selectedTab].component}
        </div>
      </StyledFormWrapper>
    )
  }
}

export default TotumRisk
