import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import SmartFundList from './SmartFundList'
import StyledMarketPlace from './styles'
import Typography from '@material-ui/core/Typography'

@inject(({ store: { navigate, fund, auth, user } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    setNavigationTabs: user.headerStore.setNavigationTabs,
  }
})
@observer
export default class Marketplace extends Component {
  state = {
    showSmartFunds: true,
  }

  componentDidMount = async () => {
    window.document.title = 'Bob | Marketplace'
    const {isAuthenticated } = this.props
    await isAuthenticated()
  }

  routeBack = async () => {
    const { navigate } = this.props
    return navigate(`/`)
  }

  render() {
    return (
      <StyledMarketPlace>
        <Box component="section">
          <Container>
            <Grid container direction="column">
              <Grid  container direction="row" spacing={8}>
                <Grid item xs={6}>
                <Typography className="smart-funds" variant="caption" component="p">
                Smart Funds
              </Typography>                
              <div className="page-heading">Marketplace</div>
                </Grid>
              </Grid>
              <SmartFundList />
            </Grid>
          </Container>
        </Box>
      </StyledMarketPlace>
    )
  }
}
