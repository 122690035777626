import { action, observable } from 'mobx'

export default class ClientStore {
  @observable listColumns = []
  @observable list = []
  @observable showFreezeSharesModal = false
  @observable showClientDetailModal = false
  @observable showAddedNewClientsModal = false
  @observable selectedClient = null
  @observable selectedClientSecurities = []
  @observable frozenShares = []

  constructor(user) {
    this.rootStore = user.rootStore
  }

  @action('Client Store | getModelById')
  setListColumn = async listColumns => {
    this.listColumns = listColumns
  }

  @action('Client Store | getModelById')
  setList = async list => {
    this.list = list
  }

  @action('Client Store | toggleFreezeSharesModal')
  toggleFreezeSharesModal = () => {
    if (this.showFreezeSharesModal) return (this.showFreezeSharesModal = false)
    return (this.showFreezeSharesModal = true)
  }

  @action('Client Store | toggleClientDetailModal')
  toggleClientDetailModal = () => {
    if (this.showClientDetailModal) return (this.showClientDetailModal = false)
    return (this.showClientDetailModal = true)
  }

  @action('Client Store | toggleClientDetailModal')
  toggleAddedNewClientsModal = () => {
    if (this.showAddedNewClientsModal) return (this.showAddedNewClientsModal = false)
    return (this.showAddedNewClientsModal = true)
  }

  @action('Client Store | setSelectedClient')
  setSelectedClient = client => {
    return (this.selectedClient = client)
  }

  @action('Client Store | getUserTdaPositions')
  getUserTdaPositions = async userId => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/getUserTdaPositions/${userId}`)
      const { data } = result
      const { securities, frozenShares = [] } = data
      this.frozenShares = frozenShares
      this.selectedClientSecurities = securities || []
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }

  @action('Client Store | freezeShares')
  freezeShares = async freeze => {
    try {
      const alreadyFrozen = this.frozenShares.find(af => af.symbol === freeze.symbol)
      if (alreadyFrozen) return (alreadyFrozen.shares = freeze.shares)
      this.frozenShares.push(freeze)
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }

  @action('Client Store | freezeShares')
  saveFrozenShares = async freeze => {
    try {
      const { api } = this.rootStore
      await api.put(`/v0/tdaPositionFreezeShares/${this.selectedClient._id}`, this.frozenShares)
      return (this.showFreezeSharesModal = false)
    } catch (e) {
      console.log(` ISSUE IN USER STORE | ${e.message}`)
    }
  }
}
