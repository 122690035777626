import { Breadcrumbs, MuiThemeProvider } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import op from 'simple-object-path'
import { typography } from '../../assets/styles'
import BaseTableColumn from '../../common/components/BaseTableColumn'
import DashboardCard from '../../common/components/DashboardCard'
import LoadingIndicator from '../../common/components/LoadingIndicator'
import CoolTable from '../../common/components/Table'
import currencyFilter from '../../lib/currency'
import { theme } from '../Admin/theme'

@inject(({ store: { navigate, auth, assetManager } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getAssetManagerModels: assetManager.getAssetManagerModels,
  }
})
@observer
export default class AssetManager extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingModels: false,
    }
  }

  async componentDidMount() {
    window.document.title = 'Bob | Your models'
    await this.getModels()
  }

  async getModels() {
    this.setState({ loadingModels: true })
    const { getAssetManagerModels, isAuthenticated } = this.props
    await isAuthenticated()
    const models = await getAssetManagerModels()
    this.setState({ models, loadingModels: false })
  }

  onSelectModel(modelRow) {
    const { navigate } = this.props
    navigate(`/assetManager/model/${modelRow.model._id}`)
  }

  render() {
    const { models = [], loadingModels } = this.state
    const { list = models, onPageChange, onFilterChange, tableConfigs = [] } = this.props
    const tableConfig = tableConfigs.find(c => (c.tableId = 'asset-manager')) || { rowsPerPage: 12 }
    const listColumns = [
      {
        id: 'd4ea42b5a',
        displayName: 'Name',
        propPath: '/',
        filter: true,
        sortProp: 'model/strategy',
        filterVal: item => item.model.strategy,
        displayFn: item => <BaseTableColumn>{item.model.strategy}</BaseTableColumn>,
      },
      {
        id: 'd4ea42b5b',
        displayName: 'Type',
        propPath: '/',
        filter: true,
        sortProp: 'type',
        filterVal: item => item.model.type,
        displayFn: item => <BaseTableColumn>{item.model.type}</BaseTableColumn>,
      },
      {
        id: 'd4ea42b5c',
        displayName: 'Consumers',
        propPath: '/',
        filter: true,
        filterVal: item => item.consumers.length,
        displayFn: item => <BaseTableColumn>{item.consumers.length}</BaseTableColumn>,
      },
      {
        id: 'd4ea42b5cx',
        displayName: 'AUM',
        propPath: '/',
        filter: true,
        filterVal: item => item.consumers.map(t => t.aum).reduce((a, b) => a + b, 0),
        displayFn: item => (
          <BaseTableColumn>
            {currencyFilter(item.consumers.map(t => t.aum).reduce((a, b) => a + b, 0))}
          </BaseTableColumn>
        ),
      },
      {
        id: 'd4ea42b5cy',
        displayName: 'Fees',
        propPath: '/',
        filter: true,
        filterVal: item => item.consumers.map(t => t.fee).reduce((a, b) => a + b, 0),
        displayFn: item => (
          <BaseTableColumn>
            {currencyFilter(item.consumers.map(t => t.fee).reduce((a, b) => a + b, 0))}
          </BaseTableColumn>
        ),
      },
    ]

    const tableOpts = {
      selectable: true,
      onSelect: this.onSelectModel.bind(this),
      listColumns,
      list: list,
    }
    return (
      <MuiThemeProvider theme={theme}>
        {loadingModels && <LoadingIndicator loadingText="" />}
        {!loadingModels && (
          <Container fixed style={{ fontFamily: typography.baseFontFamily }}>
            <Breadcrumbs>
              <span>{op(models, '0/model/assetManager/name')}</span>
            </Breadcrumbs>
            <br />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              style={{ verticalAlign: 'top' }}
            >
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: typography.extraLargeFontSize,
                    fontWeight: typography.mediumFontWeight,
                  }}
                ></span>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>
              <Grid item xs={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '280px', width: '300px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 282 }}
                        >
                          <Grid item style={{ paddingBottom: 10 }}>
                            <img
                              style={{ height: 75, width: 75 }}
                              src={
                                op(models, '0/model/assetManager/logoUrl') ||
                                'https://via.placeholder.com/75'
                              }
                              alt=""
                            />
                          </Grid>
                          <Grid item style={{ paddingBottom: 10 }}>
                            <p style={{ fontFamily: typography.baseFontFamily }}>
                              {op(models, '0/model/assetManager/name')}
                            </p>
                          </Grid>
                          <Grid item></Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={9}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Consumers</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {
                                models
                                  .map(model => model.consumers)
                                  .reduce((a, b) => a.concat(b), []).length
                              }
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Amount Managed</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {currencyFilter(
                                models
                                  .map(model => model.consumers)
                                  .reduce((a, b) => a.concat(b), [])
                                  .map(consumer => consumer.aum)
                                  .reduce((a, b) => a + b, 0)
                              )}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Total Fee</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {currencyFilter(
                                models
                                  .map(model => model.consumers)
                                  .reduce((a, b) => a.concat(b), [])
                                  .map(consumer => consumer.fee)
                                  .reduce((a, b) => a + b, 0)
                              )}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>

                  <Grid item xs={12}>
                    <DashboardCard hasTitle={false} title="" moreTool={false}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        style={{ padding: 15 }}
                      >
                        <Grid item xs={3}>
                          <span>{`Your models`}</span>
                        </Grid>
                        <Grid item xs={9} style={{ width: '100%' }}>
                          <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                          ></Grid>
                        </Grid>
                      </Grid>

                      <Box>
                        {!!tableOpts.list.length && (
                          <CoolTable
                            {...tableOpts}
                            tableConfig={tableConfig}
                            tableId="asset-manager"
                            onFilterChange={onFilterChange}
                            onPageChange={onPageChange}
                          />
                        )}
                        {!tableOpts.list.length && (
                          <Grid container direction="row" justify="center" spacing={2}>
                            <span
                              style={{
                                fontSize: typography.mediumFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >{`You have no models!`}</span>
                          </Grid>
                        )}
                      </Box>
                    </DashboardCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
      </MuiThemeProvider>
    )
  }
}
