import styled from 'styled-components'
import { patterns, typography, colors } from '../../../../assets/styles'

export const StyledFundDetails = styled.div`
  .bob-page-heading {
    height: 20px;
    width: 565px;
    color: #333333;
    font-family: Avenir;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 2.57px;
    line-height: 49px;
  }

  .back-to-smart-funds {
    cursor: pointer;
    height: 16px;
    width: 129px;
    color: #2CB7DF;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
  }

  .is-live-sticker {
    height: 26px;
    width: 93px;
    border-radius: 7px;	
    background-color: #78C263
  }

  .live {
    height: 19px;
    width: 55px;
    color: #FFFFFF;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: right;
    margin: 4px 10px 3px 10px;
    position: absolute;
  }

  .not-live-sticker {
    height: 26px;	
    width: 93px;	
    border-radius: 7px;	
    background-color: #7676
  }

  .not-live {
    height: 19px;
    width: 76px;
    color: #FFFFFF;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: right;
    margin: 4px 10px 3px 10px;
    position: absolute;
  }

  margin: 0 2rem;
  ${patterns.appContainer};
  @media screen and (min-width: 930px) {
    max-width: 100%;
    padding: 0;
  }
  @media screen and (min-width: 1180px) {
    margin: 0 auto;
    max-width: calc(100% - 22rem);
    padding: 0;
  }
  .fund-details-header-bar,
  .details-status-wrapper,
  .details-title-wrapper,
  .fund-controls-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  .fund-details-header-bar {
    flex-direction: column;
    @media screen and (min-width: 860px) {
      flex-direction: row;
    }
    justify-content: space-between;
  }
  .details-status-wrapper {
    flex-grow: 1;
    margin-left: 0;
    @media screen and (min-width: 768px) {
      margin-left: 2rem;
    }
    .status-divider {
      color: ${colors.borderGrey};
      margin: 0 2rem;
    }
    .monitoring-icon {
      margin-right: 1rem;
    }
    .fund-monitoring {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  .details-title-wrapper {
    align-items: flex-start;
    flex-direction: column;
    .fund-details-name {
      font-size: ${typography.extraLargeFontSize};
      color: ${colors.textGrey};
      font-weight: ${typography.baseFontWeight};
    }
    .fund-details-description {
      ${patterns.label};
    }
  }
  .live-sticker {
    ${patterns.liveSticker};
    border-radius: 100rem;
  }
  .cancel-button {
    ${patterns.buttonAsLink};
    background: transparent;
  }
  
  .publish-button {
    position: relative;
    margin-left: 3rem;
    .publish-button-primary {
      ${patterns.primaryButton};
      background: #78c263;
      box-shadow: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:hover,
      &:focus {
        transform: none;
        box-shadow: none;
      }
    }
  }

  .unpublish-button {
    position: relative;
    margin-left: 3rem;
    .unpublish-button-primary {
      ${patterns.primaryButton};
      background: red;
      box-shadow: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:hover,
      &:focus {
        transform: none;
        box-shadow: none;
      }
    }
  }

  .split-button {
    position: relative;
    margin-left: 3rem;
    .split-button-primary {
      ${patterns.primaryButton};
      box-shadow: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:hover,
      &:focus {
        transform: none;
        box-shadow: none;
      }
    }
    .split-button-dropdown-toggle {
      ${patterns.primaryButton};
      box-shadow: none;
      border-left: 1px solid #42a3ce;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0.95rem 0.85rem;
      &:hover,
      &:focus {
        transform: none;
        box-shadow: none;
      }
    }
    .split-button-dropdown-menu {
      ${patterns.dropdownMenuBackground};
      top: 3.25rem;
      right: 0;
      min-width: 150px;
      margin-bottom: 0;
      text-align: left;
    }
    .split-button-menu-item {
      ${patterns.buttonDefault};
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      text-align: right;
      transition: all 200ms linear;
      color: ${colors.linkBlue};
      &:hover,
      &:focus {
        background: #f3f3f3;
      }
    }
  }
  .two-column-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
    .main-container {
      flex-grow: 1;
      ${patterns.defaultCard};
      position: relative;
      &.no-background {
        background: none;
        box-shadow: none;
      }
    }
  }


  .tooltip {
    opacity: 0;
    transition: opacity 200ms ease-in;
    pointer-events: none;
    &.is-open {
      opacity: 1;
    }
  }

  .tooltip-wrapper {
    position: relative;
  }

  .tooltip-trigger.as-link {
    ${patterns.buttonAsLink};
    line-height: 0.7;
    align-self: flex-end;
  }

  .flex-group {
    display: flex;
    .form-control-group {
      flex-grow: 1;
      margin-right: 1rem;
    }
  }

  .account-buy-in {
    height: 11px;
    width: 128px;
    color: #767676;
    font-family: Avenir;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.57px;
    line-height: 11px;
  }

  .buy-in-amount {
    height: 16px;
    width: 57px;
    color: #333333;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
  }

  .new-accounts-message {
    color: red;
  }
`

export const StyledFundSideNav = styled.div`
  width: 40%;
  height: 22px;
  @media screen and (min-width: 768px) {
    margin-right: 1rem;
    padding: 4rem 2rem;
  }
  .side-nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
    list-style: none;
    margin: 0;
    padding: 0;
    .side-nav-item {
      ${patterns.buttonAsLink};
      display: flex;
      align-items: center;
      background: transparent;
      font-size: 1rem;
      font-weight: ${typography.baseFontWeight};
      color: ${colors.labelGrey};
      margin-bottom: 1rem;
      opacity: 0.6;
      transition: opacity 200ms linear;
      @media screen and (min-width: 768px) {
        margin-bottom: 3rem;
      }
      &:hover,
      &:focus {
        opacity: 1;
        text-decoration: none;
      }
      &.is-active {
        opacity: 1;
      }
      svg {
        margin-right: 0.5rem;
        @media screen and (min-width: 768px) {
          margin-right: 1.5rem;
        }
      }
    }
  }
`
