import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import './Login.css'
import profilePicture from '../../assets/img/logo.png'
window.document.title = 'Log In'

@inject(({ store: { auth } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    login: auth.login,
  }
})
@observer
class Login extends Component {
	state = {

	}

  handleLogin = (e) => {
    e.preventDefault()
    const { login } = this.props
    login()
    return false
  }

  render() {
    return (
      <div className={`login-page animate loginPage`}>
        <div className="row">
          <div className="col-md-4 col-lg-4 col-md-offset-4 col-lg-offset-4">
            <img src={profilePicture} alt="flat Avatar" className="user-avatar" />
            <div className="spacer" />
            <form>
              <button
                onClick={this.handleLogin}
                className={`customButton btn btn-outline btn-lg btn-rounded progress-login
									progress-button`}
                data-style="fill"
                data-horizontal
              >
                Log in / Register
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
