import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FundDetailsForm from '../../CreateFund/FundDetailsForm'
import StyledDetails from './styles'


class Details extends Component {
  state = {
  }

  render() {

    return (
      <StyledDetails>
        <FundDetailsForm/>
      </StyledDetails>
    )
  }
}

Details.defaultProps = {
  fundData: {},
}

Details.propTypes = {
  fundData: PropTypes.shape({})
}

export default Details
