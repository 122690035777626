import { action } from 'mobx'

class PaymentAdmin {
  constructor(rootStore) {
    this.rootStore = rootStore
    this.auth = this.rootStore.auth
  }

  @action('Payment Admin Store | getPendingPayments')
  getPendingPayments = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/pending-payments`)
      const serviceCategories = result ? result.data || [] : []
      return serviceCategories
    } catch (e) {
      console.log(`Asset Manager Store | getPendingPayments ${e.stack}`)
    }
  }

  @action('Payment Admin Store | releasePayments')
  releasePayments = async payload => {
    try {
      const { api } = this.rootStore
      const result = await api.post(`/v0/release-payments`, payload)
      const serviceCategories = result ? result.data || [] : []
      return serviceCategories
    } catch (e) {
      console.log(`Payment Admin Store | releasePayments ${e.stack}`)
    }
  }
}

export default PaymentAdmin
