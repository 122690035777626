import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { StyledClients, StyledAddClientsButton, StyledUpdateAllocationButton } from './styles'
import Client from './Client'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
  return {
      navigate,
      isAuthenticated: auth.isAuthenticated,
      getUserClients: user.getUserClients,
      currentFund: fund.currentFund,
      profile: auth.profile,
      isRetail: user.isRetail
  }
})
@observer

class Clients extends Component {
  state = {
    loading: true,
    clients: [],
    showAddClientsModal: false,
    showUpdateAllocationModal: false,
    showFreezeSharesModal: false,
  }

  toggleAddClientsModal = () => {
    const { showAddClientsModal } = this.state
    this.setState({ showAddClientsModal: !showAddClientsModal })
  }

  toggleUpdateAllocationModal = () => {
    const { showUpdateAllocationModal } = this.state
    this.setState({ showUpdateAllocationModal: !showUpdateAllocationModal })
  }

  toggleFreezeSharesModal = () => {
    const { showFreezeSharesModal } = this.state
    this.setState({ showFreezeSharesModal: !showFreezeSharesModal })
  }

  render() {
    const { currentFund, clients, isRetail } = this.props
    const { showAddClientsModal, showUpdateAllocationModal } = this.state

    return (
      <StyledClients>
        { !isRetail && currentFund.strategies.length > 0 && <StyledAddClientsButton
          className="add-clients-button"
          onClick={() => this.toggleAddClientsModal()}
        >
          Add Clients
        </StyledAddClientsButton>
        }
        { isRetail && <StyledUpdateAllocationButton
          className="update-allocation-button"
          onClick={() => this.toggleUpdateAllocationModal()}
        >
          Update Allocations
        </StyledUpdateAllocationButton> }
        <Client
          fundData={currentFund}
          clients={clients}
          strategies={currentFund.strategies}
          toggleClientsModal={showAddClientsModal}
          toggleUpdateAllocationModal={showUpdateAllocationModal}
          toggleFreezeSharesModal={this.toggleFreezeSharesModal.bind(this)}
        />
        <p className="total-clients">Total clients in fund: {currentFund.clients.length}</p>
      </StyledClients>
    )
  }
}

Clients.propTypes = {
  fundData: PropTypes.shape({}).isRequired,
}

export default Clients
