import { observable, action, set } from 'mobx'
import { FundState, FundReset } from './models/fund'
import MarketplaceStore from '../scenes/Marketplace/store'

export default class FundStore {
  @observable currentFund = FundState
  @observable currentTab //details is the default tab
  @observable totalAllocation = 0
  @observable addingModels = true
  @observable existingClients = []
  @observable qualifiedClients = []
  @observable currentFundUserProfile = {}

  constructor(rootStore) {
    this.rootStore = rootStore
    this.marketplaceStore = new MarketplaceStore(this)
  }

  @action('SmartFund Store | setCurrentTab')
  setCurrentTab = tab => {
    this.currentTab = tab
    return this.currentTab
  }

  @action('SmartFund Store | setCurrentTab')
  setAddingModels = adding => {
    this.addingModels = adding
    return this.addingModels
  }

  @action('SmartFund Store | setQualifiedClients')
  setQualifiedClients = qualifiedClients => {
    this.qualifiedClients = qualifiedClients
    return this.qualifiedClients
  }

  @action('SmartFund Store | setCurrentFund')
  setCurrentFund = fund => {
    if (!fund) return FundReset()
    if (fund && fund.strategies && fund.strategies.length > 0) this.calculateTotalAllocation(fund)
    const { user } = this.rootStore
    const { isRetail = false } = user

    if (!this.currentTab) {
      this.setCurrentTab(isRetail ? 'portfolios' : 'details')
    }
    if (this.currentTab === 'details' && isRetail) {
      this.setCurrentTab('portfolios')
    }
    console.log('CURRENT TAB', isRetail, this.currentTab)
    set(this.currentFund, fund)
    return this.currentFund
  }

  @action('SmartFund Store | updateClientOnFund')
  updateClientOnFund = client => {
    const { clients } = this.currentFund
    let currentClient = clients.find(c => c._id === client._id)
    currentClient = client
    this.setCurrentFund(this.currentFund)
    return currentClient
  }

  @action('Fund Store | getCurrentFund')
  getFundDetails = async fundId => {
    try {
      const { api } = this.rootStore
      const response = await api.get(`/v0/fund/${fundId}`)
      const { data } = response
      let { fund } = data
      // FUND ALREADY IN SCOPE
      if (this.currentFund && this.currentFund._id) {
        const { clients } = fund
        this.currentFund.clients = clients
        this.existingClients = clients
        this.calculateTotalAllocation(fund)
        return set(this.currentFund, fund)
      }
      const { clients } = fund
      this.currentFund.clients = clients
      this.existingClients = clients
      this.calculateTotalAllocation(fund)
      set(this.currentFund, fund)
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | retailFundExit')
  retailFundExit = async fundId => {
    try {
      const { api } = this.rootStore
      const response = await api.get(`/v0/retail-fund-exit/${fundId}`)
      const { data: success } = response
      return success
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | saveFund')
  updateFund = async () => {
    try {
      const { api } = this.rootStore
      const response = await api.put(`/v0/fund`, this.currentFund)
      const { data } = response
      const { clients, fund } = data
      this.existingClients = clients
      return { clients, fund }
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | saveFund')
  saveAndExit = async () => {
    try {
      const { api } = this.rootStore
      const response = await api.put(`/v0/fund`, this.currentFund)
      const { data } = response
      const { success, message } = data
      if (!success) throw new Error(message)
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | addToMarketplace')
  addToMarketplace = async () => {
    try {
      const { api } = this.rootStore
      this.currentFund.public = true
      console.log('MAKE PUBLIC')
      const {
        data: { success, message },
      } = await api.put(`/v0/fund`, this.currentFund)
      if (success) return set(this.currentFund, { public: true })
      throw new Error(message)
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | removeFromMarketplace')
  removeFromMarketplace = async () => {
    try {
      console.log('REMOVE PUBLIC')
      const { api } = this.rootStore
      this.currentFund.public = false
      const {
        data: { success, message },
      } = await api.put(`/v0/fund`, this.currentFund)
      if (success) return set(this.currentFund, { public: false })
      throw new Error(message)
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | publish')
  publish = async () => {
    try {
      const { api } = this.rootStore
      this.currentFund.published = true
      const {
        data: { success, message },
      } = await api.put(`/v0/fund`, this.currentFund)
      if (success) return set(this.currentFund, { published: true })
      throw new Error(message)
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | unpublish')
  unpublish = async () => {
    try {
      const { api } = this.rootStore
      this.currentFund.published = false
      set(this.currentFund, { published: false })
      await api.put(`/v0/fund`, this.currentFund)
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | getAccountHoldings')
  getAccountHoldings = async payload => {
    try {
      const { api } = this.rootStore
      const response = await api.post(`/v0/fund-account-holdings`, payload)
      const { data: holdings = [] } = response
      return holdings
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | ')
  calculateTotalAllocation = fund => {
    this.totalAllocation = 0
    const { strategies } = fund
    strategies.forEach(s => {
      this.totalAllocation += parseFloat(+s.allocatedToPortfolio * 100)
    })
  }

  @action('Fund Store | ')
  setModelDeployState = model => {
    const { strategies: models } = this.currentFund
    const currentModel = models.find(m => String(m._id) === String(model._id))
    if (currentModel.deploy) {
      currentModel.deploy = false
      return
    }
    currentModel.deploy = true
  }

  @action('Fund Store | ')
  setModelSelloffState = model => {
    const { strategies: models } = this.currentFund
    const currentModel = models.find(m => String(m._id) === String(model._id))
    if (currentModel.deploy) {
      currentModel.selloff = false
      return
    }
    currentModel.selloff = true
  }
  @action('Fund Store | getFundUserProfile')
  getFundUserProfile = async fundId => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/getFundUserProfile/${fundId}`)
      const { data } = result
      set(this.currentFundUserProfile, data)
    } catch (e) {
      console.log(`Issue in Fund Store | ${e.message}`)
    }
  }

  @action('Fund Store | setFundUserProfile')
  setFundUserProfile = async currentFundUserProfile => {
    set(this.currentFundUserProfile, currentFundUserProfile)
  }
}
