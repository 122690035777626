import React from 'react'

const LogoutIconSvg = () => (
  <svg width="16px" height="13px" viewBox="0 0 16 13">
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Dashboard---User-Profile-Dropdown-Menu-Active"
        transform="translate(-1388.000000, -285.000000)"
        fill="#2CB7DF"
        fillRule="nonzero"
      >
        <g id="Log-out" transform="translate(1234.000000, 267.000000)">
          <g id="logout-icon" transform="translate(154.000000, 18.000000)">
            <path
              d="M14.25,3.82692308 L10.8214286,3.82692308 L10.8214286,-1.25 L5.67857143,-1.25 L5.67857143,3.82692308 L2.25,3.82692308 L8.25,9.75 L14.25,3.82692308 Z M1.75,12.25 L1.75,14.25 L14.75,14.25 L14.75,12.25 L1.75,12.25 Z"
              id="Shape"
              transform="translate(8.250000, 6.500000) rotate(-90.000000) translate(-8.250000, -6.500000) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default LogoutIconSvg
