import { observable, action} from 'mobx'
import op from 'simple-object-path'
import currencyFilter from '../../../lib/currency'

export default class SmartFundStore {

  @observable positionPerformanceData = null

  constructor(user) {
    this.user = user
    this.rootStore = this.user.rootStore
  }

  @action('SmartFund Store | getUserSmartFunds')
  getUserSmartFunds = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/getFundsByOwner`)
      const { data : { funds : userFunds } } = result
      return userFunds;
    } catch (e) {
        console.log(`SmartFund Store | getUserSmartFunds ${e.stack}`)
    }
  }
  
  @action('SmartFund Store | getUserPerformanceDate')
  getUserPerformanceData = async () => {
    try {
      const { user, api } = this.rootStore
      const { currentUser } = user
      let { _id: ownerId, userProfile } = currentUser
      const trader = userProfile.find(p => p.roleCode === 'trader')
      if (trader) {
        const myManagerFunds = await api.get(`/v0/trader-fund?Private=true`)
        const funds = op(myManagerFunds, 'data/funds')
        const managerId = op(funds[0], 'owner/_id')
        ownerId = managerId
      }  
          
      const result = await api.get(`/v0/performance/users/${ownerId}`)
      const { data } = result
      this.positionPerformanceData = data; 
      return data;
    } catch (e) {
        console.log(`Issue in SmartFund Store | getUserPerformanceDate ${e.stack}`)
    }
  }

  @action('SmartFund Store | separatePerformanceByFund')
  separatePerformanceByFund = (positionPerformance) => {
    const {
      currentPerformanceByFunds: funds,
      ledgers,
      legacyPositionsPerformance,
    } = positionPerformance
    const performances = []
    if (funds) {
      for (const fund of funds) {
        const fundId = String(fund._id)
        const targetFund = [fund]
        const fundLedgers = ledgers.filter(l => String(l.fundId) === fundId)
        const historicalPositions = legacyPositionsPerformance.filter(
          l => String(l.fundId) === fundId
        )
        performances.push({
          currentPerformanceByFunds: targetFund,
          ledgers: fundLedgers,
          legacyPositionsPerformance: historicalPositions,
        })
      }
    }
    return performances
  }

  @action('SmartFund Store | calculateAUM')
  calculateAUM(fund) {
    const { clients = [] } = fund
    let totalAUM = 0

    clients.forEach(client => {
      totalAUM += client.initialCash || 0
    })
    return currencyFilter(totalAUM)
  }

  @action('SmartFund Store | calculateAUM')
  calculateTotalAUM(funds) {
    let totalAUM = 0
    let totalFundAUM = 0
    funds.forEach( fund => {
      const { clients = [] } = fund
      totalFundAUM = 0
      clients.forEach(client => {
        totalFundAUM += +client.aum || 0
      })
       totalAUM += totalFundAUM
    })
    return currencyFilter(totalAUM)
  }
}
