import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import op from 'simple-object-path'
import LoadingIndicator from '../../common/components/LoadingIndicator'
import { StyledDashboard } from './styles'
import SingleFundView from '../../common/components/SingleFundView'
import MultiFundView from '../../common/components/MultiFundView'

@inject(({ store: { navigate, fund, user, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    client: auth.profile.user,
    profile: auth.profile,
    currentFund: fund.smartFundStore.currentFund,
    setFundMetricsByUserId: user.metricStore.setFundMetricsByUserId,
  }
})
@observer
class Metric extends Component {
  state = {
    isLoading: true,
    clientLoading: false,
    client: null,
    totalAUM: 0,
    historicalCardActiveTab: 'ledger',
    currentView: 'single',
    performances: [],
    currentFundLegers: [],
    currentFundHistoricalPerformances: [],
    currentFundPerformance: [],
  }

  async componentDidMount() {
    window.document.title = 'Bob | Dashboard'
    const { profile, isAuthenticated, currentFund, setFundMetricsByUserId } = this.props
    const userId = op(profile, 'user/_id')
    const { _id: fundId } = currentFund
    await isAuthenticated()
    const {
      currentFundLegers,
      currentFundHistoricalPerformances,
      currentFundPerformance,
    } = await setFundMetricsByUserId(userId, fundId)
    this.setState({
      currentFundLegers,
      currentFundHistoricalPerformances,
      currentFundPerformance,
      isLoading: false,
    })
  }

  async componentDidUpdate() {
    const { hasPositionPerformance = false } = this.state
    const { client = {}, positionPerformance = {} } = this.props
    const { _id: userId } = client
    if (
      hasPositionPerformance !== userId &&
      positionPerformance &&
      Object.keys(positionPerformance).length
    ) {
      this.setState({ positionPerformance, hasPositionPerformance: userId })
    }
  }

  handleChangeFund = fund => {
    const { performances } = this.state
    const selectedFund = performances.find(
      perf => perf.currentPerformanceByFunds[0]._id === fund.currentPerformanceByFunds[0]._id
    )
    if (selectedFund) {
      this.setState({ currentFund: selectedFund })
    }
  }

  handleChangeFundFromMultiView = fund => {
    this.setState({ currentView: 'single' })
    this.handleChangeFund(fund)
  }

  handleToggleCardTabs = value => {
    this.setState({ historicalCardActiveTab: value })
  }

  toggleView = view => {
    this.setState({ currentView: view })
  }

  render() {
    const { currentFund } = this.props
    const {
      isLoading,
      performances,
      totalAUM,
      historicalCardActiveTab,
      currentView,
      currentFundLegers,
      currentFundHistoricalPerformances,
      currentFundPerformance,
    } = this.state

    currentFund.ledgers = currentFundLegers
    currentFund.legacyPositionsPerformance = currentFundHistoricalPerformances

    const filteredPerformancesForDropdown = [currentFundPerformance]

    const singleFundViewProps = {
      currentFund,
      filteredPerformancesForDropdown,
      currentFundPerformance,
      totalAUM,
      historicalCardActiveTab,
      handleChangeFund: this.handleChangeFund,
      handleToggleCardTabs: this.handleToggleCardTabs,
    }

    const multiFundViewProps = {
      performances,
      currentFund,
      changeFund: this.handleChangeFundFromMultiView,
    }

    return (
      <StyledDashboard>
        {isLoading ? (
          <LoadingIndicator loadingText="Loading your funds" />
        ) : (
          <div>
            <h2 className="heading">currentView {currentFund.name}</h2>
            {
              {
                single: <SingleFundView {...singleFundViewProps} />,
                multi: <MultiFundView {...multiFundViewProps} />,
              }[currentView]
            }
          </div>
        )}
      </StyledDashboard>
    )
  }
}

export default Metric
