import styled from 'styled-components'
import { colors, typography, patterns } from '../../../../assets/styles'

export const StyledTab = styled.li`
  &:not(:last-child) {
    margin-right: 2rem;
  }
  .tab-link {
    position: relative;
    display: flex;
    align-items: center;
    color: ${colors.textGrey};
    padding: 0 0.5rem;
    font-size: ${typography.smallBaseFontSize};
    font-weight: ${typography.headingFontWeight};
    line-height: ${typography.smallBaseFontSize};
    text-transform: uppercase;
    opacity: 0.6;
    transition: all 250ms linear;
    text-decoration: none;
    &:hover,
    &:active {
      opacity: 1;
    }
    .tab-icon {
      margin-right: 0.6rem;
    }
    &.is-active-tab {
      opacity: 1;
      .tab-icon svg > g > g {
        fill: ${colors.bobGreen};
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -23px;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${colors.bobGreen};
      }
    }
  }
  .tab-button {
    ${patterns.buttonDefault};
    position: relative;
    display: flex;
    align-items: center;
    color: ${colors.textGrey};
    padding: 0 0.5rem;
    font-size: ${typography.smallBaseFontSize};
    font-weight: ${typography.headingFontWeight};
    line-height: ${typography.smallBaseFontSize};
    text-transform: uppercase;
    opacity: 0.6;
    transition: all 250ms linear;
    text-decoration: none;
    &:hover,
    &:active {
      opacity: 1;
    }
    &.is-active-tab {
      opacity: 1;
      &:after {
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${colors.bobGreen};
      }
    }
  }
`
