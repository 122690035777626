import styled from 'styled-components'
import { patterns, typography, colors } from '../../../../../assets/styles'

const StyledPortfolios = styled.div`
  .cancel-button {
    cursor: pointer;
    height: 16px;
    width: 42px;
    color: #2cb7df;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
    margin-bottom: 2em;
  }
  .detail-close-sign {
    margin: -18px 0px 0px 14px;
    cursor: pointer;
    position: sticky;  
  }
  .separate-marketplace {
    height: 1px;
    width: 994px;
    margin-top: 3em;
    margin-bottom: 1em;
    background-color: #d8d8d8;
  }
  .add-model {
    width: 243px;
    height: 48px;
    border-radius: 4px;
    background-color: #0cb0c8;
    margin-top: 2em;
    cursor: pointer;
  }
  .add-model-label {
    width: 189px;
    height: 22px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    color: #ffffff;
    margin-left: 20%;
    margin-top: 5%;
    cursor: pointer;
  }
  .subheading {
    ${patterns.label};
    margin-top: 0rem;
  }
  .allocation-subheading {
    margin-left: 2rem;
  }
  .three-column-card-grid {
    max-width: 1500px;
    display: grid;
    grid-gap: 1.562rem;
    grid-template-columns: repeat(auto-fit, minmax(10.75rem, 1fr)); // 3 column layout
  }

  .portfolio-card {
    ${patterns.defaultCard};
    padding: 1rem 0;
    .portfolio-name-heading {
      font-size: ${typography.mediumFontSize};
      font-weight: ${typography.baseFontWeight};
      text-align: center;
    }
  }
  .portfolio-card-adding-model {
    ${patterns.defaultCard};
    height: 9em;
    padding: 1rem 0;
    .portfolio-name-heading {
      font-size: ${typography.mediumFontSize};
      font-weight: ${typography.baseFontWeight};
      text-align: center;
    }
  }
  .dot-divider {
    width: 4px;
    height: 4px;
    margin: 0.6rem auto;
    border-radius: 10rem;
    background: ${colors.bobGreen};
  }
  .portfolio-stats {
    list-style: none;
    padding: 0;
    margin-top: 2rem;
    margin-bottom: 0;
    text-align: center;
    .portfolio-stats-label {
      ${patterns.label};
    }
    .portfolio-stat {
      font-size: ${typography.smallBaseFontSize};
    }
  }
  .allocation-donut-chart-wrapper {
    display: block;
    width: 150px;
    margin: 2rem auto 1rem auto;
  }

  .no-data-text {
    margin: 2rem 0;
  }

  .model-buy-in-wrapper{
    margin-top: 1.7em;
    height: 35px;
    width: 150px;
  }

  .model-buy-in {
    height: 11px;
    width: 130px;
    color: #767676;
    font-family: Avenir;
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 0.57px;
    line-height: 11px;
    text-align: center;
  }

  .model-buy-in-amount {
    margin-left: 2.5em;
    height: 16px;
    width: 50px;
    color: #333333;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
    text-align: center;
  }
`

export default StyledPortfolios
