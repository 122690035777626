import styled from 'styled-components'
import { patterns, colors } from '../../../../../assets/styles'

export const StyledDashboard = styled.div`
  margin: 0 2rem;
  ${patterns.appContainer};
  @media screen and (min-width: 900px) {
    margin: 0 auto;
    max-width: calc(100% - 22rem);
  }
`

export const StyledChangeView = styled.div`
  display: flex;
  margin-top: 2rem;
  .change-view-button-bar {
    display: flex;
    align-items: center;
    margin-left: auto;
    p {
      ${patterns.label};
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
  .change-view-button {
    ${patterns.buttonDefault};
    background: none;
    opacity: 0.7;
    transition: all 200ms ease-out;
    &.is-active {
      opacity: 1;
    }
    svg g g {
      fill: ${colors.labelGrey};
    }
    &.is-active svg g g {
      fill: ${colors.linkBlue};
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
`
