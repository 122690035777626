import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'
import { colors, typography } from '../../../assets/styles'
import DashboardCard from '../../../common/components/DashboardCard'
console.log('colors', colors)
const StyledButton = styled(Button)({
  color: colors.bobBlue,
  borderColor: colors.bobBlue,
  fontSize: typography.extraSmallFontSize,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#E3FFFF',
  },
})

@inject(({ store: { navigate, auth, service } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getServiceCategories: service.getServiceCategories,
  }
})
@observer
export default class ServiceCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mockList: [],
    }
  }
  async componentDidMount() {
    const { getServiceCategories, isAuthenticated } = this.props
    await isAuthenticated()
    const serviceCategories = await getServiceCategories()
    this.setState({ serviceCategories })
  }
  clickServiceCategory(serviceCategory) {
    const { navigate } = this.props
    navigate(`services/${serviceCategory._id}/tasks`)
  }

  getRunningInstances(serviceCategory) {
    return (serviceCategory.serviceTasks || []).filter(
      serviceTask => serviceTask.state === 'RUNNING'
    )
  }
  render() {
    const { serviceCategories = [] } = this.state
    return (
      <React.Fragment>
        <Container fixed style={{ fontFamily: typography.baseFontFamily }}>
          <Typography style={{ fontFamily: typography.baseFontFamily }}>Services</Typography>
          <br />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            style={{ verticalAlign: 'top' }}
          >
            {serviceCategories.map((serviceCategory, index) => {
              return (
                <Grid item xs={4} key={`service_category_${index}`} style={{ paddingBottom: 10 }}>
                  <DashboardCard
                    hasTitle={false}
                    title=""
                    moreTool={false}
                    style={{
                      height: '280px',
                      width: '300px',
                      textAlign: 'center',
                      paddingBottom: 100,
                    }}
                  >
                    <Grid container direction="column" style={{ display: 'table' }}>
                      <div style={{ display: 'table-cell', verticalAlign: 'middle', height: 282 }}>
                        {this.getRunningInstances(serviceCategory).length > 0 && (
                          <Grid item style={{ paddingBottom: 10 }}>
                            <span style={{ color: colors.bobGreen }}>
                              {this.getRunningInstances(serviceCategory).length} Running Instances
                            </span>
                          </Grid>
                        )}
                        <Grid item style={{ paddingBottom: 10 }}>
                          <p style={{ fontFamily: typography.baseFontFamily }}>
                            {serviceCategory.serviceName}
                          </p>
                        </Grid>
                        <Grid item>
                          <StyledButton
                            variant="outlined"
                            style={{ width: 150 }}
                            onClick={() => this.clickServiceCategory(serviceCategory)}
                          >
                            Manage
                          </StyledButton>
                        </Grid>
                      </div>
                    </Grid>
                  </DashboardCard>
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}
