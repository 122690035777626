import React from 'react'
import { StyledNoFilledButton } from './styles'

const NoFilledButton = ({ title, action }) => {
  return (
    <StyledNoFilledButton>
      <div onClick={ ()=> {action()}} className="no-filled-button">
        <p className="no-filled-button-content">{title}</p>
      </div>
    </StyledNoFilledButton>
  )
}

export default NoFilledButton
