import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StyledMultiFundView, StyledCardFooterPortfolio, StyledCardBody } from './styles'
import currencyFilter from '../../../lib/currency'
import FavoriteIconSvg from '../../../assets/icons/favoriteIconSvg'
import StatNumber from '../StatNumber'

class MultiFundView extends Component {
  state = {
    mappedPerformances: [],
    dropdownIsOpen: false,
  }

  componentDidMount() {
    const { performances } = this.props
    const mappedPerformances = performances.map(perf => ({
      ...perf,
      currentStrategy: perf.currentPerformanceByFunds[0].strategies[0],
      isStrategyDropdownOpen: false,
    }))

    this.setState({ mappedPerformances })
  }

  toggleStrategiesDropdown = perfId => {
    const { dropdownIsOpen } = this.state
    this.setState({ dropdownIsOpen: !dropdownIsOpen, performanceId: perfId })
  }

  handleChangeCurrentStrategy = (strategy, perfId) => {
    const { mappedPerformances } = this.state
    const updatedPerfData = mappedPerformances.map(perf => {
      if (perf.currentPerformanceByFunds[0]._id === perfId) {
        return {
          ...perf,
          currentStrategy: strategy,
        }
      }
      return perf
    })

    this.setState({ mappedPerformances: updatedPerfData }, () =>
      this.toggleStrategiesDropdown(perfId)
    )
  }

  render() {
    const { changeFund } = this.props
    const { mappedPerformances, performanceId, dropdownIsOpen } = this.state

    return (
      <StyledMultiFundView>
        {mappedPerformances.map(performance => (
          <article key={performance.currentPerformanceByFunds[0]._id} className="fund-card">
            <header className="card-header">
              {/* eslint-disable-next-line no-console */}
              <button className="favorite-icon" onClick={() => console.log('favorite!')}>
                <FavoriteIconSvg />
              </button>
              <button className="card-title-as-link" onClick={() => changeFund(performance)}>
                <h2 className="card-title">{performance.currentPerformanceByFunds[0].name}</h2>
              </button>
            </header>
            <StyledCardBody>
              <p className="card-label-text">Total Principal</p>
              <p className="total-fund-balance">
                {currencyFilter(performance.currentPerformanceByFunds[0].totalInvested)}
              </p>
              <div className="badge-wrapper">
                <span className="cash-badge">
                  <span>Market Value: </span>
                  {currencyFilter(performance.currentPerformanceByFunds[0].currentPositionValue)}
                </span>
                <span className="live-sticker" title="Fund is live!">
                  LIVE
                </span>
              </div>
              <div className="stats-bar-fund">
                <StatNumber
                  statLabel="P / L"
                  statValue={currencyFilter(performance.currentPerformanceByFunds[0].profitLoss)}
                  isNegative={Math.sign(performance.currentPerformanceByFunds[0].profitLoss)}
                  hasPositiveValue
                />
                <StatNumber
                  statLabel="Fund AUM"
                  statValue={currencyFilter(
                    performance.currentPerformanceByFunds[0].totalStartingCash
                  )}
                />
              </div>
            </StyledCardBody>
            <StyledCardFooterPortfolio>
              <span className="has-liners">Portfolio</span>
              {performance.currentPerformanceByFunds[0].strategies.length > 1 && (
                <div className="strategies-dropdown">
                  <button
                    onClick={() =>
                      this.toggleStrategiesDropdown(performance.currentPerformanceByFunds[0]._id)
                    }
                    className="strategies-dropdown-button"
                  >
                    {performance.currentStrategy.name}
                  </button>
                  {dropdownIsOpen &&
                  performanceId === performance.currentPerformanceByFunds[0]._id ? (
                    <ul className="strategies-dropdown-menu">
                      {performance.currentPerformanceByFunds[0].strategies.map(strategy => (
                        <li key={strategy._id}>
                          <button
                            onClick={() =>
                              this.handleChangeCurrentStrategy(
                                strategy,
                                performance.currentPerformanceByFunds[0]._id
                              )
                            }
                            className="strategy-dropdown-item"
                          >
                            {strategy.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              )}
              {performance.currentPerformanceByFunds[0].strategies.length === 1 && (
                <p className="single-strategy-name">{performance.currentStrategy.name}</p>
              )}
              <div className="mini-strategy-bar">
                <StatNumber
                  statLabel="Market Value"
                  statValue={currencyFilter(performance.currentStrategy.currentPositionValue)}
                  isNegative={Math.sign(performance.currentStrategy.currentPositionValue)}
                />
                <StatNumber
                  statLabel="P / L"
                  statValue={
                    performance.currentStrategy.profitLoss
                      ? currencyFilter(performance.currentStrategy.profitLoss)
                      : '$0'
                  }
                  isNegative={Math.sign(performance.currentStrategy.profitLoss)}
                  hasPositiveValue
                />
                <StatNumber
                  statLabel="Principal"
                  statValue={currencyFilter(performance.currentStrategy.totalInvested)}
                  isNegative={Math.sign(performance.currentStrategy.totalInvested)}
                />
              </div>
            </StyledCardFooterPortfolio>
          </article>
        ))}
      </StyledMultiFundView>
    )
  }
}

MultiFundView.propTypes = {
  performances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeFund: PropTypes.func.isRequired,
}

export default MultiFundView
