import { observable, action } from 'mobx'

export default class MetricStore {
  @observable currentFundLegers = []
  @observable currentFundHistoricalPerformances = []
  @observable currentFundPerformance = null

  constructor(metricStore) {
    this.rootStore = metricStore.rootStore
  }

  @action('SmartFund Store | setFundMetricsByUserId')
  setFundMetricsByUserId = async (userId, fundId) => {
    const { api } = this.rootStore

    const results = await Promise.all([
      api.get(`/v0/performance/users/${userId}/${fundId}`).then(r => {
        r.type = 'performance'
        return r
      }),
      api.get(`/v0/performance/history/${userId}/${fundId}`).then(r => {
        r.type = 'historical'
        return r
      }),
    ])
    const positionPerformanceData = results.find(r => r.type === 'performance')
    const legacyPerformanceData = results.find(r => r.type === 'historical')
    const positionPerformance = positionPerformanceData.data || {}
    const legacyPerformance = legacyPerformanceData.data || {}
    const { currentPerformanceByFunds, ledgers = [] } = positionPerformance
    const { legacyPositionsPerformance } = legacyPerformance
    this.currentFundPerformance = (currentPerformanceByFunds || []).find(cp => cp._id === fundId)

    this.currentFundHistoricalPerformances = legacyPositionsPerformance
    ledgers.forEach(l => {
      if (l.fundId === fundId) this.currentFundLegers.push(l)
    })

    return {
      currentFundHistoricalPerformances: this.currentFundHistoricalPerformances,
      currentFundLegers: this.currentFundLegers,
      currentFundPerformance: this.currentFundPerformance,
    }
  }

  @action('SmartFund Store | getUserPerformanceDate')
  getUserPerformanceData = async () => {}

  @action('SmartFund Store | separatePerformanceByFund')
  separatePerformanceByFund = positionPerformance => {}
}
