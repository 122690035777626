import { observable } from 'mobx'

export let TotumRiskForm = observable({
  consistency: 'none',
  period: 0,
  impactingHousehold: 0,
  zipOwner: 0,
  zip: null,
  age: null,
  name: null,
  health: null,
  bodyCount: null,
  bodyCountDependents: null,
  income: null,
  netWorth: null,
  investmentAmount: null,
  retirementAge: null,
  expenses: null,
  maxLoss: null,
})
