import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Pick } from '../../../../common/components/Dialogs'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
    return {
        navigate,
        isAuthenticated: auth.isAuthenticated,
        setCurrentFund: fund.setCurrentFund,
        currentFund: fund.currentFund
    }
})
@observer
class Allocation extends Component {
    constructor(props) {
        super(props)
        this.state = { fund: {}, width: 4, filters: [] }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        let { currentFund } = this.props
        const { clients } = currentFund
        this.setState({ fund: currentFund, clients, loading: false })
    }

    async updateAllocationsOnFund() {

    }

    async updateAllocation(client, e) {
        let { setCurrentFund } = this.props
        const { clients, fund } = this.state
        const updatedClient = clients.find(c => c._id === client._id)
        updatedClient.bobBudget = e.target.value
        fund.clients = clients
        await setCurrentFund(fund)
        this.setState({ clients })
    }

    handleChangePage(event, tablePage) {
        this.setState({ tablePage })
    }

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleFilterValues(type, e) {
        const { filters = [] } = this.state
        const val = e.target.value
        filters.splice(filters.findIndex(f => f.type === type), 1)
        filters.push({ type, val })
        this.setState({ filters })
    }

    handleSelectAll(e) {
        this.setState({ loading: true })
        const { clients = [] } = this.state
        clients.forEach(client => {
            const mockElement = {
                target: { checked: e.target.checked },
            }
            this.pickHandler(mockElement, client)
        })
        this.setState({ loading: false })
    }

    toggleGroupView(e) {
        this.setState({ groupView: e.target.checked })
        setTimeout(async () => await this.getClients(), 0)
    }

    pickViewClient() {
        const { clients = [], tablePage = 0, rowsPerPage = 4, filters, loading = false } = this.state
        const filtered = clients.filter(p => {
            return filters.every(f => {
                if (
                    f.type === 'name' &&
                    !(
                        p.name.toUpperCase().includes(f.val.toUpperCase())
                    )
                ) {
                    return false
                }
                if (f.type === 'accountNumber' && !p.accountNum.includes(f.val)) {
                    return false
                }
                return true
            })
        })
        const page = []
            .concat(filtered)
            .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
        return (
            <div>
                {!loading && (
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={1}>
                                        <TextField
                                            onChange={this.handleFilterValues.bind(this, 'name')}
                                            label="Filter by Client"
                                            fullWidth={true}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                        <TextField
                                            onChange={this.handleFilterValues.bind(this, 'accountNumber')}
                                            label="Filter by Account"
                                            fullWidth={true}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Client Name</TableCell>
                                    <TableCell>Account #</TableCell>
                                    <TableCell>Allocation</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {page.map((client, idx) => {
                                    const { name = '', accountNum = '', bobBudget = 0 } = client
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>
                                                {name}
                                            </TableCell>
                                            <TableCell>{accountNum}</TableCell>
                                            <TableCell> <TextField
                                                value={bobBudget}
                                                onChange={this.updateAllocation.bind(this, client)}
                                                fullWidth={true}
                                            /></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={clients.length}
                            page={tablePage}
                            rowsPerPage={rowsPerPage}
                            component="div"
                            onChangePage={this.handleChangePage.bind(this)}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                        />
                    </div>
                )}
                {loading && (
                    <div>
                        <div className="row">
                            <div className="col-md-12">Loading Clients</div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <CircularProgress color="secondary" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3"></div>
                </div>
                {this.pickViewClient()}
            </div>
        )
    }
}

export default class AllocationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    body() {
        return (
            <div>
                <Allocation
                />
            </div>
        )
    }
    render() {
        const { show = false, onClose, onCommit } = this.props
        return (
            <Pick
                show={show}
                onHide={onClose}
                onCommit={onCommit}
                title="Manage Allocations"
                className="strategies-selector"
                body={this.body()}
            />
        )
    }
}
