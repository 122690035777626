import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import op from 'simple-object-path'
import LoadingIndicator from '../../../../common/components/LoadingIndicator'
import { StyledDashboard } from './styles'
import SingleFundView from '../../../../common/components/SingleFundView'

@inject(({ store: { navigate, user, fund, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    client: auth.profile.user,
    profile: auth.profile,
    currentFund: fund.currentFund,
    setFundMetricsByUserId: user.metricStore.setFundMetricsByUserId,
    currentFundLegers: user.metricStore.currentFundLegers,
    currentFundHistoricalPerformances: user.metricStore.currentFundHistoricalPerformances,
    currentFundPerformance: user.metricStore.currentFundPerformance,
  }
})
@observer
class Metrics extends Component {
  state = {
    isLoading: true,
    clientLoading: false,
    showMetrics: false,
    client: null,
    totalAUM: 0,
    historicalCardActiveTab: 'ledger',
    currentView: 'single',
  }

  async componentDidMount() {
    const { profile, isAuthenticated, currentFund, setFundMetricsByUserId } = this.props
    const userId = op(profile, 'user/_id')
    const { _id: fundId } = currentFund
    await isAuthenticated()
    await setFundMetricsByUserId(userId, fundId)
    return this.setState({
      isLoading: false,
      showMetrics: true,
    })
  }

  handleToggleCardTabs = value => {
    this.setState({ historicalCardActiveTab: value })
  }

  toggleView = view => {
    this.setState({ currentView: view })
  }

  render() {
    const {
      currentFund,
      currentFundLegers,
      currentFundHistoricalPerformances,
      currentFundPerformance,
    } = this.props
    const { isLoading, showMetrics, totalAUM, historicalCardActiveTab } = this.state
    currentFund.ledgers = currentFundLegers
    currentFund.legacyPositionsPerformance = currentFundHistoricalPerformances

    const filteredPerformancesForDropdown = [currentFundPerformance]

    const singleFundViewProps = {
      currentFund,
      filteredPerformancesForDropdown,
      currentFundPerformance,
      totalAUM,
      historicalCardActiveTab,
      handleChangeFund: this.handleChangeFund,
      handleToggleCardTabs: this.handleToggleCardTabs,
    }

    return (
      <StyledDashboard>
        {isLoading && <LoadingIndicator loadingText="Loading Fund Metics" />}
        {showMetrics && (
          <div>
            <SingleFundView {...singleFundViewProps} />{' '}
          </div>
        )}
      </StyledDashboard>
    )
  }
}

export default Metrics
