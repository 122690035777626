import currencyFilter from '../../../../lib/currency'

const tableColumns = [
  {
    id: 0,
    displayName: 'Security',
    propPath: 'security',
    filterToolTip: true,
    filter: false,
  },
  {
    id: 1,
    displayName: 'Price',
    propPath: 'price',
    displayFn: item => currencyFilter(item),
    filterVal: item => Number(item).toFixed(3),
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
  {
    id: 2,
    displayName: 'Performance',
    propPath: 'performance',
    displayFn: item => `% ${Number(item).toFixed(3)}`,
    filterVal: item => Number(item).toFixed(3),
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
  {
    id: 3,
    displayName: 'Days in Trade',
    propPath: 'daysInTrade',
    displayFn: item => item || 0,
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
  {
    id: 4,
    displayName: '# of Clients in Trade',
    propPath: 'numberOfClients',
    displayFn: item => item || 0,
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
]

export default tableColumns
