import React from 'react'

const PortfolioIconSvg = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18">
    <g id="portfolio-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#333333">
        <path
          d="M16,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,2 C18,0.9 17.1,0 16,0 Z M6,14 L4,14 L4,7 L6,7 L6,14 Z M10,14 L8,14 L8,4 L10,4 L10,14 Z M14,14 L12,14 L12,10 L14,10 L14,14 Z"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
)

export default PortfolioIconSvg
