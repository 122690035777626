import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject(({ store: { navigate, auth, app } }) => {
  return {
    navigate,
    handleAuthentication: auth.handleAuthentication,
    getTdAmeritradeToken: app.callbackStore.getTdAmeritradeToken,
    isAuthenticated: auth.isAuthenticated,

  }
})
@observer
class Callback extends Component {
  constructor(props) {
    super(props)
    this.state = { continue: false }
  }

  async componentDidMount() {
    const { location, getTdAmeritradeToken, handleAuthentication, navigate, isAuthenticated} = this.props
    if (location && location.search) {
      await isAuthenticated()
      await getTdAmeritradeToken(location)
      return navigate('/profile')
    }
    await handleAuthentication(location)
    return navigate('/')
  }

  render() {
    return (<div></div>)
  }
}

export default Callback
