const tableColumns = [
  {
    id: 0,
    displayName: 'Security',
    propPath: 'security',
    filterToolTip: true,
    filter: false,
  },
  {
    id: 1,
    displayName: 'Entry Price',
    propPath: 'totalInvested',
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    displayFn: item => `$ ${parseFloat(item).toFixed(2)}`,
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
  {
    id: 2,
    displayName: 'Exit Price',
    propPath: 'sellAmount',
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    displayFn: item => `$ ${parseFloat(item).toFixed(2)}`,
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
  {
    id: 3,
    displayName: 'Profit/Loss',
    propPath: 'returnOnInvestment',
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    displayFn: item => `$ ${parseFloat(item).toFixed(2)}`,
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
  {
    id: 4,
    displayName: 'Performance',
    propPath: 'performance',
    displayFn: item => `% ${parseFloat(item).toFixed(3)}`,
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    numericEval: true,
    filterToolTip: true,
    filter: false,
  },
]

export default tableColumns
