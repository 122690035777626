import styled from 'styled-components'
import { colors, patterns, typography } from '../../../../assets/styles'

const StyledStatNumber = styled.div`
  display: block;
  text-align: ${props => (props.alignText === 'left' ? 'left' : 'right')};
  margin-bottom: ${props => (props.isSingle ? '1rem' : 0)};
  &:not(:last-child) {
    margin-right: ${props => (!props.isSingle ? '3rem' : 0)};
  }
  .data-label {
    display: ${props => (props.hasToggleValue ? 'flex' : 'block')};
    align-items: center;
    font-size: ${typography.extraSmallFontSize};
    color: ${colors.labelGrey};
    margin: 0;
    margin-bottom: ${props => (!props.hasToggleValue ? '4px' : 0)};
    line-height: 20px; // height of pill buttons
  }
  .data-field {
    font-size: ${typography.smallBaseFontSize};
    font-weight: ${typography.headingFontWeight};
    color: ${props => (props.isNegative ? colors.alertRed : colors.textGrey)};
    &.has-positive {
      color: ${props => (props.isNegative ? colors.alertRed : colors.successGreen)};
    }
  }
  .pill-button {
    margin-left: 0.75rem;
    margin-bottom: 3px;
    .pill-button-left,
    .pill-button-right {
      ${patterns.buttonDefault};
      width: 28px;
      height: 20px;
      line-height: 23px;
      outline: none;
      padding: 0 5px;
      background: ${props => (props.darkenPillButtons ? colors.borderGrey : 'white')};
      &.is-active {
        color: ${colors.bobBlue};
      }
    }
    .pill-button-left {
      border-top-left-radius: 4rem;
      border-bottom-left-radius: 4rem;
      margin-right: 2px;
    }
    .pill-button-right {
      border-top-right-radius: 4rem;
      border-bottom-right-radius: 4rem;
    }
  }
`

export default StyledStatNumber
