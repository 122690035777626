import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '../Table'
import tableColumns from './data'
import StyledHistoricalPerformanceTable from './styles'

class HistoricalPerformanceTable extends Component {
  state = {
    currentStrategy: null,
    filteredTableDataByPortfolio: [],
  }

  componentDidMount() {
    const { currentStrategy, tableData } = this.props
    if (currentStrategy) {
      const filteredTableData = tableData.filter(
        item => item.portfolioName === currentStrategy.name
      )
      this.setState({ currentStrategy, filteredTableDataByPortfolio: filteredTableData })
    }
  }

  componentDidUpdate(prevProps) {
    const { currentStrategy, tableData } = this.props
    if (currentStrategy !== prevProps.currentStrategy) {
      const filteredTableData = tableData.filter(
        item => item.portfolioName === currentStrategy.name
      )
      this.setState({ currentStrategy, filteredTableDataByPortfolio: filteredTableData })
    }
  }

  render() {
    const { currentStrategy, filteredTableDataByPortfolio } = this.state

    return (
      <StyledHistoricalPerformanceTable>
        <div className="fund-data-wrapper">
          <span className="viewing-tag">Viewing</span>
          <h3 className="card-title">{currentStrategy ? currentStrategy.name : ''}</h3>
        </div>
        <Table rowsPerPage={5} list={filteredTableDataByPortfolio} listColumns={tableColumns} />
      </StyledHistoricalPerformanceTable>
    )
  }
}

HistoricalPerformanceTable.propTypes = {
  currentStrategy: PropTypes.shape({}).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default HistoricalPerformanceTable
