import { action, observable } from 'mobx'

export default class ProfileStore {
  @observable tdaLinked = false

  constructor(user) {
    this.rootStore = user.rootStore
  }

  @action('Profile Store | isTdaLinked')
  isTdaLinked = () => {
    const { user } = this.rootStore
    const {
      currentUser: { linkedBrokers = [] },
    } = user
    this.tdaLinked = linkedBrokers.find(b => b.broker === 'TdAmeritrade')
  }

  @action('Client Store | linkTradier')
  linkTradier = async () => {
    window.location.href =
      'https://api.tradier.com/v1/oauth/authorize?client_id=Vq23HUAPiuRL38LAFFleGizsOZDGEu8M&scope=read,write,trade&state=ohai'
  }

  @action('Client Store | linkTdAmeritrader')
  linkTdAmeritrade = async () => {
    const { user } = this.rootStore
    const { updateUserAccountNum, currentUser } = user
    const { accountNum } = currentUser
    await updateUserAccountNum(accountNum)
    window.location.href =
      'https://auth.tdameritrade.com/oauth?client_id=GZJ81YCGYAOFOXSPXBIGXF6CCN4BWITE%40AMER.OAUTHAP&response_type=code&redirect_uri=https%3A%2F%2Fprod.letbob.com%2Fcallback'
  }
}
