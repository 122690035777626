import { observable, action, set } from 'mobx'

export default class StudyStore {
  @observable currentStudy = {}

  constructor(modelStore) {
    this.rootStore = modelStore.rootStore
    this.modelStore = modelStore
  }

  @action('Model Store | setCurrentStudy')
  setCurrentStudy = study => {
    set(this.currentStudy, study)
    return this.currentStudy
  }

  @action('Model Store | initials')
  initials = text => {
    if (typeof text !== 'string') return ''
    return text
      .split(/\s/)
      .map(w => w[0])
      .join('')
      .toUpperCase()
  }

}
