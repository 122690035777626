import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import Card from './Card'
import blueLogo from '../../assets/img/bob-logo-modal.png'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const brandLogos = {
  Visa: (
    <img
      style={{ maxHeight: '60px' }}
      alt="Visa"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAK/SURBVGhD7ZlJCE1RGICvOZRpoyiRhbBCKAulWIidhSIsKISNwkKmKDZYUTJtTMmUtbIxbchURFbGLCky+77r/rm9nt5T93pPna++3j3/ued2/nfP1HtZIpFIJBI1MQP34dku0b5Mx79iF/7oUndgW8zHZg/oJudhSy5hs8bd5AVsyV0sN7qMezqsfSj3yT625D6WGy3CTmMfyn2yjy1JidRISqTcKCVSIf8skb44tLCXgT/QD8fjROxjoIFB6DOG5KXfVJLIMlyAN/AZPsX92AMDjw3e+x4H4jn03jsoJmqbTxjP/YCzMBiGxqz7gv0xqPSNDMAtGJ3xmw0eorHjeSnLXqPlm3np16YWz7uHD4rrERisx7hHywfEyofWaDyJb3GJARiHce9MdHhE+RjKI7TsCTaYXHyKb/cJRjtdjUHlichLtIO781KWbUbvcyjZoUlFWTeixLHHoTbKQANz0PpX6Bvz+jAGtSRyCr/ihryUZbfR+7bmpSxbiNHWk7SsxIg5D7y3vCicR+scgkeKa58b1JLIKjR+HccU19/QYSfxhnSsgYLF+Byj7gDKSHRyG3OYrimunYsuElJLIhMw6k4Un1cxcMIb+4iNS7Er0WmM+p64vSg/xim4oihrzKNaEnEeONmt8034uRSDa2jMsS52JlY4Ox5L9Rvsjc45y800KaklEXGfiPp36N4R2EHjfvMSb8jh87m41rXo/hTlo3ioMJbzgyi1JbIOoz72DikvvdsMwBmMmL7A5ShX0FjsN4FLr/FbeanGRDxexJHEY0fgpjm70EkcDEfHv0OsfDTxKOIzbFcmnj84L9WYyL8mJVJulBKpkJRIudF/m0jjD3RubJs6bBxrwrZ+oLuI5UbdqKfllvgDcbPG3eRcbIud2OwBnfY7xtGnbabhXmz8w6VT2pepmEgkEolExWTZT9IcutrtZdCqAAAAAElFTkSuQmCC"
    />
  ),
}

export default class PaymentProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.paymentUpdated = this.paymentUpdated.bind(this)
  }

  componentDidMount() {}
  paymentUpdated() {
    this.setState({ showCardModal: false })
    this.props.paymentUpdated()
  }
  render() {
    const { showCardModal = false } = this.state
    const { payment } = this.props
    const { brand, last4, createdDate } = payment || {}
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Payment</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <div className="row">
                    <div className="col-md-12">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Last 4</TableCell>
                            <TableCell>Last Updated</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{brandLogos[brand]}</TableCell>
                            <TableCell>{last4}</TableCell>
                            <TableCell>
                              {createdDate ? new Date(createdDate).toDateString() : ''}
                            </TableCell>
                            <TableCell>
                              {!payment && (
                                <Button
                                  onClick={() => this.setState({ showCardModal: true })}
                                  className="pull-right"
                                >
                                  Add Payment Info
                                </Button>
                              )}
                              {payment && (
                                <Button
                                  onClick={() => this.setState({ showCardModal: true })}
                                  className="pull-right"
                                >
                                  Change Payment Info
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-12"></div>
        </div>
        <div className="settings">
          <Modal
            show={showCardModal}
            onHide={() => this.setState({ showCardModal: false })}
            className="settings-modal"
            bsSize="small"
          >
            <Modal.Header closeButton>
              <div className="modal-logo-container">
                <img src={blueLogo} width={75} className="modal-logo" alt="logo" />
              </div>
            </Modal.Header>
            <Modal.Body>
              <Card saved={this.paymentUpdated} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    )
  }
}

// render() {
//	 const { showCardModal = false } = this.state;
//	 const { payment } = this.props;
//	 const { brand, last4, createdDate } = payment || {};
//	 return (
//		 <div>
//			 <div className="row animate">
//				 <div className="col-md-6">
//				 <h1>Payment</h1><br />
//				 </div>
//				 <div className="col-md-6">
//				 </div>

//			 </div>
//			 <div className="row animate">
//				 <div className="col-md-12">
//					 <table className="table" style={{width: '100%'}}>
//						 <tbody>
//							 <tr>
//								 <th>Type</th>
//								 <th>Last 4</th>
//								 <th>Last Updated</th>
//								 <td>
//								 </td>
//							 </tr>
//							 <tr>
//								 <td>{brandLogos[brand]}</td>
//								 <td>{last4}</td>
//								 <td>{createdDate ? new Date(createdDate).toDateString() : ''}</td>
//								 <td>
//								 {!payment && <button onClick={() => this.setState({showCardModal: true})} className="bob-button	pull-right">Add Payment Info</button>}
//								 {payment && <button onClick={() => this.setState({showCardModal: true})} className="bob-button	pull-right">Change Payment Info</button>}
//								 </td>
//							 </tr>
//						 </tbody>
//					 </table>
//				 </div>
//				 <div className="settings">
//					 <Modal show={showCardModal} onHide={() => this.setState({showCardModal: false})} className="settings-modal"	bsSize="small">
//					 <Modal.Header closeButton>
//					 <div className="modal-logo-container">
//						 <img src={blueLogo} width={75}className="modal-logo" alt="logo"/>
//					 </div>
//					 </Modal.Header>
//						 <Modal.Body>
//							 <Card saved={this.paymentUpdated}/>
//						 </Modal.Body>
//					 </Modal>
//				 </div>
//			 </div>
//		 </div>
//	 );
// }
