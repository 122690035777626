import React from 'react'
import { StyledFilledButton } from './styles'

const FilledButton = ({ title, action }) => {
  return (
    <StyledFilledButton>
      <div onClick={ ()=> {action()}} className="filled-button">
        <p className="filled-button-content">{title}</p>
      </div>
    </StyledFilledButton>
  )
}

export default FilledButton
