import auth0 from 'auth0-js';
import axios from 'axios';

class Auth {
	auth0 = new auth0.WebAuth({
		domain: 'daytradingbob.auth0.com',
		clientID: 'ofS6Hw0H0NH3sSPnk9GfKMVdXPLRmxI4',
		redirectUri: `${window.location.origin}/callback`,
		audience: 'https://daytradingbob.auth0.com/userinfo',
		responseType: 'token id_token',
		scope: 'openid profile'
	});

	constructor() {
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.handleAuthentication = this.handleAuthentication.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
	}

	async updateUser(authResult){
		const user = {UserId: authResult.idTokenPayload.sub};
		const response = await axios.post('/v0/user.update', user);
		return response.data.user;
	}

	async getUser(){
		const response = await axios.get('/v0/user.getUser');
		return response.data.user;
	}

	handleAuthentication(cb) {
		this.auth0.parseHash(async (err, authResult) => {
			console.log('authResult', authResult)
			if (authResult && authResult.idToken && authResult.idTokenPayload) {
				try{
					axios.defaults.headers.common.Authorization = `Bearer ${authResult.idToken}`;
					const user = await this.updateUser(authResult);
					this.setSession(authResult, user);
				}catch(e){
					console.log(e.message);
					return cb(e);
				}
				cb(null, authResult);
			} else if (err) {
				console.log(err);
				cb(err);
			}
		});
	}

	redirectToLogin() {
		if (!/\/login/.test(window.location)) window.location = '/login';
	}

	setSession(authResult, user) {
		// Set the time that the access token will expire at
		let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
		this.profile = authResult.idTokenPayload;
		this.profile.user = user;
		this.token = authResult.idToken;
		sessionStorage.setItem('token', authResult.idToken);
		sessionStorage.setItem('expires_at', expiresAt);
		sessionStorage.setItem('profile', JSON.stringify(this.profile));
	}

	updateProfile(User){
		if (!this.isAuthenticated()) return this.redirectToLogin();
		this.profile = User;
		return this.profile;
	}

	login() {
		this.auth0.authorize();
	}

	getProfile() {
		if (!this.isAuthenticated()) return this.redirectToLogin();
		if (!this.profile) {
			const json = sessionStorage.getItem('profile');
			if (!json) return this.redirectToLogin();
			this.profile = JSON.parse(json);
			this.token = sessionStorage.getItem('token');
		}
		axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
		return this.profile;
	}
	async setImpersonation(user) {
		this.impersonationUser = user;
		await this.setUserProfile();
	}
	async setUserProfile() {
		const userData = await this.getUser();
		const existingProfile = JSON.parse(sessionStorage.getItem('profile'));
		existingProfile.user.userProfile = userData.userProfile;
		sessionStorage.setItem('profile', JSON.stringify(existingProfile));
	}
	getImpersonationUser() {
		return this.impersonationUser;
	}
	isImpersonating() {
		return typeof this.impersonationUser !== 'undefined';
	}
	logout() {
		// Clear access token and ID token from local storage
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('expires_at');
		sessionStorage.removeItem('profile');
		delete this.profile;
		delete this.token;
		// navigate to the home route
		this.redirectToLogin();
	}

	isAuthenticated() {
		// Check whether the current time is past the
		// access token's expiry time
		let expiresAt = JSON.parse(sessionStorage.getItem('expires_at'));
		return new Date().getTime() < expiresAt;
	}
}

export default new Auth();
