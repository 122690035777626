import React, { Component } from 'react'
import StyledAccess from './styles'

class Access extends Component {
  render() {
    return (
      <StyledAccess>
        <p className="information-label-text">Fund Owner (example)</p>
        <p className="information-text">Mario Payne is the owner of this fund.</p>
      </StyledAccess>
    )
  }
}

export default Access
