import moment from 'moment'
import currencyFilter from '../../../../lib/currency'

const tableColumns = [
  {
    id: 0,
    displayName: 'Date',
    propPath: 'timestamp',
    isDateSort: true,
    displayFn: item => moment(item).format('L'),
    filterToolTip: true,
    filter: false,
  },
  {
    id: 1,
    displayName: 'Transaction Type',
    propPath: 'transactionType',
    filterToolTip: true,
    filter: false,
  },
  {
    id: 2,
    displayName: 'Security',
    propPath: 'security',
    filterToolTip: true,
    filter: false,
  },
  {
    id: 3,
    displayName: 'Price',
    propPath: 'price',
    displayFn: item => currencyFilter(item),
    filterToolTip: true,
    filterVal: item => item,
    toolTipText: 'Use: eq, gt, gte, lt, lte to refine filter',
    numericEval: true,
    filter: false,
  }
]

export default tableColumns
