import React from 'react'
import Payment from 'payment'
import ReactCreditCard from 'react-credit-cards'
import axios from 'axios'
import op from 'simple-object-path'

import 'react-credit-cards/es/styles-compiled.css'
import TextField from '@material-ui/core/TextField'
import { Failure } from '../../common/components/Dialogs'

export default class Card extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      number: '',
      name: '',
      expiry: '',
      cvc: '',
      focused: '',
    }
    this.handleSavePayment = this.handleSavePayment.bind(this)
  }

  componentDidMount() {
    const { saved = () => true } = this.props
    this.setState({ saved })
    Payment.formatCardNumber(document.querySelector('[name="number"]'))
    Payment.formatCardExpiry(document.querySelector('[name="expiry"]'))
    Payment.formatCardCVC(document.querySelector('[name="cvc"]'))
  }

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    })
  }

  handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      this.setState({
        [target.name]: target.value.replace(/ /g, ''),
      })
    } else if (target.name === 'expiry') {
      this.setState({
        [target.name]: target.value.replace(/ |\//g, ''),
      })
    } else {
      this.setState({
        [target.name]: target.value,
      })
    }
  }

  handleCallback(type, isValid) {
    console.log(type, isValid) //eslint-disable-line no-console
  }

  async handleSavePayment(e) {
    e.preventDefault()
    this.setState({ disabled: true })
    const { number, name, expiry, cvc } = this.state
    const payment = { number, name, expiry, cvc }
		const paymentResponse = await axios.post('/v0/createpayment', payment).catch((e) => e.response)
		const errorMessage = op(paymentResponse, 'data/error/raw/message')
		if (errorMessage) {
			this.setState({ disabled: false, errorMessage, showErrorModal: true })
		} else {
			this.setState({ disabled: false })
			this.state.saved()
		}
  }
  render() {
    const { name, number, expiry, cvc, focused, disabled } = this.state
    return (
      <div>
        <div className="row">
          <ReactCreditCard
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          <Failure
            onHide={() => this.setState({ showErrorModal: false })}
            onCommit={() => this.setState({ showErrorModal: false })}
            show={this.state.showErrorModal}
            title={this.state.errorMessage}
            commitMsg={'Ok'}
          />
        </div>
        <div className="row">
          <form className="credit-card-form">
            <TextField
              type="tel"
              name="number"
              label="Card Number"
              maxLength={19}
              onKeyUp={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
            <TextField
              type="text"
              name="name"
              label="Name"
              onKeyUp={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
            <TextField
              type="text"
              name="expiry"
              label="Valid Thru"
              maxLength={7}
              onKeyUp={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
            <TextField
              type="text"
              name="cvc"
              maxLength={3}
              label="CVC"
              onKeyUp={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
          </form>
        </div>
        <button className="modal-button" disabled={disabled} onClick={this.handleSavePayment}>
          {disabled ? 'Saving...' : 'Save payment'}
        </button>
      </div>
    )
  }
}