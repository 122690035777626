import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Chip from '@material-ui/core/Chip'
import LoadingIndicator from '../../common/components/LoadingIndicator'
import totumRiskLogo from '../../assets/img/Totum Logo_ Dark.png'
import { StyledSmartFund, StyledFilledButton, StyledRetailMessage } from './styles'
import currencyFilter from '../../lib/currency'
import CreateFund from './createFund'

@inject(({ store: { navigate, user, totumRisk, auth, fund, model } }) => {
  return {
    navigate,
    getUserClients: user.getUserClients,
    clients: user.clients,
    isRetail: user.isRetail,
    linkedBroker: user.linkedBroker,
    separatePerformanceByFund: user.smartFundStore.separatePerformanceByFund,
    getUserSmartFunds: user.smartFundStore.getUserSmartFunds,
    calculateAUM: user.smartFundStore.calculateAUM,
    calculateTotalAUM: user.smartFundStore.calculateTotalAUM,
    calculateTotalClients: user.smartFundStore.calculateTotalClients,
    isAuthenticated: auth.isAuthenticated,
    setCurrentFundTab: fund.setCurrentTab,
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
    saveFund: fund.updateFund,
    resetRequiredTotalAllocations: model.resetRequiredTotalAllocations,
    capacity: totumRisk.capacity,
  }
})
@observer
class SmartFund extends Component {
  state = {
    isLoading: true,
    newUser: false,
    smartFunds: [],
    clientLoading: false,
    client: null,
    performances: [],
    currentFund: null,
    totalAUM: 0,
    historicalCardActiveTab: 'historical',
    currentView: 'multi',
    showCreatFundModal: false,
  }

  async componentDidMount() {
    window.document.title = 'Bob | SmartFund'
    const {
      setCurrentFundTab,
      setCurrentFund,
      getUserClients,
      resetRequiredTotalAllocations,
      isAuthenticated,
    } = this.props
    await isAuthenticated()
    await setCurrentFundTab('details')
    await setCurrentFund(undefined)
    await getUserClients()
    resetRequiredTotalAllocations()
    this.setMySmartFunds()
  }

  async componentDidUpdate() {
    const { hasPositionPerformance = false } = this.state
    const { client = {}, positionPerformance = {} } = this.props
    const { _id: userId } = client
    if (
      hasPositionPerformance !== userId &&
      positionPerformance &&
      Object.keys(positionPerformance).length
    ) {
      this.setState({ positionPerformance, hasPositionPerformance: userId })
    }
  }

  getTypeColor(fundType) {
    let color
    switch (fundType) {
      case 'mixed':
        color = '#BE5BBF'
        break
      case 'etf':
        color = '#E58124'
        break
      default:
        color = '#000000'
    }
    return color
  }

  handleChangeFund = fund => {
    const { performances } = this.state
    const selectedFund = performances.find(
      perf => perf.currentPerformanceByFunds[0]._id === fund.currentPerformanceByFunds[0]._id
    )
    if (selectedFund) {
      this.setState({ currentFund: selectedFund })
    }
  }

  handleChangeFundFromMultiView = fund => {
    this.setState({ currentView: 'single' })
    this.handleChangeFund(fund)
  }

  handleToggleCardTabs = value => {
    this.setState({ historicalCardActiveTab: value })
  }

  toggleView = view => {
    this.setState({ currentView: view })
  }

  setCurrentSmartFund = (page, fund) => {
    const { setCurrentFund, navigate } = this.props
    setCurrentFund(fund)
    if (page === 'metrics') {
      navigate('/metrics')
    } else {
      navigate(`/fund/${fund._id}`)
    }
  }

  goToMarketplace = () => {
    const { navigate } = this.props
    navigate('/marketplace')
  }

  goToScoring = () => {
    const { navigate } = this.props
    navigate('/totumRisk')
  }

  goToProfile = () => {
    const { navigate } = this.props
    navigate('/profile')
  }

  showHidCreateFundModal = async () => {
    const { setCurrentFund } = this.props
    const { showCreatFundModal } = this.state
    await setCurrentFund({})
    if (showCreatFundModal) return this.setState({ showCreatFundModal: false })
    return this.setState({ showCreatFundModal: true })
  }

  createNewFund = async () => {
    const { saveFund } = this.props
    await saveFund()
    this.setMySmartFunds()
    this.showHidCreateFundModal()
  }

  setMySmartFunds = async () => {
    const { getUserSmartFunds } = this.props
    const smartFunds = (await getUserSmartFunds()) || []
    if (smartFunds.length === 0) {
      return this.setState({ newUser: true, isLoading: false })
    } else {
      this.setState({ smartFunds, isLoading: false })
    }
  }

  rendorRetail = () => {
    const { smartFunds = [], isLoading } = this.state
    const { calculateTotalAUM, capacity, linkedBroker } = this.props
    return (
      <Box component="section">
        {!isLoading && !capacity ? (
          <Container>
            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item className="totum-logo-container">
                  <img className="totum-logo" src={totumRiskLogo} alt="TotumRisk Logo" />
                </Grid>
                <Grid item className="totum-risk-getting-started">
                  <Typography>
                    Before we get started, we just need to get some information from you to best
                    help you with your financial goals.
                  </Typography>
                </Grid>
                <Grid item>
                  <StyledFilledButton>
                    <div
                      onClick={() => {
                        this.goToScoring()
                      }}
                      className="filled-button"
                    >
                      <p className="filled-button-content">{`Let's Get Started`}</p>
                    </div>
                  </StyledFilledButton>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container>
            <Grid container direction="column" spacing={2}>
              <Grid container className="fund-details" direction="row" spacing={2}>
                <Grid item className="fund-card-value" xs={4}>
                  <h2 className="heading">Smart Funds</h2>
                </Grid>
                <Grid item className="fund-totals" xs={2}>
                  <label className="fund-card-sub-labels">TOTAL FUND AUM</label>
                  <Typography variant="h5" component="h5">
                    {calculateTotalAUM(smartFunds)}
                  </Typography>
                </Grid>

                {!linkedBroker && (
                  <StyledRetailMessage>
                    <Grid container direction="row" className="retail-message-container">
                      <Grid item>
                        <Typography className="retail-message">
                          You have scores! You can now start adding Smart Funds! But first, please
                          link your brokerage account to complete set up.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className="retail-message-dismiss"
                          onClick={() => {
                            this.goToProfile()
                          }}
                        >
                          LINK ACCOUNT
                        </Typography>
                      </Grid>
                    </Grid>
                  </StyledRetailMessage>
                )}
              </Grid>
              {!isLoading && smartFunds.length === 0 && (
                <span>
                  <div className="smart-funds-box">
                    <p>You can now start adding Smart Funds!</p>
                  </div>
                  <div className="new-user-guide">
                    <h3>Head over to the Bob Marketplace to get started.</h3>
                    <Card className="new-user-card" onClick={this.goToMarketplace}>
                      <CardContent>
                        <Grid container spacing={0}>
                          <Grid item xs={4} className="card-detail">
                            &nbsp;
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="h4" component="h4">
                              Bob
                              <br />
                              Marketplace
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </span>
              )}
            </Grid>
          </Container>
        )}
      </Box>
    )
  }

  rendorInstitutional() {
    const { smartFunds = [], isLoading } = this.state
    const { calculateTotalAUM, clients } = this.props

    return (
      <Grid container direction="column" spacing={2}>
        <Grid container className="fund-details" direction="row" spacing={2}>
          <Grid item className="fund-card-value" xs={4}>
            <h2 className="heading">Smart Funds</h2>
          </Grid>
          <Grid item className="fund-totals" xs={2}>
            <label className="fund-card-sub-labels">TOTAL FUND AUM</label>
            <Typography variant="h5" component="h5">
              {calculateTotalAUM(smartFunds)}
            </Typography>
          </Grid>
          <Grid item className="fund-totals" xs={2}>
            <label className="fund-card-sub-labels">TOTAL CLIENTS</label>
            <Typography variant="h5" component="h5">
              {clients.length}
            </Typography>
          </Grid>
          <Grid item className="newFund">
            <div className="add-fund" onClick={this.showHidCreateFundModal}>
              <label className="add-fund-label">CREATE SMART FUND</label>
            </div>
          </Grid>
        </Grid>
        {!isLoading && smartFunds.length === 0 && (
          <span>
            <div className="smart-funds-box">
              <p>Oops! Looks like you don't have any Smart Funds yet.</p>
            </div>
            <div className="new-user-guide">
              <h3>Start with a pre-made Smart Fund from our Marketplace</h3>
              <Card className="new-user-card" onClick={this.goToMarketplace}>
                <CardContent>
                  <Grid container spacing={0}>
                    <Grid item xs={4} className="card-detail">
                      &nbsp;
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h4" component="h4">
                        Bob
                        <br />
                        Marketplace
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </span>
        )}
      </Grid>
    )
  }

  render() {
    const { isLoading, smartFunds = [], showCreatFundModal } = this.state
    const { isRetail } = this.props
    return (
      <StyledSmartFund>
        <Box component="section">
          <Container>
            {isRetail && this.rendorRetail()}
            {!isRetail && this.rendorInstitutional()}
            <Grid container direction="row" spacing={2}>
              {smartFunds.length > 0 &&
                smartFunds.map((fund, key) => {
                  return (
                    <Grid item xs={6} key={key} style={{ marginTop: '15px' }}>
                      <Card className="smart-fund-card">
                        <CardContent>
                          <Grid container direction="column" spacing={2}>
                            <Grid container direction="row" spacing={2}>
                              <Grid item xs={9} className="fund-card-name">
                                <Typography variant="h5" component="h5">
                                  {fund.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={1} className="fund-card-type">
                                <Chip
                                  className="fund-type-chip"
                                  style={{ backgroundColor: this.getTypeColor(fund.type) }}
                                  label={fund && fund.type ? fund.type.toUpperCase() : ''}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                {fund.published ? (
                                  <div className="is-live-sticker">
                                    <label className="live">LIVE</label>
                                  </div>
                                ) : (
                                  <div className="not-live-sticker">
                                    <label className="not-live"> NOT LIVE</label>
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item className="fund-card-action-row">
                              <Link
                                className="manage-button"
                                onClick={this.setCurrentSmartFund.bind(this, 'manage', fund)}
                              >
                                MANAGE
                              </Link>
                              {/* <Link
                            className="metric-button"
                            onClick={this.setCurrentSmartFund.bind(this, 'metrics', fund)}
                          >
                            METRICS
                          </Link> */}
                            </Grid>
                            <Grid item className="fund-card-size">
                              <label className="fund-card-sub-labels">
                                EST. FUND BUY IN AMOUNT
                              </label>
                              <br />
                              <Typography variant="body2" component="p">
                                {fund && fund.buyIn ? `${currencyFilter(fund.buyIn)}` : ''}
                              </Typography>
                            </Grid>
                            <Grid item className="fund-card-size">
                              <label className="fund-card-sub-labels">% ALLOCATED TO BOB</label>
                              <br />
                              <Typography variant="body2" component="p">
                                {fund && fund.accountAllocation
                                  ? `${fund.accountAllocation * 100}%`
                                  : '0%'}
                              </Typography>
                            </Grid>
                            <Grid item className="fund-card-sectors">
                              <label className="fund-card-sub-labels">
                                SECTORS({fund.sectors.length})
                              </label>
                              <br />
                              {fund.sectors.map((sector, key) => {
                                return (
                                  <Chip
                                    key={key}
                                    className="sector-chip"
                                    variant="outlined"
                                    label={sector}
                                  />
                                )
                              })}
                            </Grid>
                            <Grid item className="fund-card-models">
                              <label className="fund-card-sub-labels">
                                MODELS ({fund.strategies.length})
                              </label>
                              <br />
                              {fund.strategies.map((model, modelIndex) => {
                                return (
                                  <Chip
                                    key={modelIndex}
                                    className="model-chip"
                                    label={model.name}
                                  />
                                )
                              })}
                              {fund && fund.strategies.length === 0 && <div> N/A </div>}
                            </Grid>
                            <Grid container direction="row" spacing={2}>
                              <Grid item className="fund-card-value" xs={6}>
                                <label className="fund-card-sub-labels">CURRENT AUM</label>
                                <Typography variant="h5" component="h5">
                                  {currencyFilter(fund.aum)}
                                </Typography>
                              </Grid>
                              <Grid item className="fund-card-clients" xs={6}>
                                <label className="fund-card-sub-labels">
                                  {isRetail ? 'NUMBER OF ACCOUNTS' : 'NUMBER OF CLIENTS'}
                                </label>
                                <Typography variant="h5" component="h5">
                                  {fund.numberOfClients || 0}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })}
            </Grid>
            {isLoading && <LoadingIndicator loadingText="Loading your Smart Funds" />}
          </Container>
        </Box>
        <CreateFund
          show={showCreatFundModal}
          onClose={this.showHidCreateFundModal.bind(this)}
          onCommit={this.createNewFund.bind(this)}
        />
      </StyledSmartFund>
    )
  }
}

export default SmartFund
