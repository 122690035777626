import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import StyledUserProfile from './styles'
import UserProfileMenu from './UserProfileMenu'

const getUserInitials = (firstName = 'New', lastName = 'User') => {
  console.log(firstName, lastName)
  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
}

const getFullName = (firstName, lastName) => {
  return `${firstName} ${lastName}`
}

@inject(({ store: {  user } }) => {
  return {
    currentUser: user.currentUser,
  }
})
@observer
class UserProfile extends Component {
  state = {
    isProfileDropdownOpen: false,
  }

  toggleProfileDropdown = () => {
    const { isProfileDropdownOpen } = this.state
    this.setState({ isProfileDropdownOpen: !isProfileDropdownOpen })
  }

  render() {
    const {
      avatarUrl,
      currentUser,
      logout,
      impersonationData,
      initiateImpersonation,
      stopImpersonation,
    } = this.props

    const { firstName, lastName } = currentUser

    const { isProfileDropdownOpen } = this.state
    console.log(currentUser.name)
    return (
      <StyledUserProfile>
        <button
          className="user-profile-dropdown-button"
          onClick={() => this.toggleProfileDropdown()}
        >
          <div className="user-name">
            {impersonationData && impersonationData.impersonationSession ? (
              <span className="impersonating-tag">Impersonating</span>
            ) : null}
            <span>{getFullName(firstName, lastName)}</span>
          </div>
          <span className={avatarUrl ? 'avatar-circle has-avatar' : 'avatar-circle'}>
            {avatarUrl ? (
              <img src={avatarUrl} alt={`${firstName} Avatar`} />
            ) : (
              <span className="user-initials">{getUserInitials(firstName, lastName)}</span>
            )}
          </span>
        </button>
        {currentUser && isProfileDropdownOpen ? (
          <UserProfileMenu
            user={currentUser}
            logout={e => logout(e)}
            impersonationData={impersonationData}
            initiateImpersonation={usr => initiateImpersonation(usr)}
            stopImpersonation={() => stopImpersonation()}
          />
        ) : null}
      </StyledUserProfile>
    )
  }
}

UserProfile.defaultProps = {
  avatarUrl: null,
  user: null,
  impersonationData: null,
}

UserProfile.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  user: PropTypes.shape({}),
  logout: PropTypes.func.isRequired,
  initiateImpersonation: PropTypes.func.isRequired,
  stopImpersonation: PropTypes.func.isRequired,
  impersonationData: PropTypes.shape({}),
}

export default UserProfile
