import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import InputField from '../../../common/components/InputField'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import totumRiskLogoScore from '../../../assets/img/Totum Logo_Dark_X2.png'
import FilledButton from '../component/filledButton'
import NoFilledButton from '../component/noFilledButton'
import { Confirm } from '../../../common/components/Dialogs'

import { StyledFormWrapper } from '../styles'

@inject(({ store: { totumRisk, auth } }) => {
  return {
    capacity: totumRisk.capacity,
    setSelectedTab: totumRisk.setSelectedTab,
    setRiskForm: totumRisk.setRiskForm,
    riskForm: totumRisk.riskForm,
    saveRiskForm: totumRisk.saveRiskForm,
    submitRiskForm: totumRisk.submitRiskForm,
    errors: totumRisk.errors,
    lossGreaterThanInvestmentError: totumRisk.lossGreaterThanInvestmentError
  }
})
@observer
class StepTwo extends Component {
  state = {}

  componentDidMount() {
    this.setState({})
  }

  render() {
    const {
      capacity,
      setSelectedTab,
      setRiskForm,
      riskForm,
      saveRiskForm,
      submitRiskForm,
      errors,
      lossGreaterThanInvestmentError
    } = this.props

    const confirmSubmitMessage = 'You sure you are ready to submit?';
    const confirmSubmitBodyText = 'Please make sure all fields are correct.  You cannot make changes after submitting.';
    const confirmSubmitButtonText = 'Submit';
    const showSubmitConfirmation = errors.length === 1 && errors.find(e => e === 'confirmedSubmit');

    return (
      <StyledFormWrapper>
        <Confirm
          show={showSubmitConfirmation}
          onHide={() => setRiskForm({ confirmedSubmit: undefined })}
          onCommit={() => submitRiskForm.bind(this)('confirmed')}
          title={confirmSubmitMessage}
          body={confirmSubmitBodyText}
          commitMsg={confirmSubmitButtonText}
        />
        <Box component="section" className="questionnaire-info">
          <Grid container spacing={10}>
            <Grid item>
              <Typography className="page-header">Risk Score Questionnaire</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Typography className="step-title">Financial Information</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Card>
                <CardContent className="info-card">
                  <Grid item>
                    <Typography className="card-header-info">
                      {' '}
                      Just some basic information to get to know you a little better.
                    </Typography>
                  </Grid>
                  <Grid container direction="row">
                    <Grid container direction="column" className="step-two-col-one-questions">
                      <Grid item>
                        <Typography>Annual Income</Typography>
                        <InputField
                          type="number"
                          label="ex. 50000"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ income: str })}
                          initialValue={riskForm.income}
                          isRequired
                          hasError={errors.find(e => e === 'income')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Net Worth</Typography>
                        <InputField
                          type="number"
                          label="ex. 250000"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ netWorth: str })}
                          initialValue={riskForm.netWorth}
                          isRequired
                          hasError={errors.find(e => e === 'netWorth')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Investment Amount</Typography>
                        <InputField
                          type="number"
                          label="ex. 10000"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ investmentAmount: str })}
                          initialValue={riskForm.investmentAmount}
                          isRequired
                          hasError={errors.find(e => e === 'investmentAmount')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography style={{ marginBottom: '10px' }}>Job Industry</Typography>
                        {errors.find(e => e === 'impactingHousehold') && (
                          <p className="field-error">This field is required.</p>
                        )}
                        <Select
                          className="step-two-select"
                          value={riskForm.impactingHousehold}
                          onChange={event =>
                            setRiskForm({ impactingHousehold: event.target.value })
                          }
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem
                            value={`industry-financials`}
                          >{`Financials / Consumer Services and Retail`}</MenuItem>
                          <MenuItem
                            value={`industry-industrials`}
                          >{`Industrials / Technology`}</MenuItem>
                          <MenuItem
                            value={`industry-healthcare`}
                          >{`Health care or food and household staples`}</MenuItem>
                          <MenuItem value={`industry-energy`}>{`Energy or Materials`}</MenuItem>
                          <MenuItem value={`industry-telecom`}>{`Telecom / Utilities`}</MenuItem>
                          <MenuItem value={`industry-government`}>{`Government`}</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Retirement Age</Typography>
                        <InputField
                          type="number"
                          label="ex. 65"
                          customSize="80%"
                          initialValue={riskForm.retirementAge}
                          handleOnChange={str => setRiskForm({ retirementAge: str })}
                          isRequired
                          hasError={errors.find(e => e === 'retirementAge')}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="column" className="step-two-col-two-questions">
                      <Grid item>
                        <Typography>Annual Expenses</Typography>
                        <InputField
                          type="number"
                          label="ex. 40000"
                          customSize="100%"
                          handleOnChange={str => setRiskForm({ expenses: str })}
                          initialValue={riskForm.expenses}
                          isRequired
                          hasError={errors.find(e => e === 'expenses')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Income Consistency</Typography>
                        {errors.find(e => e === 'consistency') && (
                          <p className="field-error">This field is required.</p>
                        )}
                        <Select
                          className="step-two-select"
                          value={riskForm.consistency}
                          onChange={event => setRiskForm({ consistency: event.target.value })}
                        >
                          <MenuItem value={'none'}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'pay-guaranteed'}>{`Basically guaranteed`}</MenuItem>
                          <MenuItem
                            value={'pay-varies'}
                          >{`Varies or large portion is bonus/commission`}</MenuItem>
                          <MenuItem value={'pay-will-grow'}>{`Will grow with time`}</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Investment Max Loss</Typography>
                        <InputField
                          type="number"
                          label="ex. 5000"
                          customSize="100%"
                          handleOnChange={str => setRiskForm({ maxLoss: str })}
                          initialValue={riskForm.maxLoss}
                          hasError={errors.find(e => e === 'maxLoss')}
                          error={lossGreaterThanInvestmentError}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography style={{ marginBottom: '10px' }}>
                          My Time Horizon to Invest Is
                        </Typography>
                        {errors.find(e => e === 'period') && (
                          <p className="field-error">This field is required.</p>
                        )}
                        <Select
                          className="step-two-select"
                          value={riskForm.period}
                          onChange={event => setRiskForm({ period: event.target.value })}
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'1'}>{`Short Term (<3 years)`}</MenuItem>
                          <MenuItem value={'7'}>{`Intermediate Term (<10 years)`}</MenuItem>
                          <MenuItem value={'12'}>{`Long Term (>10 years)`}</MenuItem>
                          <MenuItem value={'99'}>{`Until Retirement`}</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={7}>
                    <Grid item className="step-two-button-spacer">
                      <NoFilledButton title={`Save`} action={saveRiskForm.bind(this)} />
                    </Grid>
                    <Grid item className="step-two-button-spacer step-two-previous-button">
                      <NoFilledButton title={`Back`} action={setSelectedTab.bind(this, -1)} />
                    </Grid>
                    <Grid item className="step-two-button-spacer">
                      {capacity && capacity.score > 0 ? (
                        <FilledButton title={`Scores`} action={setSelectedTab.bind(this, 1)} />
                      ) : (
                        <FilledButton title={`Submit`} action={submitRiskForm.bind(this)} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardContent className="score-card">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="risk-score-message">Powered By</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '55px' }}>
                      <img src={totumRiskLogoScore} alt="TotumRisk Logo" />
                    </Grid>
                    <Grid item>
                      <Typography className="risk-score-message">
                        The Totum Risk questionnaire was built by PhD’s in the industry who utilized
                        research from other Noble Economic Prize-winning psychologists and
                        economists. Totum Risk’s methodology is US patent pending.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </StyledFormWrapper>
    )
  }
}

export default StepTwo
