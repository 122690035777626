import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import SymbolSearch from './SymbolSearch'
import Chip from '@material-ui/core/Chip'

@inject(({ store: { model } }) => {
  return {
    currentModel: model.currentModel,
    setCurrentModel: model.setCurrentModel,
    setSelectedSecurity: model.modelBuilderStore.setSelectedSecurity,
    setSelectedTab: model.modelBuilderStore.setSelectedTab,
    deleteSecurityChart: model.modelBuilderStore.deleteSecurityChart
  }
})
@observer
class StepSecurity extends Component {
  constructor(props) {
    super(props)
    this.state = { strategy: props.strategy }
  }

  async handleSecurity(newSecurity, companyName) {
    const { currentModel, setCurrentModel } = this.props
    const securityExists = currentModel.charts.some(s => s.security === newSecurity)
    if (securityExists) return
    currentModel.charts.push({
      strategy: currentModel._id,
      id: newSecurity,
      security: newSecurity,
      companyName,
      charts: [],
      monitoredBy: 'bob-chartiq-watcher',
    })
    currentModel.Tickers = currentModel.charts.map(c => {
      return { symbol: c.security }
    })
    await setCurrentModel(currentModel)
  }

  async selectSecurityFromList(security) {
    const { setSelectedSecurity, setSelectedTab } = this.props
    await setSelectedSecurity(security)
    setSelectedTab(2)
  }

  async handleDelete(security) {
    const { id, strategy } = security
    const { currentModel, setCurrentModel, deleteSecurityChart } = this.props
    const chartId = `${id}${strategy}`
    const charts = currentModel.charts.filter(c => c.id !== security.id)
    const Tickers = currentModel.charts.map(c => {
      return { symbol: c.security }
    })
    await setCurrentModel({ charts, Tickers })
    await deleteSecurityChart(chartId)
  }

  render() {
    const { currentModel } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-md-12"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <SymbolSearch
              ticker={this.props.security}
              handleTicker={this.handleSecurity.bind(this)}
            />
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 align-right">
            <br />
            <br />
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            {currentModel.charts.length === 0 && 'Please select securities above.'}
            {currentModel.charts.map((security, idx) => {
              return (
                <span key={idx}>
                  &nbsp;
                  <Chip
                    onClick={this.selectSecurityFromList.bind(this, security, idx)}
                    onDelete={this.handleDelete.bind(this, security)}
                    value={security.security}
                    label={
                      <span>
                        {security.security} - <small>{security.companyName}</small>
                      </span>
                    }
                  />
                </span>
              )
            })}
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    )
  }
}

export default StepSecurity
