import styled from 'styled-components'
import { colors } from '../../../assets/styles'

export const StyledFormWrapper = styled.div`

@media only screen and (min-width: 800px) {
  .stepper-container {
    margin-left: 125px;
  }
}

@media only screen and (min-width: 2000px) {
  .stepper-container {
    margin-left: 550px;
  }
}  

.MuiTypography-body1 {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
  line-height: 16px;
}

.stepper-override {
  width: 800px;
  margin-left: 293px;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #7bb77c !important;
}

.MuiPaper-root {
  background-color: #1C00ff00 !important;
}

.questionnaire-info {
  margin-left: 200px;
  margin-top: 50px
}

.page-header {
  height: 34px;
  width: 335px;
  color: #333333;
  font-family: Avenir;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1.79px;
  line-height: 34px;
}

.info-card {
  color: #5E5E5E;
  font-family: Avenir !important;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: 0.86px;
  line-height: 16px;  
  height: 575px;
  width: 642px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
}

.card-header-info {
  height: 16px;
  width: 100%;
  color: #5E5E5E;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
  line-height: 16px;
}

.card-footer-info {
  height: 16px;
  width: 100%;
  color: #5E5E5E;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
  line-height: 16px;
  margin-top: 66px;
}

.step-title {
  height: 23px;
  color: #1A4E63;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.14px;
  line-height: 23px;
}

.score-card {
  height: 575px;
  width: 388px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
}

.totum-slider-container {
   margin-top: 133px;  
}

.totum-logo {
  height: 40px;
  width: 89px;
}

.totum-logo-label {
  height: 16px;
  width: 100px;
  color: #C8C8C8;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.86px;
  line-height: 16px;
  text-align: center;
}

.risk-score-message {
  margin-top: 47px;
  height: 46px;
  width: 328px;
  color: #1A4E63;
  font-family: Avenir;
  font-weight: 600;
  letter-spacing: 1.14px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 10px;
}

.step-two-col-one-questions {
  width: 300px !important;
  flex-wrap: nowrap !important;
  margin-top: 15px;
}

.step-two-col-two-questions {
  width: 230px !important;
  flex-wrap: nowrap !important;
  margin-top: 15px;
}

.step-one-save-button {
  margin-top: 40px;
}

.step-one-next-button {
  margin-left: 249px;
  margin-top: 40px;
}

.step-two-select {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  margin-top: 15px;
}

.step-two-field-spacer{
  margin-top: 25px;
}

.step-two-button-spacer{
  margin-top: 50px;
}

.step-two-previous-button{
  margin-left: 100px;
  padding-right: 8px !important;
}

.step-three-button-spacer{
  margin-top: 265px;
}

.field-error {
  color: ${colors.alertRed};
  position: absolute;
}


`