import React from 'react'
import PropTypes from 'prop-types'
import { StyledFundSideNav } from '../styles'
import { colors } from '../../../../assets/styles'
import FundDetailsIconSvg from '../../../../assets/icons/fundDetailsIconSvg'
import FundClientsIconSvg from '../../../../assets/icons/fundClientsIconSvg'
import FundPortfoliosIconSvg from '../../../../assets/icons/fundPortfoliosIconSvg'
import FundAccessIconSvg from '../../../../assets/icons/fundAccessIconSvg'

const FundSideNav = ({
  currentTab,
  onChangeTab,
  showClients = true,
  showDetails = true,
  showMetrics = true,
}) => (
  <StyledFundSideNav className="side-nav">
    <ul className="side-nav-menu">
      {showDetails && (
        <li>
          <button
            className={currentTab === 'details' ? 'side-nav-item is-active' : 'side-nav-item'}
            onClick={() => onChangeTab('details')}
          >
            <FundDetailsIconSvg
              fill={currentTab === 'details' ? colors.bobPurple : colors.labelGrey}
            />
            Details
          </button>
        </li>
      )}
      <li>
        <button
          className={currentTab === 'portfolios' ? 'side-nav-item is-active' : 'side-nav-item'}
          onClick={() => onChangeTab('portfolios')}
        >
          <FundPortfoliosIconSvg
            fill={currentTab === 'portfolios' ? colors.bobPurple : colors.labelGrey}
          />
          Models
        </button>
      </li>
      {showClients && (
        <li>
          <button
            className={currentTab === 'clients' ? 'side-nav-item is-active' : 'side-nav-item'}
            onClick={() => onChangeTab('clients')}
          >
            <FundClientsIconSvg
              fill={currentTab === 'clients' ? colors.bobPurple : colors.labelGrey}
            />
            Clients
          </button>
        </li>
      )}
      {showMetrics && (
        <li>
          <button
            className={currentTab === 'metrics' ? 'side-nav-item is-active' : 'side-nav-item'}
            onClick={() => onChangeTab('metrics')}
          >
            <FundAccessIconSvg
              fill={currentTab === 'metrics' ? colors.bobPurple : colors.labelGrey}
            />
            Metrics
          </button>
        </li>
      )}
    </ul>
  </StyledFundSideNav>
)

FundSideNav.propTypes = {
  currentTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
}

export default FundSideNav
