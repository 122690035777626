import App from './app'
import Auth from './auth'
import User from './user'
import Ui from './ui'
import Fund from './fund'
import Model from './model'
import ClientConnect from './clientConnect'
import TotumRisk from './totumRisk'
import Service from './service'
import AssetManager from './assetManager'
import PaymentAdmin from './paymentAdmin'

export class RootStore {
  api

  navigate

  ui

  auth

  user

  constructor(api, navigate, log) {
    this.api = api
    this.navigate = navigate
    this.log = log
    this.ui = new Ui(this)

    this.user = new User(this)
    this.totumRisk = new TotumRisk(this)
    this.auth = new Auth(this)
    this.fund = new Fund(this)
    this.model = new Model(this)
    this.clientConnect = new ClientConnect(this)
    this.app = new App(this)
    this.service = new Service(this)
    this.assetManager = new AssetManager(this)
    this.paymentAdmin = new PaymentAdmin(this)
  }
}
