import React from 'react'

const FundsIconSvg = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18">
    <g id="funds-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#333333">
        <path
          d="M9.48,7.9 C7.21,7.31 6.48,6.7 6.48,5.75 C6.48,4.66 7.49,3.9 9.18,3.9 C10.96,3.9 11.62,4.75 11.68,6 L13.89,6 C13.82,4.28 12.77,2.7 10.68,2.19 L10.68,0 L7.68,0 L7.68,2.16 C5.74,2.58 4.18,3.84 4.18,5.77 C4.18,8.08 6.09,9.23 8.88,9.9 C11.38,10.5 11.88,11.38 11.88,12.31 C11.88,13 11.39,14.1 9.18,14.1 C7.12,14.1 6.31,13.18 6.2,12 L4,12 C4.12,14.19 5.76,15.42 7.68,15.83 L7.68,18 L10.68,18 L10.68,15.85 C12.63,15.48 14.18,14.35 14.18,12.3 C14.18,9.46 11.75,8.49 9.48,7.9 Z"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
)

export default FundsIconSvg
