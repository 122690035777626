const typography = {
  baseFontFamily: "'Avenir LT Std', Verdana, sans-serif",
  baseFontWeight: 'normal',
  mediumFontWeight: 500,
  headingFontWeight: 900,
  extraSmallFontSize: '0.75rem',
  smallBaseFontSize: '0.875rem',
  baseFontSize: '1rem',
  mediumFontSize: '1.125rem',
  largeFontSize: '1.25rem',
  extraLargeFontSize: '1.625rem',
}

export default typography
