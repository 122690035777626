import React from 'react'
import PropTypes from 'prop-types'

const FundAccessIconSvg = ({ fill }) => (
  <svg width="14px" height="14px" viewBox="0 0 14 14">
    <g id="Bob-4.0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Fund-Detail"
        transform="translate(-47.000000, -452.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Side-Nav" transform="translate(45.000000, 214.000000)">
          <g id="Reports-inactive" transform="translate(2.000000, 234.000000)">
            <g id="Reports-icon" transform="translate(0.000000, 4.000000)">
              <path
                d="M7,12.2 C4.13333333,12.2 1.8,9.87777778 1.8,7 C1.8,4.38888889 3.72222222,2.23333333 6.23333333,1.85555556 L6.23333333,0 C2.72222222,0.4 0,3.36666667 0,6.97777778 C0,10.8555556 3.14444444,14 7.02222222,14 C10.6333333,14 13.6,11.2777778 14,7.77777778 L12.1444444,7.77777778 C11.7666667,10.2777778 9.61111111,12.2 7,12.2 Z"
                id="Path"
              ></path>
              <path
                d="M8,0 L8,6 L14,6 C13.6445242,2.85971223 11.1454219,0.356115108 8,0 Z M9.22800718,1.55395683 C10.7037702,2.12589928 11.8886894,3.31294964 12.459605,4.79136691 L9.22800718,4.79136691 L9.22800718,1.55395683 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

FundAccessIconSvg.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default FundAccessIconSvg
