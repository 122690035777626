import { action, observable } from 'mobx'

export default class MarketplaceStore {
  @observable managerList = []
  @observable assetManagers = []
  @observable filters = []
  @observable fundList = []
  @observable modelList = []
  @observable filteredList = []
  @observable assetTypes = ['ETF', 'Equities', 'Mixed']
  @observable selectedFund = {}
  @observable selectedModel = {}
  @observable factSheets = []
  @observable currentModelBuyIn = 0

  constructor(fund) {
    this.rootStore = fund.rootStore
    this.fundStore = fund
  }

  @action('Marketplace Store | setSelectedFund')
  setSelectedFund = fund => {
    this.selectedFund = fund
    this.setSelectedModel(null)
    return this.selectedFund
  }

  @action('Marketplace Store | setSelectedModel')
  setSelectedModel = async currentModel => {
    if (currentModel) {
      const { model } = this.rootStore
      const { _id, alreadyMirrored } = currentModel
      const { getSingleModelCostRequirement } = model
      this.selectedModel = await this.getModelById(_id)
      this.selectedModel.alreadyMirrored = alreadyMirrored
      this.currentModelBuyIn = await getSingleModelCostRequirement(currentModel._id)
      return this.selectedModel
    } else {
      this.selectedModel = undefined
    }
  }

  @action('Marketplace Store | getModelById')
  getModelById = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/getStrategy/${id}`)
      const {
        data: { strategy: model },
      } = result
      if (model.assetManager) {
        const { assetManager = {} } = model
        const { factSheets } = assetManager
        this.factSheets = factSheets
      }
      return model
    } catch (e) {
      console.log(`SmartFund Store | getUserSmartFunds ${e.stack}`)
    }
  }

  @action('SmartFund Store | getPublicSmartFunds')
  getPublicSmartFunds = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/getMarketplaceFunds`)
      const {
        data: { funds: publicSmartFunds },
      } = result
      this.fundList = publicSmartFunds
      this.filteredList = publicSmartFunds
    } catch (e) {
      console.log(`SmartFund Store | getPublicSmartFunds ${e.stack}`)
    }
  }

  @action('SmartFund Store | getPublicModels')
  getPublicModels = async () => {
    try {
      const { api } = this.rootStore
      const marketPlaceResult = await api.get(`/v0/getMarketplaceStrategies`)
      const advisorResult = await api.get(`/v0/getAdvisorStrategies`)
      const {
        data: { strategies: publicModels },
      } = marketPlaceResult
      const {
        data: { strategies: advisorModels = [] },
      } = advisorResult
      const allModels = advisorModels.concat(publicModels)
      this.modelList = allModels
      this.filteredList = allModels
    } catch (e) {
      console.log(`SmartFund Store | getPublicModels ${e.stack}`)
    }
  }

  @action('SmartFund Store | getPublicModels')
  getAssetManagers = async () => {
    try {
      const { api } = this.rootStore
      const result = await api.get(`/v0/getAssetManagers`)
      const {
        data: { assetManagers },
      } = result

      this.assetManagers = assetManagers.sort((a, b) => {
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
        return -1
      })

      return this.assetManagers
    } catch (e) {
      console.log(`SmartFund Store | getPublicModels ${e.stack}`)
    }
  }

  @action('SmartFund Store | copyFundToUser')
  copyFundToUser = async (_id, currentFundUserProfile) => {
    try {
      const { api } = this.rootStore
      const result = await api.post(`/v0/selectMarketplaceFund/${_id}`, currentFundUserProfile)
      const {
        data: { fund },
      } = result
      return fund
    } catch (e) {
      console.log(`SmartFund Store | copyFundToUser ${e.stack}`)
    }
  }

  @action('SmartFund Store | fundsUserHasMirrored')
  fundsUserHasMirrored = async () => {
    try {
      const {
        user: { smartFundStore },
      } = this.rootStore
      await this.getPublicSmartFunds()
      const userFunds = await smartFundStore.getUserSmartFunds()
      const publicFundsIds = this.fundList.map(fund => {
        const { _id } = fund
        return _id
      })
      const matches = []
      userFunds.forEach(f => {
        const { mirroredFund } = f
        if (publicFundsIds.find(p => p === mirroredFund)) {
          matches.push(mirroredFund)
        }
      })
      this.fundList = this.fundList.map(f => {
        const { _id: fundId } = f
        if (matches.find(m => m === fundId)) {
          f.alreadyMirrored = true
        }
        return f
      })
      this.filteredList = this.filteredList.map(f => {
        const { _id: fundId } = f
        if (matches.find(m => m === fundId)) {
          f.alreadyMirrored = true
        }
        return f
      })
    } catch (e) {
      console.log(`SmartFund Store | fundsUserHasMirrored ${e.stack}`)
    }
  }

  @action('SmartFund Store | modelsMirroredByFund')
  modelsMirroredByFund = async _id => {
    try {
      const {
        currentFund: { strategies: models },
      } = this.fundStore
      const list = this.filteredList
      list.forEach(m => {
        const found = models.find(s => String(s._id) === String(m._id))
        if (found) {
          m.alreadyMirrored = true
        }
      })
      this.modelList = list
      this.filteredList = list
    } catch (e) {
      console.log(`SmartFund Store | modelsMirroredByFund ${e.stack}`)
    }
  }

  @action('SmartFund Store | searchFilter')
  searchFilter = async e => {
    try {
      const searchValue = e.target.value
      this.filteredList =
        this.fundList.length > 0
          ? this.fundList.filter(l => l.name.toUpperCase().includes(searchValue.toUpperCase()))
          : this.modelList.filter(l => l.strategy.toUpperCase().includes(searchValue.toUpperCase()))
      if (searchValue.length === 0)
        return (this.filteredList = this.fundList.length > 0 ? this.fundList : this.modelList)
      return this.filteredList
    } catch (e) {
      console.log(`SmartFund Store | searchFilter ${e.stack}`)
    }
  }

  @action('SmartFund Store | addFundToUser')
  addFundToUser = async client => {
    try {
      const { _id } = this.selectedFund
      const { fund: fundStore } = this.rootStore

      const newFund = await this.copyFundToUser(_id, client)
      console.log('newFund', newFund)
      fundStore.setCurrentFund(newFund)
      await fundStore.publish()
      const fund = this.fundList.find(f => String(f._id) === String(_id))
      fund.alreadyMirrored = true
      this.setSelectedFund(fund)
    } catch (e) {
      console.log(`SmartFund Store | searchFilter ${e.stack}`)
    }
  }

  @action('SmartFund Store | addModelToFund')
  addModelToFund = async () => {
    try {
      const {
        model: { getModelCostRequirements, modelBuyInAmounts },
      } = this.rootStore
      const { currentFund, setCurrentFund } = this.fundStore
      const { strategies } = currentFund
      const { allocation, ...newModel } = this.selectedModel
      newModel.name = this.selectedModel.strategy
      newModel.allocatedToPortfolio = 0
      newModel.allocatedToSlot = allocation
      newModel.assetManager = this.selectedModel.assetManager
        ? this.selectedModel.assetManager._id
        : null
      delete newModel.strategy
      delete newModel.Tickers
      delete newModel.charts
      strategies.push(newModel)
      const strategyIds = strategies.map(s => s._id)
      const requiredTotalAllocations = await getModelCostRequirements(strategyIds)
      const currentFundCost = requiredTotalAllocations
      strategies.forEach(s => {
        const newModelCost = modelBuyInAmounts[s._id]
        s.allocatedToPortfolio = parseFloat(newModelCost / currentFundCost).toFixed(4)
        s.buyIn = newModelCost
      })
      this.selectedModel.alreadyMirrored = true
      await setCurrentFund({ buyIn: requiredTotalAllocations, strategies })
    } catch (e) {
      console.log(`SmartFund Store | addModelToFund ${e.stack}`)
    }
  }

  @action('SmartFund Store | removeModelFromFund')
  removeModelFromFund = async model => {
    try {
      const {
        model: { getModelCostRequirements, modelBuyInAmounts },
      } = this.rootStore
      const { currentFund, setCurrentFund } = this.fundStore
      const { strategies } = currentFund
      const newStrategies = strategies.filter(s => String(s._id) !== String(model._id))
      const strategyIds = newStrategies.map(s => s._id)
      const requiredTotalAllocations = await getModelCostRequirements(strategyIds)
      const currentFundCost = requiredTotalAllocations
      newStrategies.forEach(s => {
        const newModelCost = modelBuyInAmounts[s._id]
        s.allocatedToPortfolio = parseFloat(newModelCost / currentFundCost).toFixed(4)
        s.buyIn = newModelCost
      })
      await setCurrentFund({
        buyIn: requiredTotalAllocations,
        strategies: newStrategies,
      })
    } catch (e) {
      console.log(`SmartFund Store | addModelToFund ${e.stack}`)
    }
  }
  @action('SmartFund Store | filterByName')
  filterByName = async e => {
    try {
      const assetManager = e.target.innerText
      const filterAlreadySelected = this.filters.find(f => f === assetManager)
      if (filterAlreadySelected) return
      this.filteredList = []
      this.filters.push(assetManager)
      this.filters.forEach(filter => {
        const filtered =
          this.fundList.length > 0
            ? this.fundList.filter(l => l.name.toUpperCase().includes(filter.toUpperCase()))
            : this.modelList.filter(m => {
                if (m.assetManager)
                  return m.assetManager.name.toUpperCase().includes(filter.toUpperCase())
                return false
              })
        if (filtered.length > 0) {
          this.filteredList = this.filteredList.concat(filtered)
        }
      })
      if (this.filters.length === 0)
        this.filteredList = this.fundList.length > 0 ? this.fundList : this.modelList
    } catch (e) {
      console.log(`SmartFund Store | filterByName ${e.stack}`)
    }
  }

  @action('SmartFund Store | removeFromFilters')
  removeFromFilters = async assetManager => {
    try {
      this.filteredList = []
      this.filters = this.filters.filter(f => f !== assetManager) || []
      this.filters.forEach(filter => {
        const filtered =
          this.fundList.length > 0
            ? this.fundList.filter(l => l.name.toUpperCase().includes(filter.toUpperCase()))
            : this.modelList.filter(m => {
                if (m.assetManager)
                  return m.assetManager.name.toUpperCase().includes(filter.toUpperCase())
                return false
              })
        if (filtered.length > 0) return (this.filteredList = this.filteredList.concat(filtered))
        return
      })
      if (this.filters.length === 0)
        this.filteredList = this.fundList.length > 0 ? this.fundList : this.modelList
    } catch (e) {
      console.log(`SmartFund Store | filterByName ${e.stack}`)
    }
  }

  @action('SmartFund Store | calculateFundScore')
  calculateFundScores = async () => {
    try {
      const {
        api,
        totumRisk: { result: totum },
      } = this.rootStore
      const { input } = totum
      const investmentAmount = input['investment-amount']

      const result = await api.put(`/v0/calculateFundScores`, {
        funds: this.filteredList,
        investmentAmount,
      })
      const {
        data: { funds: newFunds },
      } = result

      this.filteredList.forEach(fl => {
        const fund = newFunds.find(f => String(f._id) === String(fl._id))
        fl.buyIn = fund.buyIn
        fl.scoreInfo = fund.scoreInfo
      })

      this.fundList.forEach(fl => {
        const fund = newFunds.find(f => String(f._id) === String(fl._id))
        fl.buyIn = fund.buyIn
        fl.scoreInfo = fund.scoreInfo
      })

      // const { riskCapacityScore: capacity, riskPreferenceScore: preference } = totum
      // this.filteredList = this.filteredList.filter(fl => {
      //   if (!fl.scoreInfo) fl.scoreInfo = {}
      //   return fl.scoreInfo.score >= preference && fl.scoreInfo.score <= capacity
      // })
      // this.fundList = this.fundList.filter(fl => {
      //   if (!fl.scoreInfo) fl.scoreInfo = {}
      //   return fl.scoreInfo.score >= preference && fl.scoreInfo.score <= capacity
      // })
    } catch (e) {
      console.log(`SmartFund Store | getPublicModels ${e.stack}`)
    }
  }

  @action('SmartFund Store | getUserSmartFundMatches')
  getUserSmartFundMatches = async () => {
    try {
      const {
        totumRisk: { result },
      } = this.rootStore
      const { input } = result
      const investmentAmount = input['investment-amount']
      this.filteredList = this.filteredList.filter(fl => fl.buyIn < +investmentAmount)
      this.fundList = this.fundList.filter(fl => fl.buyIn < +investmentAmount)
    } catch (e) {
      console.log(`SmartFund Store | searchFilter ${e.stack}`)
    }
  }
}
