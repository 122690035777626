import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Router, Link } from '@reach/router'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import FolderIcon from '@material-ui/icons/Folder'
import LockIcon from '@material-ui/icons/Lock'
import PeopleIcon from '@material-ui/icons/People'
import ServicesIcon from '@material-ui/icons/SettingsApplicationsOutlined'
import PaymentIcon from '@material-ui/icons/Payment'
import createHistory from 'history/createBrowserHistory'

import '../../App/App.css'
import Roles from './Roles'
import Permissions from './Permissions'
import Service from './Service'
import Users from './Users'
import Payments from './Payments'
import ServiceCategory from './Service/ServiceCategory'
import ServiceTask from './Service/ServiceTask'

import { Typography } from '@material-ui/core'

const history = createHistory()

@inject(({ store: { navigate, user, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
  }
})
@observer
export default class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    window.document.title = 'Bob | Admin'
    const { isAuthenticated } = this.props
    await isAuthenticated()
    history.listen(() => this.forceUpdate())
  }

  updateState(newState, cb) {
    console.log('newState', newState)
    this.setState(
      () => newState,
      () => {
        if (cb) cb()
      }
    )
  }
  handleNav(loc) {
    // history.push(loc);
    // this.setState({loc});
  }
  render() {
    const { loc } = this.state
    console.log('loc', loc)

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <br />
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-1">{this.nav()}</div>
          <div className="col-md-8">
            <Router>
              <Roles path="roles" />
              <Permissions path="permissions" />
              <Users path="users" />
              <Service path="services" />
              <ServiceCategory path="services/:serviceCategoryId/:page" />
              <ServiceTask path="services/task/:serviceTaskId/:page" />
              <Payments path="payments" />
            </Router>
          </div>
          <div className="col-md-1"></div>
        </div>
      </MuiThemeProvider>
    )
  }

  nav() {
    return (
      <List component="nav">
        <ListItem>
          <ListItemText primary="Admin"></ListItemText>
        </ListItem>
        <Divider />
        <Link to="roles">
          <ListItem button>
            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
              <FolderIcon />
            </Avatar>
            <ListItemText
              primary={<Typography style={{ color: 'gray', paddingLeft: 4 }}>Roles</Typography>}
              onClick={this.handleNav.bind(this, '/roles')}
            ></ListItemText>
          </ListItem>
        </Link>
        <Link to="permissions">
          <ListItem button>
            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
              <LockIcon />
            </Avatar>
            <ListItemText
              primary={
                <Typography style={{ color: 'gray', paddingLeft: 4 }}>Permissions</Typography>
              }
              onClick={this.handleNav.bind(this, '/permissions')}
            ></ListItemText>
          </ListItem>
        </Link>
        <Link to="users">
          <ListItem>
            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
              <PeopleIcon />
            </Avatar>
            <ListItemText
              primary={<Typography style={{ color: 'gray', paddingLeft: 4 }}>Users</Typography>}
              onClick={this.handleNav.bind(this, '/permissions')}
            ></ListItemText>
          </ListItem>
        </Link>
        <Link to="services">
          <ListItem button>
            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
              <ServicesIcon />
            </Avatar>
            <ListItemText
              primary={<Typography style={{ color: 'gray', paddingLeft: 4 }}>Services</Typography>}
              onClick={this.handleNav.bind(this, '/services')}
            ></ListItemText>
          </ListItem>
        </Link>
        <Link to="payments">
          <ListItem button>
            <Avatar style={{ backgroundColor: theme.palette.secondary.main }}>
              <PaymentIcon />
            </Avatar>
            <ListItemText
              primary={<Typography style={{ color: 'gray', paddingLeft: 4 }}>Payments</Typography>}
              onClick={this.handleNav.bind(this, '/payments')}
            ></ListItemText>
          </ListItem>
        </Link>
      </List>
    )
  }
}
