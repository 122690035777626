export const automationFees = [
  {
    range: [0, 2499],
    swingTradeRate: 0.99,
    dayTradeRate: 4.99,
  },
  {
    range: [2499, 4999],
    swingTradeRate: 1.99,
    dayTradeRate: 5.99,
  },
  {
    range: [4999, 7499],
    swingTradeRate: 2.99,
    dayTradeRate: 6.99,
  },
  {
    range: [7499, 9999],
    swingTradeRate: 3.99,
    dayTradeRate: 7.99,
  },
  {
    range: [9999],
    swingTradeRate: 4.99,
    dayTradeRate: 8.99,
  },
]

export const strategyFees = [
  {
    range: [0, 9999],
    managingRate: 0.00099,
  },
  {
    range: [9999, 19999],
    managingRate: 0.00199,
  },
  {
    range: [19999, 29999],
    managingRate: 0.00299,
  },
  {
    range: [29999, 39999],
    managingRate: 0.00399,
  },
  {
    range: [39999],
    managingRate: 0.00499,
  },
]
