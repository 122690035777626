import React, { Component } from 'react'
import { Pick } from '../../common/components/Dialogs'
import FundDetailsForm from '../Funds/CreateFund/FundDetailsForm'

export default class CreateFundModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    body() {
        return (
                <div style={{ textAlign: 'left' }}>
                <FundDetailsForm 
                />
                </div>
        )
    }
    render() {
        const { show = false, onClose, onCommit } = this.props
        return (
            <Pick
                show={show}
                onHide={onClose}
                onCommit={onCommit}
                title="Create Fund"
                className="strategies-selector"
                commitMsg="CREATE"
                body={this.body()}
            />
        )
    }
}
