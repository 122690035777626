import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import WarningIcon from '@material-ui/icons/Warning'
import StepButton from '@material-ui/core/StepButton'
import { Typography } from '@material-ui/core'

import axios from 'axios'
import createHistory from 'history/createBrowserHistory'
import StepComplete from './StepComplete'
import StepBacktest from './StepBacktest'
import StepSecurity from './StepSecurity'
import StepDetail from './StepDetail'
import StepTechnicalAnalysis from './StepTechnicalAnalysis'
import { defaultStrategy, validateProps, theme, formValidation } from './wizardUtils'
import Auth from '../../lib/auth'

import objectPath from 'simple-object-path'

import '../../App/App.css'

const silentState = {
  get: key => silentState[key],
  set: (key, val) => (silentState[key] = val),
}

const history = createHistory()

@inject(({ store: { navigate, fund, auth, user, model } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    setNavigationTabs: user.headerStore.setNavigationTabs,
    setSelectedTab: model.modelBuilderStore.setSelectedTab,
    selectedTab: model.modelBuilderStore.selectedTab,
    currentModel: model.currentModel,
    getModelDetails: model.getModelDetails,
  }
})
@observer
class Wizard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    // const {isAuthenticated, getModelDetails } = this.props
    // const modelId = window.location.pathname.split('/')[2]
    // await isAuthenticated()
    // await getModelDetails(modelId)
  }

  render() {
    const { selectedTab, fullScreen = false, lockedByUser, currentModel } = this.props
    console.log('lockedByUser', lockedByUser)
    console.log('Current Step', selectedTab)
    const formErrors = validateProps(currentModel, formValidation)
    const steps = [
      {
        name: 'Strategy Details',
        component: <StepDetail formErrors={formErrors} history={history} {...this.props} />,
      },
      { name: 'Select Securities', component: <StepSecurity history={history} {...this.props} /> },
      {
        name: 'Technical Analysis',
        component: <StepTechnicalAnalysis />,
      },
      { name: 'Backtest', component: <StepBacktest history={history} {...this.props} /> },
      { name: 'Complete Setup', component: <StepComplete history={history} {...this.props} /> },
    ]
    return (
      <div className="step-progress" style={{ width: '100%' }}>
        <MuiThemeProvider theme={theme}>
          {/* <div className="float-left-top">
            {lockedByUser && (
              <Fab color={'default'} aria-label="Add" className="item-float">
                <span>
                  <Tooltip
                    title={`Locked By: ${objectPath(lockedByUser, 'firstName')} ${objectPath(
                      lockedByUser,
                      'lastName'
                    )}`}
                  >
                    <LockIcon />
                  </Tooltip>
                </span>
              </Fab>
            )}
          </div> */}
          <div style={{ paddingLeft: '10px' }}>
            <Typography variant="h6">{currentModel.strategy}</Typography>
          </div>
          {!fullScreen && (
            <Stepper nonLinear activeStep={selectedTab}>
              {steps.map((step, index) => {
                const disableTechAnalysis = currentModel.charts.length === 0 && index === 2
                const disableDetails = formErrors.length > 0 && index === 0
                const errorLabel = (
                  <Typography color="error" variant="caption">
                    Please select securities
                  </Typography>
                )
                return (
                  <Step key={index}>
                    {disableTechAnalysis && (
                      <StepButton icon={<WarningIcon color="error" />} optional={errorLabel}>
                        {step.name}
                      </StepButton>
                    )}
                    {disableDetails && (
                      <StepButton
                        icon={<WarningIcon color="error" />}
                        onClick={() => this.props.setSelectedTab(index)}
                      >
                        {step.name}
                      </StepButton>
                    )}
                    {!(disableTechAnalysis || disableDetails) && (
                      <StepButton
                        onClick={() => {
                          console.log('this.children', this.props.children)
                          this.props.setSelectedTab(index)
                        }}
                      >
                        {step.name}
                      </StepButton>
                    )}
                  </Step>
                )
              })}
            </Stepper>
          )}
          {steps[selectedTab].component}
        </MuiThemeProvider>
      </div>
    )
  }
}

@inject(({ store: { navigate, fund, auth, user, model } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    setNavigationTabs: user.headerStore.setNavigationTabs,
    setSelectedTab: model.modelBuilderStore.setSelectedTab,
    selectedTab: model.modelBuilderStore.selectedTab,
    currentModel: model.currentModel,
    getModelDetails: model.getModelDetails,
    createModel: model.createModel,
  }
})
@observer
class StrategyWizard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    // clear previously saved chart data.
    // we do not want saved strategy to comingle
    // with other local storage data.
    localStorage.removeItem('bobStrategyChartLayout')
  }

  async saveStrategy() {
    const { strategy, lockedByUser } = this.state
    if (lockedByUser) {
      return console.warn(
        'Document currently locked by',
        lockedByUser.firstName,
        lockedByUser.lastName
      )
    }
    console.log('save strategy', strategy)
    await axios.put(`/v0/strategy`, strategy).catch(e => false)
  }

  async componentDidMount() {
    const { isAuthenticated, getModelDetails } = this.props
    await isAuthenticated()

    const strategyId = this.props.model
    const { restoreProps, createModel } = this.props
    history.push(window.location.pathname)
    let lockedByUser
    if (restoreProps) {
      console.log('RESTORE PROPS')
      this.setState(restoreProps)
    } else {
      console.log('NOT RESTORE PROPS')
      if (strategyId === 'new') {
        const newId = await createModel()
        return (window.location.href = `/model/${newId}/details`)
      }

      const modelId = window.location.pathname.split('/')[2]
      await getModelDetails(modelId)

      const lockedDocument = await this.lockDocument()
      lockedByUser = objectPath(lockedDocument, 'data/lockedObject/lockedByUser')
      const profile = Auth.getProfile()
      console.log(lockedByUser, profile)
      const { user = {} } = profile || {}
      if (lockedByUser && user.UserId === lockedByUser.UserId) {
        lockedByUser = undefined
        console.log('locked by me')
      }
      console.log('first lockedByUser', lockedByUser)
      window.addEventListener('beforeunload', async () => {
        await this.unlockDocument()
      })
    }
    this.setState({ lockedByUser })
  }

  async lockDocument() {
    const strategyId = this.props.model
    const lockedDocument = await axios.get(`/v0/lock-object/strategy/${strategyId}`).catch(e => e)
    console.log('lockDocumentResponse', lockedDocument)
    return lockedDocument
  }

  async unlockDocument() {
    const strategyId = this.props.match.params.strategy
    if (strategyId !== 'new') {
      const unlockDocument = await axios
        .get(`/v0/unlock-object/strategy/${strategyId}`)
        .catch(e => e)
      console.log('unlockDocumentResponse', unlockDocument)
    }
  }

  async loadStrategy(strategyId) {
    const response = await axios(`/v0/allocation/${strategyId}`).catch(e => false)
    let strategy
    if (!response) {
      strategy = defaultStrategy
      history.push('details')
    } else {
      const { data = {} } = response
      strategy = data.strategy
      delete strategy.State
      delete strategy.Statistics
      delete strategy.BacktestStatistics
      if (!Array.isArray(strategy.charts)) strategy.charts = []
    }
    console.log('strategyxx', strategy)
    this.setState({ strategy })
  }

  updateState(newState, cb) {
    console.log('newState', newState)
    this.setState(
      () => newState,
      () => {
        if (cb) cb()
        this.saveStrategy()
      }
    )
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('state', this.state)
  //   console.log('nextState', nextState)
  //   return (
  //     !this.state.selectedTab ||
  //     this.state.selectedTab !== nextState.selectedTab ||
  //     this.state.strategy !== nextState.strategy
  //   )
  // }

  render() {
    const Whiz = () => (
      <Wizard
        {...this.state}
        fullScreen={this.props.fullScreen}
        toggleFullScreen={() => this.props.toggleFullScreen(!this.props.fullScreen, this.state)}
      />
    )
    return (
      <Router>
        <Switch>
          <Route path="/model/:model/details" component={Whiz} />
          <Route path="/model/:model/securities" component={Whiz} />
          <Route path="/model/:model/analysis" component={Whiz} />
          <Route path="/model/:model/backtest" component={Whiz} />
          <Route path="/model/:model/complete" component={Whiz} />
        </Switch>
      </Router>
    )
  }
}

export default StrategyWizard
