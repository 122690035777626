import React, { Component } from 'react'

import axios from 'axios'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme'
import Table from './Table'
import { Checkbox, Button, FormControlLabel } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import RestoreIcon from '@material-ui/icons/Restore'
import DeleteIcon from '@material-ui/icons/Delete'
import PencilIcon from '@material-ui/icons/Edit'
import { Success, Pick } from '../../common/components/Dialogs'

import '../../App/App.css'

function PermissionsDisplay(props) {
  return props.assigned.map((p, idx) => {
    const foundPerm = props.permissions.find(perm => perm._id === p) || {}
    const { description = '' } = foundPerm
    return (
      <li key={idx}>
        <span>{description}</span>
      </li>
    )
  })
}

export default class Roles extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  setActiveRole(activeRole) {
    console.log('activeRole', activeRole)
    this.setState({ activeRole, focus: true })
  }
  startNewRole() {
    const activeRole = {
      roleCode: '',
      roleName: '',
      description: '',
    }
    this.setState({ activeRole, focus: true })
  }
  updateActiveRole(e) {
    const isCheck = e.target.type === 'checkbox'
    const { activeRole } = this.state
    activeRole[e.target.name] = isCheck ? e.target.checked : e.target.value
    this.setState({ activeRole })
  }

  async saveRole() {
    const { activeRole } = this.state
    await axios.put('/v0/role', activeRole)
    this.setState({ activeRole: undefined })
    await this.initRoles()
  }

  async deleteRole() {
    const { activeRole } = this.state
    await axios.delete(`/v0/role/${activeRole._id}`)
    this.setState({ activeRole: undefined, showDeleteSuccess: true })
    await this.initRoles()
  }

  async componentDidMount() {
    await this.initRoles()
    await this.initPermissions()
    this.initListColumns()
  }

  initListColumns() {
    const listColumns = [
      {
        displayName: 'Role Name',
        propPath: 'roleName',
        style: {
          width: '10%',
        },
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Role Description',
        propPath: 'description',
        style: {
          width: '10%',
        },
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Assigned Permissions',
        propPath: 'permissions',
        style: {
          width: '30%',
        },
        filter: false,
        displayFn: items => (
          <PermissionsDisplay assigned={items || []} permissions={this.state.permissions} />
        ),
      },
      {
        displayName: 'Edit',
        propPath: '/',
        filter: false,
        style: {
          width: '5%',
        },
        displayFn: item => (
          <Button onClick={this.setActiveRole.bind(this, item)}>
            <PencilIcon />
          </Button>
        ),
      },
      {
        displayName: 'Assign',
        propPath: '/',
        filter: false,
        style: {
          width: '5%',
        },
        displayFn: items => (
          <Button onClick={this.openPickPermModal.bind(this, items)}>+ Add perms</Button>
        ),
      },
    ]
    this.setState({ listColumns })
  }

  async initPermissions() {
    let { data: permissions = [] } = await axios.get('/v0/permission')
    this.setState({ permissions })
  }

  async initRoles() {
    let { data: roles = [] } = await axios.get('/v0/role')
    this.setState({ roles })
  }

  openPickPermModal(user) {
    this.setState({ activePermSelectionRole: user, showPickPermModal: true })
  }

  addPermToRole(perm, e) {
    console.log(perm)
    const { activePermSelectionRole } = this.state
    if (activePermSelectionRole) {
      const { permissions = [] } = activePermSelectionRole
      if (e.target.checked === false) {
        permissions.splice(permissions.findIndex(p => p._id === perm._id), 1)
      } else {
        if (!permissions.includes(perm._id)) permissions.push(perm._id)
      }
      activePermSelectionRole.permissions = permissions
      this.setState({ activePermSelectionRole })
    }
  }

  async saveRolePerms() {
    const { activePermSelectionRole } = this.state
    console.log('save', activePermSelectionRole)
    this.closePickPermModal()
    let { data } = await axios.put('/v0/role', activePermSelectionRole)
    console.log('saved?', data)
  }
  closePickPermModal() {
    this.setState({ showPickPermModal: false })
  }
  updateState(newState, cb) {
    console.log('role newState', newState)
    this.setState(
      () => newState,
      () => {
        if (cb) cb()
      }
    )
  }

  render() {
    const {
      roles = [],
      permissions = [],
      listColumns = [],
      activePermSelectionRole = {},
      showPickPermModal = false,
      activeRole,
      focus = false,
      showDeleteSuccess = false,
    } = this.state
    const { permissions: selectedPermissions = [] } = activePermSelectionRole
    const tableOpts = {
      listColumns,
      list: roles,
    }
    if (focus) {
      setTimeout(() => this.setState({ focus: false }), 0)
    }
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <div className="row">
            <div className="col-md-12">
              <br />
              <Button className="pull-right" onClick={this.startNewRole.bind(this)}>
                + Add new role
              </Button>
              <hr />
              <br />
            </div>
          </div>
          {activeRole && (
            <div className={activeRole._id ? 'row edit-object' : 'row new-object'}>
              <div className="col-md-3">
                <TextField
                  label="Role Code"
                  autoFocus={focus}
                  onChange={this.updateActiveRole.bind(this)}
                  name="roleCode"
                  value={activeRole.roleCode}
                  fullWidth={true}
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="Role Name"
                  onChange={this.updateActiveRole.bind(this)}
                  name="roleName"
                  value={activeRole.roleName}
                  fullWidth={true}
                />
                <FormControlLabel
                  label="Group Designation"
                  control={
                    <Checkbox
                      onChange={this.updateActiveRole.bind(this)}
                      name="group"
                      checked={activeRole.group}
                    />
                  }
                />
              </div>
              <div className="col-md-3">
                <TextField
                  label="Role Description"
                  onChange={this.updateActiveRole.bind(this)}
                  name="description"
                  value={activeRole.description}
                  fullWidth={true}
                />
              </div>
              <div className="col-md-2">
                <Button onClick={this.saveRole.bind(this)}>
                  <SaveIcon /> Save
                </Button>
                <Button onClick={() => this.setState({ activeRole: undefined })}>
                  <RestoreIcon /> Cancel
                </Button>
                {activeRole._id && (
                  <Button onClick={this.deleteRole.bind(this)}>
                    <DeleteIcon /> Delete
                  </Button>
                )}
              </div>
            </div>
          )}
          <Table {...tableOpts}></Table>
        </div>
        <PermPickModal
          show={showPickPermModal}
          selectedPermissions={selectedPermissions}
          addPermToRole={this.addPermToRole.bind(this)}
          permissions={permissions}
          onCommit={this.saveRolePerms.bind(this)}
          onClose={this.closePickPermModal.bind(this)}
        />
        <Success
          show={showDeleteSuccess}
          hideLegal={true}
          title="Role removed"
          onHide={() => this.setState({ showDeleteSuccess: false })}
        />
      </MuiThemeProvider>
    )
  }
}

class PermPickModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  body() {
    const { permissions = [] } = this.props
    const tableOpts = {
      listColumns: this.listColumns(),
      list: permissions,
      rowsPerPage: 5,
    }
    return (
      <div>
        <Table {...tableOpts}></Table>
      </div>
    )
  }
  render() {
    const { show = false, onClose, onCommit } = this.props
    return (
      <Pick
        show={show}
        onHide={onClose}
        onCommit={onCommit}
        title="Select roles"
        className="strategies-selector"
        body={this.body()}
      />
    )
  }
  handlePick(...a) {
    console.log(...a)
  }
  listColumns() {
    // console.log(this.props.addRolesToUser);
    const { selectedPermissions = [] } = this.props
    return [
      {
        displayName: 'Select',
        propPath: '/',
        displayFn: item => (
          <Checkbox
            checked={selectedPermissions.includes(item._id)}
            onClick={e => this.props.addPermToRole(item, e)}
          />
        ),
        filter: false,
      },
      {
        displayName: 'Permssion',
        propPath: 'permissionCode',
      },
      {
        displayName: 'Description',
        propPath: 'description',
      },
    ]
  }
}
