import { observable, action } from 'mobx'
import getNavigationTabs from '../../../services/getNavigationTabs'

export default class HeaderStore {
  @observable navigationTabs = []
  @observable impersonationData = {}

  constructor(userStore) {
    this.rootStore = userStore.rootStore
    this.log = this.rootStore.log
  }

  @action('HeaderStore | setNavigationTabs')
  setNavigationTabs = (routeLabel = '/') => {
    try {
      const {
        auth: { profile },
      } = this.rootStore
      const { user } = profile
      this.navigationTabs = getNavigationTabs(user, routeLabel)
      this.log.info(`Test completed`)
    } catch (e) {
      this.log.warn(`ISSUE In HeaderStore | ${e.message}`)
    }
  }

  @action('HeaderStore | getImpersonationData')
  getImpersonationData = async () => {
    try {
      const { api } = this.rootStore
      const impersonations = await api.get('/v0/impersonations')
      const impersonationSession = impersonations.data
      const usersData = await api.get('/v0/users')
      const usersObject = usersData.data || {}
      const { users = [] } = usersObject
      const filteredUsers = users.filter(
        user => user.hasOwnProperty('firstName') && user.hasOwnProperty('lastName')
      )
      const impersonationData = {
        impersonationSession,
        users: filteredUsers,
      }
      this.impersonationData = impersonationData
    } catch (e) {
      this.log.warn(`ISSUE In HeaderStore | ${e.message}`)
    }
  }

  @action('HeaderStore | initiateImpersonation')
  initiateImpersonation = async selectedUser => {
    try {
      const { api, auth } = this.rootStore
      if (this.impersonationData.impersonationSession) {
        await api.post('/v0/impersonation.stop', selectedUser)
      }
      const impersonationResponse = await api
        .post('/v0/impersonation.start', selectedUser)
        .catch(() => false)

      if (impersonationResponse) {
        const { data: impersonationUser } = impersonationResponse
        await auth.setImpersonation(impersonationUser)
      }
      await auth.setUserProfile()
      window.location.href = '/'
    } catch (e) {
      this.log.warn(`ISSUE In HeaderStore | ${e.message}`)
    }
  }

  @action('HeaderStore | stopImpersonation')
  stopImpersonation = async () => {
    try {
      const { api, auth } = this.rootStore
      await api.post('/v0/impersonation.stop', {})
      await auth.setUserProfile()
      window.location.href = '/'
    } catch (e) {
      this.log.warn(`ISSUE In HeaderStore | ${e.message}`)
    }
  }
}
