import { createMuiTheme } from '@material-ui/core/styles'
import objectPath from 'simple-object-path'

export const defaultStrategy = {
  strategist: undefined,
  published: false,
  allocation: {},
  rebalance: {},
  periodicity: {},
  charts: [],
  Tickers: [],
}
export const setProp = (obj, path, propVal) => {
  const propsList = path.split('/')
  const prop = propsList.pop()
  if (propsList.length === 0) {
    return (obj[prop] = propVal)
  }
  const parent = objectPath(obj, propsList.join('/'))
  return (parent[prop] = propVal)
}
export const validateProps = (obj, formValidation) => {
  const formErrors = []
  const validations = Object.keys(formValidation)
  for (let path of validations) {
    const validationFn = formValidation[path]
    const propsList = path.split('/')
    const prop = propsList.pop()
    if (propsList.length === 0) {
      const result = validationFn(obj[prop])
      if (!result) formErrors.push(path)
    } else {
      const parent = objectPath(obj, propsList.join('/')) || {}
      const result = validationFn(parent[prop])
      if (!result) formErrors.push(path)
    }
  }
  return formErrors
}
export const getSelectedTab = (history, idx) => {
  let selectedTab = 0
  const tabMap = {
    details: 0,
    portfolios: 1,
    clients: 2,
    backtest: 3,
  }
  if (typeof idx === 'number') {
    const url = Object.keys(tabMap)[idx]
    selectedTab = tabMap[url]
    history.push(url)
  } else {
    const tabName = window.location.href.split('/').pop()
    selectedTab = tabMap[tabName]
  }
  return selectedTab
}

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#0cb0c8',
      // dark: '#1a4e63'
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0cb0c8',
      main: '#78c263',
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
    error: {
      main: '#8b0000',
    },
  },
})

const required = val => {
  if (typeof val === 'object') return false
  if (typeof val === 'string') return val.length > 0
  if (typeof val === 'number') return true
  return false
}
export const formValidation = {
  strategy: val => required(val),
  'allocation/strategy': val => required(val),
  'allocation/percentage': val => required(val),
  'rebalance/every': val => required(val),
  'rebalance/interval': val => required(val),
  'rebalance/strategy': val => required(val),
}

export const initials = text => {
  if (typeof text !== 'string') return ''
  return text
    .split(/\s/)
    .map(w => w[0])
    .join('')
    .toUpperCase()
}
