import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import StyledDonutChart from './styles'

class DonutChart extends Component {
  state = {
    value: 0,
    halfSize: 0,
    radius: 0,
    rotateVal: '',
    circumference: 0,
    strokeVal: 0,
    dashVal: 0,
    trackStyle: {},
    indicatorStyle: {},
    invalidForm: false,
    exceedsMaxEditableVal: false,
  }

  componentDidMount() {
    const { value } = this.props
    this.calculateChart(value)
  }

  componentDidUpdate(prevProps, prevState) {
    const { exceedsMaxEditableVal } = this.state
    const { value } = this.props
    // Recalculate chart values if value changes
    if (prevState.value !== value) {
      if (isNaN(value) || exceedsMaxEditableVal) {
        return
      }
      this.calculateChart(value)
    }
  }

  calculateChart = val => {
    const { chartSize } = this.props
    // Calculations
    const halfSize = chartSize * 0.5
    const radius = halfSize - 6 * 0.5
    const circumference = 2 * Math.PI * radius
    const strokeVal = (val * circumference) / 100
    const dashVal = strokeVal + ' ' + circumference
    const trackStyle = { strokeWidth: 6 }
    const indicatorStyle = { strokeWidth: 6, strokeDasharray: dashVal }
    const rotateVal = 'rotate(-90 ' + halfSize + ',' + halfSize + ')'

    this.setState({
      value: val,
      halfSize,
      radius,
      rotateVal,
      circumference,
      strokeVal,
      dashVal,
      trackStyle,
      indicatorStyle,
    })
  }

  handleValueChange = e => {
    const { maxEditableValue, handleUpdatedValue } = this.props
    const { value : oldValue } = this.state
    this.setState(
      {
        value: e.target.value,
        exceedsMaxEditableVal: +e.target.value > (maxEditableValue + +oldValue),
        invalidForm: isNaN(e.target.value),
      },
      () => {
        const { value } = this.state
        handleUpdatedValue(value)
      }
    )
  }

  render() {
    const {
      label,
      chartSize,
      textXValue,
      textLabelXValue,
      textLabelYValue,
      isEditable,
    } = this.props
    const {
      value,
      halfSize,
      radius,
      rotateVal,
      trackStyle,
      indicatorStyle,
      invalidForm,
      exceedsMaxEditableVal,
    } = this.state

    return (
      <StyledDonutChart>
        <svg width={`${chartSize}px`} height={`${chartSize}px`} className="donut-chart">
          <circle
            r={radius}
            cx={halfSize}
            cy={halfSize}
            transform={rotateVal}
            style={trackStyle}
            className="donut-chart-track"
          />
          <circle
            r={radius}
            cx={halfSize}
            cy={halfSize}
            transform={rotateVal}
            style={indicatorStyle}
            className="donut-chart-indicator"
          />
          <text x={textXValue} y={halfSize} className="donut-chart-label">
            {!isEditable ? (
              <Fragment>
                <tspan className="donut-chart-text-val">{value}</tspan>
                <tspan className="donut-chart-text-percent">%</tspan>
                <tspan
                  className="donut-chart-text-label"
                  x={textLabelXValue || halfSize}
                  y={textLabelYValue || halfSize + 20}
                >
                  {label}
                </tspan>
              </Fragment>
            ) : null}
          </text>
        </svg>
        {invalidForm && <p className="form-error">Can only contain numbers.</p>}
        {exceedsMaxEditableVal && (
          <p className="form-error">Max Allocation Exceeded.</p>
        )}
        {isEditable && (
          <div
            className={
              invalidForm || exceedsMaxEditableVal
                ? 'donut-form-wrapper is-error'
                : 'donut-form-wrapper'
            }
          >
            <label htmlFor="donut-form-control" className="donut-form-label">
              %
            </label>
            <input
              id="donut-form-control"
              className="donut-form-control"
              type="text"
              onChange={val => this.handleValueChange(val)}
              value={value}
            />
            <span className="donut-form-chart-label">{label}</span>
          </div>
        )}
      </StyledDonutChart>
    )
  }
}

DonutChart.defaultProps = {
  textXValue: 69,
  textLabelXValue: 0,
  textLabelYValue: 0,
  chartSize: 175,
  isEditable: null,
  maxEditableValue: null,
  handleUpdatedValue: () => {},
}

DonutChart.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  textXValue: PropTypes.number,
  textLabelXValue: PropTypes.number,
  textLabelYValue: PropTypes.number,
  chartSize: PropTypes.number,
  isEditable: PropTypes.bool,
  maxEditableValue: PropTypes.number,
  handleUpdatedValue: PropTypes.func,
}

export default DonutChart
