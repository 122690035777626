import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Pick } from '../../../../common/components/Dialogs'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
    getUserTdaPositions: user.clientStore.getUserTdaPositions,
    selectedClientSecurities: user.clientStore.selectedClientSecurities,
    selectedClient: user.clientStore.selectedClient,
    freezeShares: user.clientStore.freezeShares,
    frozenShares: user.clientStore.frozenShares
  }
})
@observer
class FreezeShares extends Component {
  state = { fund: {}, width: 4, filters: [] }

  async componentDidMount() {
    this.setState({ loading: true })
    const { selectedClient: client, getUserTdaPositions } = this.props
    const { _id } = client
    await getUserTdaPositions(_id)
    this.setState({ loading: false })
  }

  async updateSharesToFreeze(client, symbol, shares, e) {
    const { freezeShares } = this.props
    if (e.target.value > shares) return console.log('shares must be less')
    freezeShares({ symbol, shares : e.target.value })
  }

  handleChangePage(event, tablePage) {
    this.setState({ tablePage })
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value })
  }

  pickViewClient() {
    const { tablePage = 0, rowsPerPage = 4, loading } = this.state
    const { selectedClient: client, selectedClientSecurities = [], frozenShares = [] } = this.props
    const page = []
      .concat(selectedClientSecurities)
      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
    return (
      <div>
        {!loading && (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h5>{client.name}</h5>
                  </TableCell>
                  <TableCell>
                    <h5>{client.accountNum}</h5>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Shares</TableCell>
                  <TableCell># of Shares to Freeze</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {page.map((holding, idx) => {
                  const { symbol, shares } = holding
                  const sharesFrozen = frozenShares.find(f => f.symbol === symbol) || {
                    symbol,
                    shares: 0,
                  }
                  return (
                    <TableRow key={idx}>
                      <TableCell>{symbol}</TableCell>
                      <TableCell>{shares}</TableCell>
                      <TableCell>
                        {' '}
                        <TextField
                          value={sharesFrozen.shares}
                          onChange={this.updateSharesToFreeze.bind(this, client, symbol, shares)}
                          fullWidth={true}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              count={selectedClientSecurities.length}
              page={tablePage}
              rowsPerPage={rowsPerPage}
              component="div"
              onChangePage={this.handleChangePage.bind(this)}
              onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
            />
          </div>
        )}
        {loading && (
          <div>
            <div className="row">
              <div className="col-md-12">Loading Client Positions</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <CircularProgress color="secondary" />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6"></div>
          <div className="col-md-3"></div>
        </div>
        {this.pickViewClient()}
      </div>
    )
  }
}

export default class FreezeSharesModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  body() {
    return (
      <div>
        <FreezeShares />
      </div>
    )
  }
  render() {
    const { show = false, onClose, onCommit } = this.props
    return (
      <Pick
        show={show}
        onHide={onClose}
        onCommit={onCommit}
        commitMsg="Save Frozen Shares"
        title="Freeze Shares"
        className="strategies-selector"
        body={this.body()}
      />
    )
  }
}
