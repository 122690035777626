import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'

@inject(({ store: { model, auth } }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    currentModel: model.currentModel,
    setCurrentModel: model.setCurrentModel,
    selectedSecurity: model.modelBuilderStore.selectedSecurity,
    setSelectedSecurity: model.modelBuilderStore.setSelectedSecurity,
    getModelCharts: model.getModelCharts
  }
})
@observer
class StepTechnicalAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { selectedSecurity } = this.props
    window.setChartHandler = loadChartDataStore => {
      this.loadChartDataStore = loadChartDataStore
      this.loadChartDataStore(selectedSecurity)
    }
    window.chartHandler = this.chartUpdated.bind(this)
    window.updateAllCharts = this.updateAllCharts.bind(this)
    window.addEventListener("message", this.updateAllCharts.bind(this), false);
  }

  chartUpdated(chartData) {
    const { setCurrentModel, currentModel, selectedSecurity } = this.props
    const index = currentModel.charts.findIndex(c => c.id === selectedSecurity.id)
    if (index === -1) {
      console.error('Attempt to edit chart not found on strategy')
      return
    }
    currentModel.charts[index] = JSON.parse(JSON.stringify(chartData))
    setCurrentModel(currentModel, true)
  }

  async updateAllCharts(){
    const { getModelCharts } = this.props
    await getModelCharts()
  }

  async selectSecurityFromList(securityIndex, preload) {
    debugger
    const { currentModel, setSelectedSecurity } = this.props
    let selectedSecurity = currentModel.charts[securityIndex]
      ? currentModel.charts[securityIndex]
      : currentModel.charts[0]
    console.log('leaving and setting chart control', JSON.parse(JSON.stringify(currentModel.charts)))

    await setSelectedSecurity(selectedSecurity)
    if (this.loadChartDataStore) this.loadChartDataStore(selectedSecurity)
  }
  toggleFullScreen() {
    const { strategy } = this.state
    console.log('toggle - charts', strategy.charts)
    this.props.updateState({ strategy }, this.props.toggleFullScreen)
  }
  render() {
    const { fullScreen = false, auth0Profile, selectedSecurity, currentModel } = this.props
    if (!currentModel) return <div>Loading...</div>
    const selectedSecurityIndex = selectedSecurity
      ? currentModel.charts.findIndex(s => s.id === selectedSecurity.id)
      : 0
    const profile = auth0Profile.user.userProfile
    let canEditCustomStudy = ''
    for (let role of profile) {
      for (let permission of role.permissions) {
        if (permission.permissionCode === 'buildCustomStudy') {
          canEditCustomStudy = '#canEditCustomStudy'
        }
      }
    }
    const fullScreenTab = fullScreen ? (
      <span>
        <FullscreenExitIcon />
        <small>Collapse</small>
      </span>
    ) : (
      <span>
        <FullscreenIcon />
        <small>Expand</small>
      </span>
    )
    return (
      <div style={{ width: '100%' }}>
        <div className="row">
          <div className="col-md-12">
            <AppBar position="static" color="default">
              <Tabs
                value={selectedSecurityIndex}
                onChange={(e, idx) => this.selectSecurityFromList(idx)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                {currentModel.charts.map((security, idx) => {
                  return <Tab key={idx} label={security.security} value={idx}></Tab>
                })}
                <Tab label={fullScreenTab} value={99} onClick={this.toggleFullScreen.bind(this)} />
              </Tabs>
            </AppBar>
          </div>
        </div>
        {selectedSecurity && (
          <iframe
            src={`/chartiq/chart.html${canEditCustomStudy}`}
            id="chartWindow"
            title="Chart Window"
            style={{ width: '100%', height: `calc(100vh - ${fullScreen ? 48 : 184}px)`, border: 0 }}
          ></iframe>
        )}
      </div>
    )
  }
}

export default StepTechnicalAnalysis
