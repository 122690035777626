import styled from 'styled-components'
import { colors, patterns } from '../../../../assets/styles'

export const StyledSingleFundView = styled.div`
  .card-wrapper-grid {
  }

  .historical-ledger-card {
    ${patterns.defaultCard};
    height: auto;
    margin-top: 0.937rem;
    .card-header-nav {
      display: flex;
      margin-bottom: 2rem;
      padding: 0;
      align-items: center;
      min-height: 2.812rem;
      border-bottom: 1px solid ${colors.borderGrey};
      .tab-button:first-child {
        padding: 0 1.5rem;
      }
    }
    .card-header {
      display: flex;
      padding: 1.562rem 1.25rem 0 1.25rem;
      align-items: center;
      min-height: 2.812rem;
      .card-header-label {
        ${patterns.label};
        margin: 0;
      }
    }
  }
`
