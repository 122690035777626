import { Breadcrumbs, Link, Button, TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import {
  CheckCircle,
  CloseRounded,
  Warning,
  Edit,
  Save,
  Add,
  RemoveCircle,
} from '@material-ui/icons'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component } from 'react'
import op from 'simple-object-path'
import { typography } from '../../../assets/styles'
import BaseTableColumn from '../../../common/components/BaseTableColumn'
import DashboardCard from '../../../common/components/DashboardCard'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import CoolTable from '../../../common/components/Table'
import { Pick } from '../../../common/components/Dialogs'

@inject(({ store: { navigate, auth, service } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getServiceCategories: service.getServiceCategories,
    serviceCategory: service.serviceCategory,
    getServiceCategoryDetail: service.getServiceCategoryDetail,
    updateServiceCategoryInsights: service.updateServiceCategoryInsights,
    updateServiceCategoryActions: service.updateServiceCategoryActions,
  }
})
@observer
export default class ServiceCategory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mockList: [],
      loadingServiceCategory: false,
    }
  }

  async componentDidMount() {
    await this.getServiceCategory()
  }

  async getServiceCategory() {
    this.setState({ loadingServiceCategory: true })
    const { serviceCategoryId, getServiceCategoryDetail, isAuthenticated } = this.props
    await isAuthenticated()
    const serviceCategory = await getServiceCategoryDetail(serviceCategoryId)
    this.setState({ serviceCategory, loadingServiceCategory: false })
  }
  getExceptionCount(item) {
    const uncaughtExceptions = op(item, 'insights/insights/uncaughtExceptions') || {}
    return Object.keys(uncaughtExceptions).length
  }

  onSelectTask(taskRow) {
    const { navigate } = this.props
    navigate(`/admin/services/task/${taskRow._id}/instructions`)
  }

  onClickBack() {
    const { navigate } = this.props
    navigate(`/admin/services`)
  }

  parseKeyInsights(serviceTask) {
    const { serviceCategory = {} } = this.state
    const { insights } = serviceTask
    const { keyInsights: serviceCategoryKeyInsights = [] } = serviceCategory
    const keyInsights = serviceCategoryKeyInsights.map(ki => {
      const { delimiter = ' ' } = ki
      return {
        name: ki.name,
        value: (ki.paths || []).reduce((a, p) => a + op(insights, p) + delimiter, ''),
      }
    })
    return keyInsights
  }

  getTaskStatusIcon(serviceTask) {
    const stateMap = {
      SHUTDOWN: <CloseRounded color="error" />,
      RUNNING: <CheckCircle color="secondary" />,
      STOPPED: <Warning color="error" />,
      STOPPING: <Warning style={{ color: 'gold' }} />,
    }
    return stateMap[serviceTask.state] || ''
  }

  onViewChange(page) {
    const { serviceCategory = {} } = this.state
    const { navigate } = this.props
    navigate(`/admin/services/${serviceCategory._id}/${page}`)
  }

  onKeyInsightChange(object, prop, value) {
    object[prop] = value.target.value
    this.forceUpdate()
    console.log(value.target.value)
  }

  copyEcsInfo(e, ecsTaskId) {
    e.stopPropagation()
    navigator.clipboard.writeText(ecsTaskId || 'no ecs info available')
  }

  render() {
    const {
      serviceCategory = {},
      loadingServiceCategory,
      showRemoveKeyInsight = false,
    } = this.state
    const {
      list = serviceCategory.serviceTasks || [],
      onPageChange,
      onFilterChange,
      tableConfigs = [],
      page,
    } = this.props
    const tableConfig = tableConfigs.find(c => (c.tableId = 'asset-manager')) || { rowsPerPage: 12 }
    const listColumns = [
      {
        id: 'd4ea42b5a',
        displayName: 'Status',
        propPath: '/',
        filter: true,
        sortProp: 'state',
        filterVal: item => item.state,
        displayFn: item => (
          <BaseTableColumn>
            {this.getTaskStatusIcon(item)} {item.state}
          </BaseTableColumn>
        ),
      },
      {
        id: 'd4ea42b5b',
        displayName: 'Startup',
        propPath: '/',
        filter: true,
        sortProp: 'startUp',
        isDateSort: true,
        filterVal: item => moment(item.startUp).format('L LTS'),
        displayFn: item => (
          <BaseTableColumn>{moment(item.startUp).format('L LTS')}</BaseTableColumn>
        ),
      },
      {
        id: 'd4ea42b5c',
        displayName: 'Shutdown',
        propPath: '/',
        filter: true,
        sortProp: 'startUp',
        isDateSort: true,
        filterVal: item => moment(item.shutDown).format('L LTS'),
        displayFn: item => (
          <BaseTableColumn>
            {item.shutDown ? moment(item.shutDown).format('L LTS') : ''}
          </BaseTableColumn>
        ),
      },
      {
        id: 'f7b7fc5e',
        displayName: 'Exceptions',
        propPath: '/',
        filter: true,
        sortable: false,
        filterVal: item => this.getExceptionCount(item),
        displayFn: item => <BaseTableColumn>{this.getExceptionCount(item)}</BaseTableColumn>,
      },
      {
        id: 'f7b7fc5ex',
        displayName: 'Key Insights',
        propPath: '/',
        filter: true,
        sortable: false,
        filterVal: item => this.getExceptionCount(item),
        displayFn: item => (
          <BaseTableColumn>
            {this.parseKeyInsights(item).map((insight, index) => {
              return (
                <div key={`key_insight_value_${index}`}>
                  {insight.name}: {insight.value}
                </div>
              )
            })}
          </BaseTableColumn>
        ),
      },
      {
        id: 'f7b7fc5ey',
        displayName: 'ECS Info',
        propPath: '/',
        filter: true,
        sortable: false,
        filterVal: item => op(item, 'ecsInfo/ecsTaskId'),
        displayFn: item => (
          <BaseTableColumn>
            <Button onClick={e => this.copyEcsInfo(e, op(item, 'ecsInfo/ecsTaskId'))}>Copy</Button>
          </BaseTableColumn>
        ),
      },
    ]

    const tableOpts = {
      selectable: true,
      onSelect: this.onSelectTask.bind(this),
      listColumns,
      list: list,
    }
    const defaultActions = ['STOP', 'START']
    return (
      <React.Fragment>
        {loadingServiceCategory && <LoadingIndicator loadingText="" />}
        {!loadingServiceCategory && (
          <Container fixed style={{ fontFamily: typography.baseFontFamily }}>
            <Breadcrumbs>
              <Link
                color="secondary"
                style={{ cursor: 'pointer' }}
                onClick={this.onClickBack.bind(this)}
              >
                Services
              </Link>
              <span>{serviceCategory.serviceName}</span>
            </Breadcrumbs>
            <br />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              style={{ verticalAlign: 'top' }}
            >
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: typography.extraLargeFontSize,
                    fontWeight: typography.mediumFontWeight,
                  }}
                >
                  {serviceCategory.serviceName}
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Running Instances</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {
                                (op(serviceCategory, 'serviceTasks') || []).filter(
                                  t => t.state === 'RUNNING'
                                ).length
                              }
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Stopped Instances</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {
                                (op(serviceCategory, 'serviceTasks') || []).filter(
                                  t => t.state === 'SHUTDOWN'
                                ).length
                              }
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Today's Total</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {(op(serviceCategory, 'serviceTasks') || []).length}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>

                  <Grid item xs={12}>
                    <DashboardCard hasTitle={false} title="" moreTool={false}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        style={{ padding: 15 }}
                      >
                        <Grid item xs={3}>
                          <Button
                            variant="outlined"
                            color={page === 'tasks' ? 'secondary' : 'default'}
                            onClick={() => this.onViewChange('tasks')}
                          >
                            Tasks
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            variant="outlined"
                            color={page === 'insights' ? 'secondary' : 'default'}
                            onClick={() => this.onViewChange('insights')}
                          >
                            Edit Insights
                          </Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color={page === 'actions' ? 'secondary' : 'default'}
                            variant="outlined"
                            onClick={() => this.onViewChange('actions')}
                          >
                            Edit Actions
                          </Button>
                        </Grid>
                        <Grid item xs={9} style={{ width: '100%' }}></Grid>
                      </Grid>
                      {page === 'actions' && (
                        <div>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            style={{ padding: 15 }}
                          >
                            <Grid item xs={6}>
                              <span>{`${serviceCategory.serviceName} Actions`}</span>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                              {defaultActions.map((action, index) => (
                                <Button
                                  key={`service_category_actions_config_${index}`}
                                  variant="outlined"
                                  color={
                                    (serviceCategory.actions || []).includes(action)
                                      ? 'secondary'
                                      : 'default'
                                  }
                                  onClick={() => this.toggleAction(action)}
                                >
                                  {action}
                                </Button>
                              ))}
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      {page === 'insights' && (
                        <div>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            style={{ padding: 15 }}
                          >
                            <Grid item xs={6}>
                              <span>{`${serviceCategory.serviceName} Key Insight Configuration`}</span>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                              {(serviceCategory.keyInsights || []).map((keyInsight, index) =>
                                this.keyInsightRow(keyInsight, index)
                              )}
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                              <Button onClick={this.addKeyInsight.bind(this)}>
                                <Add /> Add Insight
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      {page === 'tasks' && (
                        <div>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            style={{ padding: 15 }}
                          >
                            <Grid item xs={3}>
                              <span>{`${serviceCategory.serviceName} Tasks`}</span>
                            </Grid>
                            <Grid item xs={9} style={{ width: '100%' }}>
                              <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                                spacing={2}
                              ></Grid>
                            </Grid>
                          </Grid>

                          <Box>
                            {!!tableOpts.list.length && (
                              <CoolTable
                                {...tableOpts}
                                tableConfig={tableConfig}
                                tableId="asset-manager"
                                onFilterChange={onFilterChange}
                                onPageChange={onPageChange}
                              />
                            )}
                            {!tableOpts.list.length && (
                              <Grid container direction="row" justify="center" spacing={2}>
                                <span
                                  style={{
                                    fontSize: typography.mediumFontSize,
                                    fontWeight: typography.mediumFontWeight,
                                  }}
                                >{`No tasks for ${serviceCategory.serviceName}!`}</span>
                              </Grid>
                            )}
                          </Box>
                        </div>
                      )}
                    </DashboardCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Pick
              show={showRemoveKeyInsight}
              onHide={this.closeRemoveKeyInsight.bind(this)}
              onCommit={() => this.removeKeyInsight()}
              commitMsg="Yes, Remove Insight"
            />
          </Container>
        )}
      </React.Fragment>
    )
  }

  async toggleAction(action) {
    const { updateServiceCategoryActions } = this.props
    const { serviceCategory } = this.state
    const { actions = [] } = serviceCategory

    if (actions.includes(action)) {
      serviceCategory.actions = actions.filter(a => a !== action)
    } else {
      serviceCategory.actions = actions.concat([action])
    }
    await updateServiceCategoryActions(serviceCategory)
    await this.getServiceCategory()
  }

  addKeyInsight() {
    const editingKeyInsight = {
      name: '',
      paths: [],
    }
    const { serviceCategory = {} } = this.state
    const { keyInsights = [] } = serviceCategory
    keyInsights.push(editingKeyInsight)
    serviceCategory.keyInsights = keyInsights
    this.setState({ serviceCategory, editingKeyInsight })
  }

  async removeKeyInsight() {
    const { updateServiceCategoryInsights } = this.props
    const { serviceCategory = {}, removeKeyInsightIndex } = this.state
    const { keyInsights = [] } = serviceCategory
    keyInsights.splice(removeKeyInsightIndex, 1)
    serviceCategory.keyInsights = keyInsights
    await updateServiceCategoryInsights(serviceCategory)
    this.setState({ serviceCategory, showRemoveKeyInsight: false })
  }

  addKeyInsightPath() {
    const { editingKeyInsight } = this.state
    editingKeyInsight.paths.push('')
    this.forceUpdate()
  }

  removeKeyInsightPath(index) {
    const { editingKeyInsight } = this.state
    editingKeyInsight.paths.splice(index, 1)
    this.forceUpdate()
  }

  async saveKeyInsights() {
    const { updateServiceCategoryInsights } = this.props
    const { serviceCategory } = this.state
    await updateServiceCategoryInsights(serviceCategory)
    this.setState({ editingKeyInsight: undefined })
    await this.getServiceCategory()
  }

  closeRemoveKeyInsight() {
    this.setState({ showRemoveKeyInsight: false })
  }
  confirmRemoveKeyInsight(index) {
    this.setState({ showRemoveKeyInsight: true, removeKeyInsightIndex: index })
  }

  keyInsightRow(keyInsight, index) {
    const { editingKeyInsight } = this.state
    keyInsight.paths = keyInsight.paths || []
    return (
      <Grid
        key={`key_insight_edit_${index}`}
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
        style={{
          border: '1px solid black',
          borderRadius: 8,
          padding: 20,
          marginBottom: 10,
        }}
      >
        <Grid item xs={3}>
          {editingKeyInsight === keyInsight && (
            <TextField
              autoComplete="off"
              value={keyInsight.name || ''}
              onChange={v => this.onKeyInsightChange(keyInsight, 'name', v)}
              label="Name"
              fullWidth={true}
            />
          )}
          {editingKeyInsight !== keyInsight && <span>{`${keyInsight.name}`}</span>}
        </Grid>
        <Grid item xs={8}>
          <Grid
            key={`key_insight_edit_${index}`}
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            {keyInsight.paths.map((path, pathIndex) => {
              return (
                <Grid
                  key={`key_insight_path_item_${pathIndex}`}
                  item
                  xs={9}
                  style={{ width: '100%' }}
                >
                  {editingKeyInsight === keyInsight && (
                    <TextField
                      autoComplete="off"
                      value={path || ''}
                      onChange={v => this.onKeyInsightChange(keyInsight.paths, pathIndex, v)}
                      label=""
                      fullWidth={true}
                    />
                  )}
                  {editingKeyInsight === keyInsight && (
                    <Button size="small" onClick={() => this.removeKeyInsightPath(pathIndex)}>
                      <RemoveCircle color="error" />
                    </Button>
                  )}
                  {editingKeyInsight !== keyInsight && <span>{`${path}`}</span>}{' '}
                </Grid>
              )
            })}
          </Grid>
          {editingKeyInsight === keyInsight && (
            <Grid
              key={`key_insight_add_path_${index}`}
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Grid item xs={9} style={{ width: '100%' }}>
                <Button onClick={this.addKeyInsightPath.bind(this)}>
                  <Add /> Add Path
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={1}>
          {editingKeyInsight === keyInsight && (
            <Button onClick={this.saveKeyInsights.bind(this)}>
              <Save />
            </Button>
          )}
          {editingKeyInsight !== keyInsight && (
            <Button onClick={() => this.setState({ editingKeyInsight: keyInsight })}>
              <Edit />
            </Button>
          )}
          {editingKeyInsight !== keyInsight && (
            <Button onClick={() => this.confirmRemoveKeyInsight(index)}>
              <RemoveCircle color="error" />
            </Button>
          )}
        </Grid>
      </Grid>
    )
  }
}
