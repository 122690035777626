import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import StyledPortfolios from './styles'
import DonutChart from '../../../../common/components/DonutChart'
// import LoadingDateTooltip from '../../../../common/components/LoadingDateTooltip'
import ModelList from '../../../Marketplace/ModelList'
import closeIcon from '../../../../assets/img/plus-sign.png'
import Typography from '@material-ui/core/Typography'
import currencyFilter from '../../../../lib/currency'


const minAccountSizeValues = [
  '$25,000 - $50,000',
  '$50,001 - $100,000',
  '$100,001 - $200,000',
  'Over $200,000',
]
const maxAllocationValues = [
  '$250,000 - $500,000',
  '$500,001 - $1,000,000',
  '$1,000,001 - $2,000,000',
  'Over $2,000,000',
]

@inject(({ store: { navigate, fund, auth, user, model } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    auth0Profile: auth.profile,
    setNavigationTabs: user.headerStore.setNavigationTabs,
    currentFund: fund.currentFund,
    setCurrentFund: fund.setCurrentFund,
    setCurrentFundTab: fund.setCurrentTab,
    totalAllocation: fund.totalAllocation,
    addingModels: fund.addingModels,
    setAddingModels: fund.setAddingModels,
    setModelDeployState: fund.setModelDeployState,
    setModelSelloffState: fund.setModelSelloffState,
    requiredTotalAllocations: model.requiredTotalAllocations,
    modelBuyInAmounts: model.modelBuyInAmounts,
    getModelCostRequirements: model.getModelCostRequirements,
    removeModelFromCostRequirements: model.removeModelFromCostRequirements,
    removeModelFromFund: fund.marketplaceStore.removeModelFromFund,

  }
})
@observer
class Portfolios extends Component {
  state = {}

  componentDidMount = async () => {
    const {
      currentFund: { strategies },
      setAddingModels,
    } = this.props
    if (strategies.length > 0) setAddingModels(false)
    if (strategies.length === 0) setAddingModels(true)
  }

  getTotalAllocation = () => {}

  getMinAccountSize = portfolio => {
    return minAccountSizeValues[portfolio.minAccountSize - 1] || 'No Selection'
  }

  getMaxAllocation = portfolio => {
    return maxAllocationValues[portfolio.maxAllocation - 1] || 'No Selection'
  }

  getAllocationPercentage = portfolio => {
    const newAllocation =  portfolio.allocatedToPortfolio * 100 || 0
    return newAllocation
  }

  handleUpdatedAllocationVal = async (val, portfolio) => {
    const { currentFund, setCurrentFund } = this.props
    const { strategies } = currentFund
    const update = strategies.find(s => s._id === portfolio._id)
    update.allocatedToPortfolio = +val / 100
    await setCurrentFund(currentFund)
  }

  removePortfolio = async portfolio => {
    const { currentFund, setCurrentFund, setAddingModels, removeModelFromCostRequirements, modelBuyInAmounts, getModelCostRequirements } = this.props
    let { strategies } = currentFund
    currentFund.strategies = strategies.filter(s => s._id !== portfolio._id) || []
    const strategyIds = currentFund.strategies.map(s => s._id)
    debugger
    const currentFundCost = await getModelCostRequirements(strategyIds)
    currentFund.strategies.forEach(s => {
      const newModelCost = modelBuyInAmounts[s._id]
      s.allocatedToPortfolio = parseFloat(newModelCost / currentFundCost).toFixed(4)
      s.buyIn = newModelCost
    })
    if (currentFund.strategies.length === 0) {
      currentFund.totalAllocation = 0
    }
    debugger
    const newTotal = removeModelFromCostRequirements(String(portfolio._id))
    currentFund.buyIn = newTotal
    await setCurrentFund(currentFund)
    if (currentFund.strategies.length === 0) setAddingModels(true)
  }

  render() {
    const {
      currentFund: { strategies, mirroredFund, published },
      addingModels,
      setAddingModels,
      modelBuyInAmounts,
      removeModelFromFund
    } = this.props

    return (
      <StyledPortfolios>
        <p className="subheading">
          My Models{' '}
          {/* <span className="allocation-subheading">
            {totalAllocation >= 100 ? 0 : 100 - totalAllocation}% left for Allocation
          </span> */}
        </p>
        {strategies.length ? (
          <div className="three-column-card-grid">
            {strategies.map(portfolio => (
              <article
                key={portfolio._id}
                className={!addingModels ? `portfolio-card` : `portfolio-card-adding-model`}
              >
                <header>
                  {!published && (
                    <div>
                      <img
                        className="detail-close-sign"
                        src={closeIcon}
                        alt="Close Icon"
                        onClick={()=> {removeModelFromFund(portfolio)}}
                      />
                    </div>
                  )}
                  <h1 className="portfolio-name-heading">{portfolio.name}</h1>
                </header>
                {addingModels && (<div className="model-buy-in-wrapper">
                  <Typography className="model-buy-in">MODEL BUY IN AMOUNT</Typography>
                  <Typography className="model-buy-in-amount">{currencyFilter(modelBuyInAmounts[portfolio._id] || 0)}</Typography>
                </div>)}
                {!addingModels && (
                  <div>
                    <ul className="portfolio-stats">
                    <li>
                        <p className="portfolio-stats-label">MODEL BUY IN AMOUNT</p>
                        <h5>{currencyFilter(modelBuyInAmounts[portfolio._id]) || 0}</h5>
                     </li>
                    </ul>
                    <div className="allocation-donut-chart-wrapper">
                        <DonutChart
                          label="allocation"
                          chartSize={150}
                          value={parseFloat(this.getAllocationPercentage(portfolio)).toFixed(2)}
                          textXValue={this.getAllocationPercentage(portfolio) === 0 ? 63 : 57}
                          textLabelXValue={53}
                          textLabelYValue={100}
                        />
                    </div>
                  </div>
                )}
              </article>
            ))}
            {!addingModels && !mirroredFund && (
              <div className="add-model" onClick={setAddingModels.bind(null, true)}>
                <label className="add-model-label">Add New Model</label>
              </div>
            )}
          </div>
        ) : (
          <div>
            <p className="no-data-text">No models added to this fund yet.</p>
          </div>
        )}

        {addingModels && (
          <div>
            <div className="separate-marketplace"></div>
            <div className="cancel-button" onClick={setAddingModels.bind(null, false)}>
              {' '}
              Cancel{' '}
            </div>
            <ModelList />
          </div>
        )}
      </StyledPortfolios>
    )
  }
}

export default Portfolios
