import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import StyledActionButton from './styles'

@inject(({ store: { ui } }) => {
  return {}
})
@observer
class ActionButton extends Component {
  state = {
    showOptions: false,
    opened: false,
    menuItems: [],
  }

  toggleOptions = () => {
    const { showOptions } = this.state
    if (!showOptions) {
      return this.setState({ showOptions: true, opened: true })
    }
    return this.setState({ showOptions: false, opened: false })
  }

  publish = item => {}
  save = item => {}
  remove = (item, type) => {}
  finished = route => {}
  render = () => {
    const { showOptions, opened } = this.state
    const { menuItems = [] } = this.props
    return (
      <StyledActionButton>
        <div className="new-bob-button">
          <button className="action_button" onClick={this.toggleOptions.bind(this)}>
            <div className="button-content-container">
              <label className="content">ACTIONS</label>
              {!opened && <div className="up_arrow_one"></div>}
              {!opened && <div className="up_arrow_two"></div>}
              {opened && <div className="down_arrow_one"></div>}
              {opened && <div className="down_arrow_two"></div>}
            </div>
          </button>
          {showOptions &&  (<div className="selection-card">
            {menuItems &&
              menuItems.map((item, index) => {
                return (
                  <div>
                  <label className={`item${index+1}`} onClick={item.action}>
                    {item.name}
                  </label>
                  <div className={`spacer${index+1}`}></div>
                  </div>
                )
              })}
          </div>)}
        </div>
      </StyledActionButton>
    )
  }
}

export default ActionButton
