import { observable } from 'mobx'

const Call = observable({
  To: null,

  From: null,

  phone: null,
})

export default Call
