import React, { Component } from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Filter from '@material-ui/icons/FilterList'
import Tooltip from '@material-ui/core/Tooltip'

import uuid from 'uuid'
import objectPath from 'simple-object-path'

import '../../App/App.css'

const op = (obj, path) => (path === '/' ? obj : objectPath(obj, path))
const isFunction = fn => typeof fn === 'function'
export default class CoolTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.setInitialData()
  }

  setInitialData() {
    const { listColumns = [], tableConfig = {} } = this.props
    const newListColumns = listColumns.map(item => {
      item.id = item.id || uuid.v4()
      return item
    })
    const { filters, page: tablePage, rowsPerPage } = tableConfig
    this.setState({ listColumns: newListColumns, filters, tablePage, rowsPerPage })
  }

  componentDidUpdate(props) {
    if (this.props !== props) {
      this.setInitialData()
    }
  }

  changePage(...a) {
    console.log(a)
  }

  handleChangePage(event, tablePage) {
    const { onPageChange, tableId } = this.props
    if (isFunction(onPageChange)) {
      const { rowsPerPage = 5, filters = [] } = this.state
      onPageChange(tableId, tablePage, rowsPerPage, filters)
    }
    this.setState({ tablePage })
  }

  handleChangeRowsPerPage(event) {
    const { onPageChange, tableId } = this.props
    const rowsPerPage = event.target.value
    if (isFunction(onPageChange)) {
      const { tablePage, filters = [] } = this.state
      onPageChange(tableId, tablePage, rowsPerPage, filters)
    }
    this.setState({ rowsPerPage })
  }

  handleFilterValues(type, e) {
    const { filters = [], tablePage = 1, rowsPerPage = 5 } = this.state
    const { onFilterChange, tableId } = this.props
    const val = e.target.value
    const existing = filters.find(f => f.type.id === type.id)
    if (existing) {
      filters.splice(
        filters.findIndex(f => f.type.id === type.id),
        1
      )
    }
    filters.push({ type, val })
    if (isFunction(onFilterChange)) {
      onFilterChange(tableId, tablePage, rowsPerPage, filters)
    }
    this.setState({ filters, tablePage: 0 })
  }
  colValue(obj, col = {}, filter = false) {
    const { displayFn, filterVal } = col
    if (filter) {
      if (isFunction(filterVal)) {
        const item = op(obj, col.propPath)
        return filterVal(item)
      }
    }
    if (isFunction(displayFn)) {
      const item = op(obj, col.propPath)
      return filterVal(item)
    }
  }

  render() {
    const { tablePage = 0, rowsPerPage = this.props.rowsPerPage || 5, filters = [] } = this.state
    const { selectable, onSelect, list = [] } = this.props
    let { listColumns = [] } = this.state
    if (!listColumns.length) {
      listColumns = this.props.listColumns
    }
    const filtered = list.filter(p => {
      return filters.every(f => {
        const propPath = op(f, 'type/propPath')
        const filterVal = op(f, 'type/filterVal')
        const displayFn = op(f, 'type/displayFn')
        const defaultVal = op(f, 'type/default')
        let val = op(p, propPath) || defaultVal || ''
        if (isFunction(filterVal) || isFunction(displayFn)) {
          const fn = isFunction(filterVal) ? filterVal : displayFn
          val = fn(val)
        }
        if (typeof val !== 'string') {
          val = String(val).toString()
        }
        if (typeof f.val !== 'string') {
          f.val = String(f.val).toString()
        }
        if (val.toUpperCase().includes(f.val.toUpperCase())) return true
        return false
      })
    })
    const page = []
      .concat(filtered)
      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {listColumns.map((col, idx) => {
                const filter = col.filter === false ? false : true
                const filterValue = filters.find(f => f.type.id === col.id) || {}
                return (
                  <TableCell key={idx} colSpan={1}>
                    {col.filterToolTip && (
                      <div>
                        <Tooltip title={`Filter ${col.displayName}` || ''} placement="top">
                          <TextField
                            autoComplete="off"
                            value={filterValue.val || ''}
                            onChange={this.handleFilterValues.bind(this, col)}
                            label={<Filter />}
                            fullWidth={true}
                          />
                        </Tooltip>
                      </div>
                    )}
                    {filter && (
                      <TextField
                        autoComplete="off"
                        onChange={this.handleFilterValues.bind(this, col)}
                        value={filterValue.val || ''}
                        label={
                          <span>
                            <small>
                              <Filter /> {`${col.displayName}`}
                            </small>
                          </span>
                        }
                        fullWidth={true}
                      />
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {listColumns.map((col, idx) => {
                return <TableCell key={idx}>{col.displayName}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {page.map((item, idx) => {
              return (
                <TableRow
                  key={idx}
                  className={selectable ? 'table-selectable-hover' : ''}
                  onClick={selectable ? () => onSelect(item) : () => true}
                >
                  {listColumns.map((col, idx2) => {
                    return (
                      <TableCell key={idx2} style={col.style || {}}>
                        {col.displayFn
                          ? col.displayFn(op(item, col.propPath))
                          : op(item, col.propPath) || col.default || ''}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          count={filtered.length}
          page={tablePage}
          rowsPerPage={rowsPerPage}
          component="div"
          onChangePage={this.handleChangePage.bind(this)}
          onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
        />
      </div>
    )
  }
}
