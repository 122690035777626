import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import InputField from '../../../../common/components/InputField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import { StyledFormWrapper } from '../styles'

@inject(({ store: { fund } }) => {
  return {
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
  }
})
@observer
class FundDetailsForm extends Component {
  state = {}

  render() {
    const { currentFund, setCurrentFund } = this.props
    return (
      <StyledFormWrapper>
        <Grid container className="details-form">
          <Grid container direction="row" className="form-name">
            <Grid item xs={5}>
              {currentFund.mirroredFund ? (
                <InputField
                  readOnly={true}
                  type="text"
                  label="Name (required)"
                  customSize="50%"
                  initialValue={currentFund.name}
                />
              ) : (
                <InputField
                  type="text"
                  label="Name (required)"
                  customSize="50%"
                  handleOnChange={str => setCurrentFund({ name: str })}
                  isRequired
                  initialValue={currentFund.name}
                  maxLength="30"
                />
              )}
            </Grid>
          </Grid>
          <Grid container direction="row" className="form-description">
            <Grid item xs={5}>
              <InputField
                type="text"
                customSize="50%"
                label="Description"
                handleOnChange={str => setCurrentFund({ description: str })}
                initialValue={currentFund.description}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className="form-reserves">
            <Grid item xs={5}>
              <InputLabel> Cash Reserve Requirement</InputLabel>
              <Select
                value={currentFund.reservePercentage}
                onChange={event => setCurrentFund({ reservePercentage: event.target.value })}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0.01}>1%</MenuItem>
                <MenuItem value={0.02}>2%</MenuItem>
                <MenuItem value={0.03}>3%</MenuItem>
                <MenuItem value={0.04}>4%</MenuItem>
                <MenuItem value={0.05}>5%</MenuItem>
              </Select>
              <FormHelperText>Reserved cash for a given account.</FormHelperText>
            </Grid>
          </Grid>
          <Grid container direction="row" className="form-reserves">
            <Grid item xs={5}>
              <InputLabel>% of Account Reserved for Bob</InputLabel>
              <Select
                value={currentFund.accountAllocation}
                onChange={event => setCurrentFund({ accountAllocation: event.target.value })}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0.1}>10%</MenuItem>
                <MenuItem value={0.2}>20%</MenuItem>
                <MenuItem value={0.3}>30%</MenuItem>
                <MenuItem value={0.4}>40%</MenuItem>
                <MenuItem value={0.5}>50%</MenuItem>
                <MenuItem value={0.6}>60%</MenuItem>
                <MenuItem value={0.7}>70%</MenuItem>
                <MenuItem value={0.8}>80%</MenuItem>
                <MenuItem value={0.9}>90%</MenuItem>
                <MenuItem value={1.0}>100%</MenuItem>
              </Select>
              <FormHelperText>Percentage of the total account reserved for Bob.</FormHelperText>
            </Grid>
          </Grid>
          {/* <Grid container direction="row">
            <Grid item xs={5}>
              <InputLabel> Suggested Account Size</InputLabel>
              {currentFund.mirroredFund ? (
                <InputField
                  readOnly={true}
                  type="text"
                  customSize="70%"
                  initialValue={currentFund.suggestedAccountSize}
                />
              ) : (
                <Select
                  value={currentFund.suggestedAccountSize}
                  onChange={event => setCurrentFund({ suggestedAccountSize: event.target.value })}
                >
                  <MenuItem value="Any">
                    <em>ANY SIZE</em>
                  </MenuItem>
                  <MenuItem value="0-25k">$0 - $25k</MenuItem>
                  <MenuItem value="25-50k">$25k - $50k</MenuItem>
                  <MenuItem value="50k-100k">$50k - $100k</MenuItem>
                  <MenuItem value="100k-150k">$100k - $150k</MenuItem>
                  <MenuItem value="150k-200k">$150k - $200k</MenuItem>
                  <MenuItem value="200k+">$200k & ABOVE</MenuItem>
                </Select>
              )}
              <FormHelperText>
                Ideal cash allocation to maximize participating in smart fund.
              </FormHelperText>
            </Grid>
          </Grid> */}
          {/* <Grid container direction="row">
            <Grid item xs={5}>
              <div className="monitoring-section">
                <div className="section-header">
                  <MonitoringIconSvg />
                  <span>Enable monitoring for</span>
                </div>
                <div className="form-group checkbox-group">
                  <Checkbox
                    label="Email"
                    groupName="monitoring"
                    handleOnChange={val => setCurrentFund({ monitoring: { email: val } })}
                    initialChecked={currentFund.monitoring.email}
                  />
                  <Checkbox
                    label="Chart"
                    groupName="monitoring"
                    handleOnChange={val => setCurrentFund({ monitoring: { chart: val } })}
                    initialChecked={currentFund.monitoring.chart}
                  />
                  <Checkbox
                    label="Active"
                    groupName="monitoring"
                    handleOnChange={val => setCurrentFund({ monitoring: { api: val } })}
                    initialChecked={currentFund.monitoring.api}
                  />
                </div>
              </div>
            </Grid>
          </Grid> */}
        </Grid>
      </StyledFormWrapper>
    )
  }
}

export default FundDetailsForm
