import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Pick } from '../../../common/components/Dialogs'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import currencyFilter from '../../../lib/currency'
import Typography from '@material-ui/core/Typography'


import { StyledFundDetails } from './styles'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
    existingClients: fund.existingClients,
    qualifiedClients: fund.qualifiedClients,     
    isClientQualifiedForFund: user.isClientQualifiedForFund,
    selectedClient: user.clientStore.selectedClient,
  }
})
@observer
class ListClients extends Component {
  constructor(props) {
    super(props)
    this.state = { fund: {}, width: 4, filters: [] }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    let { currentFund } = this.props
    const { clients } = currentFund
    this.setState({ fund: currentFund, clients, loading: false })
  }

  handleChangePage(event, tablePage) {
    this.setState({ tablePage })
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleFilterValues(type, e) {
    const { filters = [] } = this.state
    const val = e.target.value
    filters.splice(
      filters.findIndex(f => f.type === type),
      1
    )
    filters.push({ type, val })
    this.setState({ filters, tablePage: 0 })
  }

  pickViewClient() {
    const { tablePage = 0, rowsPerPage = 4, filters, loading = false } = this.state
    const { currentFund : { clients }, existingClients, qualifiedClients } = this.props
    const newClients = clients.filter( client => !existingClients.find( ec => ec._id === client._id))
    const filtered = newClients.filter(p => {
      return filters.every(f => {
        if (
          f.type === 'name' &&
          !(
            p.firstName.toUpperCase().includes(f.val.toUpperCase()) ||
            p.lastName.toUpperCase().includes(f.val.toUpperCase())
          )
        ) {
          return false
        }
        if (f.type === 'accountNumber' && !p.UserId.toUpperCase().includes(f.val.toUpperCase())) {
          return false
        }
        return true
      })
    })
    const page = []
      .concat(filtered)
      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
    return (
      <div>
        {!loading && (
          <StyledFundDetails>
      <div>
        {!loading && (
          <div>
            <Typography className='new-accounts-message'>*** All accounts below will deploy new positions now or on the next trading day. ***</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>
                    <TextField
                      onChange={this.handleFilterValues.bind(this, 'name')}
                      label="Filter by Client"
                      fullWidth={true}
                    />
                  </TableCell>
                  <TableCell colSpan={1}>
                    <TextField
                      onChange={this.handleFilterValues.bind(this, 'accountNumber')}
                      label="Filter by Account"
                      fullWidth={true}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Account #</TableCell>
                  <TableCell>Bob Buying Power</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {page.length > 0 && page.map((c, idx) => {
                  const client = qualifiedClients.find( qc => qc._id === c._id)
                  const {
                    firstName = '',
                    lastName = '',
                    accountNum = '',
                    bobBuyingPower,
                  } = client
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                        {firstName} {lastName}
                      </TableCell>
                      <TableCell>{accountNum}</TableCell>
                      <TableCell style={{ color: 'green' }}>{currencyFilter(bobBuyingPower)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              count={newClients.length}
              page={tablePage}
              rowsPerPage={rowsPerPage}
              component="div"
              onChangePage={this.handleChangePage.bind(this)}
              onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
            />
          </div>
        )}
        {loading && (
          <div>
            <div className="row">
              <div className="col-md-12">Loading Clients</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <CircularProgress color="secondary" />
              </div>
            </div>
          </div>
        )}
      </div>
          </StyledFundDetails>
        )}
        {loading && (
          <div>
            <div className="row">
              <div className="col-md-12">Loading Client Info</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <CircularProgress color="secondary" />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6"></div>
          <div className="col-md-3"></div>
        </div>
        {this.pickViewClient()}
      </div>
    )
  }
}

export default class AddedNewClientModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  body() {
    return (
      <div>
        <ListClients />
      </div>
    )
  }
  render() {
    const { show = false, onClose, onCommit } = this.props
    return (
      <Pick
        show={show}
        onHide={onClose}
        onCommit={onCommit}
        title="Confirm New Clients"
        className="strategies-selector"
        body={this.body()}
      />
    )
  }
}
