import { action, observable } from 'mobx'

export default class InstructionStore {

  @observable instructions = []

    constructor(appStore) {
    this.rootStore = appStore.rootStore
    this.log = this.rootStore.log
  }

  @action('Marketplace Store | getAllInstructions')
  getAllInstructions = async id => {
    try {
      const { api } = this.rootStore
      const result = await api.get('/v0/instruction')
      const {
        data: { instructions = [] },
      } = result
      this.instructions = instructions
    } catch (e) {
      this.log.warn(`SmartFund Store | getUserSmartFunds ${e.stack}`)
    }
  }

}
