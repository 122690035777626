import React from 'react'
import { Link } from "@reach/router";

import PropTypes from 'prop-types'
import { StyledTab } from './styles'

const Tab = props => (
  <StyledTab>
    {!props.buttonClickMethod ? (
      <Link
        to={props.routePath}
        className={`tab-link ${props.isActive ? 'is-active-tab' : ''}`}
        onClick={props.onClick}
      >
        {props.icon ? <span className="tab-icon">{props.icon}</span> : null}
        {props.tabLabel}
      </Link>
    ) : (
      <button
        className={props.isActive ? 'is-active-tab tab-button' : 'tab-button'}
        onClick={() => props.buttonClickMethod()}
      >
        {props.tabLabel}
      </button>
    )}
  </StyledTab>
)

Tab.defaultProps = {
  routePath: '',
}

Tab.propTypes = {
  tabLabel: PropTypes.string.isRequired,
  routePath: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  icon: PropTypes.element,
  buttonClickMethod: PropTypes.func,
}

export default Tab
