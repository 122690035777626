import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component } from 'react'
import op from 'simple-object-path'
import { typography } from '../../../assets/styles'
import BaseTableColumn from '../../../common/components/BaseTableColumn'
import DashboardCard from '../../../common/components/DashboardCard'
import { Loading, Pick } from '../../../common/components/Dialogs'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import CoolTable from '../../../common/components/Table'
import currencyFilter from '../../../lib/currency'

@inject(({ store: { navigate, auth, paymentAdmin } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getPendingPayments: paymentAdmin.getPendingPayments,
    releasePayments: paymentAdmin.releasePayments,
  }
})
@observer
export default class PaymentAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {
    const { getPendingPayments, isAuthenticated } = this.props
    await isAuthenticated()
    this.setState({ loadingPayments: true })
    const pendingPayments = await getPendingPayments()
    this.setState({ pendingPayments, loadingPayments: false })
  }
  clickServiceCategory(serviceCategory) {
    const { navigate } = this.props
    navigate(`services/${serviceCategory._id}/tasks`)
  }

  getRunningInstances(serviceCategory) {
    return (serviceCategory.serviceTasks || []).filter(
      serviceTask => serviceTask.state === 'RUNNING'
    )
  }
  onSelectTask(taskRow) {
    console.log('taskRow', taskRow)
    // const { navigate } = this.props
    // navigate(`/admin/services/task/${taskRow._id}/instructions`)
  }

  closeReleasePayments() {
    this.setState({ showReleasePayment: false })
  }

  startReleasePayments(item) {
    this.setState({ paymentsForRelease: [item], showReleasePayment: true })
  }

  async releasePayments() {
    const { releasePayments } = this.props
    const { paymentsForRelease } = this.state
    this.setState({ showReleasePayment: false, showPaymentProcessing: true })
    const instructions = paymentsForRelease.map(p => p._id)
    await releasePayments({ instructions })
    this.setState({ showPaymentProcessing: false })
  }

  releaseAllPayments() {
    const { pendingPayments = [] } = this.state
    this.setState({ paymentsForRelease: pendingPayments, showReleasePayment: true })
  }

  render() {
    const {
      pendingPayments = [],
      loadingPayments,
      showReleasePayment = false,
      showPaymentProcessing = false,
    } = this.state
    const tableConfig = {
      rowsPerPage: 12,
    }
    const listColumns = [
      {
        id: 'd4ea42b5a',
        displayName: 'Status',
        propPath: '/',
        filter: true,
        sortProp: 'state',
        filterVal: item => item.state,
        displayFn: item => <BaseTableColumn>{item.state}</BaseTableColumn>,
      },
      {
        id: 'd4ea42b5b',
        displayName: 'Created',
        propPath: '/',
        filter: true,
        sortProp: 'timestamp',
        isDateSort: true,
        filterVal: item => moment(item.timestamp).format('L LTS'),
        displayFn: item => (
          <BaseTableColumn>{moment(item.timestamp).format('L LTS')}</BaseTableColumn>
        ),
      },
      {
        id: 'd4ea42b5c',
        displayName: 'User',
        propPath: '/',
        filter: true,
        sortProp: 'item/user/lastName',
        filterVal: item => `${op(item, 'user/lastName')} ${op(item, 'user/firstName')}`,
        displayFn: item => (
          <BaseTableColumn>
            {`${op(item, 'user/lastName')} ${op(item, 'user/firstName')}`}
          </BaseTableColumn>
        ),
      },
      {
        id: 'f7b7fc5e',
        displayName: 'Amount',
        propPath: '/',
        filter: true,
        sortable: false,
        filterVal: item => op(item, 'paymentRequest/amount'),
        displayFn: item => (
          <BaseTableColumn>{currencyFilter(op(item, 'paymentRequest/amount'))}</BaseTableColumn>
        ),
      },
      {
        id: 'f7b7fc5ey',
        displayName: 'Release Payment',
        propPath: '/',
        filter: false,
        sortable: false,
        filterVal: item => op(item, 'ecsInfo/ecsTaskId'),
        displayFn: item => (
          <BaseTableColumn>
            <Button onClick={() => this.startReleasePayments(item)}>Release</Button>
          </BaseTableColumn>
        ),
      },
    ]

    const tableOpts = {
      selectable: true,
      onSelect: this.onSelectTask.bind(this),
      listColumns,
      list: pendingPayments,
    }
    return (
      <React.Fragment>
        {loadingPayments && <LoadingIndicator loadingText="" />}
        {!loadingPayments && (
          <Container fixed style={{ fontFamily: typography.baseFontFamily }}>
            <span>Payments</span>
            <br />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              style={{ verticalAlign: 'top' }}
            >
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: typography.extraLargeFontSize,
                    fontWeight: typography.mediumFontWeight,
                  }}
                ></span>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Pending Payments</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {currencyFilter(
                                pendingPayments
                                  .map(p => op(p, 'paymentRequest/amount'))
                                  .reduce((a, b) => a + b, 0)
                              )}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <Grid item style={{ padding: 15 }}>
                            <span>Pending Accounts</span>
                          </Grid>
                          <Grid item>
                            <span
                              style={{
                                fontSize: typography.extraLargeFontSize,
                                fontWeight: typography.mediumFontWeight,
                              }}
                            >
                              {pendingPayments.length}
                            </span>
                          </Grid>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>
                  <Grid item xs={4}>
                    <DashboardCard
                      hasTitle={false}
                      title=""
                      moreTool={false}
                      style={{ height: '125px', textAlign: 'center' }}
                    >
                      <Grid container direction="column" style={{ display: 'table' }}>
                        <div
                          style={{ display: 'table-cell', verticalAlign: 'middle', height: 125 }}
                        >
                          <span
                            style={{
                              fontSize: typography.largeFontSize,
                              fontWeight: typography.mediumFontWeight,
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={this.releaseAllPayments.bind(this)}
                            >
                              Release All
                            </Button>
                          </span>
                        </div>
                      </Grid>
                    </DashboardCard>
                  </Grid>

                  <Grid item xs={12}>
                    <DashboardCard hasTitle={false} title="" moreTool={false}>
                      <div>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-start"
                          style={{ padding: 15 }}
                        >
                          <Grid item xs={12}></Grid>
                        </Grid>

                        <Box>
                          {!!tableOpts.list.length && (
                            <CoolTable
                              {...tableOpts}
                              tableConfig={tableConfig}
                              tableId="asset-manager"
                            />
                          )}
                          {!tableOpts.list.length && (
                            <Grid container direction="row" justify="center" spacing={2}>
                              <span
                                style={{
                                  fontSize: typography.mediumFontSize,
                                  fontWeight: typography.mediumFontWeight,
                                }}
                              >{`No pending payments`}</span>
                            </Grid>
                          )}
                        </Box>
                      </div>
                    </DashboardCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Pick
              show={showReleasePayment}
              onHide={this.closeReleasePayments.bind(this)}
              onCommit={() => this.releasePayments()}
              commitMsg="Yes, Release Payments"
            />
            <Loading show={showPaymentProcessing} />
          </Container>
        )}
      </React.Fragment>
    )
  }
}
