import React from 'react'
import { StyledPortfolioScore } from './styles'

const PortfolioScore = ({ capacity, preference, portfolioScore, smallScore }) => {
  return (
    <StyledPortfolioScore>
      <div>
        <div className={smallScore ? "header-portfolio-score-container" : "portfolio-score-container"}></div>
        <div className={smallScore ? "header-score-container" : "score-container"}></div>
        <div className={smallScore ? "header-score": "score"}>
          <p>{portfolioScore || capacity || preference}</p>
        </div>
      </div>
    </StyledPortfolioScore>
  )
}

export default PortfolioScore
