import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import { MoreHoriz } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import { typography } from '../../../assets/styles'

const StyledCard = withStyles(theme => ({
  root: {
    fontFamily: typography.baseFontFamily,
  },
}))(Card)

const StyledCardHeader = withStyles(theme => ({
  root: {
    paddingBottom: '0',
  },
  title: {
    fontSize: typography.smallBaseFontSize,
    fontWeight: typography.headingFontWeight,
  },
}))(CardHeader)

const StyledCardContent = withStyles(theme => ({
  root: {
    fontFamily: typography.baseFontFamily,
    paddingTop: '0',
    paddingBottom: '0',
  },
}))(CardContent)

export default class DashboardCard extends Component {
  render() {
    return (
      <StyledCard style={{ ...this.props.style, borderRadius: '0' }}>
        {this.props.hasTitle ? (
          <StyledCardHeader
            title={this.props.title}
            action={
              this.props.moreTool ? (
                <IconButton>
                  <MoreHoriz />
                </IconButton>
              ) : (
                ''
              )
            }
          />
        ) : null}
        <StyledCardContent>{this.props.children}</StyledCardContent>
      </StyledCard>
    )
  }
}
