import styled from 'styled-components'

export const StyledHeader = styled.header`
  display: flex;
  width: 100%;
  height: 4rem;
  background: white;
  padding: 0 2rem;
  justify-content: space-between;
  align-items: center;
  .flex-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(100% - 22rem);
    height: 100%;
  }
  .header-logo {
    display: inline-block;
    img {
      width: 3rem;
      @media screen and (min-width: 900px) {
        width: 5rem;
      }
    }
  }
  .portfolio-score-label {
    margin-top: 15px;
    margin-left: -14px;
    margin-right: 15px;
    height: 16px;
    width: 55px;
    color: #5E5E5E;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.86px;
    line-height: 16px;
  }
`

export const StyledNavigation = styled.nav`
  label: 'navigation';
  .nav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }
`
