import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { StyledHeader, StyledNavigation } from './styles'
import Logo from '../../../assets/img/bob-logomark-green-header.png'
import UserProfile from '../UserProfile'
import Tab from '../Tab'
import PortfolioScore from '../../../scenes/TotumRisk/component/portfolioScore'

@inject(({ store: { navigate, user, auth, totumRisk } }) => {
  return {
    navigate,
    setUser: user.setUser,
    currentUser: user.currentUser,
    isAuthenticated: auth.isAuthenticated,
    logout: auth.logout,
    setNavigationTabs: user.headerStore.setNavigationTabs,
    navigationTabs: user.headerStore.navigationTabs,
    getImpersonationData: user.headerStore.getImpersonationData,
    impersonationData: user.headerStore.impersonationData,
    initiateImpersonation: user.headerStore.initiateImpersonation,
    stopImpersonation: user.headerStore.stopImpersonation,
    capacity: totumRisk.capacity,
    preference: totumRisk.preference,
  }
})
@observer
class Header extends Component {
  state = {}

  async componentDidMount() {
    const { isAuthenticated, setNavigationTabs, getImpersonationData } = this.props
    await isAuthenticated()
    console.log(window.location.pathname)
    await setNavigationTabs(window.location.pathname)
    await getImpersonationData()
  }

  initiateImpersonation = async selectedUser => {
    const { initiateImpersonation } = this.props
    await initiateImpersonation(selectedUser)
  }

  stopImpersonation = async () => {
    const { stopImpersonation } = this.props
    await stopImpersonation()
  }

  logout = e => {
    const { logout } = this.props
    e.preventDefault()
    return logout()
  }

  setTabs = async path => {
    const { setNavigationTabs } = this.props
    await setNavigationTabs(path)
  }

  render() {
    const { currentUser, navigationTabs, impersonationData, capacity, preference } = this.props
    const { firstName, lastName, userType } = currentUser
    return (
      <StyledHeader>
        <a href="/" className="header-logo">
          <img src={Logo} alt="Home - Bob logo" />
        </a>
        <div className="flex-header">
          <StyledNavigation className="header-nav">
            {currentUser ? (
              <ul className="nav-list">
                {navigationTabs.map((tab, tabIndex) => (
                  <Tab
                    onClick={this.setTabs.bind(this, tab.routePath)}
                    key={tabIndex}
                    tabLabel={tab.tabLabel}
                    routePath={tab.routePath}
                    isActive={tab.isSelected}
                    icon={tab.icon}
                  />
                ))}
              </ul>
            ) : null}
          </StyledNavigation>
        </div>
        {userType === 'retail' && capacity && capacity.score > 0 && (
          <div style={{ display: 'flex' }}>
            <PortfolioScore smallScore={true} preference={preference.score} />
            <span className="portfolio-score-label">Preference</span>
            <PortfolioScore smallScore={true} capacity={capacity.score} />
            <span className="portfolio-score-label">Capacity</span>
          </div>
        )}
        <UserProfile
          firstName={firstName}
          lastName={lastName}
          user={currentUser}
          impersonationData={impersonationData}
          initiateImpersonation={usr => this.initiateImpersonation(usr)}
          stopImpersonation={() => this.stopImpersonation()}
          logout={e => this.logout(e)}
        />
      </StyledHeader>
    )
  }
}

Header.propTypes = {
  path: PropTypes.string.isRequired,
}

export default Header
