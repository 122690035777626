import React, { Component } from 'react'
import axios from 'axios'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme'
import Table from './Table'
import { Checkbox, Button, FormControlLabel } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import RestoreIcon from '@material-ui/icons/Restore'
import DeleteIcon from '@material-ui/icons/Delete'
import { Success } from '../../common/components/Dialogs'

import '../../App/App.css'
export default class Permissions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.listColumns = [
      {
        displayName: 'Permission Code',
        propPath: 'permissionCode',
        style: {
          width: '15%',
        },
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Permission Description',
        propPath: 'description',
        filterToolTip: true,
        filter: false,
      },
      {
        displayName: 'Edit',
        propPath: '/',
        filter: false,
        displayFn: item => (
          <Button onClick={this.setActivePermission.bind(this, item)}>Edit</Button>
        ),
      },
    ]
  }

  async componentDidMount() {
    await this.initPermissions()
  }

  async initPermissions() {
    let { data: permissions = [] } = await axios.get('/v0/permission')
    this.setState({ permissions })
  }

  updateState(newState, cb) {
    console.log('newState', newState)
    this.setState(
      () => newState,
      () => {
        if (cb) cb()
      }
    )
  }

  setActivePermission(activePermission) {
    console.log('activePermission', activePermission)
    this.setState({ activePermission, focus: true })
  }

  startNewPermission() {
    const activePermission = {
      permissionCode: '',
      description: '',
    }
    this.setState({ activePermission, focus: true })
  }

  updateActivePermission(e) {
    const isCheck = e.target.type === 'checkbox'
    const { activePermission } = this.state
    activePermission[e.target.name] = isCheck ? e.target.checked : e.target.value
    this.setState({ activePermission })
  }

  async savePermission() {
    const { activePermission } = this.state
    await axios.put('/v0/permission', activePermission)
    this.setState({ activePermission: undefined })
    await this.initPermissions()
  }

  async deletePermission() {
    const { activePermission } = this.state
    await axios.delete(`/v0/permission/${activePermission._id}`)
    this.setState({ activePermission: undefined, showDeleteSuccess: true })
    await this.initPermissions()
  }

  render() {
    const {
      permissions = [],
      activePermission,
      focus = false,
      showDeleteSuccess = false,
    } = this.state
    const { listColumns } = this
    const tableOpts = {
      listColumns,
      list: permissions,
    }
    if (focus) {
      setTimeout(() => this.setState({ focus: false }), 0)
    }
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <div className="row">
            <div className="col-md-12">
              <br />
              <Button className="pull-right" onClick={this.startNewPermission.bind(this)}>
                + Add new permission
              </Button>
              <hr />
              <br />
            </div>
          </div>
          {activePermission && (
            <div className={activePermission._id ? 'row edit-object' : 'row new-object'}>
              <div className="col-md-3">
                <TextField
                  label="Permission Code"
                  autoFocus={focus}
                  onChange={this.updateActivePermission.bind(this)}
                  name="permissionCode"
                  value={activePermission.permissionCode}
                  fullWidth={true}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Permission Description"
                  onChange={this.updateActivePermission.bind(this)}
                  name="description"
                  value={activePermission.description}
                  fullWidth={true}
                />
              </div>
              <div className="col-md-3">
                <FormControlLabel
                  label="Dev Publishing"
                  control={
                    <Checkbox
                      onChange={this.updateActivePermission.bind(this)}
                      name="devPublish"
                      checked={activePermission.devPublish}
                    />
                  }
                />
                <FormControlLabel
                  label="Trader Publishing"
                  control={
                    <Checkbox
                      onChange={this.updateActivePermission.bind(this)}
                      name="traderPublish"
                      checked={activePermission.traderPublish}
                    />
                  }
                />
              </div>
              <div className="col-md-2">
                <Button onClick={this.savePermission.bind(this)}>
                  <SaveIcon /> Save
                </Button>
                <Button onClick={() => this.setState({ activePermission: undefined })}>
                  <RestoreIcon /> Cancel
                </Button>
                {activePermission._id && (
                  <Button onClick={this.deletePermission.bind(this)}>
                    <DeleteIcon /> Delete
                  </Button>
                )}
              </div>
            </div>
          )}
          <Table {...tableOpts}></Table>
          <Success
            show={showDeleteSuccess}
            hideLegal={true}
            title="Permission removed"
            onHide={() => this.setState({ showDeleteSuccess: false })}
          />
        </div>
      </MuiThemeProvider>
    )
  }
}
