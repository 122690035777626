import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import LoadingIndicator from '../../../common/components/LoadingIndicator'
import searchIcon from '../../../assets/img/search.png'
import closeIcon from '../../../assets/img/plus-sign.png'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import StyledMarketPlaceList from './styles'
import currencyFilter from '../../../lib/currency'

@inject(({ store: { navigate, fund, model, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getModelById: fund.marketplaceStore.getModelById,
    getPublicSmartFunds: fund.marketplaceStore.getPublicSmartFunds,
    copyFundToUser: fund.marketplaceStore.copyFundToUser,
    fundsUserHasMirrored: fund.marketplaceStore.fundsUserHasMirrored,
    searchFilter: fund.marketplaceStore.searchFilter,
    getPublicModels: fund.marketplaceStore.getPublicModels,
    filters: fund.marketplaceStore.filters,
    filteredList: fund.marketplaceStore.filteredList,
    managerList: fund.marketplaceStore.managerList,
    assetTypes: fund.marketplaceStore.assetTypes,
    assetManagers: fund.marketplaceStore.assetManagers,
    getAssetManagers: fund.marketplaceStore.getAssetManagers,
    factSheets: fund.marketplaceStore.factSheets,
    setSelectedModel: fund.marketplaceStore.setSelectedModel,
    addModelToFund: fund.marketplaceStore.addModelToFund,
    modelsMirroredByFund: fund.marketplaceStore.modelsMirroredByFund,
    selectedModel: fund.marketplaceStore.selectedModel,
    filterByName: fund.marketplaceStore.filterByName,
    removeFromFilters: fund.marketplaceStore.removeFromFilters,
    currentModelBuyIn: fund.marketplaceStore.currentModelBuyIn,
    client: auth.profile.user,
    profile: auth.profile,
  }
})
@observer
class ModelList extends Component {
  state = {
    isLoading: true,
    inUserCollection: false,
  }

  async componentDidMount() {
    const {
      getAssetManagers,
      isAuthenticated,
      setSelectedModel,
      getPublicModels,
      modelsMirroredByFund,
    } = this.props
    await isAuthenticated()
    setSelectedModel(undefined)
    await getAssetManagers()
    await getPublicModels()
    await modelsMirroredByFund()

    this.setState({
      isLoading: false,
    })
  }

  getTypeColor(fundType) {
    let color
    switch (fundType) {
      case 'mixed':
        color = '#BE5BBF'
        break
      case 'etf':
        color = '#E58124'
        break
      default:
        color = '#000000'
    }
    return color
  }

  getAvatarColor(fundType) {
    let color
    switch (fundType) {
      case 'mixed':
        color = '#512D44'
        break
      case 'etf':
        color = '#ED5A36'
        break
      default:
        color = '#000000'
    }
    return color
  }

  closeModelDetail() {
    const { setSelectedModel } = this.props
    setSelectedModel(undefined)
  }

  isAlreadyMirrored(model) {
    const {
      currentFund: { strateies: models },
    } = this.prop
    const alreadyMirrored = models.find(m => String(m._id) === String(model._id))
    debugger
    if (alreadyMirrored && alreadyMirrored._id) return true
    return false
  }

  render() {
    const { isLoading } = this.state

    const {
      assetManagers,
      addModelToFund,
      selectedModel,
      assetTypes,
      filters,
      filteredList,
      searchFilter,
      setSelectedModel,
      filterByName,
      removeFromFilters,
      currentModelBuyIn
    } = this.props

    let masterList = filteredList ? filteredList.filter(l => !l.featureFund) : []
    const modelCount = masterList.length

    return (
      <StyledMarketPlaceList>
        <Box component="section" className="model-container">
          {isLoading ? (
            <LoadingIndicator loadingText="Loading the Marketplace" />
          ) : (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={2.5}>
                <Card className="filter-container">
                  <CardContent>
                    <p className="filter-label">Filter by</p>

                    <h5>ASSET TYPE</h5>
                    {assetTypes.map((assetType, index) => {
                      return (
                        <span className="filter-asset-manager-name" key={index}>
                          <span className="filter-link" onClick={filterByName}>
                            {assetType}
                          </span>
                          <br />
                        </span>
                      )
                    })}

                    <h5>MANAGER</h5>
                    {assetManagers.map((manager, index) => {
                      return (
                        <span className="filter-asset-manager-name" key={index}>
                          <span className="filter-link" onClick={filterByName}>
                            {manager.name}
                          </span>
                          <br />
                        </span>
                      )
                    })}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={5}>
                <div className="search-box">
                  <img className="search-box-icon" src={searchIcon} alt="Search Icon" />
                  <div className="search-box-divider"></div>
                  <input
                    className="search-box-content"
                    placeholder="Search by Fund Name, Asset Type, Model Name…"
                    onChange={searchFilter.bind(this)}
                  />
                </div>
                {filters.length > 0 && (
                  <Grid className="filter-box" container direction="row" spacing={3}>
                    <Grid>
                      <Typography className="filter-label" variant="caption" component="p">
                        Filters:
                      </Typography>
                    </Grid>
                    <Grid>
                      {filters.map(f => {
                        return (
                          <Chip
                            className="filer-chip-content"
                            label={f}
                            onDelete={removeFromFilters.bind(null, f)}
                          ></Chip>
                        )
                      })}
                    </Grid>
                  </Grid>
                )}
                <Typography className="number-smartfunds-message" variant="caption" component="p">
                  {`Showing all ${modelCount} Models`}
                </Typography>
                <Grid className="model-list-container">
                  {masterList.map((model, index) => {
                    return (
                      <Card
                        key={index}
                        className="fund-card"
                        model={model}
                        onClick={() => setSelectedModel(model)}
                      >
                        <CardContent>
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={1} className="fund-card-avatar">
                              <Avatar style={{ backgroundColor: this.getAvatarColor(model.type) }}>
                                {model.strategy
                                  .split(' ')
                                  .map(word => word.charAt(0))
                                  .join('')
                                  .toUpperCase()}
                              </Avatar>
                            </Grid>
                            <Grid item xs={9} className="fund-card-label">
                              <Typography variant="h5" component="h5">
                                {model.strategy}
                              </Typography>
                              <Typography className="asset-range" variant="caption" component="p">
                                {model.assetRange}
                              </Typography>
                              {model.assetManager && (
                                <Grid container direction="row" spacing={2}>
                                  <Grid item xs={13}></Grid>
                                  <Grid item className="fund-card-type">
                                    <Typography variant="caption" component="p">
                                      {model.assetManager.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={1} className="fund-card-type">
                              <Chip
                                className="type-chip"
                                style={{ backgroundColor: this.getTypeColor(model.type) }}
                                label={model.type.toUpperCase()}
                              />
                              {model.alreadyMirrored && (
                                <Typography className="asset-range" variant="caption" component="p">
                                  Mirrored
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  })}
                </Grid>
              </Grid>
              <Grid item xs={4} className="detail-wrapper">
                {selectedModel && (
                  <Card className={'fund-detail-card'}>
                    <Grid container direction="column" spacing={2}>
                      <Grid>
                        <img
                          className="detail-close-sign"
                          src={closeIcon}
                          alt="Close Icon"
                          onClick={this.closeModelDetail.bind(this)}
                        />
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        direction="column"
                        justify="center"
                        className="fund-detail-header"
                      >
                        <Avatar
                          style={{ backgroundColor: this.getAvatarColor(selectedModel.type) }}
                        >
                          {selectedModel.strategy
                            .split(' ')
                            .map(word => word.charAt(0))
                            .join('')
                            .toUpperCase()}
                        </Avatar>
                        <Typography
                          className="fund-detail-name"
                          align="center"
                          variant="h5"
                          component="h5"
                        >
                          {selectedModel.strategy}
                        </Typography>
                        {!selectedModel.alreadyMirrored ? (
                          <Button
                            variant="contained"
                            color="primary"
                            className="add-to-collection-button"
                            onClick={addModelToFund.bind(null)}
                          >
                            Add to Collection
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            className="added-to-collection-button"
                          >
                            Added!
                          </Button>
                        )}
                      </Grid>
                      <Grid item className="fund-detail-type">
                        <label className="detail-header">Type</label>
                        <br />
                        <Chip
                          style={{ backgroundColor: this.getTypeColor(selectedModel.type) }}
                          className="type-chip"
                          label={selectedModel.type.toUpperCase()}
                        />
                      </Grid>
                      <Grid item className="fund-detail-size">
                        <label className="detail-header">Model BuyIn Amount</label>
                        <Typography variant="body2" component="p">
                          {currencyFilter(currentModelBuyIn)}
                        </Typography>
                      </Grid>
                      <Grid item className="model-fee">
                        <label className="detail-header">Model Fee</label>
                        <Typography variant="body2" component="p">
                          {selectedModel.fee && Number(selectedModel.fee) > 0
                            ? selectedModel.fee * 100 + '%'
                            : 'FREE'
                          }
                        </Typography>
                      </Grid>
                      <Grid item className="model-detail-securities">
                        <label className="detail-header">Holdings</label>
                        <br />
                        {selectedModel.Tickers.map((security, key) => {
                          return (
                            <Chip
                              variant="outlined"
                              key={key}
                              className="security-chip"
                              label={security.symbol}
                            />
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </StyledMarketPlaceList>
    )
  }
}

export default ModelList
