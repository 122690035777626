import { action } from 'mobx'

export default class CallbackStore {
  constructor(app) {
    this.rootStore = app.rootStore
    this.user = this.rootStore.user
  }

  @action('Client Store | getModelById')
  getTdAmeritradeToken = async location => {
    try{
      const code = location.href.split('?')[1].split('=')[1]
      const { api } = this.rootStore
      const { currentUser, setUser } = this.user
      const result = await api.get(`/v0/getTdAmeritradeToken/${code}`)
      const { data } = result
      const { linkedBrokers, success, message } = data
      if(success){
        currentUser.linkedBrokers = linkedBrokers
        await setUser(currentUser)
      }else{
        throw new Error(message)
      }
    }catch(e){
       console.log(`${e}`)
    }
  }
}
