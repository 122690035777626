import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import ClientList from './ClientList'
import Allocation from './Allocation'
import ClientDetail from './ClientDetail'
import FreezeShares from './FreezeShares'
import ClientTable from './ClientTable'
import { Loading } from '../../../../common/components/Dialogs'
import axios from 'axios'

@inject(({ store: { navigate, user, fund, ui, auth } }) => {
  return {
    navigate,
    isAuthenticated: auth.isAuthenticated,
    getUserClients: user.getUserClients,
    setCurrentFund: fund.setCurrentFund,
    currentFund: fund.currentFund,
    showFreezeSharesModal: user.clientStore.showFreezeSharesModal,
    toggleFreezeSharesModal: user.clientStore.toggleFreezeSharesModal,
    showClientDetailModal: user.clientStore.showClientDetailModal,
    toggleClientDetailModal: user.clientStore.toggleClientDetailModal,
    saveFrozenShares: user.clientStore.saveFrozenShares
  }
})
@observer
class Client extends Component {
  state = {
    fund: {},
    strategies: [],
    clients: [],
    positions: [],
    showClientsModal: false,
    showUpdateAllocationModal: false,
  }

  componentDidMount() {
    const { fundData, strategies, clients } = this.props
    this.setState({
      fund: fundData,
      strategies,
      clients,
    })
  }

  componentDidUpdate(prevProps) {
    const { toggleClientsModal, toggleUpdateAllocationModal } = this.props
    if (prevProps.toggleClientsModal !== toggleClientsModal) {
      this.setState({ showClientsModal: toggleClientsModal })
    }

    if (prevProps.toggleUpdateAllocationModal !== toggleUpdateAllocationModal) {
      this.setState({ showUpdateAllocationModal: toggleUpdateAllocationModal })
    }
  }

  closeClientModal() {
    this.setState({ showClientsModal: false })
  }

  closeAllocationModal() {
    this.setState({ showUpdateAllocationModal: false })
  }

  confirmFreeze(fundClient, position, currentFrozenState = false, typeOfFreeze) {
    const message = `Are you sure you want to ${
      currentFrozenState ? 'unfreeze' : 'freeze'
    } this client?`
    this.setState({
      showFreezeConfirm: true,
      confirming: { fundClient, position, currentFrozenState, typeOfFreeze },
      confirmingMessage: message,
    })
  }

  async freezeToggle() {
    const { confirming = {} } = this.state
    const { fundClient, position, currentFrozenState = true, typeOfFreeze } = confirming
    const frozen = !currentFrozenState
    const active = !currentFrozenState
    if (position) {
      const instruction = {
        fundId: this.state.fund._id,
        userId: position._id,
      }
      if (typeOfFreeze === 'trades') {
        instruction.active = active
      }
      if (typeOfFreeze === 'buys') {
        instruction.frozen = frozen
      }
      await axios.put('/v0/fund/allocation', instruction)
    }
    if (typeOfFreeze === 'trades') {
      fundClient.active = active
    }
    if (typeOfFreeze === 'buys') {
      fundClient.frozen = frozen
    }
  }

  onPageChange(tableId, page, rowsPerPage = 10, filters) {
    const { tableConfigs = [] } = this.state
    const existing = tableConfigs.find(t => t.tableId === tableId)
    if (existing) {
      existing.page = page
      existing.filters = filters
    } else {
      tableConfigs.push({
        tableId,
        page,
        rowsPerPage,
        filters,
      })
    }
    this.setState({ tableConfigs })
  }

  onFilterChange(tableId, page, rowsPerPage = 10, filters) {
    const { tableConfigs = [] } = this.state
    const existing = tableConfigs.find(t => t.tableId === tableId)
    if (existing) {
      existing.page = page
      existing.filters = filters
    } else {
      tableConfigs.push({
        tableId,
        filters,
        rowsPerPage,
        page,
      })
    }
    console.log('tableConfigs', tableConfigs)
    this.setState({ tableConfigs })
  }

  render() {
    const { currentFund: fund, toggleFreezeSharesModal, showFreezeSharesModal, saveFrozenShares, toggleClientDetailModal, showClientDetailModal } = this.props
    const {
      showClientsModal = false,
      showUpdateModal = false,
      showUpdateAllocationModal = false,
      tableConfigs = [],
    } = this.state
    const { clients: fundClients = [] } = fund
    const clientIds = fundClients.filter(c => c.fundActive).map(c => c._id)
    const onPageChange = this.onPageChange.bind(this)
    const onFilterChange = this.onFilterChange.bind(this)
    return (
      <div>
        <ClientTable
          list={fundClients}
          tableConfigs={tableConfigs}
          onFilterChange={onFilterChange}
          onPageChange={onPageChange}
          toggleFreezeSharesModal={toggleFreezeSharesModal}
          toggleClientDetailModal={toggleClientDetailModal}
        />
        <Loading show={showUpdateModal} />
        <ClientList
          show={showClientsModal}
          onClose={this.closeClientModal.bind(this)}
          onCommit={this.closeClientModal.bind(this)}
          selectedClients={clientIds}
        />
        <Allocation
          fund={fund}
          show={showUpdateAllocationModal}
          onClose={this.closeAllocationModal.bind(this)}
          onCommit={this.closeAllocationModal.bind(this)}
        />
        <FreezeShares
          fund={fund}
          show={showFreezeSharesModal}
          onClose={toggleFreezeSharesModal.bind(this)}
          onCommit={saveFrozenShares.bind(this)}
        />
        <ClientDetail
          fund={fund}
          show={showClientDetailModal}
          onClose={toggleClientDetailModal.bind(this)}
          onCommit={toggleClientDetailModal.bind(this)}
        />
      </div>
    )
  }
}

Client.propTypes = {
  fundData: PropTypes.shape({}).isRequired,
  strategies: PropTypes.arrayOf(PropTypes.shape({})),
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  toggleClientsModal: PropTypes.bool,
}

export default Client
