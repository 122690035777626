import styled from 'styled-components'
import { colors, typography, patterns } from '../../../../assets/styles'

const StyledDonutChart = styled.div`
  position: relative;
  .donut-chart-track {
    fill: transparent;
    stroke: #e3e3e3;
    stroke-width: 26;
  }
  .donut-chart-indicator {
    fill: transparent;
    stroke: ${colors.successGreen};
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray 0.3s ease;
  }

  .donut-chart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }

  .donut-chart-text-val {
    font-size: 22px;
    fill: ${colors.successGreen};
  }
  .donut-chart-text-percent {
    font-size: 22px;
    fill: ${colors.successGreen};
  }
  .donut-chart-text-label {
    font-size: 12px;
    fill: ${colors.labelGrey};
  }

  // Editable Chart Styles
  .donut-form-wrapper {
    position: absolute;
    top: 50px;
    left: 50px;
    &.is-error {
      .donut-form-control {
        color: ${colors.alertRed};
        border-bottom-color: ${colors.alertRed};
      }
    }
  }
  .donut-form-label {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: ${typography.extraSmallFontSize};
    color: ${colors.borderGrey};
  }

  .donut-form-control {
    width: 48px;
    height: 28px;
    font-family: inherit;
    font-size: ${typography.extraLargeFontSize};
    font-weight: ${typography.headingFontWeight};
    color: ${colors.successGreen};
    text-align: center;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: textfield;
    border: 0;
    border-bottom: 1px solid ${colors.labelGrey};
    background: none;
    &:focus {
      outline: 0;
    }
  }
  .donut-form-chart-label {
    ${patterns.label};
    display: block;
    margin-top: 0.375rem;
  }
  .form-error {
    margin-top: 1rem;
    color: ${colors.alertRed};
    font-size: ${typography.smallBaseFontSize};
    text-align: center;
  }
`

export default StyledDonutChart
