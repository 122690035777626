import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import InputField from '../../../common/components/InputField'
import RadioButtons from '../../../common/components/RadioButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import totumRiskLogoScore from '../../../assets/img/Totum Logo_Dark_X2.png'
import FilledButton from '../component/filledButton'
import NoFilledButton from '../component/noFilledButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { StyledFormWrapper } from '../styles'

@inject(({ store: { totumRisk } }) => {
  return {
    portfolioScore: totumRisk.portfolioScore,
    setSelectedTab: totumRisk.setSelectedTab,
    radioButtonOptions: totumRisk.radioButtonOptions,
    setRiskForm: totumRisk.setRiskForm,
    riskForm: totumRisk.riskForm,
    saveRiskForm: totumRisk.saveRiskForm,
    errors: totumRisk.errors,
  }
})
@observer
class StepOne extends Component {
  render() {
    const {
      setSelectedTab,
      radioButtonOptions,
      setRiskForm,
      riskForm,
      saveRiskForm,
      errors,
    } = this.props
    return (
      <StyledFormWrapper>
        <Box component="section" className="questionnaire-info">
          <Grid container direction="row" spacing={10}>
            <Grid item>
              <Typography className="page-header">Risk Score Questionnaire</Typography>
            </Grid>
            {/* <Grid item className="totum-info-container">
              <Grid container spacing={1} direction="column">
                <Grid item className="totum-logo-label">
                  {' '}
                  Powered By
                </Grid>
                <Grid item>
                  <img className="totum-logo" src={totumRiskLogo} alt="TotumRisk Logo" />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Typography className="step-title">Personal Information</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Card>
                <CardContent className="info-card">
                  <Grid item>
                    <Typography className="card-header-info">
                      {' '}
                      Just some basic information to get to know you a little better.
                    </Typography>
                  </Grid>
                  <Grid container direction="row">
                    <Grid direction="column" className="step-two-col-one-questions">
                      <Grid item>
                        <Typography>Name</Typography>
                        <InputField
                          type="text"
                          label="ex. John Doe"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ name: str })}
                          isRequired
                          initialValue={riskForm.name}
                          hasError={errors.find(e => e === 'name')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Age</Typography>
                        <InputField
                          type="number"
                          label="ex. 35"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ age: str })}
                          isRequired
                          initialValue={riskForm.age}
                          hasError={errors.find(e => e === 'age')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Your Zip Code</Typography>
                        <InputField
                          type="number"
                          label="ex. 35033"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ zip: str })}
                          isRequired
                          initialValue={riskForm.zip}
                          hasError={errors.find(e => e === 'zip')}
                        />
                      </Grid>
                    </Grid>
                    <Grid direction="column" className="step-two-col-two-questions">
                      <Grid item>
                        <Typography># of People In Your Household </Typography>
                        <InputField
                          type="number"
                          label="ex. 4"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ bodyCount: str })}
                          isRequired
                          initialValue={riskForm.bodyCount}
                          hasError={errors.find(e => e === 'bodyCount')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography># of Dependents</Typography>
                        <InputField
                          type="number"
                          label="ex. 2"
                          customSize="80%"
                          handleOnChange={str => setRiskForm({ bodyCountDependents: str })}
                          isRequired
                          initialValue={riskForm.bodyCountDependents}
                          hasError={errors.find(e => e === 'bodyCountDependents')}
                        />
                      </Grid>
                      <Grid item className="step-two-field-spacer">
                        <Typography>Do you own the home at that zip?</Typography>
                        {errors.find(e => e === 'zipOwner') && (
                          <p className="field-error">This field is required.</p>
                        )}
                        <Select
                          style={{ 'margin-top': '15px' }}
                          value={riskForm.zipOwner}
                          onChange={event => setRiskForm({ zipOwner: event.target.value })}
                        >
                          <MenuItem value={0}>
                            <em>
                              <small>Please Select</small>
                            </em>
                          </MenuItem>
                          <MenuItem value={true}>{`Yes`}</MenuItem>
                          <MenuItem value={false}>{`No`}</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className="step-two-field-spacer">
                    <Typography>Household Health</Typography>
                    {errors.find(e => e === 'health') && (
                      <p className="field-error">This field is required.</p>
                    )}
                    <Grid style={{ 'margin-top': '15px' }}>
                      <RadioButtons
                        className="risk-score-radio-buttons"
                        options={radioButtonOptions}
                        onchangeHandler={setRiskForm.bind(this)}
                        defaultValue={riskForm.health}
                        currentValue={riskForm.health}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={10}>
                    <Grid item className="step-one-save-button">
                      <NoFilledButton title={`Save`} action={saveRiskForm.bind(this)} />
                    </Grid>
                    <Grid item className="step-one-next-button">
                      <FilledButton title={`Next`} action={setSelectedTab.bind(this, 1)} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item direction="row">
              <Card>
                <CardContent className="score-card">
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className="risk-score-message">Powered By</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '55px' }}>
                      <img src={totumRiskLogoScore} alt="TotumRisk Logo" />
                    </Grid>
                    <Grid item>
                      <Typography className="risk-score-message">
                        Totum Risk scores the Risk Preference to show the investor how much risk
                        they are willing to take at this moment in time and scores the Risk Capacity
                        to show the investor how much risk they should or should not be taking based
                        on their life situation. The premise of the scores is to make sure the
                        investor’s portfolio falls between the risk preference and risk capacity
                        score, but closer to the risk capacity score since the risk capacity score
                        shows us the investors ability to take risk.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </StyledFormWrapper>
    )
  }
}

export default StepOne
