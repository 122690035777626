import styled from 'styled-components'
import { typography, patterns, colors } from '../../../../assets/styles'
import chevronDownIcon from '../../../../assets/icons/chevron-down-icon.svg'

const StyledUserProfile = styled.div`
  position: relative;
  .user-profile-dropdown-button {
    outline: none;
    ${patterns.buttonDefault};
    background: transparent;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    line-height: unset;
    font-size: ${typography.smallBaseFontSize};
    font-weight: ${typography.mediumFontWeight};
    .avatar-circle {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 0.75rem;
      border-radius: 1000rem;
      background: ${colors.bobBlue};
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1000rem;
      }
      .user-initials {
        font-size: ${typography.baseFontSize};
        color: white;
        line-height: 2.7;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 1.125rem;
      right: 0;
      width: 9px;
      height: 7px;
      background-image: url(${chevronDownIcon});
      background-repeat: no-repeat;
    }
    .user-name {
      @media screen and (min-width: 320px) and (max-width: 800px) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      text-align: right;
      .impersonating-tag {
        font-size: 9px;
        color: ${colors.bobOrange};
      }
    }
  }
`

export default StyledUserProfile
