import styled from 'styled-components'

const StyledActionButtonCard = styled.div`
  .new-bob-button {
    margin-left: 13em;
    margin-top: 1em;
    z-index: 100;
    position: absolute;
  }

  .button-content-container {
    columns: 3;
  }
  .up_arrow_one {
    width: 3px;
    height: 8px;
    transform: rotate(-315deg);
    background-color: #ffffff;
    position: relative;
    left: 6.8em;
    top: 0.5em;
  }

  .up_arrow_two {
    width: 3px;
    height: 8px;
    transform: rotate(315deg);
    background-color: #ffffff;
    position: relative;
    left: 6.5em;
  }

  .down_arrow_one {
    width: 3px;
    height: 8px;
    transform: rotate(-315deg);
    background-color: #ffffff;
    position: relative;
    left: 6.5em;
    top: 0.5em;
  }

  .down_arrow_two {
    width: 3px;
    height: 8px;
    transform: rotate(315deg);
    background-color: #ffffff;
    position: relative;
    left: 6.8em;
  }

  .action_button {
    width: 200px;
    height: 40px;
    border-radius: 4px;
    background-color: #2cb7df;
  }

  .content {
    height: 22px;
    color: #ffffff;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.14px;
    line-height: 22px;
  }

  .selection-card {
    height: 161px;
    width: 200px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .spacer1 {
    height: 1px;
    width: 200px;
    background-color: #d8d8d8;
    position: absolute;
    margin: 52px 0 108px 0;
  }

  .spacer2 {
    height: 1px;
    width: 200px;
    background-color: #d8d8d8;
    position: absolute;
    margin: 108px 0 52px 0;
  }

  .item1 {
    height: 22px;
    color: #333333;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.14px;
    line-height: 22px;
    margin: 15px 12px 12px 16px;
    position: absolute;
  }

  .item2 {
    height: 22px;
    width: 115px;
    color: #333333;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.14px;
    line-height: 22px;
    margin: 71px 69px 68px 16px;
    position: absolute;
  }

  .item3 {
    height: 22px;
    width: 115px;
    color: #333333;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.14px;
    line-height: 22px;
    margin: 124px 69px 15px 16px;
    position: absolute;
  }
`
export default StyledActionButtonCard
