import styled from 'styled-components'
import { patterns, typography } from '../../../../assets/styles'
import chevronDownIcon from '../../../../assets/icons/chevron-down-icon.svg'

export const StyledCurrentPerformance = styled.div`
  .portfolio-stat-bar {
    display: flex;
  }
  .strategies-dropdown {
    position: relative;
  }
  .strategies-dropdown-button {
    ${patterns.dropdownButtonWithBg};
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 2.3rem;
    font-size: ${typography.mediumFontSize};
    font-weight: ${typography.baseFontWeight};
    max-height: 46px;
    background: none;
    &:after {
      content: '';
      position: absolute;
      top: 8px;
      right: 16px;
      width: 9px;
      height: 7px;
      background-image: url(${chevronDownIcon});
      background-repeat: no-repeat;
    }
  }
  .strategies-dropdown-menu {
    ${patterns.dropdownMenuBackground};
    top: 2rem;
    left: 0;
    min-width: 260px;
    margin-bottom: 0;
    text-align: left;
  }
  .strategy-dropdown-item {
    ${patterns.buttonDefault};
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    text-align: left;
    transition: all 200ms linear;
    &:hover,
    &:focus {
      background: #f3f3f3;
    }
  }
`

export const StyledCard = styled.article`
  ${patterns.defaultCard};
  height: 100%;
  .card-header {
    padding: 25px 20px 0 20px;
  }
  .card-header-label {
    ${patterns.label};
    margin: 0;
  }
  .card-header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-title {
    font-size: ${typography.largeFontSize};
    margin: 0;
  }
`
